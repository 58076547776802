import styled from 'styled-components';

export const Heading = styled.h3`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.1rem;
  font-family: 'Open Sans', sans-serif;
  margin-top: 0;
  margin-bottom: ${(props) => (props.noMarginBottom ? 0 : '1rem')};
  color: ${({ color }) => (color ? `var(--${color})` : 'var(--blue)')};
`;

export const HeadingLink = styled.a`
  font-weight: 300;
  display: flex;
  align-items: center;
  font-size: 15px;
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 0.4px;
  line-height: 1.538461538;
  text-transform: uppercase;
  color: #8898aa;
  margin-bottom: 20px;
  .logo {
    width: 50px;
    height: 50px;
    margin-right: 2px;
  }
`;

export const Icon = styled.div`> 
  width: 13px;
  height: 13px;
  margin-right: 13px;
  background-color: #6772e5;
  opacity: 0.8;
  display: inline-block;
`;

export const DropdownSection = styled.div`
  padding: 28px;
  position: relative;
`;

export const DropdownBody = styled.div`
  width: 100%;
  height: 100%;
`;

export const DropdownMenuLayout = styled.div`
   display: grid;
    grid-template-columns:repeat(2,auto);
    grid-template-rows:repeat(2,auto);
    gap: 1rem;  

   
}`;

export const ProductList = styled.section`
  display: flex;
  flex-direction: column;

  span {
    font-size: 15px;
    font-weight: 425;
    color: #256dfe;
  }
`;

export const Logo = styled.span`
  width: 200px;
  width: 200px;
`;

export const ProductListTitle = styled.a`
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 15px;
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 0.4px;
  line-height: 1.538461538;
  text-transform: uppercase;
  color: #8898aa;
  display: flex;
  align-items: center;

  .logo {
    width: 50px;
    height: 50px;
    margin-right: 2px;
  }
`;
export const ProductLists = styled.ul`
  min-width: 100px;
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const ProductListItem = styled.li`
  font-weight: 300;
  font-size: 15px;
  letter-spacing: 0.2px;
  color: #256dfe;
`;

export const ProductListItemLink = styled.a`
  display: -webkit-inline-box;
  display: inline-flex;
  color: #256dfe;
  font-size: 16px;
  outline: none;
  max-width: 60%;

  &:hover {
    color: #0a2540;
  }
`;

export const ItemLink = styled.a`
  color: #0a2540;
  font-size: 15px;
  text-transform: none;
  cursor: pointer;

  & :hover {
    color: #87a2ff;
  }
`;
