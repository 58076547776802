import React, { useEffect } from 'react';

////components
import CommonButton from '../../components/buttons/Button';
import Navbar from '../../components/Products/nav/Navbar';

/////assets
import dash from '../../assets/bbfcc.jpg';
import Module from '../../assets/customer-logo/module.svg';
import Automatic from '../../assets/customer-logo/automic.svg';
import Financial from '../../assets/customer-logo/financial-growth-analysis.svg';
import Learning from '../../assets/customer-logo/learning.svg';
import Thinking from '../../assets/customer-logo/critical-thinking.svg';
import { ReactComponent as Model } from '../../assets/customer-logo/model.svg';
import { ReactComponent as Numbers } from '../../assets/customer-logo/numbers.svg';
import { ReactComponent as Chart } from '../../assets/customer-logo/chart.svg';
import { ReactComponent as DropShipping } from '../../assets/customer-logo/drop-shipping.svg';
import { ReactComponent as HandTouch } from '../../assets/customer-logo/hand-touch.svg';
import { ReactComponent as Cursor } from '../../assets/customer-logo/cursor.svg';
import { ReactComponent as Puzzle } from '../../assets/customer-logo/puzzle.svg';
import { ReactComponent as SLCamera } from '../../assets/customer-logo/slr-camera.svg';
import { ReactComponent as TitanMachineLogo } from '../../assets/customer-logo/titanmachine.svg';
import LittleGym from '../../assets/customer-logo/littleGym.svg';
import { ReactComponent as Wood } from '../../assets/customer-logo/wood.svg';
import { ReactComponent as Workout } from '../../assets/customer-logo/workout.svg';
import TitanLogo from '../../assets/customer-logo/titanLogo.svg';

//sphere images
import sphereNormal from '../../assets/sphere-normal.png';
import sphereExpand from '../../assets/sphere-expand.png';
import sphereThird from '../../assets/sphere-third.png';

import {
  GlobalContent,
  Main,
  CommonTitle,
  HeroSection,
  HighLight,
  CommonButtonGroup,
  SectionIntro,
  SectionLayoutColumns,
  LogoItem,
  LogoList
} from './style';

import BackGround from '../../components/Products/background/BackGround';
import LayoutColumn from '../../components/Products/layoutColumn/LayoutColumn';
import Card from '../../components/Products/card/Card';
import Button from '../../components/buttons/Button';
import Footer from '../../components/footer/Footer';

const Hero = () => {
  return (
    <HeroSection className='container-lg '>
      <BackGround />
      <CommonTitle className='productTitle'>
        Smartest way to optimize your media placements in{' '}
        <HighLight>the palm of your hand</HighLight>
      </CommonTitle>
      <CommonButtonGroup>
        <li>
          <CommonButton
            bg='#765DE2'
            color='#fff'
            target="_blank"
            href='/login.jsp'>
            Sign in to Titan
          </CommonButton>
        </li>
        <li>
          <CommonButton color='#000' href={'/contact-us'}>
            learn more
          </CommonButton>
        </li>
      </CommonButtonGroup>
    </HeroSection>
  );
};

const SectionLogo = () => {
  return (
    <LogoItem className='container-lg'>
      <LogoList>
        <img src={LittleGym} alt='little gym logo' />
        <Wood className='sub' />
        <Workout className='sub' />
      </LogoList>
    </LogoItem>
  );
};

const Intro = () => {
  return (
    <SectionIntro>
      <SectionLayoutColumns className='layoutColumns--3'>
        <LayoutColumn
          icon={Module}
          title='Use Algorithms that actually work'
          text='Titan helps optimize media and for any type of business using machine learning that trains on data across millions of global data points. Artificial Intelligence is built into Titan and requires no additional setup to get started.'
        />
        <LayoutColumn
          icon={Automatic}
          title='Stop manual budget shifting'
          text='Old ways of manual budget shifting were never designed for modern internet businesses and can lead to lost revenue. Titan can help you distinguish and divert budget to tactics that work and starve attempts that don’t.'
        />
        <LayoutColumn
          icon={Financial}
          title='Get the engine that adapts to you'
          text='Our machine learning infrastructure lets us retrain thousands of models—including ones customized for your business—every day. Titan’s algorithms adapt quickly to shifting patterns and to your unique business.'
        />
      </SectionLayoutColumns>
    </SectionIntro>
  );
};

const Cards = () => {
  return (
    <section className='globalFooterCards mtop'>
      <div className='container-xl'>
        <Card
          icon={Learning}
          titlecolor='#765DE2'
          title='SMARTER OPTIMIZATION WITH MACHINE LEARNING '
          text='Effective systems should identify opportunities without sacrificing reach. Our models on how machine learning can produce predictive models of media budgeting from large sets of data are state of the art.'
        />
        <Card
          icon={Thinking}
          titlecolor='#256DFE'
          title='FRICTIONLESS AUTHENTICATION WITH ARTIFICIAL INTELLIGENCE'
          text='Artificial Intelligence can help separate strategies that work from manual ideas that don’t. Our algorithms work on the newest version of AI which can reduce optimization time without compromising on user experience.'
        />
      </div>
    </section>
  );
};
const SectionTech = () => {
  return (
    <section className='tech'>
      <div className='container-lg medium-columns'>
        <aside className='animation sticky'>
          <div
            className='shadow'
            style={{
              transitionDuration: '750ms',
              transitionTimingFunction: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
              opacity: 1
            }}></div>
        </aside>
        <div className='copy'>
          <div className='section-1'>
            <img src={sphereNormal} alt='' width='100%' />
            <h2 className='commonSectionTitle'>
              Trained with billions of data points
            </h2>
            <p className='commonIntroText'>
              Eulerity’s Titan processes data points from hundreds of thousands
              of campaign for every industry, company size, and business model.
              Even if a campaign is new to your business, there’s more than 90%
              chance it’s been seen before on our network.
            </p>
            <ul className='feature-list small-row'>
              <li className='feature-block small-icon-top md-icon-left'>
                <figure className='icon'>
                  <Numbers />
                </figure>
                <h3 className='commonBodyTitle'>Strength in numbers</h3>
                <p className='commonBodyText'>
                  By learning from millions of global businesses processing
                  millions in data points each year, Titan can assign
                  algorithmic scores to every media transaction and
                  automatically block any non performing media.
                </p>
              </li>
              <li className='feature-block small-icon-top md-icon-left'>
                <figure className='icon '>
                  <Model />
                </figure>
                <h3 className='commonBodyTitle'>
                  Better ML outcomes with large scale data
                </h3>
                <p className='commonBodyText'>
                  Titan scans every media purchase using thousands of signals
                  from across the Eulerity network to help detect and optimize
                  media strategies—even before it hits your business.
                </p>
              </li>
            </ul>
          </div>
          <div className='section-2'>
            <img src={sphereExpand} alt='' width='100%' />
            <h2 className='commonSectionTitle'>
              We don’t grade our own homework
            </h2>
            <p className='commonIntroText'>
              Better accuracy with third-party tools. Titan ingests data from
              every layer of your marketing stack and integrates data from
              attribution softwares, POS data to paint a complete picture of
              what is the true return of ad spend for your marketing efforts.
            </p>
            <ul className='feature-list small-row'>
              <li className='feature-block small-icon-top md-icon-left'>
                <figure className='icon '>
                  <Chart />
                </figure>
                <h3 className='commonBodyTitle'>
                  Data from Attribution Software
                </h3>
                <p className='commonBodyText'>
                  Eulerity Titan’s integrations with Google Analytics and other
                  website tracking software let us use real sales data to help
                  isolate signals to train our ML models.
                </p>
              </li>
              <li className='feature-block small-icon-top md-icon-left'>
                <figure className='icon '>
                  <DropShipping />
                </figure>
                <h3 className='commonBodyTitle'>
                  Data from POS and delivery services
                </h3>
                <p className='commonBodyText'>
                  Titan is full integrated into helping any industry type by
                  directly linking data from POS (
                  <a href='https://square.com'>Square</a>) and delivery systems
                  (like <a href='https://www.seamless.com/'>Seamless</a>) to
                  enable maximum coverage for our intelligence engine.
                </p>
              </li>
            </ul>
          </div>
          <div className='section-3'>
            <img src={sphereThird} alt='' width='100%' />
            <h2 className='commonSectionTitle'>
              Sophisticated signals engineered for impact
            </h2>
            <p className='commonIntroText'>
              Titan is built for global internet businesses facing evolving
              media optimization needs. We constantly tweak our algorithms, test
              which attributes are most relevant, and generate compound signals
              to help precisely identify and deliver the best return on ad
              spend.
            </p>
            <ul className='feature-list small-row'>
              <li className='feature-block small-icon-top md-icon-left'>
                <figure className='icon '>
                  <HandTouch />
                </figure>
                <h3 className='commonBodyTitle'>Media fingerprints</h3>
                <p className='commonBodyText'>
                  Tie multiple signals from different channels into a single
                  campaign profile.
                </p>
              </li>
              <li className='feature-block small-icon-top md-icon-left'>
                <figure className='icon '>
                  <SLCamera />
                </figure>
                <h3 className='commonBodyTitle'>Historical snapshots</h3>
                <p className='commonBodyText'>
                  Spot recurring patterns across months of historical data.
                </p>
              </li>
              <li className='feature-block small-icon-top md-icon-left'>
                <figure className='icon '>
                  <Cursor />
                </figure>
                <h3 className='commonBodyTitle'>Outlier detection</h3>
                <p className='commonBodyText'>
                  Identify trends easily and spot outliers very quickly.
                </p>
              </li>
              <li className='feature-block small-icon-top md-icon-left'>
                <figure className='icon '>
                  <Puzzle />
                </figure>
                <h3 className='commonBodyTitle'>Thousands more</h3>
                <p className='commonBodyText'>
                  Titan scores every media execution by combining multiple
                  signals to help determine whether strategies are successful or
                  not.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

const Pricing = () => {
  return (
    <section className='pricing container-lg simple'>
      <h2 className='commonSectionTitle'>Simple, always included.</h2>
      <div className='tiers'>
        <span className='helm-icon titanMachine'>
          <TitanMachineLogo />
        </span>
        <div className='description'>
          <h4 className='commonBodyTitle'>Titan’s machine learning</h4>
          <p className='commonBodyText'>
            Optimization engine powered by millions of data points across the
            Eulerity Network to help you get the most out of your media.
          </p>
        </div>
        <div className='price'>
          <h3 className='commonIntroText'>$0 per campaign</h3>
          <div className='price-note'>
            Always included with your standard subscription to optimize your
            campaigns.
          </div>
        </div>
      </div>
    </section>
  );
};

const Teams = () => {
  return (
    <section className='for-teams'>
      <div className='background'></div>
      <div className='container-xl'>
        <aside className='Commoncards'>
          <header className='small-columns'>
            <div className='copy'>
              <h1 className='commonSectionTitle'>Titan for Marketers</h1>
              <p className='commonIntroText'>
                Powerful tools to support marketing professionals.
              </p>
            </div>
            <Button bg='#765DE2' color='#fff' href={'/contact-us'}>
              Explore
            </Button>
          </header>
          <div className='medium-columns'>
            <ul className='features'>
              <li>
                <h3 className='commonBodyTitle'>
                  <i className='fas fa-check-circle'></i> Optimized performance
                </h3>
                <p className='commonBodyText'>
                  Surface the most relevant data for more efficient and accurate
                  performance reviews.
                </p>
              </li>
              <li>
                <h3 className='commonBodyTitle'>
                  <i className='fas fa-check-circle'></i> Robust insights
                </h3>
                <p className='commonBodyText'>
                  See rich analytics on performance and conversion rates.
                </p>
              </li>
              <li>
                <h3 className='commonBodyTitle'>
                  <i className='fas fa-check-circle'></i> Custom rules
                </h3>
                <p className='commonBodyText'>
                  Set precise rules to flag, block, or dynamically apply rules
                  only to certain campaigns.
                </p>
              </li>
            </ul>
            <a className='screenshot' href='/login.jsp'>
              <div className='image-container'>
                <img src={dash} alt='dashboard' />
              </div>
            </a>
          </div>
        </aside>
      </div>
    </section>
  );
};

const FooterSection = () => {
  return (
    <footer className='globalFooter'>
      <article className='helm-sectionCTA'>
        <div className='helm-footerCta_container container-lg'>
          <div className='helm-footerCta__copy'>
            <h1 className='Helm-FooterCTA__title '>
              Titan is Built into Eulerity
            </h1>
            <span className='subtitle'>
              Titan’s advanced machine learning is available to every Eulerity
              account.
            </span>
          </div>
          <CommonButtonGroup>
            <CommonButton
              href='/login.jsp'
              bg='#765DE2'
              color='#fff'>
              Create Account
            </CommonButton>
            <CommonButton color='#000' href={'/contact-us'}>
              Contact SALES
            </CommonButton>
          </CommonButtonGroup>
        </div>
      </article>
      <Footer />
    </footer>
  );
};
const ProductTwo = () => {
  useEffect(() => {
    const s = document.querySelector('.shadow');

    const keyframe = [
      {
        transform: 'rotate(0deg)'
      },
      {
        transform: 'rotate(360deg)'
      }
    ];
    const option = {
      duration: 15000,
      easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
      iterations: Infinity
    };
    function handleScroll() {
      if (window.scrollY > 2540) {
        s.style.backgroundImage = `url(${sphereExpand})`;
      } else if (window.scrollY > 3256) {
        s.style.backgroundImage = `url(${sphereThird})`;
      } else {
        s.style.backgroundImage = `url(${sphereNormal})`;
      }
    }
    window.addEventListener('scroll', handleScroll);
    s.animate(keyframe, option);
  }, []);
  return (
    <>
      <Navbar
        name='Titan'
        href='/producttwo'
        color='#765DE2'
        icon={TitanLogo}
      />
      <GlobalContent>
        <Main>
          <Hero />
          <Intro />
          <SectionLogo />
          <Cards />
          <SectionTech />
          <Teams />
          <Pricing />
        </Main>
      </GlobalContent>
      <FooterSection />
    </>
  );
};

export default ProductTwo;
