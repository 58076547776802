import styled from 'styled-components';

export const ArticleContainer = styled.article`
    padding: 30px;
    margin: 0 auto;
    font-family: 'Roboto', sans-serif;

    .post-it {
        box-sizing: border-box;
        margin: 20px auto;
    }

    @media only screen and (min-width: 1050px) {
        width: 70%;
        h1, h2 {
            text-align: center;
        }
    }
`;

export const MediumArticleContainer = styled(ArticleContainer)`
    @media only screen and (min-width: 1050px) {        
        width: 700px;
    }

    line-height: 25px;
`;

export const Title = styled.h1`
    font-size: 32px;
    margin: 50px 0 20px 0;
    line-height: 1.5;
`;

export const Author = styled.h2`
    font-weight: normal;
    font-size: 18px;
    margin-bottom: 20px;
`;

export const Paragraph = styled.p`
    margin-bottom: 40px;
    font-weight: 400;
`;

export const SmParagraph = styled(Paragraph)`
    margin-bottom: 20px;
`

export const GrayParagraph = styled(Paragraph)`
    color: gray;
`;

export const Image = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 0;
`;

export const Position = styled.span`
    display: inline-block;
`;

export const EstimatedRead = styled.h3`
    font-size: 14px;
    font-style: normal;
    padding: 5px 0;
`;

export const Headers = styled.h2`
    text-align: left !important;
    font-weight: bold;
    margin-bottom: 10px;
`;

export const ItalicHeader = styled.h3`
    text-align: center;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    margin-bottom: 10px;
`

export const SideImageWithText = styled.div`
    h1, h2, h3 {
        text-align: left;
    }
`;

export const FloatedImage = styled.div`
    float: left;
    width: 450px;
    padding: 5px 30px;
`;

export const BoldItalics = styled.span`
    font-style: italic;
`;

export const PDFContainer = styled.div`
    .react-pdf__Document {
        display: flex;
        justify-content: center;
    }

`;

export const UnorderedList = styled.ul`
    li {
        list-style-type: disc;
    }
`