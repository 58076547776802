import React from 'react';
import Footer from '../../components/footer/Footer';
import {
  PrivacyTitle,
  Intro,
  IntroTitle,
  IntroText,
  BasicTextBox
} from '../privacy/style';

const Msa = () => {
  return (
    <>
      <Intro>
        <PrivacyTitle>Eulerity INC. MASTER SUBSCRIPTION AGREEMENT</PrivacyTitle>
        <IntroText>
          THIS MASTER SUBSCRIPTION AGREEMENT (“AGREEMENT”) APPLIES TO ALL ORDERS
          FOR Eulerity PRODUCTS, INCLUDING ANY SOFTWARE EMBEDDED IN Eulerity
          PRODUCTS, ISSUED BY Eulerity INC. (“Eulerity”) TO CUSTOMER. Eulerity’S
          ACCEPTANCE OF ANY CUSTOMER ORDER IS EXPRESSLY CONDITIONED ON
          CUSTOMER’S ASSENT TO THIS AGREEMENT. NO TERMS OR CONDITIONS SET FORTH
          IN ANY CUSTOMER ORDER FORM, TO WHICH NOTICE OF OBJECTION IS HEREBY
          GIVEN, OR IN ANY FUTURE CORRESPONDENCE BETWEEN CUSTOMER AND Eulerity
          WILL ALTER OR SUPPLEMENT THIS AGREEMENT UNLESS BOTH PARTIES HAVE
          AGREED IN WRITING TO MODIFY THIS AGREEMENT. NEITHER Eulerity’S
          COMMENCEMENT OF PERFORMANCE NOR DELIVERY WILL BE DEEMED OR CONSTRUED
          AS ACCEPTANCE OF CUSTOMER’S ADDITIONAL OR DIFFERENT TERMS AND
          CONDITIONS. 
        </IntroText>
        <BasicTextBox>
          <IntroTitle>1. DEFINITIONS </IntroTitle>
          <IntroText>
            1.1 “Eulerity Software” means any software platform access provided
            by Eulerity that is identified on an Order Form. 
          </IntroText>
          <IntroText>
            1.2 “Eulerity Products” means, collectively, the Eulerity Software
            and the Eulerity Subscriptions. 
          </IntroText>
          <IntroText>
            1.3 “Eulerity Subscriptions” means those subscriptions to Eulerity
            services identified on an Order Form. 
          </IntroText>
        </BasicTextBox>

        <BasicTextBox>
          <IntroTitle>2. Use OF Eulerity PRODUCTS </IntroTitle>
          <IntroText>
            2.1 Subject to the terms and conditions of this Agreement, Eulerity
            hereby provides to Customer the Eulerity Software ordered pursuant
            to any Order Form for the term stated in the applicable Order Form.
            The Eulerity Software is provided to Customer solely for use by
            Customer in connection with Eulerity Subscriptions. Customer will
            not use the Eulerity Software or any purpose other than use in
            connection with the Eulerity Subscriptions. 
          </IntroText>
          <IntroText>
            2.2 Eulerity has title to the Eulerity Software at all times.
            Customer acquires no ownership, title, property, right, equity, or
            interest in the Eulerity Software other than its subscription
            interest solely as a user subject to all the terms and conditions of
            this Agreement. 
          </IntroText>
          <IntroText>
            2.3 If Customer initiates an order for Eulerity Products with a
            written order form or other written request submitted to Eulerity,
            such order must set forth the details for the order of Eulerity
            Products. Orders must comply with the order lead-time requirements
            established by Eulerity. Eulerity reserves the right to accept or
            reject orders, in whole or in part, in its sole discretion, or to
            cancel any order previously accepted if Eulerity determines that
            Customer is in default or otherwise. No partial acceptance of an
            order will constitute the acceptance of the entire order. The terms
            of this Agreement will govern the order. The terms of a Customer
            order form or any other document that conflict with, or in any way
            purport to amend, any of the terms of this Agreement are hereby
            specifically objected to and will be of no force or effect. 
          </IntroText>
          <IntroText>
            2.4 Subscriptions. Subject to the terms and conditions of this
            Agreement, solely during any paid Eulerity Subscriptions agreed in
            one or more Order Forms, Eulerity grants to Customer a limited,
            non-exclusive, non-transferable right during the term of the
            applicable Eulerity Subscription to access and use the Eulerity
            Software solely in connection with Customer’s internal business
            operations. 
          </IntroText>
          <IntroText>
            2.5 General Restrictions. Except as otherwise explicitly provided in
            this Agreement or as may be expressly permitted by applicable law,
            Customer will not, and will not permit or authorize third parties
            to: (a) reproduce, modify, translate, enhance, create derivative
            works of, decompile, disassemble, reverse engineer, or otherwise
            attempt to discover the source code or underlying ideas or
            algorithms of any portion of any Eulerity Products (b) modify or
            attempt to service or repair the Eulerity Software; nor (c)
            circumvent or disable any technological features or measures in the
            Eulerity Products, including security features. Customer shall take
            reasonable measures to prevent the Eulerity Products from being
            stolen or accessed without authorization and to prevent third
            parties from carrying out the restricted activities set forth in
            this Section are available at law or in equity, including seeking
            damages, specific performance or an injunction.
          </IntroText>
          <IntroText>
            2.6 Changes to Agreement. From time to time Eulerity may modify,
            amend, or otherwise alter the terms of this Agreement. Eulerity
            reserves the right to make any and all such changes. Eulerity will
            strive to provide advance notice of any change that materially
            alters the terms of this Agreement. 
          </IntroText>
          <IntroText>
            2.7 Service Level Agreement. Eulerity will use commercially
            reasonable efforts to provide Customer with the services during the
            applicable term set forth in an Order Form and in accordance with
            the service levels set forth by Eulerity. 
          </IntroText>
          <IntroText>
            2.8 Support Policy. Eulerity will use commercially reasonable
            efforts to provide Customer with the services during the applicable
            term set forth in an Order Form and in accordance with the support
            levels set forth in the Eulerity. 
          </IntroText>
        </BasicTextBox>

        <BasicTextBox>
          <IntroTitle>PAYMENTS</IntroTitle>
          <IntroText>
            3.1 Payment. The order form signed by Eulerity (“Order Form”) will
            reflect the total order made by Customer. The same billing terms
            will apply to all Eulerity Products reflected on an individual Order
            Form. Unless otherwise agreed by Eulerity, Customer must pay all
            fees and other amounts specified on an Order Form prior to shipment
            by wire transfer or other payment method agreed upon by the parties
            (“Payment Method”). 
          </IntroText>
          <IntroText>
            3.2 Eulerity Subscription Renewals. ALL Eulerity SUBSCRIPTIONS RENEW
            AUTOMATICALLY AT THE END OF THE FULL SUBSCRIPTION PERIOD SPECIFIED
            ON THE APPLICABLE ORDER FORM. ALL RENEWAL SUBSCRIPTION PERIODS WILL
            BE FOR A PERIOD EQUAL TO THE INITIAL SUBSCRIPTION PERIOD THAT
            OCCURRED PRIOR TO RENEWAL. UPON RENEWAL OF A Eulerity SUBSCRIPTION,
            CUSTOMER’S PAYMENT METHOD WILL BE CHARGED IN ACCORDANCE WITH
            Eulerity’S THEN-CURRENT PRICING FOR THE Eulerity PRODUCTS ORDERED IN
            THE APPLICABLE ORDER FORM (UNLESS OTHERWISE AGREED BY THE PARTIES IN
            WRITING), AND CUSTOMER HEREBY AGREES TO SUCH RECURRING CHARGES UPON
            RENEWAL. IF CUSTOMER DECIDES TO CANCELS A Eulerity SUBSCRIPTION
            PRIOR TO THE END OF THE APPLICABLE SUBSCRIPTION PERIOD AS DESCRIBED
            IN THE ORDER FORM, CUSTOMER WILL NOT RECEIVE A REFUND OR CREDIT FOR
            THE REMAINDER OF THE SUBSCRIPTION PERIOD. 
          </IntroText>
          <IntroText>
            3.3 Currency and Late Payment. Any amount not paid when due will be
            subject to finance charges equal to the unpaid balance per month or
            the highest rate permitted by applicable usury law, whichever is
            less, determined and compounded daily from the date due until the
            date paid. Customer will reimburse any costs or expenses (including
            reasonable attorneys’ fees) incurred by Eulerity to collect any
            amount that is not paid when due. Eulerity may accept any payment in
            any amount without prejudice to Eulerity’s right to recover the
            balance of the amount due or to pursue any other right or remedy.
            Amounts due from Customer under this Agreement may not be withheld
            or offset by Customer against amounts due to Customer for any
            reason. If amounts remain due following 30 days’ notice of required
            payment, Customer will be in material breach of this Agreement. All
            amounts payable under this Agreement are denominated in United
            States dollars, and Customer will pay all such amounts in United
            States dollars. 
          </IntroText>
          <IntroText>
            3.4 Taxes. Other than federal and state net income taxes imposed on
            Eulerity by the United States, Customer will bear all taxes, duties,
            and other governmental charges (collectively, “taxes”) resulting
            from this Agreement. Customer will pay any additional taxes as are
            necessary to ensure that the net amounts received by Eulerity after
            all such taxes are paid are equal to the amounts which Eulerity
            would have been entitled to in accordance with this Agreement as if
            the taxes did not exist. 
          </IntroText>
          <IntroText>
            3.5 Solvency. Customer warrants to Eulerity that it is (and will be)
            financially solvent on the date on which it places each order for
            Eulerity Products and expects to be solvent on the date of delivery.
            Eulerity reserves the right, in its discretion, to suspend or cease
            performance, or to change the credit terms provided herein, when in
            Eulerity’s opinion, the financial condition or previous payment
            record of Customer so warrants. Eulerity may enforce its rights and
            remedies under this Section 3.5 without prior notice or demand. 
          </IntroText>
        </BasicTextBox>

        <BasicTextBox>
          <IntroTitle>4. TERM AND TERMINATION </IntroTitle>
          <IntroText>
            4.1 Termination for Cause. If either party fails to perform any of its material obligations under this Agreement, the other party may terminate this Agreement under terms governed by your specific order form. 
          </IntroText>
          <IntroText>
            4.2 No Liability for Termination. Except as expressly required by
            law, if either party terminates this Agreement in accordance with
            any of the provisions of this Agreement, neither party will be
            liable to the other because of such termination for compensation,
            reimbursement, or damages on account of the loss of prospective
            profits or anticipated sales or on account of expenditures,
            inventory, investments, leases, or commitments in connection with
            the business or goodwill of Eulerity or Customer. Termination will
            not, however, relieve either party of obligations incurred prior to
            the effective date of the termination. 
          </IntroText>
          <IntroText>
            4.3 Effects of Termination. The termination or expiration of this
            Agreement will not relieve Customer of (a) the obligation to pay any
            fees that are due to Eulerity under this Agreement and (b)
            Customer’s obligation to indemnify Eulerity as specified in this
            Agreement. 
          </IntroText>
        </BasicTextBox>
        <BasicTextBox>
          <IntroTitle>5. PROPRIETARY RIGHTS AND NOTICES </IntroTitle>
          <IntroText>
            5.1 Proprietary Rights. Eulerity and its licensors own all right,
            title, and interest, including all intellectual property rights, in
            and to the Eulerity Products. Customer will not act to jeopardize,
            limit, or interfere in any manner with Eulerity’s ownership of and
            rights with respect to the Eulerity Products. Customer will have
            only those rights in or to the Eulerity Products and documentation
            granted to it pursuant to this Agreement.
          </IntroText>
          <IntroText>
            5.2 Customer Data. Customer will own all right, title, and interest
            in and to any data collected by Eulerity and used by Customer,
            including any such data processed in connection with Eulerity
            Subscriptions (“Customer Data”). Customer hereby grants to Eulerity
            a nonexclusive, worldwide, royalty-free, fully paid right and
            license to the Customer Data for the term of any Eulerity
            Subscriptions to the extent necessary for Eulerity to provide the
            services in connection with the Eulerity Subscriptions. Customer
            hereby grants to Eulerity a nonexclusive, worldwide, perpetual,
            royalty-free, fully paid right and license to the Customer Data (i)
            for Eulerity’s internal use only for research and development
            purposes and to improve Eulerity’s products and services, and (ii)
            in aggregate, anonymized format, so long as Eulerity does not
            disclose Customer as the source of the data. 
          </IntroText>
          <IntroText>
            5.3 Proprietary Rights Notices. Customer and its employees and
            agents will not remove or alter any trademark, trade name,
            copyright, patent, patent pending, or other proprietary notices,
            legends, symbols, or labels appearing on the Eulerity Products or
            related documentation delivered by Eulerity. 
          </IntroText>
          <IntroText>
            5.4 Third Party Copyright Notices. The Eulerity Products include
            third-party code licensed to Eulerity for use and redistribution
            under opensource licenses (“Third Party Software”). The terms of
            certain open-source licenses may be applicable to your use of the
            Eulerity Products, as set forth in the applicable open-source
            license. A list of disclosures and disclaimers in connection with
            Eulerity’s incorporation of certain open-source licensed software
            into the Eulerity Products is provided upon request. 
          </IntroText>
        </BasicTextBox>
        <BasicTextBox>
          <IntroTitle>
            6. WARRANTY DISCLAIMER OTHER THAN AS SET FORTH IN THIS AGREEMENT
          </IntroTitle>
          <IntroText>
            Eulerity MAKES NO ADDITIONAL REPRESENTATION OR WARRANTY OF ANY KIND
            WHETHER EXPRESS, IMPLIED (EITHER IN FACT OR BY OPERATION OF LAW), OR
            STATUTORY, AS TO ANY MATTER WHATSOEVER. Eulerity EXPRESSLY DISCLAIMS
            ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSE, QUALITY, ACCURACY, AND TITLE. Eulerity DOES NOT WARRANT
            AGAINST INTERFERENCE WITH THE ENJOYMENT OF THE Eulerity PRODUCTS OR
            AGAINST INFRINGEMENT. Eulerity DOES NOT WARRANT THAT THE Eulerity
            PRODUCTS ARE ERROR FREE OR THAT OPERATION OF THE Eulerity PRODUCTS
            WILL BE SECURE OR UNINTERRUPTED. Eulerity EXERCISES NO CONTROL OVER
            AND EXPRESSLY DISCLAIMS ANY LIABILITY ARISING OUT OF OR BASED ON A
            CUSTOMER’S USE OF THE Eulerity PRODUCTS. Eulerity PRODUCTS ARE NOT
            DESIGNED, OR INTENDED FOR USE IN ANY MEDICAL, LIFE SAVING OR LIFE
            SUSTAINING SYSTEMS, OR FOR ANY OTHER MISSION CRITICAL APPLICATION IN
            WHICH THE FAILURE OF THE Eulerity PRODUCTS COULD CREATE A SITUATION
            WHERE SUBSTANTIAL PROPERTY DAMAGE OR PERSONAL INJURY OR DEATH MAY
            OCCUR. Eulerity RECOMMENDS AGAINST, AND DISCLAIMS ANY LIABILITY FOR,
            USE OF THE Eulerity PRODUCTS IN ANY SUCH MANNER.
          </IntroText>
        </BasicTextBox>
        <BasicTextBox>
          <IntroTitle>7. INFRINGEMENT INDEMNIFICATION </IntroTitle>
          <IntroText>
            7.1 Defense of Claims. Eulerity will, at its option and expense,
            defend Customer and its officers, employees, directors, agents, and
            representatives (“Customer Indemnified Parties”) from or settle any
            claim, proceeding, or suit (“Claim”) brought by a third party
            against a Customer Indemnified Party alleging that Customer’s use of
            the Eulerity Product (excluding Third Party Software) infringes or
            misappropriates any United States patent, copyright, trade secret,
            trademark, or other intellectual property right if: (a) the Customer
            Indemnified Party gives Eulerity prompt written notice of the Claim;
            (b) Eulerity has full and complete control over the defense and
            settlement of such Claim; (c) the Customer Indemnified Parties
            provide assistance, at Eulerity’s expense as specified in connection
            with the defense and settlement of such Claim as Eulerity may
            reasonably request; and (d) the Customer Indemnified Parties comply
            with any settlement or court order made in connection with such
            Claim (e.g., relating to the future use, sale, or distribution of
            any infringing Eulerity Products). The Customer Indemnified Parties
            will not defend or settle any such Claim without Eulerity’s prior
            written consent. The applicable Customer Indemnified Party shall
            have the right to participate in the defense of such Claim at its
            own expense and with counsel of its own choosing, but Eulerity will
            have sole control over the defense and settlement of the Claim.
          </IntroText>
          <IntroText>
            7.2 Indemnification. Eulerity will indemnify the Customer
            Indemnified Parties against and pay (a) all damages, costs, and
            attorneys’ fees finally awarded against a Customer Indemnified Party
            in any Claim under Section 7.1; (b) all out-of-pocket costs
            (including reasonable attorneys’ fees) reasonably incurred by any of
            them in connection with the defense of such Claim, including
            assistance provided under Section 7.1(c) (other than attorneys’ fees
            and costs incurred without Eulerity’s consent after Eulerity has
            accepted defense of such claim); and, (c) if any Claim arising under
            Section 7.1 is settled, all amounts to be paid to any third party in
            settlement of any such Claim (as agreed to by Eulerity). 
          </IntroText>
          <IntroText>
            7.3 Mitigation. If Customer’s or their respective agents’ use, sale,
            or distribution of a Eulerity Product is, or in Eulerity’s
            reasonable opinion is likely to become, enjoined or materially
            diminished as a result of a Claim under Section 7.1, then Eulerity
            will either: (a) procure the continuing right of Customer to use the
            Eulerity Product; (b) replace or modify the Eulerity Product in a
            functionally equivalent manner while maintaining the same form, fit,
            and function so that it no longer infringes; or if, despite its
            commercially reasonable efforts, Eulerity is unable to do either (a)
            or (b), Eulerity will (c) terminate Customer’s rights to the
            Eulerity Products under this Agreement.
          </IntroText>
          <IntroText>
            7.4 Exceptions. Eulerity will have no obligation under this Section
            7 for any alleged infringement or misappropriation to the extent
            that it arises out of or is based upon (a) use of a Eulerity Product
            in combination with other products, including other Eulerity
            Products, if such alleged infringement or misappropriation would not
            have arisen but for such combination; (b) a Eulerity Product that is
            provided to comply with designs, requirements, or specifications
            required by or provided by Customer, if the alleged infringement or
            misappropriation would not have arisen but for the compliance with
            such designs, requirements, or specifications; (c) use of a Eulerity
            Product for purposes not intended; (d) use of Eulerity Products
            after Customer has been notified of any termination of its right to
            use the Eulerity Products pursuant to Section 7.3(c); (e) Customer’s
            failure to use a Eulerity Product in accordance with instructions
            provided by Eulerity, if the alleged infringement or
            misappropriation would not have occurred but for such failure; or
            (f) any modification of a Eulerity Product not made or authorized in
            writing by Eulerity where such alleged infringement or
            misappropriation would not have occurred absent such modification.
            Customer is responsible for any costs or damages that result from
            these actions. 7.5 Exclusive Remedy. This Section 7 states
            Eulerity’s sole and exclusive liability, and Customer’s sole and
            exclusive remedy, for the actual or alleged infringement or
            misappropriation of any third party intellectual property right by a
            Eulerity Product. 
          </IntroText>
        </BasicTextBox>

        <BasicTextBox>
          <IntroTitle>8. CUSTOMER INDEMNIFICATION </IntroTitle>
          <IntroText>
            8.1 Defense of Claims. Customer will defend Eulerity and its
            affiliates and their employees, directors, agents, and
            representatives (“Eulerity Indemnified Parties”) from any actual or
            threatened third party claim arising out of or based upon Customer’s
            performance or failure to perform under this Agreement, its
            negligence or willful misconduct, or its breach of this Agreement.
            The Eulerity Indemnified Parties will: (a) give Customer prompt
            written notice of the claim; (b) grant Customer full and complete
            control over the defense and settlement of the claim; and (c) assist
            Customer with the defense and settlement of the claim as Customer
            may reasonably request. 
          </IntroText>
          <IntroText>
            8.2 Indemnification. Customer will indemnify each of the Eulerity
            Indemnified Parties against (a) all damages, costs, and attorneys’
            fees finally awarded against any of them in any proceeding under
            Section 8.1; (b) all out-of-pocket costs (including reasonable
            attorneys’ fees) reasonably incurred by any of them in connection
            with the defense of such proceeding (other than attorneys’ fees and
            costs incurred without Customer’s consent after Customer has
            accepted defense of such claim); and, (c) if any proceeding arising
            under Section 8.1 is settled, Customer will pay any amounts to any
            third party agreed to by Customer in settlement of any such claims. 
          </IntroText>
        </BasicTextBox>

        <BasicTextBox>
          <IntroTitle>9. CONFIDENTIAL INFORMATION </IntroTitle>
          <IntroText>
            9.1 “Confidential Information” means any trade secrets or other
            information of a party, whether of a technical, business, or other
            nature (including information relating to a party’s technology,
            software, products, services, designs, methodologies, business
            plans, finances, marketing plans, distributors, prospects, or other
            affairs), that is disclosed to a party during the term of this
            Agreement. The Eulerity Products and related information will be the
            Confidential Information of Eulerity. Confidential Information does
            not include any information that: (a) was known to the receiving
            party prior to receiving the same from the disclosing party in
            connection with this Agreement; (b) is independently developed by
            the receiving party without use of or reference to the Confidential
            Information of the disclosing party; (c) is acquired by the
            receiving party from another source without restriction as to use or
            disclosure; or (d) is or becomes part of the public domain through
            no fault or action of the receiving party. 
          </IntroText>
          <IntroText>
            9.2 Nondisclosure. During and after the term of this Agreement, each
            party will: (a) not disclose the other party’s Confidential
            Information to a third party unless the third party must access the
            Confidential Information to perform in accordance with this
            Agreement and the third party has executed a written agreement that
            contains terms that are substantially similar to the terms contained
            in this Section 9; and (b) protect the other party’s Confidential
            Information from unauthorized disclosure to the same extent (but
            using no less than a reasonable degree of care) that it protects its
            own Confidential Information of a similar nature. 
          </IntroText>
          <IntroText>
            9.3 Confidentiality of Agreement. Neither party to this Agreement
            will disclose the terms of this Agreement to any third party without
            the consent of the other party, except as required by securities or
            other applicable laws. Notwithstanding the above provisions, each
            party may disclose the terms of this Agreement (a) in connection
            with the requirements of a public offering or securities filing; (b)
            in confidence, to accountants, banks, and financing sources and
            their advisors; (c) in confidence, in connection with the
            enforcement of this Agreement or rights under this Agreement; or (d)
            in confidence, in connection with a merger or acquisition or
            proposed merger or acquisition, or the like. 
          </IntroText>
          <IntroText>
            9.4 Return of Materials. Upon the termination or expiration of this
            Agreement, or upon earlier request, each party will deliver to the
            other all Confidential Information that it may have in its
            possession or control. Notwithstanding the foregoing, neither party
            will be required to return materials that it must retain in order to
            receive the benefits of this Agreement or properly perform in
            accordance with this Agreement. 9.5 Existing Obligations. The
            obligations in this Section 9 are in addition to, and supplement,
            each party’s obligations of confidentiality under any nondisclosure
            or other agreement between the parties. 
          </IntroText>
        </BasicTextBox>
        <BasicTextBox>
          <IntroTitle>10. LIMITATION OF LIABILITY </IntroTitle>
          <IntroText>
            10.1 Disclaimer of Consequential Damages. NOTWITHSTANDING ANYTHING
            TO THE CONTRARY CONTAINED IN THIS AGREEMENT, Eulerity WILL NOT,
            UNDER ANY CIRCUMSTANCES, BE LIABLE TO CUSTOMER FOR CONSEQUENTIAL,
            INCIDENTAL, SPECIAL, PUNITIVE, OR EXEMPLARY DAMAGES ARISING OUT OF
            OR RELATED TO THE TRANSACTION CONTEMPLATED UNDER THIS AGREEMENT,
            INCLUDING BUT NOT LIMITED TO LOST PROFITS OR LOSS OF BUSINESS, EVEN
            IF Eulerity IS APPRISED OF THE LIKELIHOOD OF SUCH DAMAGES
            OCCURRING. 
          </IntroText>
          <IntroText>
            10.2 Cap on Liability. UNDER NO CIRCUMSTANCES WILL Eulerity’S TOTAL
            LIABILITY OF ALL KINDS ARISING OUT OF OR RELATED TO THIS AGREEMENT
            (INCLUDING BUT NOT LIMITED TO WARRANTY CLAIMS), REGARDLESS OF THE
            FORUM AND REGARDLESS OF WHETHER ANY ACTION OR CLAIM IS BASED ON
            CONTRACT, TORT, OR OTHERWISE, EXCEED THE TOTAL AMOUNT PAID BY
            CUSTOMER TO Eulerity UNDER THIS AGREEMENT. 
          </IntroText>
          <IntroText>
            10.3 Independent Allocations of Risk. EACH PROVISION OF THIS
            AGREEMENT THAT PROVIDES FOR A LIMITATION OF LIABLITY, DISCLAIMER OF
            WARRANTIES, OR EXCLUSION OF DAMAGES IS TO ALLOCATE THE RISKS OF THIS
            AGREEMENT BETWEEN THE PARTIES. THIS ALLOCATION IS REFLECTED IN THE
            PRICING OFFERED BY Eulerity TO CUSTOMER AND IS AN ESSENTIAL ELEMENT
            OF THE BASIS OF THE BARGAIN BETWEEN THE PARTIES. EACH OF THESE
            PROVISIONS IS SEVERABLE AND INDEPENDENT OF ALL OTHER PROVISIONS OF
            THIS AGREEMENT, AND EACH OF THESE PROVISIONS WILL APPLY EVEN IF THE
            REMEDIES IN THIS AGREEMENT HAVE FAILED OF THEIR ESSENTIAL PURPOSE. 
          </IntroText>
        </BasicTextBox>

        <BasicTextBox>
          <IntroTitle>11. GENERAL </IntroTitle>
          <IntroText>
            11.1 Marketing. Customer agrees Eulerity may publicly use Customer’s
            logo and name to identify Customer as a customer of Eulerity. 
          </IntroText>
          <IntroText>
            11.2 Export Restrictions. Customer will not resell or otherwise
            distribute the Eulerity Products in any foreign territory where
            applicable laws would not provide the protections to Eulerity and
            the Eulerity Products intended under this Agreement, or where there
            is a significant risk that the Eulerity Products would fall into the
            public domain. Customer will not directly or indirectly import,
            export, or re-export the Eulerity Products outside the United States
            without obtaining all permits and licenses as may be required by,
            and conforming with, all applicable laws and regulations of the
            governments of the United States and the foreign territory. 
          </IntroText>
          <IntroText>
            11.3 Independent Contractors. The relationship of the parties
            established by this Agreement is that of independent contractors,
            and nothing contained in this Agreement should be construed to give
            either party the power to (a) act as an agent or (b) direct or
            control the day-to-day activities of the other. Financial and other
            obligations associated with each party’s business are the sole
            responsibility of that party. 
          </IntroText>
          <IntroText>
            11.4 Assignability. Customer may not assign its right, duties, or
            obligations under this Agreement without Eulerity’s prior written
            consent. As used in this Section 11.4, “assign” includes undergoing
            any direct or indirect change in control, whether via a merger,
            acquisition, or sale of all or substantially all assets of Customer.
            If consent is given, this Agreement will bind Customer’s successors
            and assigns. Any attempt by Customer to transfer its rights, duties,
            or obligations under this Agreement except as expressly provided in
            this Agreement is void. 
          </IntroText>
          <IntroText>
            11.5 Nonsolicitation. During the term of this Agreement and for a
            period of one year thereafter, Customer will not, directly or
            indirectly, employ or solicit the employment or services of a
            Eulerity employee or independent contractor without the prior
            written consent of Eulerity. 
          </IntroText>
          <IntroText>
            11.6 Notices. Any notice required or permitted to be given in
            accordance with this Agreement will be effective if it is in writing
            and sent by certified or registered mail, or insured courier, return
            receipt requested, to the appropriate party at the address set forth
            in the Order Form and with the appropriate postage affixed. Eulerity
            may also be contacted at the email address listed in the Order Form.
            Either party may change its address for receipt of notice by notice
            to the other party in accordance with this Section. Notices are
            deemed given two business days following the date of mailing or one
            business day following delivery to a courier. 
          </IntroText>
          <IntroText>
            11.7 Force Majeure. Eulerity will not be liable for, or be
            considered to be in breach of or default under this Agreement on
            account of, any delay or failure to perform as required by this
            Agreement as a result of any cause or condition beyond Eulerity’s
            reasonable control, so long as Eulerity uses commercially reasonable
            efforts to avoid or remove such causes of nonperformance. 
          </IntroText>
          <IntroText>
            11.8 Foreign Corrupt Practices Act. In conformity with the United
            States Foreign Corrupt Practices Act and with Eulerity’s corporate
            policies regarding foreign business practices, Customer and its
            employees and agents shall not directly or indirectly make and
            offer, payment, promise to pay, or authorize payment, or offer a
            gift, promise to give, or authorize the giving of anything of value
            for the purpose of influencing an act or decision of an official of
            any government, including the United States Government (including a
            decision not to act) or inducing such a person to use his influence
            to affect any such governmental act or decision in order to assist
            Eulerity in obtaining, retaining, or directing any such business. 
          </IntroText>
          <IntroText>
            11.9 Governing Law. This Agreement will be interpreted, construed,
            and enforced in all respects in accordance with the local laws of
            the State of New York, U.S.A without reference to its choice of law
            rules and not including the provisions of the 1980 U.N. Convention
            on Contracts for the International Sale of Goods. 
          </IntroText>
          <IntroText>
            11.10 Arbitration. The parties agree to resolve all disputes arising
            under or in connection with this Agreement through binding
            arbitration. The arbitration will be held in New York County, New
            York, USA. If Customer is an entity incorporated or formed under the
            state or federal laws of the United States of America, the
            arbitration will be conducted in accordance with the applicable
            rules of the American Arbitration Association (“AAA”). If Customer
            is an entity incorporated or formed under the laws of a foreign
            jurisdiction, the arbitration will be conducted in accordance with
            the International Chamber of Commerce (“ICC”) Rules of Arbitration.
            If there is a dispute between the parties under this Agreement, the
            parties will use good faith efforts to agree upon and appoint one
            arbitrator no later than 20 days after the notice of arbitration is
            received from the other party. If the parties do not agree on an
            arbitrator, the arbitrator will be selected in accordance with the
            applicable rules of the AAA or ICC (as applicable) for the
            appointment of an arbitrator. The selection of an arbitrator under
            the rules of the AAA or ICC will be final and binding on the
            parties. The arbitrator must be independent of the parties. The
            arbitrator will conduct the arbitration in accordance with the
            applicable rules of the AAA or ICC (as applicable). The arbitrator
            will limit discovery as reasonably practicable to complete the
            arbitration as soon as practicable. The arbitrator’s decision will
            be final and binding on both parties. The costs and expenses of the
            arbitration will be shared equally by both parties. This Section
            11.10 will not prohibit either party from seeking injunctive relief
            in a court of competent jurisdiction. 
          </IntroText>
          <IntroText>
            11.11 Waiver. The waiver by either party of any breach of any
            provision of this Agreement does not waive any other breach. The
            failure of any party to insist on strict performance of any covenant
            or obligation in accordance with this Agreement will not be a waiver
            of such party’s right to demand strict compliance in the future, nor
            will the same be construed as a novation of this Agreement. 
          </IntroText>
          <IntroText>
            11.12 Severability. If any part of this Agreement is found to be
            illegal, unenforceable, or invalid, the remaining portions of this
            Agreement will remain in full force and effect. If any material
            limitation or restriction on the grant of any rights to Customer
            under this Agreement is found to be illegal, unenforceable, or
            invalid, the right granted will immediately terminate. 
          </IntroText>
          <IntroText>
            11.13 Interpretation. The parties have had an equal opportunity to
            participate in the drafting of this Agreement and the attached
            exhibits, if any. No ambiguity will be construed against any party
            based upon a claim that that party drafted the ambiguous language.
            The headings appearing at the beginning of several sections
            contained in this Agreement have been inserted for identification
            and reference purposes only and must not be used to construe or
            interpret this Agreement. Whenever required by context, a singular
            number will include the plural, the plural number will include the
            singular, and the gender of any pronoun will include all genders.
            Whenever -5- the words “include”, “includes” or “including” are used
            in this Agreement, they will be deemed to be followed by the words
            “without limitation.” The word “or” is used in the inclusive sense
            of “and/or.” The terms “or,” “any” and “either” are not exclusive. 
          </IntroText>
          <IntroText>
            11.14 Entire Agreement. This Agreement, including any applicable
            Order Forms, is the final and complete expression of the agreement
            between these parties regarding the Eulerity Products. This
            Agreement supersedes, and the terms of this Agreement govern, all
            previous oral and written communications regarding these matters,
            all of which are merged into this Agreement. No employee, agent, or
            other representative of Eulerity has any authority to bind Eulerity
            with respect to any statement, representation, warranty, or other
            expression unless the same is specifically set forth in this
            Agreement. No usage of trade or other regular practice or method of
            dealing between the parties will be used to modify, interpret,
            supplement, or alter the terms of this Agreement. This Agreement may
            be changed only by a written agreement signed by an authorized agent
            of the party against whom enforcement is sought.
          </IntroText>
        </BasicTextBox>
      </Intro>
      <Footer />
    </>
  );
};

export default Msa;
