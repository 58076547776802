import React from 'react';
import { Link } from 'react-router-dom';
import { ContainerDiv, InnerContainer, BoxContent, HomeButton } from './style';
const NotFound = ({ message, longMessage }) => {
  return (
    <ContainerDiv>
      <InnerContainer>
        <BoxContent>
          <h1>404</h1> <br />
          <h4>{message || ' Page not found'}</h4>
        </BoxContent>
        <p>
          {longMessage ||
            'Not all those who wander are lost, but it seems you may have taken a wrong turn.'}
        </p>
        <HomeButton>
          <Link to='/'>
            <button type='button'>BACK TO HOME</button>
          </Link>
        </HomeButton>
      </InnerContainer>
      {/* <ImageContainer>
        <ImageCover src='' alt='not found  svg' />
      </ImageContainer> */}
    </ContainerDiv>
  );
};

export default NotFound;
