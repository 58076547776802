import React from 'react';
import { Container } from './styles'
import visionSourceBanner from '../../assets/visionSourceBanner.png'

const VisionSource = () => {
    return (
        <Container>
            <img src={visionSourceBanner} alt="banner"/>
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSc4K_xH9IObHM5IDAAxuMbLNYpHT9gtmLL5BZGSHiAqxqA4lA/viewform?embedded=true" width="100%" height="1000" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
        </Container>
    )
}

export default VisionSource;