import { useState, useEffect } from 'react';

export default function (defaultValue) {
  const [height, setHeight] = useState(window.scrollY);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const updateValue = () => {
    setHeight(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', updateValue);
    return () => window.removeEventListener('scroll', updateValue);
  });

  return [height, toggleMenu];
}
