import React from 'react';

import { ReactComponent as Panacea } from '../../assets/customer-logo/panacea-logo.svg';
import { ReactComponent as Titan } from '../../assets/customer-logo/titanLogo.svg';

import {
  Section,
  SectionMasked,
  BackgroundMask,
  SectionBackground,
  Guides,
  GuideContainer,
  Guide,
  SectionContainer,
  SectionLayout,
  SectionLayoutContainer,
  ColumnLayout,
  RowLayout,
  Copy,
  CopyTitle,
  CopyBody,
  CopyFooter,
  CopyFooterContainer,
  CtaButton,
  CopyHeader,
  CopyCaption,
  CopyIcon,
  AnimatedIcon,
  CopySubtitle,
  CopyBodySubText
} from './style';
const Developer = () => {
  return (
    <Section className='Section'>
      <SectionMasked>
        <BackgroundMask>
          <SectionBackground className='angle'>
            <Guides>
              <GuideContainer>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
              </GuideContainer>
            </Guides>
          </SectionBackground>
        </BackgroundMask>
      </SectionMasked>

      <SectionContainer>
        <SectionLayoutContainer>
          <SectionLayout>
              <RowLayout>
                <Copy className='variant--Section'>
                  <CopyHeader>
                    <CopyCaption>
                      Designed for nimble marketing teams
                    </CopyCaption>
                    <CopyTitle>
                      The world’s most powerful and easy-to-use marketing
                      automation software
                    </CopyTitle>
                  </CopyHeader>
                  <CopyBody>
                    <strong>Ranked #1</strong> in ease of use by our customers.
                    All the integrations you need at your fingertips. Modular
                    software allows you to customize for your ideal system
                    configuration.
                  </CopyBody>
                  <CopyFooter>
                    <CopyFooterContainer>
                      <CtaButton
                        className='variant_button'
                        href={'/contact-us'}>
                        Let’s chat &rarr;
                      </CtaButton>
                    </CopyFooterContainer>
                  </CopyFooter>
                </Copy>
                      <CopyHeader>
                        <CopyIcon>
                          <AnimatedIcon>
                            <Panacea className='animatedIcon' />
                          </AnimatedIcon>
                        </CopyIcon>
                        <CopySubtitle>Prebuilt Integrations</CopySubtitle>
                      </CopyHeader>
                      <CopyBodySubText>
                        The channels where consumers spend the most time.
                        Prebuilt integrations with Google search,
                        Facebook/Instagram, YouTube, LinkedIn, Snapchat, and
                        1000’s more.
                      </CopyBodySubText>
                      <CopyFooter>
                        <CopyFooterContainer>
                          <CtaButton href={'/productOne'}>
                            See Panacea &rarr;
                          </CtaButton>
                        </CopyFooterContainer>
                      </CopyFooter>

                    <Copy className='variant--Section'>
                      <CopyHeader>
                        <CopyIcon>
                          <AnimatedIcon>
                            <Titan className='animatedIcon' />
                          </AnimatedIcon>
                        </CopyIcon>
                        <CopySubtitle>Intelligent Core</CopySubtitle>
                      </CopyHeader>
                      <CopyBodySubText>
                        Automated optimization for every major media platform
                        Whether it’s paid search, social, video, or display, all
                        formats and sizes are optimized to save cost and time.
                      </CopyBodySubText>
                      <CopyFooter>
                        <CopyFooterContainer>
                          <CtaButton href='/productTwo'>
                            See Titan &rarr;
                          </CtaButton>
                        </CopyFooterContainer>
                      </CopyFooter>
                    </Copy>
              </RowLayout>
          </SectionLayout>
        </SectionLayoutContainer>
      </SectionContainer>
    </Section>
  );
};

export default Developer;
