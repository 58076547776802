import React from "react";
import {
  Section,
  SectionMasked,
  BackgroundMask,
  SectionBackground,
  Guides,
  GuideContainer,
  Guide,
  SectionContainer,
  SectionLayout,
  SectionLayoutContainer,
  SectionFooterLayout,
  SiteFooterSectionColumn,
  SectionFooterLocaleContainer,
  SectionCopyright,
  CopyTitle,
} from "./style";
const Footer = ({small}) => {
  return (
    <Section className="Section angleTop ">
      <SectionMasked>
        <BackgroundMask>
          <SectionBackground>
            <Guides>
              <GuideContainer>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
              </GuideContainer>
            </Guides>
          </SectionBackground>
        </BackgroundMask>
      </SectionMasked>

      <SectionContainer>
        <SectionLayoutContainer>
          <SectionLayout>
            <SectionFooterLayout className={small}>
              <SiteFooterSectionColumn className="locale">
                <SectionFooterLocaleContainer>
                  <div className="sectionfooter_logo">
                    <a className="sectionfooter_logoLink" href="/">
                      EULERITY
                    </a>
                  </div>
                  <div className="sectionfooter_adresse">
                    <p>
                      <i className="fas fa-map-marker-alt"></i>New York
                    </p>
                  </div>
                </SectionFooterLocaleContainer>
                <SectionCopyright className="list">
                  <ul className="lists">
                    <li className="List__item">
                      <span>Eulerity &copy;</span>
                    </li>
                  </ul>
                </SectionCopyright>
              </SiteFooterSectionColumn>

              {/**products */}
              <SiteFooterSectionColumn>
                <section className="list">
                  <CopyTitle>Products</CopyTitle>
                  <ul className="lists">
                    <li className="List__item">
                      <a className="link" href="/productone">
                        Panacea
                      </a>
                    </li>
                    <li className="List__item">
                      <a className="link" href="/productTwo">
                        Titan
                      </a>
                    </li>
                    <li className="List__item">
                      <a className="link" href="/productThree">
                        Dolos
                      </a>
                    </li>
                    <li className="List__item">
                      <a className="link" href="/productFour">
                        Metis
                      </a>
                    </li>
                  </ul>
                </section>
              </SiteFooterSectionColumn>

              {/**use casess */}
              <SiteFooterSectionColumn>
                <section className="list">
                  <CopyTitle>Use Cases</CopyTitle>
                  <ul className="lists">
                    <li className="List__item">
                      <a className="link" href="/franchise/industry">
                        Franchise
                      </a>
                    </li>
                    <li className="List__item">
                      <a className="link" href="/franchise/enterprise">
                        Enterprise
                      </a>
                    </li>
                    <li className="List__item">
                      <a className="link" href="/franchise/retail">
                        Retail
                      </a>
                    </li>
                    <li className="List__item">
                      <a className="link" href="/automotive">
                        Automotive
                      </a>
                    </li>
                    <li className="List__item">
                      <a className="link" href="/financial-services">
                        Financial Services
                      </a>
                    </li>
                  </ul>
                </section>
              </SiteFooterSectionColumn>

              {/**company */}
              <SiteFooterSectionColumn>
                <section className="list">
                  <CopyTitle>Company</CopyTitle>
                  <ul className="lists">
                    <li className="List__item">
                      <a className="link" href="/company/about-us">
                        About us
                      </a>
                    </li>
                    <li className="List__item">
                      <a className="link" href="/company/contenthub">
                        Content Hub
                      </a>
                    </li>
                    <li className="List__item">
                      <a
                        className="link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.linkedin.com/company/eulerity/"
                      >
                        <i className="fab fa-linkedin"></i>
                        Linkedin
                      </a>
                    </li>
                    <li className="List__item">
                      <a
                        className="link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.instagram.com/eulerity_/?hl=en"
                      >
                        <i class="fab fa-instagram"></i>
                        Instagram
                      </a>
                    </li>
                    <li className="List__item">
                      <a
                        className="link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://open.spotify.com/show/6Q45gu4eCBSXr8mITnMNuX"
                      >
                        <i class="fab fa-spotify"></i>
                        Spotify Podcast
                      </a>
                    </li>
                    <li className="List__item">
                      <a
                        className="link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://podcasts.apple.com/ca/podcast/eulerity-presents-darwinian-times-survival-nimblest/id1529298351"
                      >
                        <i class="fab fa-apple"></i>
                        Apple Podcast
                      </a>
                    </li>
                    <li className="List__item">
                      <a className="link" href="/privacy">
                        Privacy Policy
                      </a>
                    </li>
                    <li className="List__item">
                      <a className="link" href="/tos" rel="nofollow">
                        Terms Of Service
                      </a>
                    </li>
                  </ul>
                </section>
              </SiteFooterSectionColumn>
            </SectionFooterLayout>
          </SectionLayout>
        </SectionLayoutContainer>
      </SectionContainer>
    </Section>
  );
};

export default Footer;
