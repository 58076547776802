import React from 'react';
import Footer from '../../components/footer/Footer';
import './pilot.css';
import sky from '../../assets/skyzoneImg.png';
const PilotSkyzone = () => {
  return (
    <>
      <div className='sky'>
        <div className='container-lg'>
          <h1>Sky Zone Pilot Stat Sheet</h1>
          <img src={sky} alt='skyzone pilot ' />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PilotSkyzone;
