import React from 'react';
import { ReactComponent as Easy } from '../../assets/customer-logo/handwritting.svg';
import { ReactComponent as Sharing } from '../../assets/customer-logo/idea-sharing.svg';
import { ReactComponent as Binocular } from '../../assets/customer-logo/binoculars.svg';
import { ReactComponent as Synchronize } from '../../assets/customer-logo/synchronize.svg';
// import Proptypes from 'prop-types';
import {
  Section,
  SectionMasked,
  BackgroundMask,
  SectionBackground,
  Guides,
  GuideContainer,
  Guide,
  SectionContainer,
  SectionLayout,
  SectionLayoutContainer,
  ColumnLayout,
  RowLayout,
  Copy,
  CopyTitle,
  CopyHeader,
  CopyCaption,
  CopyIcon,
  AnimationSequence,
  // AnimatedIcon,
  CopySubtitle,
  CopyBodySubText
  // CodeEditor
} from './style';
const Eulerity = () => {
  return (
    <Section className='Section'>
      <SectionMasked>
        <BackgroundMask>
          <SectionBackground className='angle'>
            <Guides>
              <GuideContainer>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
              </GuideContainer>
            </Guides>
          </SectionBackground>
        </BackgroundMask>
      </SectionMasked>

      <SectionContainer>
        <SectionLayoutContainer>
          <SectionLayout>
            <RowLayout>
              <ColumnLayout data-columns='3,1'>
                <Copy className='variant--Section'>
                  <CopyHeader>
                    <CopyCaption>Why Eulerity</CopyCaption>
                    <CopyTitle>
                      A technology first approach to marketing automation
                    </CopyTitle>
                  </CopyHeader>
                </Copy>
              </ColumnLayout>
              <AnimationSequence>
                <ColumnLayout data-columns='1,1,1,1'>
                  <Copy className='variant--Section'>
                    <CopyHeader>
                      <CopyIcon>
                        <Easy />
                      </CopyIcon>
                      <CopySubtitle>Ease of use</CopySubtitle>
                    </CopyHeader>
                    <CopyBodySubText>
                      Our platform was built mobile first for business owners on
                      the go. Let the cloud lighten your marketing load. Make it
                      easy for your teams with cross channel media assets at
                      their fingertips.
                    </CopyBodySubText>
                  </Copy>
                  <Copy className='variant--Section'>
                    <CopyHeader>
                      <CopyIcon>
                        <Sharing />
                      </CopyIcon>
                      <CopySubtitle>World class intelligence</CopySubtitle>
                    </CopyHeader>
                    <CopyBodySubText>
                      Enable media optimization powered by machine learning. Let
                      live performance data decide the right combination of
                      creative units, media channel, and audience to drive the
                      highest return on ad spend.
                    </CopyBodySubText>
                  </Copy>
                  <Copy className='variant--Section'>
                    <CopyHeader>
                      <CopyIcon>
                        <Binocular />
                      </CopyIcon>
                      <CopySubtitle>Transparency at every step</CopySubtitle>
                    </CopyHeader>
                    <CopyBodySubText>
                      Spend what you can afford. No more rigid minimums or
                      locked in monthly spend. Flat fees allows your marketing
                      to work harder. Adjust your spend on the fly and on the
                      go.
                    </CopyBodySubText>
                  </Copy>
                  <Copy className='variant--Section'>
                    <CopyHeader>
                      <CopyIcon>
                        <Synchronize />
                      </CopyIcon>
                      <CopySubtitle>Connects everything</CopySubtitle>
                    </CopyHeader>
                    <CopyBodySubText>
                      No more point solutions. Our complete platform includes
                      standard features like re-targeting, full motion video
                      ads, and dynamic creative testing all in one place.
                    </CopyBodySubText>
                  </Copy>
                </ColumnLayout>
              </AnimationSequence>
            </RowLayout>
          </SectionLayout>
        </SectionLayoutContainer>
      </SectionContainer>
    </Section>
  );
};

export default Eulerity;
