export const PodcastList = [
  {
    episode: 33,
    title:
      "The Power of Entrepreneurship",
    subtitle:
      "This episode features Kai Kaapro, the CEO of TourScale, a tour and activity franchise platform for entrepreneurs who want to pursue their dreams and craft exceptional and unique customer experiences. TourScale is currently the franchisor of the pedal-powered concepts Trolley Pub, Tiki Pub, and Paddle Pub, all of which Kaapro serves as the Co-Founder and Co-Owner of. Hear about the importance of perseverance, recruiting and training great teams, attracting clients through different marketing strategies, researching geographical locations best fit for your business, and taking your dreams to the next level through true entrepreneurship.",
    spotifyLink:
      "https://open.spotify.com/episode/0sv9j3K5t31dHyNZq45HrG?si=9hH1_tm-RxqQTZYkozz8gA&nd=1",
    appleLink:
      "https://podcasts.apple.com/us/podcast/the-power-of-entrepreneurship/id1529298351?i=1000576954664",
    duration: 42
  },
  {
    episode: 32,
    title:
      "Bryce Henson on Fit Body, Fearless Growth, and a Fire Brand",
    subtitle:
      "When push comes to shove, we get a choice to either change our lives or remain stagnant. Bryce Henson, CEO of Fit Body Boot Camp, chose to not only change his own life, but also the lives of others through the rise of a fitness empire. This epic journey didn't come without dedication, hard work, brutal honesty, and constant drive. Check out our conversation with Bryce as he gives us an in-depth look at his career and the Fit Body Boot Camp brand.",
    spotifyLink:
      "https://open.spotify.com/episode/0f7KCZCC9ul0wUv5Pe4Akr?si=ERtvg9rCRvO3hxm3LcrreQ&nd=1",
    appleLink:
      "https://podcasts.apple.com/ca/podcast/bryce-henson-on-fit-body-fearless-growth-and-a-fire-brand/id1529298351?i=1000568287194",
    duration: 38,
  },
  {
    episode: 31,
    title:
      "Flexing the Future: An Interview with Andrea Pirrotti-Dranchak",
    subtitle:
      "Andrea Pirrotti-Dranchak has unmatched experience in the co-working and flexible workspace category. She has run sales, marketing and business strategy for over 900 locations and over 50 companies across 65 countries. Andrea recently made her debut as the new Global Enterprise Director at NewFlex, a brand that delivers a range of powerful workspace brands paired with expertise in marketing and sales that creates value for landlords by supporting and delivering hybrid working. Prior to her current role, Andrea served as the Chief Marketing and Development Officer for Office Evolution and worked with brands such as Regus and Work Better. This episode features Andrea's insights into the revolution of co-working spaces, strategies for employee success, her personal leadership mantras and journey, and what's next for NewFlex.",
    spotifyLink:
      "https://open.spotify.com/episode/6E0kZQaC7Sm85qCxjgxwtX?si=a5da7ff5f6f74357&nd=1",
    appleLink:
      "https://podcasts.apple.com/us/podcast/flexing-the-future-an-interview-with/id1529298351?i=1000563992409",
    duration: 33,
  },
  {
    episode: 30,
    title:
      "Limitless Leader: Burke Cueny of Alliance Franchise Brands",
    subtitle:
      "Burke Cueny is the Vice President of Marketing for Alliance Franchise Brands, which serves the multi-billion dollar market for marketing, print, sign and graphics communications services. Burke spoke on his experience managing multiple different brand portfolios, the structures of Alliance's marketing teams, how their brands scale, specialization areas, and what makes a great leader. A little more on Alliance Franchise Brands: In its Marketing & Print Group, the company supports the following brands: Allegra Marketing Print Mail, American Speedy Printing and Insty-Prints in the United States, and Allegra, KKP, and Speedy Printing in Canada. In its Sign & Graphics Group, the company supports these brands in North America: Image360, Signs by Tomorrow and Signs Now. In the Direct Mail Group, the company supports the RSVP Advertising brand in the U.S.",
    spotifyLink:
      "https://open.spotify.com/episode/1Wi48dHtcsEF3yht7cP1nO?si=Zwhvf8CvQq6Ctsqg1O5U4A&nd=1",
    appleLink:
      "https://podcasts.apple.com/us/podcast/limitless-leader-burke-cueny-of-alliance-franchise-brands/id1529298351?i=1000561087787",
    duration: 34,
  },
  {
    episode: 29,
    title:
      "Limitless Leaders: Part VII",
    subtitle:
      "What makes a great leader? It's the way they inspire, advise, and rally for others. We asked top industry leaders to tell us about their brand, what makes a remarkable leader, and what's in store for 2022. This episode includes Chris Dull, CFE, President and CEO of Freddy's Frozen Custard & Steakburgers, Paige Robinson, CFE, Director of Franchise Development for American Family Care, Nancy Bigley, CFE, President and CEO of The Little Gym International, Michael O. Browning Jr., Founder and CEO of Unleashed Brands, Joshua Liggins, Franchise Development Manager at Inspire Brands, Gyula Csitári, Co-Founder and Managing Director of Logiscool, Ltd., Marci Kleinasser, CFE, Vice President of Marketing for Handyman Connection, and Heather McLeod, MBA, CFE, Chief Marketing Officer of Authority Brands.",
    spotifyLink:
      "https://open.spotify.com/episode/2ExLCF4mGoytnPaeBaaBmL?si=b262ebd5a9ff442d&nd=1",
    appleLink:
      "https://podcasts.apple.com/us/podcast/limitless-leaders-part-vii/id1529298351?i=1000558894585",
    duration: 16,
  },
  {
    episode: 28,
    title:
      "Limitless Leaders: Part VI",
    subtitle:
      "What makes a great leader? It's the way they inspire, advise, and rally for others. We asked top industry leaders to tell us about their brand, what makes a remarkable leader, and what's in store for 2022. This episode includes: Catheron Monson, Chief Executive Officer, Propelled Brands, Christy Barnes, Vice President of Marketing, Camp Bow Wow, Adam Geisler, Co-Founder and CEO, Youth Athletes United, Dirk Stallmann, President, Verlo Mattress, Chris Seman, President, Strategic Franchising Systems, Genevieve Sheehan, Chief Executive Officer, SheenCo Travel, Bary El-Yacoubi, Co-Founder and CEO, Send Me A Trainer, Jeff Platt, President, The NOW Massage, Sarah Toney, Multi-Unit Franchisee, Any Lab Test Now.",
    spotifyLink:
      "https://open.spotify.com/episode/4FYCT5Wkm0VylWkkigsVsn?si=484f2ccace4f400a&nd=1",
    appleLink:
      "https://podcasts.apple.com/us/podcast/limitless-leaders-part-vi/id1529298351?i=1000555748097",
    duration: 18,
  },
  {
    episode: 27,
    title:
      "Building A Fitness Brand: An Interview With Row House Co-Founder, Debra Strougo",
    subtitle:
      "Row House Co-Founder, Debra Strougo, spoke to us about her journey of taking her passion for healthy living and fitness and growing it into a boutique brand empire. From her time building the Row House brand to her next exciting endeavor: Fitizens Holdings, a business she's started with her husband that specializes in Health, Wellness, & Fitness Advisory Services to companies that are launching, scaling, and growing, Struogo brings immense talent, perspective, experience and dedication to every thing she does.",
    spotifyLink:
      "https://open.spotify.com/episode/1q6d5b8Xdvu3TFeJp4ZlOu?si=ac3153bec18946c9&nd=1",
    appleLink:
      "https://podcasts.apple.com/us/podcast/building-a-fitness-brand-an-interview-with-row/id1529298351?i=1000555005404",
    duration: 42,
  },
  {
    episode: 26,
    title:
      "Limitless Leaders: Part V",
    subtitle:
      "What makes a great leader? It's the way they inspire, advise, and rally for others. We asked top industry leaders to tell us about their brand, what makes a remarkable leader, and what's in store for 2022. This episode includes: Angela Paules, Chief Marketing Officer, Buzz Franchise Brands, Scott Abbott, CEO and Co-Founder, Five Star Franchising, Laura Coe, CEO and Co-Founder, Snapology, Chris Hadley, VP of Franchising, Byrider, Tony Zaccario, President and CEO, Stretch Zone, Tiffany Dodson, Chief Experience Officer, The Salt Suite, Frank Milner, President, Tutor Doctor, Tim Vogel, Founder and CEO, Scenthound.",
    spotifyLink:
      "https://open.spotify.com/episode/0n988qHx1BA0CV7n0NyLHw?si=823b707ee0a446ca&nd=1",
    appleLink:
      "https://podcasts.apple.com/us/podcast/limitless-leaders-part-v/id1529298351?i=1000554392098",
    duration: 20,
  },
  {
    episode: 25,
    title:
      "Limitless Leaders: Part IV",
    subtitle:
      "What makes a great leader? It's the way they inspire, advise, and rally for others. We asked top industry leaders to tell us about their brand, what makes a remarkable leader, and what's in store for 2022. This episode kicks off with Founder, COO, and Director of Streamline Brands, Paul Gerrard, Chief Creative Officer for Frios Gourmet Pops, Ingrid Schneider, Co-Founder and Co-CEO of Biggby Coffee, Bob Fish, CEO of LearningRx, Kim Hanson, CEO of Athletic Republic, Charlie Graves, President of Property Management, Inc., Danessa Itaya, and CEO & President of Home Helpers Home Care, Emma Dickison.",
    spotifyLink:
      "https://open.spotify.com/episode/5NHqSDMq1DvO4sOt1dZEaJ?si=c209f125b6b64eb0&nd=1",
    appleLink:
      "https://podcasts.apple.com/us/podcast/limitless-leaders-part-iv/id1529298351?i=1000553567394",
    duration: 18,
  },
  {
    episode: 24,
    title:
      "Reach For The Stars: An Interview With StretchLab President, Lou DeFrancisco",
    subtitle:
      "Hear from the President of StretchLab, Lou DeFrancisco, as he talks about the love he has for fitness and his franchise. Learn what Lou's three key rules are for entrepreneurs, and who inspires him as a leader. We touch on the power of incredible teamwork, what day to day life looks like with a parent company, and more. StretchLab is a unique wellness concept, offering customized assisted-stretch sessions for all ages and demographics. The boutique fitness concept has grown incredibly popular and continues to make a lasting mark on both the industry and its clients.",
    spotifyLink:
      "https://open.spotify.com/episode/3CWR8142U54MJo7S57KAav?si=Rn37GCFaSDCp4_z4wZeJxg&nd=1",
    appleLink:
      "https://podcasts.apple.com/us/podcast/reach-for-the-stars-an-interview-with/id1529298351?i=1000551412370",
    duration: 54,
  },
  {
    episode: 23,
    title:
      "Customer Care: How Christian Brothers Automotive Puts People First",
    subtitle:
      "On this episode, we sat down with the amazing Donnie Carr, President of Christian Brothers Automotive. Donnie touched on the impact of quality customer service, how to identify brands that inspire you, what team building is for CBA, and much more.",
    spotifyLink:
      "https://open.spotify.com/episode/3WFJuLUynBVl7LEVO9ITPf?si=Bn8O5EdvTnuKI0MqFRv4Jw&nd=1",
    appleLink:
      "https://podcasts.apple.com/ca/podcast/customer-care-how-christian-brothers-automotive-puts/id1529298351?i=1000548961406",
    duration: 35,
  },
  {
    episode: 22,
    title:
      "Defining Your Why: Embracing Emerging Brands",
    subtitle:
      "What happens when you put two powerful leaders together? You get a great conversation, inspiring insights and unbeatable best practices. Hear from CEO of Wow Wow Hawaiian Lemonade, Tim Weiderhoft, CFE, and CEO of Hammer & Nails Grooming Shop for Guys, Aaron Meyers. Having worked together in the past, the two grew to be leaders of amazing emerging brands. Their collective experience and positive attitudes serve as a reminder for business owners looking for their \"why\" — you find purpose along the way, and, if you're lucky, get to do it every day.",
    spotifyLink:
      "https://open.spotify.com/episode/4EIwwKbUcaz8VXhplTM2NO?si=a15100cf11f94dbd&nd=1",
    appleLink:
      "https://podcasts.apple.com/ca/podcast/defining-your-why-embracing-emerging-brands/id1529298351?i=1000543863995",
    duration: 58,
  },
  {
    episode: 21,
    title:
      "Limitless Leaders: Part III",
    subtitle:
      "What makes a great leader? It's the way they inspire, advise, and rally for others. We asked top industry leaders two questions. First, what is the culture of your company? Second, what is the culture of franchising as a whole? Our third and final episode features Samir Daoud, CFE, Franchise Director, Gold Star Chili and Tom & Chee, Jason Frazier, President and Chief Operating Officer, Skyhawks Sports Academy, Tyler Demuth, Director of Franchise Growth, Teriyaki Madness, and Salomon Mishaan, Founder, President & CEO, Oxxo Care Cleaners.",
    spotifyLink:
      "https://open.spotify.com/episode/13Pt50MtADFQouZBiVjdPR?si=uvqiZ0xXSkuYnVUdA72juA&nd=1",
    appleLink:
      "https://podcasts.apple.com/ca/podcast/limitless-leaders-part-iii/id1529298351?i=1000542012460",
    duration: 15,
  },
  {
    episode: 20,
    title:
      "Limitless Leaders: Part II",
    subtitle:
      "What makes a great leader? It's the way they inspire, advise, and rally for others. We asked top industry leaders two questions. First, what is the culture of your company? Second, what is the culture of franchising as a whole? Our second episode features John Lancaster, VP, Emerging Markets Franchise Development, Choice Hotels International, Steve White, President & Chief Operating Officer, PuroClean, Kristen Pechacek, Chief Growth Officer, MassageLuXe, Joe Malmuth, Vice President of Franchise Development and Franchise Relations, Batteries Plus Bulbs, and Kelly Crompvoets, Vice President of Marketing, Any Lab Test Now.",
    spotifyLink:
      "https://open.spotify.com/episode/7j4teE07VjUB3xEuQBsOBJ?si=Ydg3UeDbQz-Gm2LYbmt8Og&nd=1",
    appleLink:
      "https://podcasts.apple.com/ca/podcast/limitless-leaders-part-ii/id1529298351?i=1000540539956",
    duration: 14,
  },
  {
    episode: 19,
    title:
      "Limitless Leaders: Part I",
    subtitle:
      "What makes a great leader? It's the way they inspire, advise, and rally for others. We asked top industry leaders two questions. First, what is the culture of your company? Second, what is the culture of franchising as a whole? Our first episode kicks off with Chief Development Officer of Wild Birds Unlimited, Paul Pickett, Managing Partner and CEO of GT&S Franchise Executive Search, Scott Lehr, VP of Franchise Development for Scenthound, Patti Rother, and IFPG President, Red Boswell.",
    spotifyLink:
      "https://open.spotify.com/episode/02n7sf4tH1EuJuKxPvRfgV?si=IBscn3QnRbutYa0erDh5EA&nd=1",
    appleLink:
      "https://podcasts.apple.com/ca/podcast/limitless-leaders-part-i/id1529298351?i=1000540008380",
    duration: 15,
  },
  {
    episode: 18,
    title:
      "Dreams and Drive: Following Your Passion With Allstate's Adam Malamut, PhD‬",
    subtitle:
      "Adam Malamut, PhD, is the Senior Vice President of Strategic Human Resources Business Partnerships for Allstate. His incredible portfolio and experience will resonate deeply with any entrepreneur and industry professional who loves what they do, is driven to follow their dreams, and who looks at life as an endless opportunity. Adam touches on his background in HR and Customer Experience, as well as his core belief that people are the innovation engine and primary customer channel. His focus on consumer trends and ensuring company resilience is evident, as is his incredible talent for art, communication and more. Since 1931, Allstate has been a pioneer of the insurance industry. Americans have trusted Allstate to help protect their families and belongings for over 80 years. Based in Northbrook, Illinois, Allstate is the nation's largest publicly held personal lines insurer. Its leaders, employees and agency owners have been an integral part of Allstate's heritage. Today, Allstate is reinventing protection to help consumers better prepare for tomorrow and protect what matters most.",
    spotifyLink:
      "https://open.spotify.com/episode/0NemTPjt7bfRYfqKFj9XWr?si=OcMjpcarRKattg-EUQdMIQ&dl_branch=1&nd=1",
    appleLink:
      "https://podcasts.apple.com/us/podcast/dreams-and-drive-following-your-passion-with/id1529298351?i=1000537995993",
    duration: 59,
  },
  {
    episode: 17,
    title:
      "Personal Advocacy and Potential With Blo Blow Dry Bar CEO, Vanessa Yakobson‬",
    subtitle:
      "Want to know the secret to success? Being your own biggest advocate. Blo Blow Dry Bar CEO, Vanessa Yakobson, shares her insights on finding your passion, bringing determination and drive to the workplace, and making best practices from both the franchisee and corporate level into a company tradition.",
    spotifyLink:
      "https://open.spotify.com/episode/60GNlASrRyr3582slmLbwS?si=_7Nfpe7bTYGdoyc3EBb2rw&dl_branch=1",
    appleLink:
      "https://podcasts.apple.com/us/podcast/personal-advocacy-and-potential-with-blo-blow-dry-bar/id1529298351?i=1000534826812",
    duration: 54,
  },
  {
    episode: 16,
    title:
      "Leading by Example: An Inspiring Story of How Hospitality Starts From Within‬",
    subtitle:
      "Eulerity's Director of Marketing, Mary Hanula, along with COO and Co-Founder, Adam Chandler, sat down with Azim Saju, the CEO & President of Hotel Development & Management Group. Azim kicked off our second season with a discussion about his childhood and how the United States offered him and his family an opportunity to reach for — and achieve — their dreams of hospitality. From mentorship to metaphors, advice on genuine connection with vendors and partners, and the importance of core values, this episode is filled with best practices and feel-good inspiration. HDG Hotels is a hotel development and management company with an astounding reputation for offering partners exceptional industry experience and results. As both a respected developer and preferred manager, HDG's team employs its core values of teamwork, communication, innovation, leadership and resourcefulness consistently.",
    spotifyLink:
      "https://open.spotify.com/episode/1vvZ7owriYiHEaOsVMOdt5?si=7Yf8oqP9RiO17xByuK5hDQ&dl_branch=1&nd=1",
    appleLink:
      "https://podcasts.apple.com/us/podcast/leading-by-example-an-inspiring-story-of/id1529298351?i=1000530536830",
    duration: 56,
  },
  {
    episode: 15,
    title:
      "Helping Heroes and the Power of Brand Authenticity‬",
    subtitle:
      "An exclusive conversation with Edward Logan, CEO & President of Sport Clips Haircuts. Edward shared the amazing involvement the brand has in multiple philanthropies, including The VFW's \"Help a Hero\" scholarship program, Dream Flights, Honor Flight Network, the Aleethia Foundation, St. Baldrick's Foundation, and more. Sport Clips also shows extreme dedication and commitment to its corporate team, franchise owners, and the customers that keep coming back for its great services. Hear about the franchise that is more like a family, how Edward stepped into the head leadership role of a business during the COVID-19 pandemic, and how the brand consistently comes out on top.",
    spotifyLink:
      "https://open.spotify.com/episode/3Z7AozUg2P9tm7KwAg00zL?si=_rncq59FR4W_eRNzkUb_PQ&dl_branch=1",
    appleLink:
      "https://podcasts.apple.com/us/podcast/helping-heroes-and-the-power-of-brand-authenticity/id1529298351?i=1000526642937",
    duration: 48,
  },
  {
    episode: 14,
    title:
      "Leadership Through Chaos: An Exclusive Interview With Former IFA President & CEO, Robert Cresanti‬",
    subtitle:
      "Robert Cresanti is the immediate past President & CEO of The International Franchise Association and recent Founder of Cresanti Associates, LLC, an international strategic business, finance, technology, and public affairs advisory firm. Eulerity's Director of Marketing, Mary Hanula, and COO and Co-Founder, Adam Chandler, spoke with Robert in an exclusive interview about how one of the most renowned industry leaders navigated the decade's most devastating threat to small businesses. Robert shares his life journey, his memories of the days in history where time stood still, what's next in his amazing career and more in this exclusive podcast.",
    spotifyLink:
      "https://rb.gy/o9b8gp",
    appleLink:
      "https://rb.gy/p1bv6c",
    duration: 53,
  },
  {
    episode: 13,
    title:
      "How A Brand's Health And Happiness Depend On Its Marketing: Insights From Curves President & CEO, Krishea Holloway‬",
    subtitle:
      "What does it take to keep a fitness franchise healthy? Krishea Holloway, President and CEO of Curves, shared the answer in her conversation with Eulerity's Senior Director of Growth, Julie Samuels. The two sat down to talk about the importance of marketing innovation, how to define and maintain wellness, what successful franchisees need and more.",
    spotifyLink:
      "https://rb.gy/kbvuqw",
    appleLink:
      "https://rb.gy/py0wei",
    duration: 31,
  },
  {
    episode: 12,
    title:
      "Defining Purpose With WOWorks CMO, Mark Mears‬",
    subtitle:
      "Join Julie Samuels, Eulerity's Senior Director of Growth, as she talks with Mark Mears, Chief Marketing Officer at WOWorks, about navigating a constantly changing world. They'll touch on how to create a strong mission within a company's environment, what it means to be purposeful, and how to deliver moments that'll make your guest go WOW. We hope you enjoy!",
    spotifyLink:
      "https://rb.gy/dtvorr",
    appleLink:
      "https://rb.gy/w8kygd",
    duration: 44,
  },
  {
    episode: 11,
    title:
      "Approaching Change: Authority Brands CMO, Heather McLeod, Discusses Navigating the Unknown‬",
    subtitle:
      "Heather McLeod, Chief Marketing Officer of Authority Brands, sat down with Eulerity's COO and Co-Founder, Adam Chandler, to discuss what it takes to create consistency, how to gain experience and create new opportunities post-grad, and the benefits of working at a well established company with a start-up attitude.",
    spotifyLink:
      "https://rb.gy/skbtzs",
    appleLink:
      "https://rb.gy/0mg8c6",
    duration: 59,
  },
  {
    episode: 10,
    title:
      "Google’s Sam Cox Talks Restructuring Priorities, Forming Interpersonal Connections + The Art of Teamwork‬",
    subtitle:
      "Sam Cox, Group Product Manager, AdX BuySide and Policy for Google, sat down with Eulerity's CEO and Co-Founder, Tanuj Joshi, to discuss what work connections really mean, how to be successful in the start-up world, and how far great communication can take you in any career. Cox elaborates upon the clarity of communication, mechanics of scale, how to identify and organize company values, and how to interpret bias per action.",
    spotifyLink:
      "https://rb.gy/drmveh",
    appleLink:
      "https://rb.gy/onmfjs",
    duration: 48,
  },
  {
    episode: 9,
    title:
      "Jump around! Up close and personal with Josh Cole, CMO of Sky Zon‪e‬",
    subtitle:
      "Get ready to hear some top tips from one of the leading marketing experts in the industry, Josh Cole. The Chief Marketing Officer of Sky Zone, the famous indoor trampoline parks, gives insight about how the franchise offers kids a space to get active, get exercise, and just have fun. Cole shares what successful mentorship looks like, how to grow with a great team, his time at Universal Studios, digital advertising's increase through the years and more. Join Adam Chandler, Eulerity's Co-Founder and Chief Operating Officer, to find out how both Sky Zone and Josh Cole leaped their way to innovation and elevation within franchising.",
    spotifyLink:
      "https://open.spotify.com/episode/46MUWVSaEd00V5GzgcXNt6?si=LiSswjejSGiYskRiirg-Tg&nd=1",
    appleLink:
      "https://podcasts.apple.com/us/podcast/jump-around-up-close-personal-josh-cole-cmo-sky-zone/id1529298351?i=1000508363052",
    duration: 52,
  },
  {
    episode: 8,
    title:
      "Soccer Shots VP of Marketing & Communications Ashley Mitchell w/Julie Samuels",
    subtitle:
      "Tune in to hear all about Soccer Shots - The Children's Soccer Experience. Learn how the brand stayed nimble in Covid Times and how they infused new thinking & energy to stay in the game. Enjoy the podcast with Julie Samuel's, Eulerity's Senior Director of Growth, as she chats with Ashley Mitchell, VP of Marketing and Communications at Soccer Shots.",
    spotifyLink: "https://open.spotify.com/episode/4N1mSToSRfaEtth2GfgSFc",
    duration: 25,
  },
  {
    episode: 7,
    title:
      "Filter free: A conversation with Matt McGowan - GM & Director, Snap",
    subtitle:
      "In this episode join Adam Chandler, COO & Co-Founder of Eulerity, in a wide ranging conversation with Snap's GM & Director, Matt McGowan. ",
    spotifyLink: "https://open.spotify.com/episode/0Eqb1EoT5uGlBRq6yXnlzl",
    duration: 46,
  },

  {
    episode: 6,
    title:
      "John Teza, President of Hand & Stone Massage, speaks with Eulerity in the rise of the Phoenix.",
    subtitle:
      "As companies old and new are navigating this “new normal”, what will allow some to emerge from the ashes? Challenges breed innovation. What new tactics and tools will the most successful companies of the 2020’s implement? From finance, to operations, to marketing - how must companies evolve to be more nimble, adaptable, and ultimately successful than ever before?",
    spotifyLink: "https://open.spotify.com/episode/4ltP8FH9BcCfu5xKGY555r",
    duration: 35,
  },
  {
    episode: 5,
    title:
      "Boots on the Ground: Let's hear from small business owners as they emerge from COVID-19",
    subtitle:
      "Join Julie Samuels, Sr. Director of Growth at Eulerity, as she has a conversation with business owner operators & franchisees from Cyclebar, Waxing the City, CertaPro Painters, & Blow Blo Dry Bar. Get a front seat to the day-to-day challenges and opportunities of owning and operating a small business as the world is transforming in front of our eyes.",
    spotifyLink: "https://open.spotify.com/episode/1sx88IUrmd8Ki7T4Ypm5sK",
    duration: 59,
  },
  {
    episode: 4,
    title:
      "Join Mark Montini, CMO, Tropical Smoothie Cafe & Eulerity discuss relevancy in Darwinian times.",
    subtitle:
      "Join Mark Montini, Tropical Smoothie Cafe's CMO, Tanuj Joshi, Eulerity's CEO and Adam Chandler, Eulerity's COO, as they discuss The Darwinian Times: Survival of the nimblest. As teams around the world see resources and budgets cut, how some industry pioneers are using this time to implement new radical ideas.",
    spotifyLink: "https://open.spotify.com/episode/785TK2JXMjW4Lx0RGICVgl",
    duration: 45,
  },
  {
    episode: 3,
    title: "Product Leadership in time of crisis: Facebook & Eulerity",
    subtitle:
      "Join Paresh Rajwat, Facebook's Head of Video and Music Products, & Tanuj Joshi, Eulerity's CEO & Co-Founder for a recorded podcast of their conversation on June 15 2020.",
    spotifyLink: "https://open.spotify.com/episode/6GktJSTbdTUZ2tr5sfXovK",
    duration: 44,
  },
  {
    episode: 2,
    title:
      "Join Ford Motor Co. Marla Skiko, Head of global marketing, discuss how Ford was built for America.",
    subtitle:
      "Join Eulerity's COO & Co-Founder Adam Chandler, and Marla Skiko, head of global media for Ford Motor Company, as they discuss the role Ford played to keep our world safe through the production of PPE equipment. Learn how Ford evolved their marketing strategies in a world of pandemic change, and get a perspective on how dealerships took on new technology and methods to interact with their consumers during an uncharted time.",
    spotifyLink: "https://open.spotify.com/episode/0d4WZuqBjJ5VpBB9OH6YUD",
    duration: 40,
  },
  {
    episode: 1,
    title:
      "Hear from The Little Gym President & CEO Alex Bingham discuss growing culture in a remote world.",
    subtitle:
      "One thing is clear. Yesterday’s business practices have evolved as we all grapple with today’s complexities. Listen to Eulerity's latest summer series conversation - Growing Culture In A Remote World - featuring Alex Bingham, President and CEO of The Little Gym International, and Adam Chandler, COO & Co-Founder of Eulerity.",
    spotifyLink: "https://open.spotify.com/episode/5SFjdDB01bjVb6pBhWTiyl",
    duration: 51,
  },
];
