import styled from 'styled-components';

export const CommonButton = styled.a`
  white-space: nowrap;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  -webkit-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
    0 1px 3px rgba(0, 0, 0, 0.08);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  background: ${(props) => props.bg || '#fff'};
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  color: ${(props) => props.color || '#6772e5'};
  text-decoration: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 1;
  &:hover {
    transform: translateY(-3px);
  }
`;
