import React from 'react';
import { Title, Author, Paragraph, MediumArticleContainer, EstimatedRead, GrayParagraph} from './style';
import Stocks from '../../assets/stocks-graphic.jpeg';
import Arrow from '../../assets/up-arrow-graphic.jpeg';
import Idea from '../../assets/idea.png';
import Paas from '../../assets/paas.png';

const FutureAsAServiceImplications = () => {
    return (
        <MediumArticleContainer>
            <Title> The Future as a Service & The Implications For Marketers </Title>
            <Author> By Adam Chandler, COO and Co-Founder, Eulerity &nbsp;
                <EstimatedRead> 2 min read </EstimatedRead>
            </Author>

            <Paragraph>
                As a follow up to our recent <a href="https://www.eulerity.com/future-as-a-service">“The Future as a Service”</a> article, written by our CEO Tanuj Joshi, I thought it may be helpful to lay out what this means for brands from a marketing perspective.
            </Paragraph>

            <Paragraph>
                The term, “everything as a service,” describes services subscription based and on demand that are available to consumers and businesses. Software as a service (SaaS) is derived from this concept.
            </Paragraph>

            <Paragraph>
                As brand marketers evaluate their options for marketing in 2021, their criteria expands into new frontiers outside of the traditional marketing expectations they look for in a partner (marketing performance, transparency, ROAS, etc).
            </Paragraph>

            <Paragraph>
                In a marketing software world, partner evaluation also includes cloud stability, corporate/business owner interoperability, customer usability, and operational scale.
            </Paragraph>

            <Paragraph>
                As marketing has gotten more complex than less, traditional marketing partners need to make the evolution to cloud based marketing automation to simply handle the rate of change and sheer amount of data a robust marketing platform will need to handle.
            </Paragraph>

            <Paragraph>
                So what are the characteristics of great SaaS companies in marketing automation? Here are a few:
            </Paragraph>

            <GrayParagraph>
                Low customer churn. Modern & scalable technology supports high growth.
            </GrayParagraph>

            <GrayParagraph>
                Triple digit average net retention. Complex technology is simplified for the customer. No manual needed, which provides device independence.
            </GrayParagraph>

            <GrayParagraph>
                Customer connection. They understand the service layer for their customers cold.
            </GrayParagraph>

            <Paragraph>
                As you create your marketing evaluation checklist for 2021, you should be thinking about which new standards you want to apply to your marketing partner list. Here’s a few questions to get you started:
            </Paragraph>

            <GrayParagraph>
                → How do they operate with your other mission critical apps used for business intelligence?
            </GrayParagraph>

            <GrayParagraph>
                → Is their data provided real-time on all devices? Desktop, Tablet, In-App customer experiences?
            </GrayParagraph>

            <GrayParagraph>
                → Are their attribution methods “proprietary,” or integrated with trusted third-party data analytics tools?
            </GrayParagraph>

            <GrayParagraph>
                → What is their core business and what is the company DNA?
            </GrayParagraph>

            <GrayParagraph>
                → Is their pricing model on demand or does it resemble lengthy contracts that make it hard to evolve from?
            </GrayParagraph>

            <Paragraph>
                This is just the tip of the iceberg. Customers should expect the best and brightest teams and most progressive technology that can scale with the needs of their business long term. After all, customer growth and retention is just too important in today’s unprecedented times.
            </Paragraph>

            <Paragraph>
                Eulerity is the world’s most efficient local marketing platform. Using machine learning and automation, Eulerity’s state-of-the-art technology simplifies the complex world of developing and executing digital marketing programs — all for a flat and transparent fee — a fraction of the cost of traditional vendors.
            </Paragraph>

            <Paragraph>
                <a href="https://www.eulerity.com"> Learn More About Eulerity </a>
            </Paragraph>
        </MediumArticleContainer>
    )
}

export default FutureAsAServiceImplications;