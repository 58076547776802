import React from 'react';
import './style.css';
const Timeline = () => {
  return (
    <div className='timeline'>
      <div className='timeline__header'>
        <h2>Our Journey</h2>
        <p>Driving a change</p>
      </div>
      <div className='timeline__content'>
        <div className='timeline__content-edge timeline__content-edge--first'>
          <h3 className='timeline__content-edge__title'>Start</h3>
        </div>
        <ul className='timeline__content-steps'>
          <li className='timeline__content-step'>
            <h4 className='timeline__content-step__title'>Summer 2017</h4>
            <p className='timeline__content-step__description'>
              Eulerity starts prototyping the first version of an easy to use
              advertising platform
            </p>
          </li>

          <li className='timeline__content-step'>
            <h4 className='timeline__content-step__title'>Spring 2018</h4>
            <p className='timeline__content-step__description'>
              Eulerity acquires its first prominent customers in New York city.
            </p>
          </li>
          <li className='timeline__content-step'>
            <h4 className='timeline__content-step__title'>Winter 2018</h4>
            <p className='timeline__content-step__description'>
              Eulerity starts working with prominent multi location franchise
              brands with thousands of locations.
            </p>
          </li>
          <li className='timeline__content-step'>
            <h4 className='timeline__content-step__title'>Winter 2019</h4>
            <p className='timeline__content-step__description'>
              Eulerity starts building fully customized enterprise deployments
              for Fortune 500 customers
            </p>
          </li>
        </ul>
        <div className='timeline__content-edge timeline__content-edge--last'>
          <h3 className='timeline__content-edge__title'>....</h3>
        </div>
      </div>
    </div>
  );
};

export default Timeline;
