import React from 'react'
import { EstimatedRead, MediumArticleContainer, Paragraph, Title, Author } from './style';


const HowToBuildASuccessfulMarketingTeam = () => {
  return (
    <MediumArticleContainer>
        <Title>How to Build A Successful Marketing Team</Title>
        <Author>Featuring Kelly Crompvoets, Vice President of Marketing, Any Lab Test Now &nbsp;
            <EstimatedRead>3 min read</EstimatedRead>
        </Author>

        <Paragraph>The great author and motivational speaker, Simon Sinek, once said: “When we tell people to do their jobs, we get workers. When we trust people to get the job done, we get leaders.” This hits the nail on the head when it comes to trusting and encouraging your employees. It’s not just about getting a job done, but more so about being passionate, supported and driven to do good work. So, how can you build a successful team that you trust?</Paragraph>
        <Paragraph>First, hire for character and competence. When you build trust in one another, you don’t have to be overbearing or check on every minute detail. You get to let the employee take the reigns in their work and understand that what they really need is encouragement and reassurance from you. As a leader, you have to focus on communication. The most important facet of communication itself is listening. When looking for a great team member, look for a great listener. So many people think about what they can say, the words they can use, or the time it takes them to answer a question. However, it’s important that people first listen in order to understand how a person processes things, how they want to be communicated with, and then take that back and use it for future conversations and correspondence.</Paragraph>
        <Paragraph>Don’t listen to answer or refute someone, but listen to process. There’s a big difference between having a conversation and just hearing someone. Whether it’s in an interview or a team onboarding, make sure you are tuned in, listening, and engaging with your team members. By listening to your team, you can learn how they process things, manage their time, and problem solve. Once you know this, you are better equipped to support, coach and mentor them to meet their team and career goals. When your team knows that you care about their career and life goals, it begins to build trust, which ultimately drives high productivity.</Paragraph>
        <Paragraph>Make sure that you don’t try to reverse engineer this strategy. If you push a worker too hard by demanding they show you how and why they should be worth your time, and simultaneously push off and delay speaking about their goals, you’ll lose both. Be in tune with what motivates your team, celebrate small goals, birthdays, milestones, lifetime achievements, and more. Remember the golden rule: treat people how you want to be treated. If you want a great, dedicated, driven leader on your marketing team, you need to be doing what it takes to nourish, encourage, and support one.</Paragraph>
        <Paragraph>Take into consideration both short term and long term goals. Ask yourself, how do you invest in your team members? Put together long term goals, whether or not your employee is set up to succeed and grow, even if they don’t stay with your personal brand. Their success is your success as a leader — you want to give them the resources and support they need in order to be the best they can be. Don’t get caught up in being someone’s one and done career move — nourish them and help them grow so that no matter where they end up career-wise, you’ll know you helped make a positive difference in their lives and both their personal and professional growth.</Paragraph>
    </MediumArticleContainer>
  )
}

export default HowToBuildASuccessfulMarketingTeam