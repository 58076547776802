export const Press = [
    {
        title: "Increasing Your Marketing Team’s Bandwidth Through Automation",
        description: "Article featured by the International Franchise Association about the seven daily digital marketing strategy and efforts every brand uses that can be simplified through automation.",
        by: "Mary Hanula, Senior Director of Marketing, Eulerity",
        date: "February 4, 2023",
        link: "https://www.franchise.org/blog/increasing-your-marketing-bandwidth-through-automation"
    },
    {
        title: "Why A Proper Digital Marketing Strategy is a Financial Advisor’s Secret Weapon",
        description: "Article featured in Entrepreneur that discusses the new era of digital marketing for financial advisors and how online advertising, brand presence and brand compliance can not only co-exist, but amplify a FA’s marketing.",
        by: "Adam Chandler, COO and Co-Founder, Eulerity",
        date: "January 24, 2023",
        link: "https://www.entrepreneur.com/growing-a-business/a-financial-advisors-secret-weapon-their-digital/442727",
    },
    {
        title: "10 Crucial Benefits and Tips for In-House Marketing",
        description: "Article featured in Entrepreneur that looks at the advantages of in-house marketing for brands, best practices, and how to know if it’s the right option for your business.",
        by: "Adam Chandler, COO and Co-Founder, Eulerity",
        date: "January 2, 2023",
        link: "https://www.entrepreneur.com/growing-a-business/10-crucial-tips-and-benefits-for-in-house-marketing/441160"
    },
    {
        title: "How Marketing Automation Solves a Brand’s Need for Speed",
        description: "Article featured by the International Franchise Association that breaks down what marketing automation is, how it can improve marketing team bandwidth, improvements to communication and online brand presence, and more.",
        by: "Mary Hanula, Senior Director of Marketing, Eulerity",
        date: "December 9, 2022",
        link: "https://www.franchise.org/blog/how-marketing-automation-solves-a-brands-need-for-speed"
    },
    {
        title: "The Benefits of Marketing Automation for Fast-Food Brands",
        description: "An article featured in QSR that discusses hard-hitting statistics and best practices for restaurant industry leaders regarding the adoption of marketing automation for brands.",
        by: "Mary Hanula, Senior Director of Marketing, Eulerity",
        date: "December 7, 2022",
        link: "https://www.qsrmagazine.com/outside-insights/benefits-marketing-automation-fast-food-brands"
    },
    {
        title: "Tackling 2022: How Hand & Stone Leveraged Digital Marketing Innovation",
        description: "An exclusive interview with Eric Haberacker, the Social Media Director of Hand & Stone, on how they leveraged marketing automation and Eulerity’s services.",
        by: "Mary Hanula, Senior Director of Marketing, Eulerity",
        date: "September 8, 2022",
        link: "https://www.franchise.org/franchise-information/marketing/tackling-2022-how-hand-stone-leveraged-digital-marketing-innovation"
    },
    {
        title: "The New Era of Digital Marketing: How Brands Are Embracing Technology",
        description: "An article in Franchising World Magazine discussing how brands can shift to digital marketing to elevate and optimize their business efforts, with quotes from Eulerity clients Clean Juice, Workout Anytime, and Blo Blow Dry Bar.",
        by: "Mary Hanula, Senior Director of Marketing, Eulerity",
        date: "September 1, 2022",
        link: "https://mydigitalpublication.com/publication/?m=24112&i=760241&p=40&ver=html5"
    },
    {
        title: "16 Franchise Podcasts for the Entrepreneurial-Minded",
        description: "Looking for advice and insights? Give these franchising podcasts a listen.",
        by: "Jeff Cheatham, featured in Entrepreneur",
        date: "August 1, 2022",
        link: "https://www.entrepreneur.com/article/431839"
    },
    {
        title: "What's in Your Podcast Stack? 10 franchise podcasts in a growing field",
        description: "Franchise Update Magazine Feature",
        by: "Jack Monson, featured in Franchise Update Magazine",
        date: "May 23, 2022",
        link: "https://www.franchising.com/articles/whats_in_your_podcast_stack_10_franchise_podcasts_in_a_growing_field.html"
    },
    {
        title: 'Eulerity Accelerates Growth With Key Hires',
        description: "Press Release — Company hires renowned business development, operations, and marketing leaders to kick off 2022",
        by: 'Mary Hanula, Director of Marketing, Eulerity',
        date: "February 22, 2022",
        link: 'https://www.prweb.com/releases/eulerity_accelerates_growth_with_key_hires/prweb18507526.htm',
    },
    {
        title: "4 Franchise Tools Every Franchise Needs to Have on Its Radar",
        description: "Tech makes it easier for a franchise brand to find digital tools and training.",
        by: "Jeff Cheatham, featured in Entrepreneur",
        date: "February 18, 2022",
        link: "https://www.entrepreneur.com/article/418105"
    },
    {
        title: '4 Keys to Building Digital Marketing During Omicron Shutdowns',
        description: 'QSR Magazine Feature',
        by: 'Mary Hanula, Director of Marketing, Eulerity',
        date: 'January 24, 2022',
        link: 'https://www.qsrmagazine.com/outside-insights/4-keys-building-digital-marketing-during-omicron-shutdowns',
    },
    {
        title: 'Apple’s Latest Ad Tracking Safeguards',
        description: 'IFA Feature',
        by: 'Tanuj Joshi, CEO and Co-Founder, Eulerity',
        date: 'December 2, 2020',
        link: 'https://www.franchise.org/franchise-information/technology/apples-latest-ad-tracking-safeguards',
    },
    {
        title: '6 Ways to Generate Profits Through Marketing Without Paying High Fees',
        description: 'QSR Magazine Feature',
        by: 'Adam Chandler, COO and Co-Founder, Eulerity',
        date: 'August 3, 2020',
        link: 'https://www.qsrmagazine.com/content/6-ways-generate-profits-through-marketing-without-paying-high-fees?_hsenc=p2ANqtz-89NYWImAoPjPEFYJ4PHgIcy-qq9JruIpKi4QbJkGM5Oqyyl-k62fLfYsHD1MizcvZOf8NTIh8XcVf1QSC58EFhjFu4k8Ne2YwixFwrkCVUwB2aCRI&_hsmi=2&utm_content=2&utm_medium=email&utm_source=hs_email',
    },
    {
        title: 'Eulerity Doubles Down on Helping Firms Digitize Their Marketing Infrastructure',
        description: 'Press Release — Taps Julie Samuels from Blink Fitness to lead growth efforts as demand for it’s software skyrockets since the crisis began',
        by: 'AIT News Desk',
        date: 'May 26, 2020',
        link: 'https://aithority.com/robots/automation/eulerity-doubles-down-on-helping-firms-digitize-their-marketing-infrastructure/',
    },
    {
        title: 'How Biggby Coffee Wrote a New Chapter in Change',
        description: 'QSR Magazine Feature',
        by: 'Adam Chandler, COO and Co-Founder, Eulerity',
        date: 'May 6, 2020',
        link: 'https://www.qsrmagazine.com/outside-insights/how-biggby-coffee-wrote-new-chapter-change',
    },
    {
        title: 'Good Business: How The Salad House is Making a Difference During the Pandemic',
        description: 'Modern Restaurant Management Feature',
        by: 'Adam Chandler, COO and Co-Founder, Eulerity',
        date: 'April 29, 2020',
        minute: '3 min',
        link: 'https://modernrestaurantmanagement.com/good-business-how-the-salad-house-is-making-a-difference-during-the-pandemic/',
    },
    {
        title: 'How to Communicate in a Time of Crisis: A Spotlight on Leading Food Service Brands',
        description: 'IFA Feature',
        by: 'Adam Chandler, COO and Co-Founder, Eulerity',
        date: 'August 11, 2020',
        link: 'https://www.franchise.org/blog/how-to-communicate-in-a-time-of-crisis-a-spotlight-on-leading-food-service-brands',
    },
    {
        title: 'Writing a New Chapter in Crisis Communication',
        description: 'Modern Restaurant Magazine Feature',
        by: 'Adam Chandler, COO and Co-Founder, Eulerity',
        date: 'April 11, 2020',
        minute: '8 min',
        link: 'https://modernrestaurantmanagement.com/writing-a-new-chapter-in-crisis-communication/',
    }
];
