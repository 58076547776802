import React from 'react';
import { ArticleContainer, Title, Author, Paragraph, Image, Position, EstimatedRead, Headers } from './style';
import TopTips from '../../assets/top-tips.png';
import PostIt from '../../assets/post-it.png';


const TopTipsToAvoidFatigue = () => {
    return (
        <ArticleContainer>
            <Title> Top Tips to Avoid Tech Fatigue </Title>
            <Author> By Mary Hanula,&nbsp;
                <Position>
                    Director of Marketing, Eulerity 
                </Position>
                <EstimatedRead> 5 min read </EstimatedRead> 
            </Author>

            <Image src={TopTips} alt="image"/>

            <Paragraph>
                Are you feeling tired of your every day routine? Trust me, I get it. The COVID-19 pandemic has been going on for over a year now, but the changes it brought are still at the forefront of each day. It wasn’t too long ago that millions of business professionals across the globe had to pivot to virtually working from home. Whether they knew how to or not is where the real ongoing issue is.
            </Paragraph>

            <Paragraph>
                For some, working remotely is now preferred. For others, they never quite got the hang of having to clock in each day by the click of a button. No matter how much you love being able to walk two steps to your desk or listen to whatever music you want without headphones (I’m a Springsteen or opera gal, there is no in-between), the one constant that we have counted on throughout all of this is technology. Yet, we would be lying if we said it wasn’t normal to be outright exhausted by it at times.
            </Paragraph>

            <Paragraph>
                Take it from us, respective leaders of the tech and marketing industry. Our company’s mission is to further technology and its availability to others. Does this mean we are immune to afternoon headaches and disrupted sleep? No. Going from a few hours on the computer each day to constantly staring at a screen is no small task, and you should be giving yourself kudos that you’ve been doing it for close to a year, but also because you’ve been trying your hardest. 
            </Paragraph>

            <Paragraph>
                It’s sad to see how frustrating we are on ourselves, but again, no one knew from the get-go that working from home would go from a treat to a standard. Technology is amazing and offers us so much., which is why I'm here to offer my top tips on avoiding a tech burnout, in hopes that they help your everyday life and WFH routine. 
            </Paragraph>

            <Image src={PostIt} alt="image" className="post-it"/>

            <Headers>
                1. Walk and talk
            </Headers>

            <Paragraph>
                If your schedule looks anything like mine, there are certain weekdays where you have check-in calls with peers, meetings with a client and/or a team discussion planned. If  your meeting is audio-only, try to take your next call while on a walk. Now that spring has arrived, the warmer weather is calling your name! Don’t equate work to simply sitting inside all day. If you can, tell your colleagues you’ll be joining the meeting while on a stroll. Giving them a heads up that you may not be next to your computer or staring at stats is key, as they will know what to expect. Who knows, you may inspire your team to make weekly walks your new standard.
            </Paragraph>

            <Headers>
                2. Set a timer for breaks throughout the day
            </Headers>

            <Paragraph>
                Remember when you were able to skip around and pick up lunch from around the corner? How about when you would go to your office kitchen and get another cup of coffee — maybe even pausing for conversation with some coworkers? Daily breaks are one thing that didn’t change due to working from home, yet sometimes we forget that. You must allow yourself time to stand up, stretch, read some of that new book you’re into, call a friend or eat a healthy lunch. If you make sure to set timers throughout the day for breaks, even if it’s a quick five minutes, the reward of downtime will most likely make you work harder to reach them.
            </Paragraph>

            <Headers>
                3. Write a checklist of to-do’s each morning
            </Headers>

            <Paragraph>
                This is one of my favorites. The sheer amount of satisfaction I get from writing a check-list is next level. Something about being able to physically check off another task is incredibly rewarding, especially when it feels like you have so many in one day. Or, maybe you feel like you’re not doing enough. A checklist is a great way to see just how much you truly get done in a day and visualize your hard work and impact. Here’s your chance to give yourself a few pats on the back. Plan your days out. Write both work and personal tasks on your list, so even a quick walk or home-gym workout is something that gets rewarded. What we’re all doing right now is living through a traumatic, unprecedented sequence of events. You are doing great. Having something to refer back to throughout the day will keep you focused, determined, aware and also excited to do each thing and be able to  wouldn’t regularly think of highlighting. Did I add an early morning run to my list? Absolutely!
            </Paragraph>

            <Headers>
                4. Change your work station up
            </Headers>

            <Paragraph>
                This one is not only easy, but fun! If you’re feeling tired of tech, take a minute or a morning and brainstorm how you can switch your desk setup. Could you add a higher laptop stand, lamp, pen holder, or a vase of fresh flowers? Just like an office or cubicle at work, maybe hang a few pictures up on the wall closest to your work station. One of my favorites is buying candles and having one there. It makes a world of difference, trust me!
            </Paragraph>

            <Headers>
                5. Create your go-to, feel good playlist
            </Headers>

            <Paragraph>
                As soon as you read that line, I know you had a few songs immediately pop into your head. Add them! Yes, the songs that bring you back to favorite memories of places, people and events. Create your own set on whatever streaming platform you use, choose a fun name for it and also a fun “cover” icon. All of these little details make it more personal, exciting and catered to you. Have your playlist ready first thing when you wake up, while you’re checking your daily emails, and/or during breaks where you let yourself dance for at least one minute. If you need any inspiration, check out Spotify’s Mood-Booster playlist <a href="https://open.spotify.com/playlist/37i9dQZF1DX3rxVfibe1L0?si=e3285bb9f535440c&nd=1">here</a>.
            </Paragraph>

            <Paragraph>
                I hope these tips bring you some inspiration on how to give yourself a little grace. Tech fatigue is real, but with one of these you may feel yours start to decrease and instead find a great addition to your day.
            </Paragraph>
        </ArticleContainer>
    )
}

export default TopTipsToAvoidFatigue;