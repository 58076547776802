// import React, { useEffect } from 'react';
// // import Proptypes from 'prop-types';
// import BB from '../../assets/customer-logo/iphonedash.svg';
// import { loadScript } from '../../helperFunction/helper';

// import {
//   Section,
//   SectionMasked,
//   BackgroundMask,
//   SectionBackground,
//   Guides,
//   GuideContainer,
//   Guide,
//   SectionContainer,
//   SectionLayout,
//   SectionLayoutContainer,
//   ColumnLayout,
//   Copy,
//   HeroHeader,
//   Gradient,
//   HeroTitleSecondary,
//   CopyBody,
//   CopyFooter,
//   CopyFooterContainer,
//   CtaButton,
//   HeroGraphic,
//   IphoneScreen,
//   PhoneGraphic
// } from './style';

// import Dashboard from '../dashboard/Dashboard';

// const Hero = () => {
//   useEffect(() => {
//     loadScript('/js/gradient.js');
//   }, []);

//   return (
//     <Section>
//       <SectionMasked>
//         <BackgroundMask>
//           <SectionBackground>
//             <Guides>
//               <GuideContainer>
//                 <Guide></Guide>
//                 <Guide></Guide>
//                 <Guide></Guide>
//                 <Guide></Guide>
//               </GuideContainer>
//             </Guides>
//           </SectionBackground>
//         </BackgroundMask>
//       </SectionMasked>

//       <SectionContainer>
//         <SectionLayoutContainer>
//           <SectionLayout>
//             <ColumnLayout data-columns='2,2'>
//               <Copy className='copy-variant'>
//                 <HeroHeader>
//                   <h1 className='hero_title'>
//                     Marketing infrastructure for the internet
//                   </h1>
//                   <Gradient className='gradient isLoaded'>
//                     <canvas
//                       id='gradient-canvas'
//                       className='Gradient__canvas isLoaded'></canvas>
//                   </Gradient>
//                   <HeroTitleSecondary className='hero_title title_overlay title_burn'>
//                     Marketing infrastructure for the internet
//                   </HeroTitleSecondary>
//                   <HeroTitleSecondary className='hero_title title_overlay '>
//                     Marketing infrastructure for the internet
//                   </HeroTitleSecondary>
//                 </HeroHeader>
//                 <CopyBody>
//                   Companies of all sizes—from startups to Fortune 500s—use
//                   Eulerity’s software and APIs to automate their marketing
//                   operations, optimize their ad spend, and manage their
//                   marketing efforts.
//                 </CopyBody>
//                 <CopyFooter>
//                   <CopyFooterContainer>
//                     <CtaButton
//                       className='cta variant-link'
//                       target="_blank"
//                       href='/login.jsp'>
//                       Sign In
//                     </CtaButton>

//                     <CtaButton
//                       className='variant_button cta cta-arrow cta variant-link'
//                       href={'/contact-us'}>
//                       Contact sales &rarr;
//                     </CtaButton>
//                   </CopyFooterContainer>
//                 </CopyFooter>
//               </Copy>
//               <HeroGraphic>
//                 <Dashboard />
//                 <PhoneGraphic className='phone'>
//                   <IphoneScreen>
//                     <img src={BB} alt='best burger reporting clay' />
//                   </IphoneScreen>
//                 </PhoneGraphic>
//               </HeroGraphic>
//             </ColumnLayout>
//           </SectionLayout>
//         </SectionLayoutContainer>
//       </SectionContainer>
//     </Section>
//   );
// };

// export default Hero;

import React from "react";

import { ReactComponent as Waves } from "../../assets/waves.svg";
import { ReactComponent as HeroImage } from "../../assets/hero-image.svg";
import { ActionsRow, CopyWrap, CtaButton, Subtitle, Title, Wrap } from "./style";

const Hero = () => {
    return (
        <Wrap>
            <CopyWrap>
                <Title>
                    Marketing infrastructure
                    <br />
                    for the internet
                </Title>
                <Subtitle>
                    Evolve your business with our powerful and
                    <br />
                    easy-to-use marketing automation software.
                </Subtitle>
                <ActionsRow>
                  <CtaButton className="variant_button cta cta-arrow cta variant-link" href={"/contact-us"}>
                    <div>
                      Contact Sales 
                      </div>
                      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.5 18H28.5" stroke="white" stroke-width="2.58333" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M18 7.5L28.5 18L18 28.5" stroke="white" stroke-width="2.58333" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

                  </CtaButton>
                </ActionsRow>
                <HeroImage id="hero-image" />
            </CopyWrap>
            <Waves id="waves" />
        </Wrap>
    );
};

export default Hero;
