import React, {useState, useEffect} from "react";
import YouTube from "react-youtube";
import {YoutubePlayer, SOIHMContainer, Title} from "./style";
import Footer from "../../components/footer/Footer";

const SOIHM = () => {
    const [dimensions, setDimensions] = useState(650);
    const [opts, setOpts] = useState({
        height: '390',
        width: '640',
        playerVars: {
          autoplay: 1,
        },
    });

    const getWindowDimensions = () => {
        const { innerWidth: width, innerHeight: height } = window;
        return {
          width,
          height
        };
    }

    useEffect(() => {
        window.addEventListener("resize", setDimensions(getWindowDimensions().width));
        setOpts(
            {
                ...opts,
                height: dimensions >= 650 ? '390' : '220',
                width: dimensions >= 650 ? '640' : '360',
            }
        )
    });
    
    const onPlay = (e) => {
        e.target.pauseVideo();
    };
    
    return (
        <SOIHMContainer>
            <Title> The Secrets of in House Marketing </Title>
            <YoutubePlayer>
                <YouTube videoId="Kj9ApFGUlMU" opts={opts} onReady={onPlay} />
            </YoutubePlayer>
            <Footer />
        </SOIHMContainer>
    );
}

export default SOIHM;