import React from 'react'
import { MediumArticleContainer, Title, Author, Paragraph, EstimatedRead, UnorderedList } from '../style';

const PowerOfStoryTelling = () => {
  return (
    <MediumArticleContainer>
      <Title>Brand Messaging: The Power of Storytelling</Title>
      <Author>
        Featuring Ashley Mitchell, Senior Vice President, Streamline Brands &nbsp;
        <EstimatedRead>2 min read</EstimatedRead>
      </Author>

      <Paragraph>Every brand has a story, but are you telling yours?</Paragraph>
      <Paragraph>There are many misconceptions about storytelling and brand awareness. Business owners may feel that their brand is already being heavily marketed, but there’s so much to marketing than simply reaching your consumers. You have to ask yourself how you’re reaching them day in and day out, what is appealing to them and how are you retaining them through your outreach and messaging?</Paragraph>
      <Paragraph>Some common challenges regarding storytelling include people thinking it’s too surface-level, too complicated, and too inauthentic. However, with the right mindset and tools, you can not only identify your story, but tell it in an authentic, encouraging and remarkable way.</Paragraph>
      <Paragraph>First, ask yourself what you try to convey to customers. What are you selling exactly? Is it swim lessons, or is it safety, confidence, and pride? Dig deeper and think about what it is that your services and products actually make your customer feel. What have you helped them achieve? If you’re telling a mother about the founding story about a brand, they may be interested, or they may just not care. They want to know how your brand will change their child’s life and why. Think about the emotions and actions that are direct effects of your brand’s services — what are they and how can they better a person? These are the very ingredients that form your brand’s special story.</Paragraph>
      <Paragraph>You need to identify the story about your brand to have someone buy into your brand. Search your messaging — is it generic, or is it poignant and different? You can use old and cliche tropes, but make sure you’re providing your own twist on it. Cookie cutter information is white noise and people won’t buy into or feel a connection with it. People make decisions based on emotions. Be authentic, be emotional, and be personal.</Paragraph>
      <Paragraph>Remember that it’s okay for messaging to vary! Whose shoes do you want to be in? Is it…</Paragraph>
      <UnorderedList>
        <li>The mother who wants to see her child overcome their fears?</li>
        <li>The child who wants to be able to say they are brave and strong?</li>
        <li>The person who wants to be a swim instructor for your brand and wants to know the day to day fulfillment?</li>
        <li>The person maybe looking to become a franchisee who wants to know why they should buy into the brand?</li>
      </UnorderedList>
      <Paragraph>These are each individual, inspiring stories to be told. Different stories can also serve different seasons. Make sure you are customizing your story to your region, geographical location, ICP, and current customers. It’s time to tell your brand’s story — we can’t wait to hear it!</Paragraph>

      <Paragraph>Watch the full episode <a href="https://www.youtube.com/watch?v=w7rqCseqA4s" target="_blank">here</a>. Make sure to <a href="https://us02web.zoom.us/webinar/register/7616606773963/WN_vgAA8cEcQhGPL96mpv24xQ" target="_blank">sign-up for live leadership conversations</a> on Eulerity’s Quick Fix series.</Paragraph>
    </MediumArticleContainer>
  )
}

export default PowerOfStoryTelling