import styled from 'styled-components';

const Section = styled.section`
  --sectionPaddingTopMax: 116;
  margin-top: 100px;
`;

const SectionMasked = styled.div`
  overflow: hidden;
`;

const BackgroundMask = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: var(--sectionBackgroundOverflow);
`;

const SectionBackground = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -webkit-transform-origin: var(--sectionTransformOrigin);
  transform-origin: var(--sectionTransformOrigin);
  -webkit-transform: skewY(var(--sectionAngle));
  transform: skewY(var(--sectionAngle));
  background: #fff;
  overflow: hidden;
`;

const Guides = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: var(--columnPaddingNormal);
  pointer-events: none;

  @media (max-width: 1111px) {
    max-width: var(--windowWidth);
  }
`;

const GuideContainer = styled.div`
  display: grid;
  grid: 1fr / repeat(var(--columnCountMax), 1fr);
  position: relative;
  max-width: var(--layoutWidth); /**1080px */
  height: 100%;
  margin: 0 auto;
`;
const Guide = styled.div`
  width: 1px;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(var(--guideDashedColor)),
    color-stop(50%, var(--guideDashedColor)),
    color-stop(50%, transparent),
    to(transparent)
  );
  background: linear-gradient(
    180deg,
    var(--guideDashedColor),
    var(--guideDashedColor) 50%,
    transparent 0,
    transparent
  );
  background-size: 1px 8px;
  &:first-of-type {
    background: var(--guideSolidColor);
  }
`;

const SectionContainer = styled.div`
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
`;

const SectionLayoutContainer = styled.div`
  width: 100%;
  max-width: var(--layoutWidth);
  margin: 0 var(--columnPaddingNormal);
`;

const SectionLayout = styled.div`
  --sectionAnglePaddingBottomBase: calc(
    var(--sectionAnglePaddingBottomBaseMax) * 1px
  );
  padding: 0 0 calc(110px * 1);
`;

const ColumnLayout = styled.div`
  --columnRowGap: var(--rowGapLarge);
  display: grid;
  row-gap: var(--columnRowGap);
  -webkit-box-align: start;
  align-items: flex-start;
  @media (min-width: 600px) {
    &[data-columns='2,2'],
    &[data-columns='2,1,0'],
    &[data-columns='2,1,1'],
    &[data-columns='2,2'] {
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;

const LogoGrid = styled.div`
  --gridColumnCount: 2;
  --gridRowGap: 30px;
  display: grid;
  grid: auto/repeat(var(--gridColumnCount), 1fr);
  justify-items: center;
  -webkit-box-align: center;
  align-items: center;
  row-gap: var(--gridRowGap);

  @media (min-width: 672px) {
    --gridColumnCount: 4;
  }
  img {
    border-radius: 0;
    box-shadow: none;
    height: 40px;
    image-rendering: -moz-crisp-edges; /* Firefox */
    image-rendering: -o-crisp-edges; /* Opera */
    image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
  }
  .customerlogo {
    --userLogoMaxWidth: 160px;
    display: block;
    width: 50%;
  }
`;

const CopyWrap = styled.div`
  width: 100%;
  max-width: 916px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 4rem;
`

const OurBrandTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  display: inline;
  white-space: nowrap;
  text-align: center;
`

const OurBrandSeparator = styled.div`
  width: 140px;
  height: 5px;
  background-color: #256DFE;
  border-radius: 2.5px;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
`

const CopyContent = styled.div`
  font-size: 18px;
  text-align: center;
  line-height: 170%;
`

export {
  Section,
  SectionMasked,
  BackgroundMask,
  SectionBackground,
  Guides,
  GuideContainer,
  Guide,
  SectionContainer,
  SectionLayout,
  SectionLayoutContainer,
  ColumnLayout,
  LogoGrid,
  CopyWrap,
  OurBrandTitle,
  OurBrandSeparator,
  CopyContent
};
