import styled from 'styled-components';
export const GlobalContent = styled.div`
  -webkit-box-flex: 1;
  flex-grow: 1;

  .isAnimated {
    animation-name: none;
    @media (min-width: 670px) {
      transition: transform 7s cubic-bezier(0.165, 0.84, 0.44, 1);
      animation: animteButton 5s infinite 5s;
    }
  }

  @keyframes animteButton {
    0% {
      transform: translateX(0px);
    }
    20% {
      transform: translateX(-7px);
    }
    40% {
      transform: translateY(70px);
    }
    60% {
      transform: translateY(130px);
    }
    70% {
      transform: translate(0px, 190px);
    }
    80% {
      transform: translate(0px, 130px);
    }
    90% {
      transform: translate(0px, 70px);
    }

    100% {
      transform: translate(0px, 0px);
    }
  }
`;

export const Main = styled.main`
  font-family: 'Open Sans', sans-serif;
`;

////*********hero******/
export const Hero = styled.section`
  .color-green-bg {
    background: #1fdd95;
    color: #fff;
  }
  .color-cyan {
    color: #87a2ff;
  }
  a {
    cursor: pointer;
  }
`;

export const HeroIntroTop = styled.div``;

export const HeroIntro = styled.div`
  display: flex;
  padding: 50px 15px 140px;
  max-width: 1040px;
  margin: 0 auto 80px;
  flex-wrap: wrap;

  @media (min-width: 670px) {
    flex-wrap: nowrap;
    padding: 80px 20px 100px;
    margin: 0 auto 120px;
  }

  @media (min-width: 670px) {
    & > div {
      flex-basis: 45%;
    }
  }
`;

export const HeroIntroContent = styled.div`
  @media (min-width: 670px) {
    flex-basis: 90% !important;
  }
  @media (min-width: 880px) {
    flex-basis: 54% !important;
  }
  & > div:first-of-type:last-of-type {
    flex-basis: 100%;
  }
  @media (min-width: 880px) {
    & > div:first-of-type:last-of-type {
      flex-basis: 65%;
    }
  }
`;

export const ControlTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
export const ControlIcon = styled.figure`
  margin-right: 16px;

  @media (min-width: 1040px) {
    margin-left: -20px;
  }
`;
export const ControlContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;

  @media (min-width: 420px) {
    flex-direction: row;
    align-items: center;
    margin-top: 0;
  }
`;
export const CommonUpperCaseTitle = styled.h1`
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;

  @media (min-width: 670px) {
    font-size: 21px;
    line-height: 32px;
  }
`;

export const HeroIntroTitle = styled.div`
  margin-top: 10px;

  @media (min-width: 670px) {
    width: 150%;
  }
`;

export const HeroTitle = styled.h1`
  display: flex;
  flex-wrap: wrap;
  font-weight: 400;
  font-size: 28px;
  line-height: 40px;

  @media (min-width: 375px) {
    font-size: 35px;
    line-height: 48px;
  }

  @media (min-width: 670px) {
    font-size: 53px;
    line-height: 68px;
  }

  span {
    color: #87a2ff;
    margin-left: 10px;
    @media (min-width: 670px) {
      margin-left: 2px;
    }
  }
`;
export const CommonTitle = styled.h1`
  font-weight: 300;
  font-size: 45px;
  line-height: 56px;
  color: #32325d;
  letter-spacing: -0.01em;

  @media (min-width: 670px) {
    font-size: 53px;
    line-height: 68px;
  }
`;

export const CommonBodyTittle = styled.h1`
  font-weight: 500;
  font-size: 19px;
  line-height: 32px;
  color: #32325d;

  &.normal {
    color: #32325d;
  }
`;

export const HeroIntroText = styled.div`
  margin-top: 16px;
`;

export const CommonIntro = styled.p`
  font-weight: 300;
  font-size: 21px;
  line-height: 32px;
  color: #424770;
  font-family: 'Open Sans', sans-serif;
  @media (min-width: 670px) {
    font-size: 24px;
    line-height: 36px;
  }

  &.color-blue {
    color: #32325d;
  }
`;

export const CommonButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 670px) {
    margin-top: 32px;
  }

  a {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin: 10px 10px 10px 0;
  }

  a:not(:last-child) {
    margin-right: 12px;
  }
  &.common {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
`;

export const CommonButton = styled.a`
  white-space: nowrap;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  -webkit-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
    0 1px 3px rgba(0, 0, 0, 0.08);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  background: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  color: #000;
  text-decoration: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;

  &.default {
    background: #1fdd95;
    color: #fff;
  }
  &:hover {
    transform: translateY(-3px);
  }
`;

////************ */
export const Logo = styled.span`
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.medium {
    height: 48px;
    width: 48px;
  }

  &.color-green-bg {
    background: #1fdd95;
    color: #fff;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const SectionMbaye = styled.section`
  margin-bottom: 64px;
  @media (min-width: 670px) {
    margin-bottom: 10px;
  }
  @media (min-width: 1285px) {
    margin-top: 400px;
  }
`;

export const MbayeContainer = styled.div`
  max-width: 940px;

  @media (min-width: 670px) {
    margin-top: -100px;
  }
`;

export const SectionIntro = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 50px 20px 0;
  margin-bottom: 50px;
  text-align: center;
  @media (min-width: 670px) {
    padding: 20px 20px 0;
    margin-bottom: 80px;
  }
`;

export const SectionIntroTitle = styled.div`
  display: flex;
  align-items: center;
  margin: 16px auto;

  @media (min-width: 670px) {
    margin-bottom: 20px;
  }
`;

export const SectionLayoutColumns = styled.div`
  display: flex;
  font-family: 'Open Sans', sans-serif;
  flex-direction: column;
  max-width: 1040px;
  padding: 0 20px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;

  @media (min-width: 670px) {
    display: grid;
    grid-column-gap: 40px;
    grid-row-gap: 40px;
  }

  @media (min-width: 670px) {
    &.layoutColumns--2 {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (min-width: 670px) {
    &.layoutColumns--3 {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media (min-width: 670px) {
    &.layoutColumns--4 {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }
  }
  @media (min-width: 880px) {
    &.adama_columns:last-of-type {
      margin-top: 40px;
    }
  }
`;

export const LayoutColumn = styled.div``;

export const FeatureDetail = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  @media (min-width: 670px) {
    margin-bottom: 0;
  }
`;

export const FeatureDetailIcon = styled.div`
  margin: 0 16px 20px 0;
  display: flex;
`;

export const FeatureDetailContent = styled.div``;

export const FeatureDetailTitleContent = styled.div`
  margin-top: 8px;
`;

export const FeatureDetailBody = styled.div`
  margin-top: 8px;
`;
export const CommonBodyText = styled.p`
  font-weight: 400;
  font-size: 17px;
  line-height: 28px;
  color: #525f7f;
  font-family: 'Open Sans', sans-serif;
`;

export const SectionMaye = styled.section`
  .carousel-cell {
    width: 100%; /* full width */
    height: 400px; /* height of carousel */
    margin-right: 10px;
    text-align: center;
    font-size: 2rem;
    display: flex;
    flex-direction: column;

    @media (min-width: 670px) {
      flex-direction: row;
      text-align: left;
    }
    .logo {
      flex: 40%;
    }

    .logoContainer1 {
      position: relative;
      height: 200px;
      background: #a3cc06;
      overflow: hidden;

      @media (min-width: 670px) {
        height: 400px;

        clip-path: polygon(0 1%, 100% 0, 78% 100%, 0% 100%);
      }

      img {
        z-index: 50;
        margin: 50px 100px;
        width: 200px;
        border-radius: 0;
        box-shadow: none;
        background: none;

        @media (min-width: 670px) {
          margin: 150px 100px;
        }
      }
    }

    .logoContainer2 {
      position: relative;
      height: 200px;
      background: #1e355e;
      overflow: hidden;
      @media (min-width: 670px) {
        height: 400px;
        z-index: 2;
        clip-path: polygon(0 1%, 100% 0, 78% 100%, 0% 100%);
      }

      img {
        z-index: 50;
        margin: 50px 100px;
        width: 200px;
        border-radius: 0;
        box-shadow: none;
        background: none;
        @media (min-width: 670px) {
          margin: 120px 120px;
        }
      }
    }

    .logoContainer3 {
      position: relative;
      height: 200px;
      background: #c41f30;
      overflow: hidden;

      @media (min-width: 670px) {
        height: 400px;
        z-index: 2;
        clip-path: polygon(0 1%, 100% 0, 78% 100%, 0% 100%);
      }
      img {
        z-index: 50;
        margin: 50px 100px;
        width: 200px;
        border-radius: 0;
        box-shadow: none;
        background: none;

        @media (min-width: 670px) {
          margin: 150px 100px;
        }
      }
    }

    .testimonialContainer {
      flex: 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      @media (min-width: 670px) {
      }

      blockquote {
        font-family: 'Opens Sans', sans-serif;
        font-size: 19px;
        color: #525f7f;
        position: relative;
        font-style: italic;
        font-weight: 400;
        font-size: 19px;
        line-height: 32px;

        &.long-text {
          font-size: 16px;
          line-height: 1;
          overflow: visible;
          @media (min-width: 670px) {
            font-size: 19px;
            line-height: 32px;
          }
        }
      }

      .person {
        display: flex;
        flex-direction: column;
        align-items: center;
        .name {
          font-size: 17px;
          line-height: 28px;
          font-weight: 600;
          text-transform: uppercase;
          letter-spacing: 0.025em;
          color: #32325d;
        }

        .role {
          font-weight: 400;
          font-size: 17px;
          line-height: 28px;
          color: #525f7f;
          margin-bottom: 30px;
        }
      }
    }
  }
`;

export const CommonSectionTitle = styled.h2`
  font-weight: 400;
  font-size: 34px;
  line-height: 44px;
  color: #32325d;

  @media (min-width: 670px) {
    font-size: 42px;
    line-height: 52px;
  }

  &.work_title {
    margin-bottom: 40px;
    text-align: center;
  }
  &.adama_title {
    margin-bottom: 40px;
    text-align: center;

    @media (min-width: 880px) {
      margin-bottom: 80px;
    }
  }
`;

// export const CommonGrid = styled.div`
//   --stripe-height: 48px;
//   --content-columns: 12;
//   --gutter-columns: 4;
//   --content-column-width: minmax(0, calc(1040px / var(--content-columns)));
//   --gutter-column-width: 1fr;
//   position: absolute;
//   width: 100%;
//   top: 0;
//   bottom: 0;
//   z-index: -1;
//   pointer-events: none;

//   &.backgroundContainer,
//   &.stripeContainer {
//     display: -webkit-box;
//     display: -ms-flexbox;
//     display: flex;
//     -webkit-box-orient: vertical;
//     -webkit-box-direction: normal;
//     -ms-flex-direction: column;
//     flex-direction: column;
//     -webkit-box-align: center;
//     -ms-flex-align: center;
//     align-items: center;
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     -webkit-transform: skewY(-12deg);
//     transform: skewY(-12deg);
//   }
// `;

/**********adama *******/

export const SectionAdama = styled.div`
  position: relative;
  padding: 160px 0;

  @media (min-width: 670px) {
    padding-bottom: 220px;
  }

  .backgroundColor {
    background: #f6f9fc;
  }
  .backgroundContainer {
    top: 70%;
  }
  .testStripes .stripe:first-child {
  }
  .testStripes .stripe:nth-child(2) {
  }

  .testStripes .stripe:nth-child(3) {
  }

  .testStripes .stripe:nth-child(4) {
  }
`;
