import React from 'react';
import covidimg from '../../assets/covidimg.png';
import covidimg2 from '../../assets/covidImg2.png';
import Button from '../../components/buttons/Button';
import './covid.css';
import Footer from '../../components/footer/Footer';
const Faq = ({ title, question, response, list }) => {
  return (
    <div className='faq'>
      <h1>{title}</h1>
      <div className='faqContent'>
        <h3 className='faqQuestion'>Q:{question}</h3>
        <p className='faqText'>A: {response}</p>
      </div>
    </div>
  );
};
const Covid = () => {
  return (
    <>
      <div className='covid'>
        <div className='container-lg'>
          <div className='titleContent'>
            <h1 className='titleContent_title'>
              We are pitching in to help you re-open post COVID-19
            </h1>
            <p className='titleContent_text'>
              Eulerity understands what you’ve been through. It’s no mean feat.
              We have designed special programs while we all rebuild, together.
            </p>
          </div>

          <div className='gradual'>
            <div className='gradual_img'>
              <img src={covidimg} alt='covid img' />
            </div>

            <div className='gradual_textContent'>
              <h2>Option 1 - Gradual Ramp Up & Unlock Ad Credits</h2>
              <ul className='gradual_list'>
                <li className='gradual_item'>
                  Simply choose your target advertising spend by hitting the
                  button below.
                </li>
                <li className='gradual_item'>
                  Our system will transition you from your current state to your
                  target budget over 3 months.
                </li>
                <li className='gradual_item'>
                  Advertising credits automatically unlocked along the way.
                </li>
              </ul>
              <ul className='gradual_secondaryList'>
                <li>30% Ad Credits for Month 1 </li>
                <li>20% Ad Credits for Month 2 </li>
                <li>10% Ad Credits for Month 3 </li>
              </ul>
              <Button color='#fff' bg='#256DFE' href='/contact-us'>
                I'm In
              </Button>
            </div>
          </div>
          <hr className='hr' />

          <div className='scale'>
            <div className='scaleContent'>
              <h2>Option 2 - Scale now and unlock even more ad credits</h2>
              <p>
                You know your business better than anyone else. Simply unlock
                the right budget for your business by going into the Eulerity
                App on your phone. Eulerity will automatically unlock ad credits
                for you every month. Your Eulerity account manager will send you
                a confirmation as soon as you increase your budget.{' '}
              </p>
              <ul className='scale_list'>
                <li>30% Ad Credits for Month 1 </li>
                <li>30% Ad Credits for Month 2 </li>
                <li>30% Ad Credits for Month 3 </li>
              </ul>
            </div>
            <div className='scaleImg'>
              <img src={covidimg2} alt='covid img' className='' />
            </div>
          </div>

          <hr className='hr' />

          <div className='schedule'>
            <h2>
              Can’t decide which option to go with or have other questions?
            </h2>
            <p>
              No worries. We’ve got you covered. Our specialists are here to
              help 24x7.
            </p>
            <Button
              color='#fff'
              bg='#256DFE'
              href='https://calendly.com/support-798/eulerity-check-in?month=2020-04'>
              schedule my appointment now
            </Button>
          </div>
          <Faq
            title='FAQ'
            question='Can I change from one plan to another mid way?'
            response='Yes, you can. Simply reach out to your account manager or drop us a line at support@eulerity.com'
          />
          <Faq
            question='What are some special ways in which Eulerity is handling advertising post the pandemic?'
            response='Eulerity is working with your corporate team to target your existing customers heavily after you re-open. We have invested heavily in re-targeting based strategies to show more ads to your website visitors. In some special cases, we are also enabling Customer Database Targeting. To learn more, please reach out to your account manager or drop us a line at support@eulerity.com'
          />
          <Faq
            question='Why advertise if I’m not open fully yet?'
            response="While demand may be subdued during the height of the COVID-19 crisis, that doesn’t mean it's going away. Internet traffic levels are at all time highs as more consumers are working from home. With a spike in researching products and services maintaining an internet marketing presence will help your current and future customers know you will be back. Advertising during this time helps maintain your brand within your community. Given the COVID-19 crisis consumers have a renewed need to understand how your company is meeting new consumer demands like safety, flexibility, and more. "
          />
          <Faq
            question='Why should I increase budgets on Eulerity as I re-open if I’m using Facebook to do posts?'
            response="First, you can track the performance of your ads beyond clicks or likes with Eulerity. We track conversions consumers take post click, which creative is responsible for more performance, etc. Understanding your return on investment from boosted posts can be challenging. Second, a boosted post JUST runs on Facebook. All of your marketing dollars shouldn't go to just one place on the internet. With Eulerity, we run your ads on 1000’s of the top internet sites, paid google search ads, YouTube, in addition to Facebook. Finally, a boosted post will advertise your single creative unit to consumers on Facebook. With Eulerity, your single creative is multiplied into additional sizes and formats across all major digital channels, giving your advertising more reach and increased chances to resonate and convert existing and new local customers."
          />
          <Faq
            question='What are some of the other resources available to Small Business’s during this time?'
            list
          />

          <ul className='plan'>
            <li>
              <a href='https://covid19relief.sba.gov/#/' target='__blank'>
                SBA EIDL PLAN
              </a>
            </li>
            <li>
              <a
                href='https://www.sba.gov/funding-programs/loans/coronavirus-relief-options/paycheck-protection-program-ppp'
                target='__blank'>
                SBA PPP PLAN  --> Contact your bank directly
              </a>
            </li>
            <li>
              <a
                href='https://www.sba.gov/funding-programs/loans/coronavirus-relief-options/sba-debt-relief'
                target='__blank'>
                SBA DEBT RELIEF
              </a>
            </li>
            <li>
              <a
                href='https://www.sba.gov/document/support--express-bridge-loan-pilot-program-guide'
                target='__blank'>
                SBA EXPRESS BRIDGE LOAN 
              </a>
            </li>
            <li>
              <a
                href='https://www1.nyc.gov/site/sbs/businesses/covid19-business-financial-assistance.page'
                target='__blank'>
                YOUR LOCAL CITY/STATE PROGRAMS
              </a>
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Covid;
