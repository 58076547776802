import styled from 'styled-components';
import bg from '../../assets/Cutting Edge Technology Desaturation.png';
export const GlobalContent = styled.div`
  -webkit-box-flex: 1;
  flex-grow: 1;
`;
export const Main = styled.main``;

export const CommonButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 670px) {
    margin-top: 32px;
  }

  a {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin: 10px 10px 10px 0;
  }

  a:not(:last-child) {
    margin-right: 12px;
  }
  &.common {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
`;

export const HeroSection = styled.section`
  padding: 150px 0 216px;

  &.container {
    position: relative;
  }
  .testStripes .stripe:first-child {
    background-color: #256dfe;
    grid-row: -2;
    grid-column: 1 / span 5;
  }
  .testStripes .stripe:nth-child(2) {
    background-color: #256dfe;
    grid-row: -3;
    grid-column: span 7 / -1;
  }

  .testStripes .stripe:nth-child(3) {
    background-color: #87a2ff;
    grid-row: -2;
    grid-column: span 5 / -1;
  }
  .testStripes .stripe:nth-child(4) {
    -webkit-box-shadow: inset 0 0 0 1.5px #e6ebf1;
    box-shadow: inset 0 0 0 1.5px #e6ebf1;
    grid-row: -3;
    grid-column: 7 / span 5;
  }
  .testStripes .stripe:nth-child(5) {
    -webkit-box-shadow: inset 0 0 0 1.5px #e6ebf1;
    box-shadow: inset 0 0 0 1.5px #e6ebf1;
    grid-row: -4;
    grid-column: 8 / span 10;
  }
  .testStripes .stripe:nth-child(7) {
    -webkit-box-shadow: inset 0 0 0 1.5px #e6ebf1;
    box-shadow: inset 0 0 0 1.5px #e6ebf1;
    grid-row: -4;
    grid-column: 15 / span 5;
  }
`;

export const HeroContainer = styled.div`
  display: flex;
  margin-left: 20px;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: 670px) {
    flex-direction: row;
    margin-top: -10px;
  }
  @media (min-width: 1285px) {
    flex-direction: row;
    margin-top: -10px;
    margin-left: 0;
  }
`;
export const HeroContent = styled.div`
  @media (min-width: 670px) {
    flex-basis: 60%;
  }
`;

export const Graphic = styled.aside`
  display: none;
  width: 400px;
  height: 350px;
  padding: 0;
  transform: perspective(900px) rotateY(-10deg);
  background-image: url(${bg});
  z-index: 5;
  box-shadow: 0 6px 12px -2px rgba(50, 50, 93, 0.25),
    0 3px 7px -3px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  overflow: hidden;

  @media (min-width: 670px) {
    flex-basis: 40%;
    display: block;
  }
  h1 {
    color: black;
    text-align: center;
    padding: 50px;
  }

  img {
    border-radius: 0;
    width: 110%;
    height: 350px;
  }
`;
export const CommonTitle = styled.h1`
  font-weight: 300;
  font-size: 45px;
  line-height: 56px;
  color: #32325d;
  letter-spacing: -0.01em;

  @media (min-width: 670px) {
    font-size: 53px;
    line-height: 68px;
    margin-top: 10px;
  }
`;

export const WizardSection = styled.section`
  background: #f6f9fc;
  padding: 0;
`;

export const SectionLayoutColumns = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1040px;
  padding: 0 20px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;

  @media (min-width: 670px) {
    display: grid;
    grid-column-gap: 40px;
    grid-row-gap: 40px;
  }

  @media (min-width: 670px) {
    &.layoutColumns--2 {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (min-width: 670px) {
    &.layoutColumns--3 {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media (min-width: 670px) {
    &.layoutColumns--4 {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  @media (min-width: 880px) {
    &.adama_columns:last-of-type {
      margin-top: 40px;
    }
  }
`;

export const Santa = styled.section`
  position: relative;
  z-index: 1;
  padding: 80px 20px 40px;
  margin-top: 30px;
  @media (min-width: 1160px) {
    padding: 20px 0 40px;
    margin-top: -30px;
  }
`;

export const CommonUpperCaseTitle = styled.h2`
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;

  @media (min-width: 670px) {
    font-size: 21px;
    line-height: 32px;
  }
`;
