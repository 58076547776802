import React from 'react';
import TOS from "../unconnected/terms/TOS";

export const TermsObj = {
    arWorkshop: <TOS title="AR Workshop" effectiveDate="February 8, 2022" duration="180 days" locationFee="150"
                altMonthly="150/month will be deducted from the primary subscription media spend and $49/month will be deducted from any profile subscriptions' media spend. " />,
    bigChicken: <TOS title="Big Chicken" effectiveDate="February 8, 2022" duration="180 days" locationFee="125"
                altMonthly="125/month will be deducted from the primary subscription media spend and $49/month will be deducted from any profile subscriptions' media spend. " />,
    blo: <TOS title="Blo Blow" effectiveDate="February 15, 2022" duration="120 days" locationFee="99"
                altMonthly="99/month will be deducted from the primary subscription media spend. " />,
    cleanJuice: <TOS title="Clean Juice" effectiveDate="February 8, 2022" duration="180 days" locationFee="1" />,
    freeCoatNails: <TOS title="Free Coat Nails" effectiveDate="February 8, 2022" duration="180 days" locationFee="99"
                altMonthly="99/month will be deducted from the primary subscription media spend and $49/month will be deducted from any profile subscriptions' media spend. "/>,
    hammerAndNails: <TOS title="Hammer and Nails" effectiveDate="February 8, 2022" duration="180 days" locationFee="99"
                altMonthly="99/month will be deducted from the primary subscription media spend and $49/month will be deducted from any profile subscriptions' media spend. " />,
    kitchenSolvers: <TOS title="Kitchen Solvers" effectiveDate="February 8, 2022" duration="90 days" locationFee="165"
                altMonthly="165/month will be deducted from the primary subscription media spend and $49/month will be deducted from any profile subscriptions' media spend. "/>,
    koalaInsulation: <TOS title="Koala Insulation" effectiveDate="September 16, 2022" locationFee="149" duration="180 days"
                altMonthly="149/month will be deducted from the primary subscription media spend and $49/month will be deducted from any profile subscriptions' media spend. " />,
    littleCaesars: <TOS title="Little Caesars" effectiveDate="February 8, 2022" duration="180 days" locationFee="99" />,
    massageHeights: <TOS title="Massage Heights" effectiveDate="February 8, 2022" duration="180 days" locationFee="125"
                altMonthly="125/month will be deducted from the primary subscription media spend and $49/month will be deducted from any profile subscriptions' media spend. "/>,
    mathnasium: <TOS title="Mathnasium" effectiveDate="February 8, 2022" duration="180 days" locationFee="99"
                altMonthly="99/month will be deducted from the primary subscription media spend and $49/month will be deducted from any profile subscriptions' media spend. "/>,
    officeEvolution: <TOS title="Office Evolution" effectiveDate="February 8, 2022" duration="180 days" locationFee="125"
                altMonthly="125/month will be deducted from the primary subscription media spend and $49/month will be deducted from any profile subscriptions' media spend. "/>,
    rockinJump: <TOS title="Rockin Jump" effectiveDate="October 1, 2022" duration="90 days" locationFee="110"
                altMonthly="110/month will be deducted from the primary subscription media spend and $49/month will be deducted from any profile subscriptions' media spend. "/>,
    skyZone: <TOS title="Sky Zone" effectiveDate="October 1, 2022" duration="90 days" locationFee="110"
                altMonthly="110/month will be deducted from the primary subscription media spend and $49/month will be deducted from any profile subscriptions' media spend. "/>,
    spavia: <TOS title="Spavia" effectiveDate="February 8, 2022" duration="120 days" locationFee="175"
                altMonthly="175/month will be deducted from the primary subscription media spend and $49/month will be deducted from any profile subscriptions' media spend. "/>,
    streamLineBrands: <TOS title="Streamline Brands" effectiveDate="February 8, 2022" duration="" locationFee="1"
                altCancel="You may cancel your local subscription with Eulerity after the subscription has been active for a minimum of 90 days. Please contact your corporate team to request an account cancelation. Subscriptions will be deactivated at the conclusion of the current subscription cycle. "/>,
    theLittleGym: <TOS title="The Little Gym" effectiveDate="April 1st, 2023" duration="120 days" locationFee="119"
                altMonthly="119/month will be deducted from the primary subscription media spend and $49/month will be deducted from any profile subscriptions' media spend. "/>,
    snapology: <TOS title="Snapology" effectiveDate="April 1st, 2023" duration="180 days" locationFee="119" altMonthly="119/month will be deducted from the primary subscription media spend and $49/month will be deducted from any profile subscriptions' media spend. "/>,
    titleBoxing: <TOS title="Title Boxing" effectiveDate="February 8, 2022" duration="180 days" locationFee="99"
                altMonthly="99/month will be deducted from the primary subscription media spend and $49/month will be deducted from any profile subscriptions' media spend. "/>,
    twistBrands: <TOS title="Twist Brands" effectiveDate="February 8, 2022" duration="120 days" locationFee="75"
                altMonthly="75/month will be deducted from the primary subscription media spend and $49/month will be deducted from any profile subscriptions' media spend. " />,
    visionSource: <TOS title="Vision Source" effectiveDate="February 8, 2022" duration="180 days" locationFee="70"
                altMonthly="70/month will be deducted from the primary subscription media spend and $49/month will be deducted from any profile subscriptions' media spend. "/>,
    woodhouseDaySpa: <TOS title="Woodhouse Day Spa" effectiveDate="February 8, 2022" duration="180 days" locationFee="179"
                altMonthly="179/month will be deducted from the primary subscription media spend and $49/month will be deducted from any profile subscriptions' media spend. "/>,
    workoutAnytime: <TOS title="Workout Anytime" effectiveDate="February 8, 2022" duration="90 days"locationFee="85"
                altMonthly="85/month will be deducted from the primary subscription media spend and $49/month will be deducted from any profile subscriptions' media spend. "/>,
    wowWowLemonade: <TOS title="Wow Wow Lemonade" effectiveDate="February 8, 2022" duration="180 days" locationFee="99"
                altMonthly="99/month will be deducted from the primary subscription media spend and $49/month will be deducted from any profile subscriptions' media spend. " />,
    wow1Day: <TOS title="Wow1Day" effectiveDate="February 8, 2022" duration="120 days" locationFee="99"
                altMonthly="99/month will be deducted from the primary subscription media spend and $49/month will be deducted from any profile subscriptions' media spend. "/>,
    woWorks: <TOS title="WOWorks" effectiveDate="February 8, 2022" duration="120 days" locationFee="99"
                altMonthly="99/month will be deducted from the primary subscription media spend and $49/month will be deducted from any profile subscriptions' media spend. "/>,
    buddysHomeFurnishing: <TOS title="Buddy's Home Furnishing" effectiveDate="February 1, 2022" duration="180 days" locationFee="129"
                altMonthly="75/month will be deducted from the primary subscription media spend and $49/month will be deducted from any profile subscriptions' media spend. "/>,
    premiumServiceBrands: <TOS title="Premium Service Brands" effectiveDate="Wednesday, January 1, 2022" duration="180 days" locationFee="99"
                altMonthly="125/month will be deducted from the primary subscription media spend and $49/month will be deducted from any profile subscriptions' media spend. " />,
    rockBoxFitness: <TOS title="RockBox Fitness" effectiveDate="March 28th, 2022" duration="180 days" locationFee="99"
                altMonthly="1/month will be deducted from the subscription media spend. " />,
    handAndStone: <TOS title="Hand and Stone" effectiveDate="February 28, 2022" duration="180 days" locationFee="1"
                altMonthly="1/month will be deducted from the subscription media spend. " />,
    centralBark: <TOS title="Central Bark" effectiveDate="April 22nd, 2022" duration="180 days" locationFee="99"
                altMonthly="129/month will be deducted from the primary subscription media spend and $49/month will be deducted from any profile subscriptions' media spend. " />,
    fitBodyBootCamp: <TOS title="Fit Body Boot Camp" effectiveDate="April 27th, 2022" duration="180 days" locationFee="99"
                altMonthly="129/month will be deducted from the primary subscription media spend and $49/month will be deducted from any profile subscriptions' media spend. " />,
    littleKitchenAcademy: <TOS title="Little Kitchen Academy" effectiveDate="May 24th, 2022" duration="180 days" locationFee="99"
                altMonthly="129/month will be deducted from the primary subscription media spend and $49/month will be deducted from any profile subscriptions' media spend. " />,
    premierMartialArts: <TOS title="Premier Martial Arts" effectiveDate="July 6, 2022" duration="180 days" locationFee="1" />,
    xponentialFitness: <TOS title="Xponential Fitness" effectiveDate="June 17, 2022" duration="180 days" locationFee="99"
                altMonthly="299/month will be deducted from the primary subscription media spend and $99/month will be deducted from any profile subscriptions' media spend. " />,
    firstLightHomeCare: <TOS title="FirstLight Home Care" effectiveDate="August 15th, 2022" duration="180 days" locationFee="99"
                altMonthly="129/month will be deducted from the primary subscription media spend and $49/month will be deducted from any profile subscriptions' media spend. " />,
    cycleBar: <TOS title="CycleBar" effectiveDate="August 1st, 2022" duration="180 days" locationFee="99"
                altMonthly="274/month will be deducted from the primary subscription media spend and $150/month will be deducted from any profile subscriptions' media spend. " />,
    royalBankOfCanada: <TOS title="Royal Bank of Canada" effectiveDate="July 18, 2022" duration="180 days" locationFee="125" />,
    youngChefsAcademy: <TOS title="Young Chefs Academy" effectiveDate="August 8th, 2022" duration="180 days" locationFee="1" />,
    aceHandyMan: <TOS title="Ace Handyman" effectiveDate="September 1th, 2022" duration="180 days" locationFee="129" />,
    aceProPainters: <TOS title="Ace Pro Painters" effectiveDate="October 12th, 2022" duration="180 days" locationFee="250" />,
    sylvanLearning: <TOS title="Sylvan Learning" effectiveDate="October 21st, 2022" duration="180 days" locationFee="179"
                        altMonthly="179/month will be deducted from the primary subscription media spend and $49/month will be deducted from any profile subscriptions' media spend. " />,
    ellieMentalHealth: <TOS title='Ellie Mental Health' effectiveDate="November 2nd, 2022" duration="120 days" locationFee="129"
                        altMonthly="129/month will be deducted from the primary subscription media spend and $49/month will be deducted from any profile subscriptions' media spend. "/>,
    yogaSix: <TOS title="YogaSix" effectiveDate='November 22nd, 2022' duration='180 days' locationFee="274" altMonthly="274/month will be deducted from the primary subscription media spend and $150/month will be deducted from any profile subscriptions' media spend. "/>,
    i9Sports: <TOS title="i9 Sports" effectiveDate='March 21st, 2023' duration='180 days' locationFee='1' altMonthly="1/month will be deducted from the primary subscription media spend and $1/month will be deducted from any profile subscriptions' media spend. "/>,
    costCutters: <TOS
        title='Cost Cutters' effectiveDate="March 24th, 2023" duration="180 days"
        customMonthly="When you sign up for a local campaign, you agree to recurring billing with a flat fee per subscription. $59/month will be deducted from the primary subscription media spend and $49/month will be deducted from any profile subscriptions' media spend. Billing occurs on the same day each month, based on the date that you started the Plan."
        additionalMonthly="When you sign up for a regional campaign, you agree to recurring billing per subscription. $129/month plus 10% of your media spend will be deducted from the primary subscription media spend and $49/month plus 10% of your media spend will be deducted from any profile subscriptions' media spend. Billing occurs on the same day each month, based on the date that you started the Plan."
    />,
    firstChoiceHair: <TOS
        title="First Choice Haircutters" effectiveDate="March 24th, 2023" duration="180 days"
        customMonthly="When you sign up for a local campaign, you agree to recurring billing with a flat fee per subscription. $59/month will be deducted from the primary subscription media spend and $49/month will be deducted from any profile subscriptions' media spend. Billing occurs on the same day each month, based on the date that you started the Plan."
        additionalMonthly="When you sign up for a regional campaign, you agree to recurring billing per subscription. $129/month plus 10% of your media spend will be deducted from the primary subscription media spend and $49/month plus 10% of your media spend will be deducted from any profile subscriptions' media spend. Billing occurs on the same day each month, based on the date that you started the Plan."
    />,
    roosters: <TOS
        title="Roosters" effectiveDate="March 24th, 2023" duration="180 days"
        customMonthly="When you sign up for a local campaign, you agree to recurring billing with a flat fee per subscription. $129/month will be deducted from the primary subscription media spend and $49/month will be deducted from any profile subscriptions' media spend. Billing occurs on the same day each month, based on the date that you started the Plan."
        additionalMonthly="When you sign up for a regional campaign, you agree to recurring billing per subscription. $129/month plus 10% of your media spend will be deducted from the primary subscription media spend and $49/month plus 10% of your media spend will be deducted from any profile subscriptions' media spend. Billing occurs on the same day each month, based on the date that you started the Plan."
    />,
    pokeWorks: <TOS
        title='Pokeworks' effectiveDate='April 4th, 2023' duration='180 days'
        customIndemnity='Indemnity. Notwithstanding anything set forth in these Terms, both parties shall defend, indemnify, and hold harmless the other and the others’ officers, directors, employees, agents, successors, and assigns from and against any Losses (including reasonable attorneys fees) suffered, incurred, or sustained by one of the parties to this Agreement, resulting from or arising out of any third-party claim relating to: (i) a breach of an obligation under this Agreement, including fines or penalties arising from such breach; (ii) fraud, negligence, or willful misconduct.'
        customLegalFees='If either party files an action against each other claiming either party breached these Terms and either party prevails, either party is entitled to recover all reasonable legal fees, expenses, and any damages or other relief we may be awarded.'
        locationFee='1999'
        altMonthly="129/month will be deducted from the primary subscription media spend and $49/month will be deducted from any profile subscriptions' media spend. "
    />,
    novus: <TOS
        title='NOVUS' effectiveDate='April 25th, 2023' duration='180 days'
        locationFee='119'
        altMonthly="119/month will be deducted from the primary subscription media spend and $39/month will be deducted from any profile subscriptions' media spend. "
    />,
    dekaLash: <TOS
        title='Deka Lash' effectiveDate='May 9th, 2023' duration='180 days'
        locationFee='129'
        altMonthly="129/month will be deducted from the primary subscription media spend and $49/month will be deducted from any profile subscriptions' media spend. "
    />
}