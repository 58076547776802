import React from 'react';
import './homepage.css';
// import Proptypes from 'prop-types';
import Hero from '../components/hero/Hero';
import Customer from '../components/customers/Customer';
import Platform from '../components/platform/Platform';
import Developer from '../components/developer/Developer';
import Eulerity from '../components/us/Eulerity';
import Global from '../components/global/Global';
import GetStarted from '../components/started/GetStarted';
import Footer from '../components/footer/Footer';

const HomePage = () => {
  return (
    <div className='homepage'>
      <Hero />
      <Customer />
      <Platform />
      <Developer />
      <Eulerity />
      <Global />
      <GetStarted />
      <Footer />
    </div>
  );
};

export default HomePage;
