import React, { useEffect } from 'react';

/******components */
import LayoutColumn from '../../components/Products/layoutColumn/LayoutColumn';
import CommonButton from '../../components/buttons/Button';
import { loadScript } from '../../helperFunction/helper';
import Card from '../../components/Products/card/Card';
import PlainText from '../../components/Products/plainText/PlainText';
import Background from '../../components/Products/background/BackGround';
import Footer from '../../components/footer/Footer';

/*******logos */
// import SL from '../../assets/sylvanLogo.jpg';
/*********styles */
import {
  GlobalContent,
  Hero,
  HeroIntro,
  HeroIntroContent,
  HeroIntroTop,
  ControlTitle,
  ControlIcon,
  ControlContainer,
  CommonUpperCaseTitle,
  CommonIntro,
  HeroIntroTitle,
  HeroIntroText,
  HeroTitle,
  CommonButtonGroup,
  Logo,
  Main,
  SectionMaye,
  MbayeContainer,
  SectionIntro,
  SectionIntroTitle,
  SectionLayoutColumns,
  CommonBodyText,
  CommonSectionTitle,
  SectionMbaye,
  SectionAdama
} from './style';

/***********svg */
import { ReactComponent as PanaceaLogo } from '../../assets/customer-logo/panacea-logo.svg';
import IntegrateLogo from '../../assets/customer-logo/integrate.svg';
import HiddenCostLogo from '../../assets/customer-logo/hidden-cost.svg';
import coverageLogo from '../../assets/customer-logo/keep-dry.svg';
import Search from '../../assets/customer-logo/search.svg';
import Share from '../../assets/customer-logo/share.svg';
import Video from '../../assets/customer-logo/video-camera.svg';
import Banner from '../../assets/customer-logo/banner.svg';
import Markup from '../../assets/customer-logo/marker.svg';
import Gears from '../../assets/customer-logo/gears.svg';
import LeapLogo from '../../assets/customer-logo/leafLogo_big.svg';
import Juice from '../../assets/customer-logo/juice.svg';
import workout from '../../assets/customer-logo/workout_big.svg';
import Button from '../../components/buttons/Button';
import cleanPdf from '../../assets/case-studies/Clean-Juice.pdf';
import woat from '../../assets/case-studies/WOAT.pdf';
import leap from '../../assets/case-studies/Leap.pdf';

const HeroSection = () => {
  useEffect(() => {
    let container = document.querySelector('.stripeContainer .grid');
    function generateStripes(color, row, expand, from) {
      const newStripe = document.createElement('div');
      newStripe.className = 'stripe';
      newStripe.style.background = `${color}`;
      newStripe.style.boxShadow =
        '0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset';
      newStripe.style.gridRow = `${row}`;
      newStripe.style.gridColumn = `span ${expand} / ${from}`;
      container.appendChild(newStripe);
      return newStripe;
    }
    generateStripes('#87a2ff', '-2', '7', '-1').classList.add('isAnimated'); //1
    generateStripes('#6570DF', '-2', '5', '-7').classList.add('isAnimated'); //2
    generateStripes('#43458B', '-3', '7', '-1').classList.add('isAnimated'); //3
    generateStripes('#636EDB', '-3', '4', '-7').classList.add('isAnimated'); //4
    generateStripes('#32325E', '-4', '7', '-1').classList.add('isAnimated'); //5
    generateStripes('#256dfe', '-4', '3', '-7').classList.add('isAnimated'); //6
    generateStripes('#32325E', '-5', '5', '-1').classList.add('isAnimated'); //7
    generateStripes('#256dfe', '-5', '3', '-5').classList.add('isAnimated'); //8
    generateStripes('#32325E', '-6', '3', '-1').classList.add('isAnimated'); //9
    generateStripes('#256dfe', '-6', '3', '-3').classList.add('isAnimated'); //10
    generateStripes('#256dfe', '-7', '3', '-1').classList.add('isAnimated'); //11
    generateStripes('#256dfe', '-8', '1', '-1').classList.add('isAnimated'); //12
  }, []);

  return (
    <Hero>
      <Background />
      <HeroIntro className='container'>
        <HeroIntroContent>
          <HeroIntroTop>
            <ControlTitle>
              <ControlIcon>
                <Logo className='medium '>
                  <PanaceaLogo />
                </Logo>
              </ControlIcon>
              <ControlContainer>
                <CommonUpperCaseTitle className='color-cyan'>
                  Panacea
                </CommonUpperCaseTitle>
              </ControlContainer>
            </ControlTitle>
          </HeroIntroTop>
          <HeroIntroTitle>
            <HeroTitle>
              Everything in one place<span>for easy access</span>
            </HeroTitle>
          </HeroIntroTitle>
          <HeroIntroText>
            <CommonIntro className='text-color-bleue'>
              Never worry about another place to showcase your brand . We do the
              hard work so you can be rest assured that you have the maximum
              coverage.
            </CommonIntro>
          </HeroIntroText>
          <CommonButtonGroup>
            <CommonButton
              bg='#87a2ff'
              color='white'
              target="_blank"
              href='/login.jsp'>
              Sign in to Panacea
            </CommonButton>
            <CommonButton color='#000000' href={'/contact-us'}>
              learn more
            </CommonButton>
          </CommonButtonGroup>
        </HeroIntroContent>
      </HeroIntro>
    </Hero>
  );
};

const SectionLayoutGrid = () => {
  return (
    <SectionLayoutColumns className='layoutColumns--3'>
      <LayoutColumn
        icon={IntegrateLogo}
        title='Get integrated quickly'
        text=' All our media channels are available off the bat for you to use. No waiting and you can get started in under a day.'
      />
      <LayoutColumn
        icon={HiddenCostLogo}
        title='No Hidden Costs'
        text='Pay your one subscription fee that never changes. There are no hidden charges, unfavorable obligations, or top up fees.'
      />
      <LayoutColumn
        icon={coverageLogo}
        title='Widest Coverage'
        text='Our integration partners address 94% of the US population. This gives us the widest range currently available on the internet for your messaging.'
      />
    </SectionLayoutColumns>
  );
};

const SectionTop = () => {
  return (
    <SectionMbaye>
      <MbayeContainer className='container'>
        <SectionIntro>
          <SectionIntroTitle>
            <PlainText text=' Businesses prior to using Eulerity rank access to quality media as their biggest obstacle to marketing growth. Traditional vendors aren’t set up to serve modern businesses, typically requiring lengthy processes, complex obligations, and upfront payment schedules. We built Eulerity Panacea to get everything in one place. Our goal is to provide access to all possible media channels including search, social (organic and paid), banner and video in one central hub.' />
          </SectionIntroTitle>
        </SectionIntro>
      </MbayeContainer>
      <SectionLayoutGrid />
    </SectionMbaye>
  );
};

const Caroussel = () => {
  useEffect(() => {
    loadScript('/js/carousel.js');
  }, []);

  return (
    <SectionMaye className='container-xl'>
      <div className='cardcontent'>
        <div className='Commoncards Helm-UserCard__card main-carousel'>
          <div className='carousel-cell'>
            <figure className='logo'>
              <div className='logoContainer1'>
                <img src={Juice} alt='sylvan learning logo' />
              </div>
            </figure>
            <div className='testimonialContainer'>
              <blockquote className='long-text'>
                "Eulerity solved a large need for our organization! The
                technology integrated with many of the data points that are of
                value for us at Clean Juice and helped our franchisee's quantify
                the value of our Marketing Services Program. Eulerity's system
                is more visually appealing and more elegant than the of dozen
                systems we looked at.”
              </blockquote>
              <div className='person'>
                <div className='name'>Dion Beary</div>
                <div className='role'>Marketing Services Manager</div>
                <Button
                  href={cleanPdf}
                  target='_blank'
                  bg='#A3CC06'
                  color='#000'>
                  Clean Juice case study
                </Button>
              </div>
            </div>
          </div>

          <div className='carousel-cell'>
            <figure className='logo'>
              <div className='logoContainer2'>
                <img src={LeapLogo} alt='leap logo' />
              </div>
            </figure>
            <div className='testimonialContainer'>
              <blockquote>
                “Eulerity offers a very comprehensive digital marketing
                solution. And I’ve probably invested no more than 4 hours of my
                time toward this initiative since our launch!.”
              </blockquote>

              <div className='person'>
                <div className='name'> Allison Daly</div>
                <div className='role'>VP Marketing</div>
                <Button
                  href={leap}
                  target='_blank'
                  bg='#1D355F'
                  color='#D48240'>
                  Leap case study
                </Button>
              </div>
            </div>
          </div>

          <div className='carousel-cell'>
            <figure className='logo'>
              <div className='logoContainer3'>
                <img src={workout} alt='workout logo' />
              </div>
            </figure>
            <div className='testimonialContainer'>
              <blockquote>
                "Our franchisees love this self-supported AI platform.  It’s easy to manage and provides great visibility of their club's performance through the dashboard.  Eulerity has really helped to increase Workout Anytime's brand awareness."
              </blockquote>
              <div className='person'>
                <div className='name'>Dennis Holcom</div>
                <div className='role'>SVP of Franchise Support</div>
                <Button href={woat} target='_blank' bg='#C41F30' color='#fff'>
                  Workout Anytime case study
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SectionMaye>
  );
};

const WorkOne = () => {
  return (
    <section className='sokhna'>
      <SectionLayoutColumns className='layoutColumns--4 adama_columns'>
        <LayoutColumn
          icon={Search}
          title='Search'
          text='Search marketing is a powerful form of advertising because people who search on Google reveal a lot about their intent. Our customers have the ability to bid on keywords so that their ads show up when people are looking for exactly the kinds of things they sell.'
        />
        <LayoutColumn
          icon={Share}
          title='Social'
          text='Use social media platforms to connect with your audience to build your brand, increase sales, and drive website traffic. This involves publishing great content on your social media profiles and running social media advertisements. The major social media platforms are Facebook, Instagram, Twitter, LinkedIn, and Snapchat.'
        />
        <LayoutColumn
          icon={Video}
          title='Video'
          text='With video ads, reach potential customers and have them take action when they watch or search for videos on major platforms like YouTube, Facebook and Instagram (TikTok coming soon).The easiest way for you conceptualize, and disseminate your digital video ads has a significant impact on your marketing ROI.'
        />

        <LayoutColumn
          icon={Banner}
          title='Display Multi-format'
          text='The Display Multi-format channel is designed to help you find the right audience. Its sophisticated reach and highly curated top site list (including the top news, sports and other publishers ) let you strategically show your message to potential customers at the right place and the right time.'
        />
      </SectionLayoutColumns>
    </section>
  );
};

const Works = () => {
  return (
    <SectionAdama>
      <CommonSectionTitle className='adama_title'>
        Most Comprehensive Coverage
      </CommonSectionTitle>
      <WorkOne />
      <Background />
    </SectionAdama>
  );
};

const FooterSection = () => {
  return (
    <footer className='globalFooter withCards'>
      <section className='globalFooterCards'>
        <div className='container-xl'>
          <Card
            icon={Markup}
            link={'/login.jsp'}
            title='Everything in one place'
            text='Our team has made sure you never have to worry about the new or upcoming media trends.
             Our team works behind the curtains to make sure you have access to the widest  and most robust set of media integrations'
          />
          <Card
            icon={Gears}
            link={'/login.jsp'}
            titlecolor='#F3AD24'
            title='No Hassle Integration'
            text='Everything is ready out of the box for you to get started and can be set up under a day.Thanks to our smart technology we integrate with any media channel, faster and using less resources.'
          />
        </div>
      </section>
      <article className='helm-sectionCTA'>
        <div className='helm-footerCta_container container-lg'>
          <div className='helm-footerCta__copy'>
            <div className='container-lg'>
              <div className='content'>
                <h1 className='title'>Ready to get started?</h1>
              </div>
              <CommonButtonGroup className='common-ButtonGroup'>
                <CommonButton bg='#87a2ff' color='#fff' href={'/contact-us'}>
                  Learn More
                </CommonButton>
              </CommonButtonGroup>
            </div>
            <div className='container-lg'>
              <div className='fine-print'>
                <CommonBodyText>
                  Panacea is offered to all our clients. If you are interested
                  in learning more about our offerings, please reach out to our
                  team.
                </CommonBodyText>
              </div>
            </div>
          </div>
        </div>
      </article>
      <Footer />
    </footer>
  );
};

const ProductOne = () => {
  return (
    <>
      <GlobalContent>
        <Main>
          <HeroSection />
          <SectionTop />
          <Caroussel />
          <Works />
        </Main>
      </GlobalContent>
      <FooterSection />
    </>
  );
};

export default ProductOne;
