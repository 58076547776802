import styled from 'styled-components';

const GlobalContent = styled.div`
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
`;

const Main = styled.main`
font-family:'Open Sans',sans-serif;
`;

const GradientBackground = styled.div`
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#748df8),
    to(#6fa7f8)
  );
  background: #256dfe;
  overflow-x: hidden;
  opacity: 1;
  -webkit-transition: opacity 1s ease;
  transition: opacity 1s ease;
  height: 100%;
  width: 100%;
  position: absolute;

  @media (min-width: 670px) {
    overflow: visible;
  }

  @media (min-width: 880px) {
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#777bf8),
      color-stop(200%, #68d4f8)
    );
    background: #256dfe;
  }
`;

const SectionSale = styled.section`
  padding-bottom: 50px;
  position: relative;
`;
export { GlobalContent, Main, GradientBackground, SectionSale };
