import styled from "styled-components";
import play from "../../../assets/play.svg";
export const HeroSection = styled.section`
  position: relative;
  margin: 0 auto;
  font-family: "Open Sans", sans-serif;

  @media (min-width: 880px) and (max-width: 1800px) {
    width: 80%;
  }
  @media (min-width: 1800px) {
    width: 80%;
  }
`;

export const Trending = styled.div`
  font-family: "Open Sans", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 880px) {
    flex-direction: row;
    margin-top: -80px;
  }
  @media (min-width: 1900px) {
    flex-direction: row;
  }

  h1 {
    text-transform: capitalize;
    font-size: 28px;
  }

  .trend {
    flex-basis: 55%;
    display: flex;
    flex-direction: column;

    .btn {
      padding: 5px;
      width: 50%;
      margin-top: 30px;
      background: #256dfe;
      font-weight: 700;
      color: #fff;
      border: none;
      border-radius: 5px;
      text-align: center;
    }
  }

  .date {
    font-size: 15px;
    font-weight: 425;
  }

  .image {
    flex-basis: 45%;
    margin-top: 20px;
    background: url("https://miro.medium.com/max/500/1*7ZbOyENsJCWltYNPzROUSQ.png");
    background-size: cover;
    background-position: center;
    height: 340px;

    @media (min-width: 880px) and (max-width: 1200px) {
      max-width: 100%;
    }
    @media (min-width: 1920px) {
      flex-basis: 50%;
      height: 330px;
      margin-top: 0px;
    }
  }
  .trendingTitle {
    font-size: 30px;
    font-family: "Open Sans";
    margin: 20px 0;
    color: #000;
    font-weight: 700;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
  .read {
    font-size: 15px;
    font-weight: 425;
  }
`;

/*********news */
export const News = styled.div`
  font-family: "Open Sans", sans-serif;
  width: 75%;
  margin: 30px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 800px) {
    flex-direction: column;
  }

  @media (min-width: 670px) {
    flex-direction: row;
  }

  @media (min-width: 1920px) {
    width: 65%;
  }

  &.usecase {
    width: 70%;
    padding: 0 15px;
  }

  a {
    cursor: pointer;
  }
`;
export const NewsContent = styled.div`
  width: 50%;
  font-family: "Open Sans", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .date {
    font-size: 15px;
    font-weight: 425;
  }

  @media (max-width: 800px) {
    width: 100%;
  }

  @media (min-width: 1920px) {
    flex-basis: 30%;
  }

  &.usecaseText {
    flex-basis: 53%;
  }
`;
export const NewsContentTitle = styled.a`
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
  font-weight: 425;
  margin: 30px 0;
  color: #000;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  &.useTitle {
    &:hover {
      text-decoration: none;
      cursor: pointer;
    }
  }

  @media (max-width: 1280px) {
    font-size: 17px;
  }
`;
export const NewsRead = styled.p`
  color: rgba(0, 0, 0, 0.56);
`;
export const NewsImage = styled.img`
  width: 100px;
  height: 100px;
  box-shadow: none;
  border-radius: 0;
`;

export const NewsList = styled.section`
  display: flex;
  flex-direction: column;

  &.usecase {
    display: grid;
    gap: 15px;
    width: 90%;
    margin: 30px auto;
    grid-template-columns: 1fr;

    @media (max-width: 1366px) and (min-width: 800px) {
      grid-template-columns: repeat(2, 1fr);
      width: 70%;
      margin: 30px auto;
    }

    @media (min-width: 1367px) {
      grid-template-columns: repeat(4, 1fr);
      width: 70%;
      margin: 30px auto;
    }
  }
  &.podcasts {
    max-width: 1200px;
    margin: 0 auto 100px;
    display: flex;
    font-family: "Open Sans", sans-serif;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 1366px) and (min-width: 800px) {
      width: 70%;
      margin: 30px auto;
    }
    @media (min-width: 1367px) {
      width: 80%;
      margin: 10px auto;
    }
  }
`;

export const PodcastItem = styled.div`
  display: block;
  width: 100%;
  font-family: "Open Sans", sans-serif;
  height: auto;
  padding: 38px 0;
  border-bottom: 1px solid #d7d9dd;

  .episodes__player {
    vertical-align: top;
    display: inline-block;
    width: 130px;
    height: 130px;
    padding-left: 30px;
    @media screen and (max-width: 639px) {
      width: 80px;
      height: 80px;
      padding-left: 0;
    }
  }
  .episodes__player--small {
    width: 70px;
    height: 25px;
    margin-top: 6px;
    @media screen and (max-width: 639px) {
      width: 50px;
      height: 25px;
      padding-left: 0;
    }
  }

  .play-button {
    width: 60px;
    height: 60px;
    background-color: #256dfe;
    border-radius: 50%;
    box-shadow: 0 0px 20px 0px rgba(0, 0, 0, 0.2);
    @media screen and (max-width: 639px) {
      margin: 9px auto;
    }
  }
  .play-button:after {
    content: "";
    position: absolute;
    width: 60px;
    height: 60px;
    background-size: 40px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-image: url(${play});
  }

  .episodes__details {
    vertical-align: top;
    display: inline-block;
    width: calc(100% - 350px);

    @media screen and (max-width: 639px) {
      width: calc(100% - 80px);
    }
  }
  .episodes__line-item {
    display: block;
    width: 100%;
    height: auto;
    padding: 38px 0;
  }
  .episodes__line-item:hover {
    background: #ffffff;
    background: -moz-linear-gradient(left, #fff 5%, #f7f8f9 50%, #fff 95%);
    background: -webkit-linear-gradient(left, #fff 5%, #f7f8f9 50%, #fff 95%);
    background: linear-gradient(to right, #fff 5%, #f7f8f9 50%, #fff 95%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=1 );
  }

  .episodes__line-item--small {
    padding: 26px 0;
  }

  .episodes__details__count {
    width: auto;
    margin-bottom: 10px;
    color: #256dfe;
    font-size: 1.5em;
    font-weight: 900;
    letter-spacing: 3px;
    text-transform: uppercase;
  }
  .episodes__details__title {
    width: auto;
    margin-bottom: 15px;
    color: #21343a;
    font-size: 1.7rem;
    letter-spacing: 3px;
    @media screen and (max-width: 1280px) {
      font-size: 1.4em;
    }
  }
  .episodes__details__duration {
    width: auto;
    margin-bottom: 30px;
    color: #6e757e;
    font-size: 1.3em;
    letter-spacing: 2px;
    font-weight: 700;
    text-transform: uppercase;

    @media screen and (max-width: 639px) {
      margin-bottom: 0px;
    }
    @media screen and (max-width: 1280px) {
      font-size: 1em;
    }
  }
  .episodes__details__subtitle {
    padding-right: 80px;
    color: #6e757e;
    font-size: 1.5em;
    @media screen and (max-width: 639px) {
      display: none;
    }

    @media screen and (max-width: 1280px) {
      font-size: 18px;
      padding-right: 0px;
    }
  }
  .episodes__actions {
    vertical-align: top;
    display: inline-block;
    width: 220px;
    height: auto;

    @media screen and (max-width: 639px) {
      display: none;
    }
  }
  .episodes__actions__item {
    vertical-align: top;
    display: inline-block;
    text-align: center;
    color: #21343a;
    font-size: 1.1em;
    letter-spacing: 2px;
  }
  .episodes__actions__item__icon {
    width: 36px;
    height: 36px;
    padding-left: 3px;
    margin: 0 auto 10px;
    border: 1px solid #21343a;
    border-radius: 50%;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    line-height: 34px;
  }
  .episodes__actions__item__icon__download {
    margin: 0 50px 10px;
  }
  .fa-spotify {
    color: #19b26b;
  }
  .fa-spotify {
    color: #000000;
  }
`;

export const HeroPodcast = styled(PodcastItem)`
  border-bottom: none;
`;

/********navlist */
export const NavBar = styled.nav`
  font-family: "Open Sans", sans-serif;
  width: 70%;
  margin: 0 auto;
  border-bottom: 2px solid grey;
  @media (max-width: 880px) {
    width: 100%;
  }
  @media (min-width: 885px) and (max-width: 1290px) {
    width: 75%;
  }

  @media (min-width: 1292px) {
    width: 68%;
  }
`;
export const NavList = styled.ul`
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 880px) {
    align-items: flex-start;
  }

  @media (min-width: 880px) {
    width: 100%;
  }

  @media screen and (max-width: 1100px) and (min-width: 885px) {
    width: 100%;
  }
`;
export const NavListItem = styled.li`
  @media (max-width: 1280px) {
    & :not(:first-child) {
      margin-left: 20px;
    }
  }
  @media (min-width: 1290px) {
    & :not(:first-child) {
      margin-left: 150px;
    }
  }
`;
export const NavListLink = styled.a`
  font-size: 20px;
  font-weight: 425;
  color: inherit;

  &:hover {
    cursor: pointer;
  }

  &.active {
    border-radius: 5px;
    color: white;
    padding: 5px;
    font-weight: 700;
    background: #256dfe;
    transition: all ease 0.3s;
  }
  @media (max-width: 880px) {
    font-size: 13px;
  }
`;

export const Load = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
  cursor: pointer;
`;

export const NewsLetter = styled.div`
  width: 50%;
  margin: 20px auto;
  background: #256dfe;
  color: white;
  padding: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  input {
    margin-right: 30px;
    align-self: center;
  }

  input[type="submit"] {
    padding: 5px;
    color: black;
    border: none;
    border-radius: 5px;
    text-align: center;
  }
`;

export const CaseCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  -webkit-box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
    0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025);
  box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
    0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025);
  transition:all 0.3s ease-in;

    &:hover{
      transform:translateY(-2px);
      cursor:pointer;
    }
  }

  .content {
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    p {
      font-size: 16px;
      line-height: 1.3em;
      font-weight: 300;
      font-style: normal;
      font-family: 'Open Sans', sans-serif;
      padding: 10px;
    }
  }

  @media (max-width: 880px) {
    font-size: 14px;
  }
`;

export const HeroCaseCard = styled(CaseCard)`
  margin: 50px auto;
  max-width: 1100px;

  &:hover {
    transform: none;
  }

  .content {
    padding: 20px;
    line-height: 24px;

    p {
      padding: 15px;
    }
  }

  img {
    padding: 10px;
  }
`;

export const CaseLogo = styled.div`
  height: 100px;
  weight: 100%;
  background: ${(props) => props.color};
  display: flex;

  img {
    margin: 0 auto;
    align-self: center;
    border-radius: 0;
    box-shadow: none;
    border: none;
  }
`;

export const YoutubePlayer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const FeaturedArticleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 60px;
`

export const ContentHubBannerContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  padding: 20px;

  img {
    all: unset;
    max-width:100%;
    max-height:100%;
  }
`;