import React from 'react';
import './style.css';
const LayoutColumn = ({ icon, title, text }) => {
  return (
    <div className='helm-layoutColumns_column'>
      <div className='helm-featureDetail'>
        <div className='helm-featureDetail__icon'>
          <span className='helm-logo helm-medium'>
            <img src={icon} alt={icon} />
          </span>
        </div>
        <div className='helm-featureDetail__content'>
          <div className='helm-featureDetail__title'>
            <h1 className='commonBodyTitle commonBodyTitle-slate1'>{title}</h1>
          </div>
          <div className='helm-featureDetail__body'>
            <p className='commonBodyText'>{text}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LayoutColumn;
