import React from 'react';
import Footer from '../../components/footer/Footer';
import {
  PrivacyTitle,
  Intro,
  InTroDate,
  IntroBox,
  IntroTitle,
  IntroText,
  TableList,
  TableItem,
  TableTitle,
  BasicTitle,
  BasicTextBox,
  ListContainer,
  NumberedListText,
  LetteredListText
} from './style';

const BasicsSection = () => {
  return (
    <>
      <BasicTitle>1.Basics</BasicTitle>
      <BasicTextBox>
        <IntroTitle>A.About us</IntroTitle>
        <IntroText>
          Eulerity is an online marketing platform (the "Services")
          headquartered in New York city. The Services enable our users to,
          among other things, send and manage email campaigns and serve
          advertisements. We also provide other related services, such as
          real-time data analytics. Find out more about our Services here.
        </IntroText>
      </BasicTextBox>
      <BasicTextBox>
        <IntroTitle>B.Key Terms</IntroTitle>
        <IntroText>
          In this privacy policy, these terms have the following meanings:
        </IntroText>
        <IntroText>
          <strong>"User"</strong> means any person or entity that is registered
          with us to use the Services.
        </IntroText>
        <IntroText>
          <strong> "Personal Information" </strong>means any information that
          identifies or can be used to identify a user, directly or indirectly.
          Examples of Personal Information include, but are not limited to,
          first and last name, date of birth, email address, gender, occupation,
          or other demographic information.
        </IntroText>
        <IntroText>
          <strong> "Website(s)"</strong>means any website(s) we own and operate
          (such as{' '}
          <a style={{ color: '#256dfe' }} href='/'>
            www.eulerity.com
          </a>
          ) or any web pages, interactive features, applications, widgets,
          blogs, social networks, social network "tabs," or other online,
          mobile, or wireless offerings that post a link to this privacy policy.
        </IntroText>
        <IntroText>
          <strong>"you" </strong> and <strong>"your"</strong> means, depending
          on the User.
        </IntroText>
      </BasicTextBox>
    </>
  );
};
const ForUsers = () => {
  return (
    <>
      <BasicTitle>2. Privacy for Users</BasicTitle>
      <IntroText>
        This section applies to the Personal Information we collect and process
        from a Member or potential Member through the provision of the Services.
        If you are not a User section of this policy may be more applicable to
        you and your data. In this section,
        <strong>"you" and "your" refer to users and potential users.</strong>
      </IntroText>
      <BasicTextBox>
        <IntroTitle>A.Information We Collect</IntroTitle>
        <IntroText>
          The Personal Information that we may collect broadly falls into the
          following categories:
        </IntroText>
        <IntroText>
          (i) <strong>Information you provide to us:</strong> In the course of
          engaging with our Services, you may provide Personal Information about
          you and your Contacts. Personal Information is often, but not
          exclusively, provided to us when you sign up for and use the Services,
          consult with our customer service team, send us an email, integrate
          the Services with another website or service (for example, when you
          choose to connect your e-commerce account with eulerity), or
          communicate with us in any other way.
        </IntroText>
        <IntroText>
          We will let you know prior to collection whether the provision of
          Personal Information we are collecting is compulsory or if it may be
          provided on a voluntary basis and the consequences, if any, of not
          providing the information. By giving us this information, you agree to
          this information being collected, used and disclosed as described in
          our Terms of Use and in this privacy policy.
        </IntroText>
        <IntroText>
          (ii)<strong> Information we collect automatically:</strong> When you
          use the Services, we may automatically collect certain information
          about your device and usage of the Services.
        </IntroText>
        <IntroText>
          (iii) <strong>Information we collect from other sources: </strong>
          From time to time, we may obtain information about you or your
          Contacts from third-party sources, such as public databases, social
          media platforms, third-party data providers and our joint marketing
          partners. We take steps to ensure that such third parties are legally
          or contractually permitted to disclose such information to us.
        </IntroText>
        <IntroText>
          (iv) <strong> Information from the use of our mobile apps:</strong>
          When you use our mobile apps, we may collect certain device and
          usage-related information in addition to information described
          elsewhere in this privacy policy.
        </IntroText>
      </BasicTextBox>
      <BasicTextBox>
        <IntroTitle>B. Use of Personal Information</IntroTitle>
        <IntroText>
          We may use the Personal Information we collect through the Services or
          other sources for a range of reasons, including:
        </IntroText>
        <TableItem>
          <strong> To bill and collect money owed to us by you</strong>. This
          includes sending you emails, invoices, receipts, notices of
          delinquency, and alerting you if we need a different credit card
          number. We use third parties for secure credit card transaction
          processing, and those third parties collect billing information to
          process your orders and credit card payments. To eulerity about the
          steps we take to safeguard that data, see the "Our Security" section
          of this privacy policy.
        </TableItem>
        <TableItem>
          <strong>To send you system alert messages</strong>. For example, we
          may inform you about temporary or permanent changes to our Services,
          such as planned outages, or send you account, security or compliance
          notifications, such as new features, version updates, releases, abuse
          warnings, and changes to this privacy policy.
        </TableItem>

        <TableItem>
          <strong>
            To communicate with you about your account and provide customer
            support.
          </strong>
          For example, if you use our mobile apps, we may ask you if you want to
          receive push notifications about activity in your account. If you have
          opted in to these push notifications and no longer want to receive
          them, you may turn them off through your operating system.
        </TableItem>

        <TableItem>
          <strong>
            To enforce compliance with our Terms of Use and applicable law, and
            to protect the rights and safety of our users and third parties, as
            well as our own.
          </strong>
          This may include developing tools and algorithms that help us prevent
          violations. For example, sometimes we review the content of our users’
          email campaigns to make sure they comply with our Terms of Use. To
          improve that process, we have software that helps us find email
          campaigns that may violate our Terms of Use. Our employees or
          independent contractors may review those particular email campaigns.
          This benefits all users who comply with our Terms of Use because it
          reduces the amount of spam being sent through our servers and helps us
          maintain high deliverability. Email was not built for confidential
          information. Please do not use eulerity to send confidential
          information.
        </TableItem>

        <TableItem>
          <strong>To meet legal requirements</strong> , including complying with
          court orders, valid discovery requests, valid subpoenas, and other
          appropriate legal mechanisms.
        </TableItem>
        <TableItem>
          <strong>
            To provide information to representatives and advisors
          </strong>
          , including attorneys and accountants, to help us comply with legal,
          accounting, or security requirements.
        </TableItem>
        <TableItem>
          <strong>
            To prosecute and defend a court, arbitration, or similar legal
            proceeding.
          </strong>
        </TableItem>
        <TableItem>
          <strong>To respond to lawful requests by public authorities</strong> ,
          including to meet national security or law enforcement requirements.
        </TableItem>
        <TableItem>
          <strong>To provide, support and improve the Services</strong> . For
          example, this may include sharing your or your Contacts’ information
          with third parties in order to provide and support our Services or to
          make certain features of the Services available to you. When we share
          Personal Information with third parties, we take steps to protect your
          information by requiring these third parties to enter into a contract
          with us that requires them to use the Personal Information we transfer
          to them in a manner that is consistent with this privacy policy and
          applicable privacy laws.
        </TableItem>
        <TableItem>
          <strong>To provide suggestions to you</strong>. This includes adding
          features that compare users’ email campaigns, using data to suggest
          other publishers your Contacts may be interested in, or using data to
          suggest products or services that you may be interested in or that may
          be relevant to you or your Contacts. Some of these suggestions are
          generated by use of our data analytics projects, which are described
          below.
        </TableItem>
        <TableItem>
          <strong>Combined information</strong> . We may combine Personal
          Information with other information we collect or obtain about you
          (such as information we source from our third-party partners) to serve
          you specifically, such as to deliver a product or service according to
          your preferences or restrictions, or for advertising or targeting
          purposes in accordance with this privacy policy. When we combine
          Personal Information with other information in this way, we treat it
          as, and apply all of the safeguards in this privacy policy applicable
          to, Personal Information.
        </TableItem>
        <TableItem>
          <strong>Other purposes</strong>. To carry out other legitimate
          business purposes, as well as other lawful purposes about which we
          will notify you.
        </TableItem>
        <IntroTitle>C.Cookies and Tracking Technologies</IntroTitle>
        <IntroText>
          Our partners may use various technologies to collect and store
          information when you use our Services, and this may include using
          cookies and similar tracking technologies, such as pixels and web
          beacons. For example Google’s advertising policies are listed{' '}
          <a href='/' style={{ color: '#256dfe' }}>
            here
          </a>{' '}
          and Facebook’s advertising policies are listed{' '}
          <a href='/' style={{ color: '#256dfe' }}>
            here
          </a>
          .
        </IntroText>
      </BasicTextBox>
      <BasicTextBox>
        <IntroTitle>D. Other Data Protection Rights</IntroTitle>
        <IntroText>
          You and your Contacts may have the following data protection rights:
        </IntroText>

        <TableItem>
          <strong>To access, correct, update or request deletion </strong> of
          Personal Information. eulerity takes reasonable steps to ensure that
          the data we collect is reliable for its intended use, accurate,
          complete and up to date. As a Member, you can manage many of your
          individual account and profile settings within the dashboard provided
          through the eulerity platform, or you may contact us directly by
          emailing us at personaldatarequests@eulerity.com. You can also manage
          information about your Contacts within the dashboard provided through
          the eulerity platform to assist you with responding to requests to
          access, correct, update or delete information that you receive from
          your Contacts.
        </TableItem>
        <TableItem>
          In addition, individuals who are residents of the EEA can{' '}
          <strong>object to processing </strong>of their Personal Information,
          ask to <strong>restrict processing </strong>of their Personal
          Information or <strong> request portability</strong> of their Personal
          Information. You can exercise these rights by contacting us using the
          contact details provided in the "Questions and Concerns" section
          below. If any of your Contacts wishes to exercise any of these rights,
          they should contact you directly, or contact us as described in the
          "Privacy for Contacts" section below.
        </TableItem>
        <TableItem>
          Similarly, if Personal Information is collected or processed on the
          basis of consent, the data subject can{' '}
          <strong>withdraw their consent</strong> at any time. Withdrawing your
          consent will not affect the lawfulness of any processing we conducted
          prior to your withdrawal, nor will it affect processing of your
          Personal Information conducted in reliance on lawful processing
          grounds other than consent. If you receive these requests from
          Contacts, you can segment your lists within the eulerity platform to
          ensure that you only market to Contacts who have not opted out of
          receiving such marketing.
        </TableItem>
        <TableItem>
          The <strong>right to complain to a data protection authority</strong>
          about the collection and use of Personal Information. For more
          information, please contact your local data protection authority.
          Contact details for data protection authorities in the EEA are
          available{' '}
          <a href='/' style={{ color: '#256dfe' }}>
            here
          </a>
          .
        </TableItem>
        <IntroText>
          We respond to all requests we receive from individuals wishing to
          exercise their data protection rights in accordance with applicable
          data protection law. We may ask you to verify your identity in order
          to help us respond efficiently to your request. If we receive a
          request from one of your Contacts, we will either direct the Contact
          to reach out to you, or, if appropriate, we may respond directly to
          their request.
        </IntroText>
      </BasicTextBox>
    </>
  );
};

const InfoGeneral = () => {
  return (
    <>
      <BasicTitle>3. General Information</BasicTitle>
      <BasicTextBox>
        <IntroTitle>A. How We Share Information</IntroTitle>
        <IntroText>
          We may share and disclose your Personal Information to the following
          types of third parties for the purposes described in this privacy
          policy (
          <strong>
            for purposes of this section, "you" and "your" refer to users unless
            otherwise indicated
          </strong>
          ):
        </IntroText>
        <IntroText>
          (i) <strong>Our service providers</strong>: Sometimes, we share your
          information with our third-party service providers, who help us
          provide and support our Services and other business-related functions.
        </IntroText>
        <IntroText>
          For example, if it is necessary to provide users something they have
          requested (like enable a feature such as Social Profiles), then we may
          share users’ or Contacts’ Personal Information with a service provider
          for that purpose. Other examples include analyzing data, hosting data,
          engaging technical support for our Services, processing payments, and
          delivering content.
        </IntroText>
        <IntroText>
          These third-party service providers enter into a contract that
          requires them to use your Personal Information only for the provision
          of services to us and in a manner that is consistent with this privacy
          policy.
        </IntroText>
        <IntroText>
          (ii)<strong>Advertising partners</strong> : We partner with
          third-party advertising networks and exchanges to display advertising
          on our Websites or to manage and serve our advertising on other sites
          and may share Personal Information with them for this purpose. Our
          third-party partners may use cookies and other tracking technologies,
          such as pixels and web beacons, to gather information about your
          activities on our Websites and other sites in order to provide you
          with targeted advertising based on your browsing activities and
          interests. For example Google’s advertising policies are listed{' '}
          <a href='/' style={{ color: '#256dfe' }}>
            here
          </a>
          and Facebook’s advertising policies are listed{' '}
          <a href='/' style={{ color: '#256dfe' }}>
            here
          </a>
          .
        </IntroText>
        <IntroText>
          (iii)
          <strong>
            {' '}
            Any competent law enforcement body, regulatory body, government
            agency, court or other third party{' '}
          </strong>
          where we believe disclosure is necessary (a) as a matter of applicable
          law or regulation, (b) to exercise, establish, or defend our legal
          rights, or (c) to protect your vital interests or those of any other
          person.
        </IntroText>
        <IntroText>
          (iv) <strong>A potential buyer (and its agents and advisors)</strong>{' '}
          in the case of a sale, merger, consolidation, liquidation,
          reorganization, or acquisition.
        </IntroText>
        <IntroText>
          In that event, any acquirer will be subject to our obligations under
          this privacy policy, including your rights to access and choice. We
          will notify you of the change either by sending you an email or
          posting a notice on our Website.
        </IntroText>
      </BasicTextBox>
      <BasicTextBox>
        <IntroTitle>
          B. Legal Basis for Processing Personal Information (EEA Persons Only)
        </IntroTitle>
        <IntroText>
          If you are from the European Economic Area, our legal basis for
          collecting and using the Personal Information described above will
          depend on the Personal Information concerned and the specific context
          in which we collect it.
        </IntroText>
        <IntroText>
          However, we will normally collect and use Personal Information from
          you where the processing is in our{' '}
          <strong>legitimate interests</strong> and not overridden by your
          data-protection interests or fundamental rights and freedoms.
          Typically, our legitimate interests include improving, maintaining,
          providing, and enhancing our technology, products, and services;
          ensuring the security of the Services and our Websites; and for our
          marketing activities.
        </IntroText>
        <IntroText>
          If you are a Member, we may need the Personal Information to{' '}
          <strong> perform a contract</strong> with you. In some limited cases,
          we may also have a legal obligation to collect Personal Information
          from you.
        </IntroText>
        <IntroText>
          If we ask you to provide Personal Information to comply with a legal
          requirement or to perform a contact with you, we will make this clear
          at the relevant time and advise you whether the provision of your
          Personal Information is mandatory or not, as well as of the possible
          consequences if you do not provide your Personal Information.
        </IntroText>
        <IntroText>
          Where required by law, we will collect Personal Information only where
          we have your <strong>consent</strong> to do so.
        </IntroText>
        <IntroText>
          If you have questions about or need further information concerning the
          legal basis on which we collect and use your Personal Information,
          please contact us using the contact details provided below.
        </IntroText>
      </BasicTextBox>
      <BasicTextBox>
        <IntroTitle>C. Your Choices and Opt-Outs</IntroTitle>
        <IntroText>
          Users who have opted in to our marketing emails can opt out of
          receiving marketing emails from us at any time by clicking the
          "unsubscribe" link at the bottom of our marketing messages.
        </IntroText>
        <IntroText>
          Also, all opt-out requests can be made by emailing us using the
          contact details provided below. Please note that some communications
          (such as service messages, account notifications, billing information)
          are considered transactional and necessary for account management, and
          users cannot opt out of these messages unless you cancel your eulerity
          account.
        </IntroText>
      </BasicTextBox>
      <BasicTextBox>
        <IntroTitle>D. Our Security</IntroTitle>
        <IntroText>
          We take appropriate and reasonable technical and organizational
          measures to protect Personal Information from loss, misuse,
          unauthorized access, disclosure, alteration, and destruction, taking
          into account the risks involved in the processing and the nature of
          the Personal Information. If you have any questions about the security
          of your Personal Information, you may contact us at
          privacy@eulerity.com.
        </IntroText>
        <IntroText>
          eulerity accounts require a username and password to log in. users
          must keep their username and password secure, and never disclose it to
          a third party. Because the information in a Member’s eulerity account
          is so sensitive, account passwords are hashed, which means we cannot
          see a Member’s password. We cannot resend forgotten passwords either.
          We will only provide users with instructions on how to reset them.
        </IntroText>
      </BasicTextBox>
      <BasicTextBox>
        <IntroTitle>E. International Transfers</IntroTitle>
        <IntroText>
          (i) <strong>We operate in the United States</strong>
        </IntroText>
        <IntroText>
          Our servers and offices are located in the United States, so your
          information may be transferred to, stored, or processed in the United
          States. While the data protection, privacy, and other laws of the
          United States might not be as comprehensive as those in your country,
          we take many steps to protect your privacy.
        </IntroText>
        <IntroText>
          (ii){' '}
          <strong>
            Data transfers from Switzerland or the EU to the United States
          </strong>
        </IntroText>
        <IntroText>
          eulerity participates in and has certified its compliance with the
          EU-U.S. Privacy Shield Framework and the Swiss-U.S. Privacy Shield
          Framework. We are committed to subjecting all Personal Information
          received from European Union (EU) member countries and Switzerland,
          respectively, in reliance on each Privacy Shield Framework, to each
          Framework’s applicable Principles. To learn more about the Privacy
          Shield Frameworks, and to view our certification, visit the U.S.
          Department of Commerce’s Privacy Shield website,{' '}
          <a href='/' style={{ color: '#256dfe' }}>
            here
          </a>
          .
        </IntroText>
        <IntroText>
          A list of Privacy Shield participants is maintained by the Department
          of Commerce and is available{' '}
          <a href='/' style={{ color: '#256dfe' }}>
            here
          </a>
          .
        </IntroText>
        <IntroText>
          eulerity is responsible for the processing of Personal Information we
          receive under each Privacy Shield Framework and subsequently transfer
          to a third party acting as an agent on our behalf. We comply with the
          Privacy Shield Principles for all onward transfers of Personal
          Information from the EU and Switzerland, including the onward transfer
          liability provisions.
        </IntroText>
        <IntroText>
          With respect to Personal Information received or transferred pursuant
          to the Privacy Shield Frameworks, we are subject to the regulatory
          enforcement powers of the U.S. Federal Trade Commission. In certain
          situations, we may be required to disclose Personal Information in
          response to lawful requests by public authorities, including to meet
          national security or law enforcement requirements.
        </IntroText>
        <IntroText>
          If you have an unresolved privacy or data use concern that we have not
          addressed satisfactorily, please contact our U.S.-based third-party
          dispute resolution provider TRUSTe (free of charge to you){' '}
          <a href='/' style={{ color: '#256dfe' }}>
            here
          </a>
          . Under certain conditions, more fully described on the Privacy Shield
          website,{' '}
          <a href='/' style={{ color: '#256dfe' }}>
            here
          </a>
          , you may be entitled to invoke binding arbitration when other dispute
          resolution procedures have been exhausted.
        </IntroText>

        <IntroText>
          (iii) <strong>Users located in Australia</strong>
        </IntroText>
        <IntroText>
          If you are a Member who lives in Australia, this section applies to
          you. We are subject to the operation of the Privacy Act 1988 (
          <strong>"Australian Privacy Act"</strong>). Here are the specific
          points you should be aware of:
        </IntroText>
        <TableItem>
          Where we say we assume an obligation about Personal Information, we
          are also requiring our subcontractors to undertake a similar
          obligation, where relevant.
        </TableItem>
        <TableItem>
          We will not use or disclose Personal Information for the purpose of
          our direct marketing to you unless you have consented to receive
          direct marketing; you would reasonably expect us to use your personal
          details for the marketing; or we believe you may be interested in the
          material but it is impractical for us to obtain your consent. You may
          opt out of any marketing materials we send to you through an
          unsubscribe mechanism or by contacting us directly. If you have
          requested not to receive further direct marketing messages, we may
          continue to provide you with messages that are not regarded as "direct
          marketing" under the Australian Privacy Act, including changes to our
          terms, system alerts, and other information related to your account.
        </TableItem>
        <TableItem>
          Our servers are located in the United States. In addition, we or our
          subcontractors may use cloud technology to store or process Personal
          Information, which may result in storage of data outside Australia. It
          is not practicable for us to specify in advance which country will
          have jurisdiction over this type of offshore activity. All of our
          subcontractors, however, are required to comply with the Australian
          Privacy Act in relation to the transfer or storage of Personal
          Information overseas.
        </TableItem>
        <TableItem>
          If you think the information we hold about you is inaccurate, out of
          date, incomplete, irrelevant, or misleading, we will take reasonable
          steps, consistent with our obligations under the Australian Privacy
          Act, to correct that information upon your request.
        </TableItem>
        <TableItem>
          If you are unsatisfied with our response to a privacy matter then you
          may consult either an independent advisor or contact the Office of the
          Australian Information Commissioner for additional help. We will
          provide our full cooperation if you pursue this course of action.
        </TableItem>
      </BasicTextBox>
      <BasicTextBox>
        <IntroTitle>F. Retention of Data</IntroTitle>
        <IntroText>
          We retain Personal Information where we have an ongoing legitimate
          business or legal need to do so. Our retention periods will vary
          depending on the type of data involved, but, generally, we'll refer to
          these criteria in order to determine retention period:
        </IntroText>
        <TableItem>
          Whether we have a legal or contractual need to retain the data.
        </TableItem>
        <TableItem>
          Whether the data is necessary to provide our Services.
        </TableItem>
        <TableItem>
          Whether our users have the ability to access and delete the data
          within their eulerity accounts.
        </TableItem>
        <TableItem>
          Whether our users would reasonably expect that we would retain the
          data until they remove it or until their eulerity accounts are closed
          or terminated.
        </TableItem>
        <IntroText>
          When we have no ongoing legitimate business need to process your
          Personal Information, we will either delete or anonymize it or, if
          this is not possible (for example, because your Personal Information
          has been stored in backup archives), then we will securely store your
          Personal Information and isolate it from any further processing until
          deletion is possible.
        </IntroText>
      </BasicTextBox>
      <BasicTextBox>
        <IntroTitle>G. California Privacy</IntroTitle>
        <IntroText>
          Under California Law, California residents have the right to request
          in writing from businesses with whom they have an established business
          relationship, (a) a list of the categories of Personal Information,
          such as name, email, and mailing address, and the type of services
          provided to the customer that a business has disclosed to third
          parties (including affiliates that are separate legal entities) during
          the immediately preceding calendar year for the third-parties’ direct
          marketing purposes, and (b) the names and addresses of all such third
          parties. To request the above information, please contact us through
          email us at privacy@eulerity.com.
        </IntroText>
      </BasicTextBox>
      <BasicTextBox>
        <IntroTitle>H. Changes to this Policy</IntroTitle>
        <IntroText>
          We may change this privacy policy at any time and from time to time.
          The most recent version of the privacy policy is reflected by the
          version date located at the top of this privacy policy. All updates
          and amendments are effective immediately upon notice, which we may
          give by any means, including, but not limited to, by posting a revised
          version of this privacy policy or other notice on the Websites. We
          encourage you to review this privacy policy often to stay informed of
          changes that may affect you. Our electronically or otherwise properly
          stored copies of this privacy policy are each deemed to be the true,
          complete, valid, authentic, and enforceable copy of the version of
          this privacy policy that was in effect on each respective date you
          visited the Website.
        </IntroText>
      </BasicTextBox>

      <BasicTextBox>
        <IntroTitle>I. Limited and Conditional use of Google Advertising</IntroTitle>
        <ListContainer>
          <NumberedListText> {/* 1 */}
            <span className='underscore'>Limited Use:</span> Customer’s use of data  must comply with the below requirements.
            These requirements apply to the raw data obtained from the scopes and data
            aggregated, anonymized, or derived from them.

            <ListContainer>
              <NumberedListText> {/* 1 */}
                Customer must limit use of data to providing or improving user-facing features that are prominent in the requesting application's user interface;
              </NumberedListText>

              <NumberedListText> {/* 2 */}
                Transfers of data are not allowed, except:
                <ListContainer>
                  <LetteredListText> {/* a */}
                    To provide or improve your appropriate access or user-facing features that are visible and prominent in the requesting application's user interface and only with the user's consent;
                  </LetteredListText>

                  <LetteredListText> {/* b */}
                    For security purposes (for example, investigating abuse);
                  </LetteredListText>

                  <LetteredListText> {/* c */}
                    To comply with applicable laws; or;
                  </LetteredListText>

                  <LetteredListText> {/* d */}
                    As part of a merger, acquisition, or sale of assets of the developer after obtaining explicit prior consent from the user.
                  </LetteredListText>
                </ListContainer>

                <NumberedListText> {/* 3 */}
                  Humans are not allowed to read the data, unless:
                  <ListContainer>
                    <LetteredListText> {/* a */}
                      Customer first obtained the user's affirmative agreement to view specific messages, files, or other data, with the limited exception of use cases approved by Google under additional terms applicable to the Nest Device Access program;
                    </LetteredListText>

                    <LetteredListText> {/* b */}
                      It is necessary for security purposes (for example, investigating a bug or abuse);
                    </LetteredListText>

                    <LetteredListText> {/* c */}
                      It is necessary to comply with applicable law; or
                    </LetteredListText>

                    <LetteredListText> {/* d */}
                      The data (including derivations) is aggregated and used for internal operations in accordance with applicable privacy and other jurisdictional legal requirements.
                    </LetteredListText>
                  </ListContainer>
                </NumberedListText>
              </NumberedListText>
            </ListContainer>
          </NumberedListText>

          <NumberedListText> {/* 2 */}
            All other transfers, uses, or sales of user data are prohibited, including:
            <ListContainer>
              <NumberedListText> {/* 1 */}
                Transferring or selling user data to third parties like advertising platforms, data brokers, or any information resellers.
              </NumberedListText>

              <NumberedListText> {/* 2 */}
                Transferring, selling, or using user data for serving ads, including retargeting, personalized or interest-based advertising.
              </NumberedListText>

              <NumberedListText> {/* 3 */}
                Transferring, selling, or using user data to determine credit-worthiness or for lending purposes.
              </NumberedListText>
            </ListContainer>
          </NumberedListText>

          <NumberedListText> {/* 3 */}
            Customer must ensure that their employees, agents, contractors, and successors comply with
            this <a href='https://developers.google.com/terms/api-services-user-data-policy'>Google API Services User Data Policy.</a>
          </NumberedListText>

          <NumberedListText> {/* 4 */}
            <span className='underscore'>Secure Data Handling:</span> Customers accessing the product specified scopes
            (the "<a href='https://support.google.com/cloud/answer/9110914#sensitive-scopes'>Sensitive</a>" and "<a href='https://support.google.com/cloud/answer/9110914#restricted-scopes'>Restricted</a>" Scopes) must demonstrate to Eulerity that they adhere to certain security
            practices. Depending on the product being accessed and number of user grants or users, Eulerity
            will work with the Customer to prepare applications which must pass an annual security assessment
            and obtain a Letter of Assessment from a Google-designated third party. More information about
            the assessment requirements from Google to obtain or keep access to the specific scopes is also
            available in the <a href='https://support.google.com/cloud/answer/9110914'>OAuth Application Verification FAQ</a> and the product's <a href='https://developers.google.com'>Google Developer Page</a>.
          </NumberedListText>
	
        </ListContainer>
      </BasicTextBox>

      <BasicTextBox>
        <IntroTitle>J. Questions & Concerns</IntroTitle>
        <IntroText>
          If you have any questions or comments, or if you have a concern about
          the way in which we have handled any privacy matter, please use our
          email{' '}
          <a href='mailto:contactus@eulerity.com'>contactus@eulerity.com</a> to
          send us a message.
        </IntroText>
        <IntroText>
          <strong>For EEA Residents:</strong>
        </IntroText>
        <IntroText>
          For the purposes of EU data protection legislation, Eulerity Inc. is
          the controller of your Personal Information. Our Data Protection
          Officer can be contacted at{' '}
          <a href='mailto: dpo@eulerity.com'>dpo@eulerity.com</a>.
        </IntroText>

        <IntroText>
          <strong>For Non-EEA Residents:</strong>
        </IntroText>
        <IntroText>Euerity Inc.</IntroText>
        <IntroText>Attn. Chief Privacy Officer</IntroText>
        <IntroText>privacy@eulerity.com</IntroText>
        <IntroText>303 Spring Street</IntroText>
        <IntroText>New York, NY-10013, USA</IntroText>
      </BasicTextBox>
    </>
  );
};

const TableOfContents = () => {
  return (
    <>
      <TableTitle>TABLE OF CONTENTS:</TableTitle>
      <TableList>
        <TableItem>The Basics</TableItem>
        <TableItem>Privacy for Users</TableItem>
        <TableItem>General Information</TableItem>
        <TableItem>Questions & Concerns</TableItem>
      </TableList>
    </>
  );
};
const Introduction = () => {
  return (
    <Intro>
      <PrivacyTitle>Privacy Policy:</PrivacyTitle>
      <InTroDate>Effective Date: May 23, 2018</InTroDate>
      <IntroBox>
        <IntroTitle>Introduction</IntroTitle>
        <IntroText>
          LNRD by Eulerity takes data privacy seriously. This privacy policy
          explains who we are, how we collect, share and use Personal
          Information, and how you can exercise your privacy rights.
        </IntroText>
        <IntroText>
          We recommend that you read this privacy policy in full to ensure you
          are fully informed. However, to make it easier for you to review the
          parts of this privacy policy that apply to you, we have divided up the
          document into sections that are specifically applicable to Users.
        </IntroText>
        <IntroText>
          If you have any questions or concerns about our use of your Personal
          Information, then please contact us using the contact details provided
          at the end of this policy.
        </IntroText>
      </IntroBox>
      <TableOfContents />
      <BasicsSection />
      <ForUsers />
      <InfoGeneral />
    </Intro>
  );
};

const Privacy = () => {
  return (
    <div>
      <Introduction />
      <Footer />
    </div>
  );
};

export default Privacy;
