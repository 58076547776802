import React from 'react'
import ChallengeCarousel from './components/ChallengeCarousel';
import MomentumGrid from './components/MomentumGrid';
import CaseStudyGrid from './components/CaseStudyGrid';
import CustomersCarousel from './components/CustomersCarousel';

import Footer from '../../../components/footer/Footer';
import { BackgroundMask, ChallengesContainer, ClientSuccessContainer, CorporateControlContainer, CustomerContainer, GainMomentumContainer, GetStartedContainer, HeroContainer, Main, MobileExperienceContainer, ScaleMediaContainer, Section, SectionBackground, SectionContainer, SectionMasked } from './newstyles'

// * Images
import IndustryHeroImage from '../../../assets/industry/IndustryHeroImage.png';
import MobilePhone from '../../../assets/industry/MobilePhone.png';
import LaptopImage from '../../../assets/industry/LaptopImage.png';
import GetStartedImage from '../../../assets/industry/GetStartedImage.png';
import { ReactComponent as CheckMark } from '../../../assets/industry/CheckMark.svg';

// * Hero
const Hero = () => {
    return (
        <Section>
            <SectionContainer className='hero__padding'>
                <HeroContainer>
                    <div className='grid-item-1'>
                        <h1>Transforming the Future of Digital Marketing</h1>
                    </div>
                    <div className='grid-item-2'>
                        <img src={IndustryHeroImage} />
                    </div>
                    <div className='grid-item-3'>
                        <p className='subtext'>Gain an unprecedented level of control over all aspects of your digital marketing.</p>
                        <div className='check-list-container'>
                            <p><CheckMark />Automate Marketing Operations</p>
                            <p><CheckMark />Optimize Ad Spend</p>
                            <p><CheckMark />Quickly Scale</p>
                        </div>
                        <div className='learn-more-container'>
                            <p className='subtext'>Want to Learn More?</p>
                            <a className='learn-btn' href='/contact-us'>Schedule A Demo</a>
                        </div>
                    </div>
                </HeroContainer>
            </SectionContainer>
        </Section>
    )
}

// * Challenges
const Challenges = () => {
    return (
        <Section>
            <SectionContainer className='challenge__padding'>
                <ChallengesContainer>
                    <div className='text-container'>
                        <h1>Solving Your Digital Marketing Challenges, At Scale</h1>
                        <p>From local to global, our cutting-edge technology enables franchise organizations to have complete control of their marketing infrastructure. Eulerity provides all of the elements required to run your digital marketing at whatever scale is needed.</p>
                    </div>
                    <ChallengeCarousel />
                </ChallengesContainer>
            </SectionContainer>

        </Section>
    )
}

// * Mobile Exp
const MobileExperience = () => {
    return (
        <Section className='Section industryAngle'>
            <SectionMasked>
                <BackgroundMask>
                    <SectionBackground></SectionBackground>
                </BackgroundMask>
            </SectionMasked>
            <SectionContainer className='mobile__exp__padding'>
                <MobileExperienceContainer>
                    <div className='header-container'>
                        <h1>Fully Centralized. Fully Flexible.</h1>
                    </div>
                    <div className='left-content'>
                        <h2>Mobile-first User Experience</h2>
                        <p>Built specifically for the busy business owner, the mobile app provides easy access to review marketing performance, adjust budget and targeting parameters, and upload or build ads using pre-configured brand assets.</p>
                    </div>
                    <div className='right-content'>
                        <div className='img-container'>
                            <img src={MobilePhone} />
                        </div>
                    </div>
                </MobileExperienceContainer>
            </SectionContainer>
        </Section>
    )
}

// * Corp Control
const CorporateControl = () => {
    return (
        <Section className='Section industryAngle'>
            <SectionMasked>
                <BackgroundMask>
                    <SectionBackground></SectionBackground>
                </BackgroundMask>
            </SectionMasked>
            <SectionContainer className='corp__padding'>
                <CorporateControlContainer>
                    <div className='left-content'>
                        <div className='img-container'>
                            <img src={LaptopImage} />
                        </div>
                    </div>
                    <div className='right-content'>
                        <h2>Extensive Corporate Controls</h2>
                        <p>The fully centralized command center puts the corporate team in full control to easily build and configure campaigns, review performance, and customize budgeting and targeting parameters.</p>
                    </div>
                </CorporateControlContainer>
            </SectionContainer>
        </Section>
    )
}

// * Gain Momentum
const GainMomentum = () => {
    return (
        <Section>
            <SectionContainer className='momentum__padding'>
                <GainMomentumContainer>
                    <div className='text-container'>
                        <h1>How Eulerity is Helping Franchisors Gain Momentum</h1>
                        <p>Keeping up with today’s business demands means you need to act fast and react even faster. Supporting both in-house and decentralized approaches, Eulerity’s all-in-one solution allows teams to accelerate speed to market.</p>
                    </div>
                    <MomentumGrid />
                </GainMomentumContainer>
            </SectionContainer>
        </Section>
    )
}

// * Scale Media
const ScaleMedia = () => {
    return (
        <Section className='Section industryAngle'>
            <SectionMasked>
                <BackgroundMask>
                    <SectionBackground></SectionBackground>
                </BackgroundMask>
            </SectionMasked>
            <SectionContainer className='cost__padding'>
                <ScaleMediaContainer>
                    <div className='text-container'>
                        <h1>Scale Your Media, Not Your Costs.</h1>
                        <p>Hidden costs. Complex fee structures. The traditional agency approaches eat away at the funds available to market your brand. Eulerity takes a simple approach to streamline the investment for both the franchisor and franchisees.</p>
                    </div>
                    <div className='cost-container'>
                        <div className='cost-box'>
                            <p className='cost-header'>LOW Monthly Flat Fee</p>
                            <p className='cost-text'>More money towards ads means a higher return on ad spend</p>
                        </div>
                        <div className='cost-box'>
                            <p className='cost-header'>NO Monthly Minimums</p>
                            <p className='cost-text'>Franchisees can adjust their monthly spend whenever they want, within pre-set boundaries - if allowed by the franchisor</p>
                        </div>
                        <div className='cost-box'>
                            <p className='cost-header'>NO Hidden Costs</p>
                            <p className='cost-text'>One all inclusive price with access to all current and future products and services</p>
                        </div>
                    </div>
                </ScaleMediaContainer>
            </SectionContainer>
        </Section>
    )
}

// * Customers
const Customers = () => {
    return (
        <Section>
            <SectionContainer className='customers__padding'>
                <CustomerContainer>
                        <h1>Don’t Take Our Word For It</h1>
                        <p>Here is what our customers have to say.</p>
                    <CustomersCarousel />
                </CustomerContainer>
            </SectionContainer>
        </Section>
    )
}

// * Client Succes
const ClientSuccess = () => {
    return (
        <Section>
            <SectionContainer className='client__padding'>
                <ClientSuccessContainer>
                    <h1>Client Success</h1>
                    <CaseStudyGrid />
                    <a className='case-btn' href='/company/case-studies' >
                        <span>View All Case Studies</span>
                    </a>
                </ClientSuccessContainer>
            </SectionContainer>
        </Section>
    )
}

// * Get Started
const GetStarted = () => {
    return (
        <Section>
            <SectionContainer className='get__started__padding'>
                <GetStartedContainer>
                    <div className='grid-item-1'>
                        <h1>Ready to Get Started?</h1>
                    </div>
                    <div className='grid-item-2'>
                        <img src={GetStartedImage} />
                    </div>
                    <div className='grid-item-3'>
                        <div className='learn-more-container'>
                            <p>Schedule a demo and see the future of marketing technology at work.</p>
                            <a href='/contact-us'>
                                <span>Schedule a Demo</span>
                            </a>
                        </div>
                    </div>
                </GetStartedContainer>
            </SectionContainer>
        </Section>
    )
}

const NewIndustry = () => {
    return (
        <Main>
            <Hero />
            <Challenges />
            <MobileExperience />
            <CorporateControl />
            <GainMomentum />
            <ScaleMedia />
            <Customers />
            <ClientSuccess />
            <GetStarted />
            <Footer />
        </Main>
    )
}

export default NewIndustry