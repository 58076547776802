import cleanjuice from "../../../assets/case-studies/Clean-Juice.pdf";
import blo from "../../../assets/case-studies/Blo.pdf";
import tlg from "../../../assets/case-studies/TLG.pdf";
import wood from "../../../assets/case-studies/Woodhouse-Day-Spa.pdf";
import woat from "../../../assets/case-studies/WOAT.pdf";
import zerorez from "../../../assets/case-studies/Zerorez.pdf";
import saladH from "../../../assets/case-studies/Salad-House.pdf";
import kitchen from "../../../assets/case-studies/kitchen-solvers-cs2.pdf";
import skyzone from "../../../assets/case-studies/Skyzone.pdf";
import juiceLogo from "../../../assets/case-studies/juice.svg";
import workout from "../../../assets/case-studies/woat.svg";
import woodhouse from "../../../assets/case-studies/wood.svg";
import blologo from "../../../assets/case-studies/blo.svg";
import gymlogo from "../../../assets/case-studies/gym.svg";
import saladlogo from "../../../assets/case-studies/salad.svg";
import kitch from "../../../assets/case-studies/kitch.svg";
import zero from "../../../assets/case-studies/zero.svg";
import skyzoneLogo from "../../../assets/case-studies/skyzone.svg";
import wowLogo from "../../../assets/case-studies/wow-logo.png";
import wowPDF from "../../../assets/case-studies/wow-pdf.pdf";

export const NewsArticle = [
  {
    id: 65,
    title: "5 Tips for Boosting Your Brand’s Online Presence",
    by: "Mary Hanula, Senior Director of Marketing, Eulerity",
    date: "January 2, 2023",
    minute: "3 min read",
    link: "https://www.eulerity.com/5-tips-for-boosting-your-brands-online-presence"
  },
  {
    id: 64,
    title: "Brand Messaging: The Power of Storytelling",
    by: "Ashley Mitchell, Senior Vice President of Marketing, Streamline Brands",
    date: "November 1, 2022",
    minute: "3 min",
    link: "https://www.eulerity.com/brand-messaging-the-power-of-story-telling"
  },
  {
    id: 63,
    title: "How to Build A Successful Marketing Team",
    by: "Kelly Crompvoets, Vice President of Marketing, Any Lab Test Now",
    date: "October 18, 2022",
    minute: "3 min",
    link: "https://www.eulerity.com/how-to-build-a-successful-marketing-team"
  },
  {
    id: 62,
    title: "How Data Transparency Can Boost Franchisees",
    by: "Scott Greenberg, Author, Franchise Expert, and TEDx Keynote Speaker",
    date: "October 11, 2022",
    minute: "3 min",
    link: "https://www.eulerity.com/how-data-transparency-can-boost-franchisees"
  },
  {
    id: 61,
    title: "Five Marketing Practices You Need to Know",
    by: "Heather McLeod, CMO, Authority Brands",
    date: "September 25, 2022",
    minute: "2 min",
    link: "https://www.eulerity.com/five-marketing-practices-you-need-to-know"
  },
  {
    id: 60,
    title: "Putting Money Where Your Mouth Is",
    by: "Kristen Pechacek, Chief Growth Officer, MassageLuXe",
    date: "September 25, 2022",
    minute: "3 min",
    link: "https://www.eulerity.com/putting-money-where-your-mouth-is"
  },
  {
    id: 59,
    title: "How to Use Technology to Accelerate Growth",
    by: "Heather Anderson, VP of Operations & Enablement, Eulerity",
    date: "September 13, 2022",
    minute: "3 min",
    link: "https://www.eulerity.com/how-to-use-technology-to-accelerate-brand-growth"
  },
  {
    id: 58,
    title: "Local Marketing Adoption & Enablement",
    by: "Andrea Pirrotti-Dranchak, Global Enterprise Director, NewFlex",
    date: "August 30, 2022",
    minute: "3 min",
    link: "https://www.eulerity.com/local-marketing-adoption-and-enablement"
  },
  {
    id: 57,
    title: "Managing Marketing Budget Shifts",
    by: "Nicole Salla, Chief Marketing Officer, Kiddie Academy",
    date: "August 23, 2022",
    minute: "3 min",
    link: "https://www.eulerity.com/managing-marketing-budget-shifts"
  },
  {
    id: 56,
    title: "How to Use Data to Tell A Story",
    by: "Brandi Kloostra, VP of Digital Marketing, Franworth",
    date: "August 5, 2022",
    minute: "3 min",
    link: "https://www.eulerity.com/how-to-use-data-to-tell-a-story"
  },
  {
    id: 55,
    title: "What’s In and What’s Out — The Keys to In-House Marketing and Outsourcing Vendors",
    by: "Adam Chandler, COO and Co-Founder, Eulerity",
    date: "August 5, 2022",
    minute: "3 min",
    link: "https://www.eulerity.com/the-keys-to-in-house-marketing-and-outsourcing-vendors"
  },
  {
    id: 54,
    title: "Top Takeaways from the First Annual Franchise Customer Experience Conference",
    by: "Mary Hanula, Senior Director of Marketing, Eulerity",
    date: "June 29, 2022",
    minute: "3 min",
    link: "https://www.franchise.org/franchise-information/marketing/top-takeaways-from-the-first-annual-franchise-customer-experience"
  },
  {
    id: 53,
    title: "5 Takeaways From the International Franchise Association’s 2022 Convention",
    by: "Adam Chandler, COO and Co-Founder, Eulerity",
    date: "March 7, 2022",
    minute: "3 min",
    link: "https://www.entrepreneur.com/article/421593"
  },
  {
    id: 52,
    title: "The Creative Conundrum",
    by: "Mary Hanula, Director of Marketing, Eulerity",
    date: "November 22, 2021",
    minute: "4 min",
    link: "https://www.eulerity.com/the-creative-conundrum"
  },
  {
    id: 51,
    title: "The Emergence of the Cloud in Marketing",
    by: "Adam Chandler, COO and Co-Founder, Eulerity",
    date: "October 10, 2021",
    minute: "1.3 min",
    link: "https://www.eulerity.com/the-emergence-of-the-cloud-in-marketing"
  },
  {
    id: 50,
    title: "Multi-Unit Master Series: Part 1",
    by: "Mary Hanula, Senior Director of Marketing, Eulerity",
    date: "September 24, 2021",
    minute: "3 min",
    link: "https://www.franchise.org/blog/multi-unit-masters-series-part-i"
  },
  {
    id: 49,
    title: "Woodhouse Reunion: Connecting at Client Conferences",
    by: "Harlie Kneler, Account Manager, Eulerity",
    date: "September 21, 2021",
    minute: "2.1 min",
    link: "https://www.eulerity.com/woodhouse-blog"
  },
  {
    id: 48,
    title: "What Do I Say Now?",
    by: "Adam Chandler, COO and Co-Founder, Eulerity",
    date: "July 2, 2021",
    minute: "2.5 min",
    link: "https://www.eulerity.com/what-do-i-say-now"
  },
  {
    id: 47,
    title: "Virtual Conferences 101: What We Learned From IFA 2021",
    by: "Mary Hanula, Director of Marketing, Eulerity",
    date: "June 25, 2021",
    minute: "5 min",
    link: "https://www.eulerity.com/what-we-learned-from-ifa"
  },
  {
    id: 46,
    title: "Top Tips to Avoid Tech Fatigue",
    by: "Mary Hanula, Director of Marketing, Eulerity",
    date: "June 25, 2021",
    minute: "5 min",
    link: "https://www.eulerity.com/top-tips-to-avoid-fatigue"
  },
  {
    id: 45,
    title: "The Future as a Service & The Implications For Marketers",
    by: "Adam Chandler, COO and Co-Founder, Eulerity",
    date: "August 17, 2021",
    minute: "2 min",
    link: "https://www.eulerity.com/future-as-a-service-and-implications-for-marketers"
  },
  {
    id: 44,
    title: "The Future as a Service",
    date: "Feb 02, 2021",
    minute: "4 min",
    by: "Tanuj Joshi",
    link: "https://www.eulerity.com/future-as-a-service",
  },

  {
    id: 2,
    title:
      "[CUSTOMER SPOTLIGHT] - Hear why progressive franchise brand Clean Juice chose Eulerity for their marketing infrastructure needs.",
    date: "August 15,2020",
    minute: "2 min",
    link: "https://www.youtube.com/watch?feature=youtu.be&v=pNtvE8MAD8s",
  },

  {
    id: 4,
    title:
      " Eulerity’s COO, Adam Chandler chats with Ford’s Head of Global Media, Marla Skiko on how Ford is responding to the pandemic ",
    date: "July 15, 2020",
    minute: "10 min",
    by: "Adam Chandler",
    link: "https://www.youtube.com/watch?v=CegfRjYIvCI&ab_channel=TeamEulerity",
  },
  {
    id: 5,
    title:
      "Interview with Facebook’s Paresh Rajwat (Head of Video and Music Products @ Facebook) on how he manages and prioritizes products ",
    date: "June 15, 2020",
    minute: "10 min",
    link:
      "https://www.youtube.com/watch?feature=youtu.be&v=6S9Wq2x5KUk&ab_channel=TeamEulerity",
  },
  {
    id: 6,
    title:
      "Eulerity interviews Mark Motini, CMO - Tropical Smoothie Cafe on how he and his team are implementing radical ideas during the crisis",
    date: "May 19, 2020",
    minute: "10 min",
    link: "https://www.youtube.com/watch?v=dpk9fW2_qxc&ab_channel=TeamEulerity",
  },
  {
    id: 11,
    title:
      "[IFA Feature]:  How automation can transform your local marketing strategy as you rebound from this pandemic.  ",
    date: "April 5, 2020",
    minute: "5 min",
    link:
      "https://static1.squarespace.com/static/5c3b9c8289c172c97722c5f9/t/5ea9a380c4b6b342382fa958/1588175753220/FranchisingWorld.April2020.pdf",
    by: "IFA",
  },
  {
    id: 12,
    title: "What to say? How communication in this new era is being tested? ",
    date: "April 1, 2020",
    link: "https://medium.com/@eulerity/what-to-say-f2378086dee2",
    minute: "2 min",
  },
  {
    id: 13,
    title: "Getting customers for something new",
    date: "Mar 5, 2020",
    minute: "3 min",
    by: "Tanuj Joshi",
    link:
      "https://medium.com/@tanuj_2419/finding-customers-for-something-new-c57418a89a8f",
  },
  {
    id: 14,
    title: "The Post IFA Scaling Dilemma ",
    date: "Feb 13, 2020",
    minute: "3 min",
    link: "https://medium.com/@eulerity/the-scaling-dilemma-642de5d0c0e2",
  },
  {
    id: 15,
    title:
      "[IFA] Eulerity and IFA: What the recent Google Chrome changes mean for your business",
    date: "Jan 23, 2020",
    minute: "3 min",
    link:
      "https://www.franchise.org/blog/internet-marketers-what-the-recent-google-browser-change-means-and-why-you-should-care",
  },
  {
    id: 16,
    title: "The Poetry Of Product",
    date: "Dec 11, 2019",
    minute: "3 min",
    link: "https://medium.com/@eulerity/the-poetry-of-product-33bc5d6c250c",
  },
  {
    id: 17,
    title: "New Year’s Resolution for Marketing Leaders",
    date: "Dec 04, 2019",
    minute: "2 min",
    link:
      "https://medium.com/@tanuj_2419/new-years-resolutions-for-marketing-leaders-6986a5242d46",
  },
  {
    id: 18,
    title: "Sounds of 2020",
    date: "Nov 27, 2019",
    minute: "2 min",
    link:
      "https://medium.com/@eulerity/the-potential-sounds-of-2020-99a3ab7911e0",
  },
  {
    id: 19,
    title: "Marketing Leaders Your Team Is Your Biggest Strength (or Weakness)",
    date: "Nov 20, 2019",
    minute: "2 min",
    link:
      "https://medium.com/@tanuj_2419/your-team-is-your-biggest-strength-or-weakness-fb2491348e5e",
  },
  {
    id: 20,
    title: "Rock the ensemble because the set list changes",
    date: "Nov 13, 2019",
    minute: "3 min",
    link:
      "https://www.linkedin.com/pulse/rock-ensemble-because-set-list-changes-adam-chandler/?trackingId=MMGKk4SqIRV%2BF1ncDBNwvQ%3D%3D",
  },
  {
    id: 21,
    title:
      "How to Prioritize the Right Message to your Customers when Everything is #1",
    date: "Nov 05, 2019",
    minute: "5 min",
    link:
      "https://medium.com/@tanuj_2419/how-to-prioritize-the-right-message-to-your-customers-when-everything-is-1-8ec0c5656da8",
  },
  {
    id: 22,
    title: "7 Sound Bites from MITCON",
    date: "Oct 30, 2019",
    minute: "3 min",
    link:
      "https://medium.com/@eulerity/7-soundbites-from-mitcon-2019-b7e80f7dc23f",
  },
  {
    id: 23,
    title: "Max Results, Min Resources ",
    date: "Oct 23, 2019",
    minute: "3 min",
    link:
      "https://medium.com/@tanuj_2419/max-result-min-resources-eaa28cd04de8",
  },

  {
    id: 24,
    title: "Wednesday Wise Webinar with the IFA",
    date: "Oct 15, 2019",
    minute: "4 min",
    link:
      "https://medium.com/@eulerity/breaking-down-the-basics-how-new-technology-like-ai-is-transforming-the-franchise-marketing-space-9f567de5fe74",
  },
  {
    id: 25,
    title: "Please, please listen to your customer ",
    date: "Sep 25, 2019",
    minute: "3 min",
    link:
      "https://medium.com/@tanuj_2419/please-please-listen-to-your-customer-f21aea8425c3",
  },

  {
    id: 26,
    title: "Thinking digital without disruption",
    date: "Sep 16, 2019",
    minute: "2 min",
    link:
      "https://medium.com/@eulerity/thinking-digital-without-disruption-943a55df1064",
  },
  {
    id: 27,
    title: "Clicks for Bricks",
    date: "Sep 10, 2019",
    minute: "3 min",
    link:
      "https://medium.com/@tanuj_2419/making-clicks-work-for-bricks-c1cb9253ab83",
  },
  {
    id: 28,
    title: "The Translation Machine",
    date: "Sep 03, 2019",
    minute: "3 min",
    link: "https://www.franchise.org/blog/the-translation-machine",
  },
  {
    id: 29,
    title: "The Future of Restaurant Advertising: Let Tech Do the Work ",
    date: "June 25, 2019",
    minute: "3 min",
    link:
      "https://www.qsrmagazine.com/outside-insights/future-restaurant-advertising-let-tech-do-work",
  },
  {
    id: 30,
    title:
      "Woodhouse Day Spas Partners Up To Make Local Digital Marketing More Effective ",
    date: "June 18, 2019",
    minute: "3 min",
    link:
      "https://www.franchising.com/articles/woodhouse_day_spas_partners_up_to_make_local_digital_marketing_more_effecti.html?ref=twitter",
  },
  {
    id: 31,
    title: "AI's power to simplify, magnify QSR digital marketing ",
    date: "June 10, 2019",
    minute: "3 min",
    link:
      "https://www.qsrweb.com/blogs/ais-power-to-simplify-magnify-qsr-digital-marketing/",
  },
  {
    id: 32,
    title: "How was Eulerity Named?",
    date: "April 25, 2019",
    minute: "3 min",
    link:
      "https://hear.ceoblognation.com/2019/04/19/entrepreneurs-explain-how-they-came-up-with-their-business-names-10/",
  },
  {
    id: 33,
    title: "The Future of Restaurant Advertising Is Machine Learning",
    date: "Apr 18, 2019",
    minute: "3 min",
    link:
      "https://modernrestaurantmanagement.com/the-future-of-restaurant-advertising-is-machine-learning/",
  },
  {
    id: 34,
    title: "The Hidden Tax in Digital Advertising",
    date: "Apr 08, 2019",
    minute: "3 min",
    img:
      "https://static1.squarespace.com/static/5c3b9c8289c172c97722c5f9/t/5cae26affa0d6012e434d517/1554917040677/Franchising+World_April+2019.pdf",
  },
  {
    id: 35,
    title: "The Role Of Machine Learning In Local Marketing",
    date: "Mar 21, 2019",
    minute: "3 min",
    link:
      "https://www.franchise.org/blog/the-role-of-machine-learning-in-local-marketing",
  },
  {
    id: 36,
    title:
      "Tech Entrepreneurs Unveil Mobile Platform that Fully Automates Cross Channel Digital Marketing for the Franchise Marketplace",
    date: "Feb 7,  2019",
    minute: "3 min",
    link:
      "https://www.prweb.com/releases/tech_entrepreneurs_unveil_mobile_platform_that_fully_automates_cross_channel_digital_marketing_for_the_franchise_marketplace/prweb16082569.htm",
  },
  {
    id: 37,
    title:
      "Learning from your customers. Moving from experience to transformational offering.",
    date: "Feb 5, 2019",
    minute: "3 min",
    link:
      "https://www.linkedin.com/pulse/learning-from-your-customers-moving-experience-adam-chandler/",
  },
  {
    id: 38,
    title: "Building something simple can be harder than complex. ",
    date: "Jan 27, 2019",
    minute: "3 min",
    link:
      "https://medium.com/@tanuj_2419/building-something-simple-can-be-harder-than-complex-25c9db4c51d6",
  },
  {
    id: 39,
    title: "Should I attend this expo/conference?",
    date: "May 6, 2018",
    minute: "3 min",
    link:
      "https://medium.com/@tanuj_2419/should-i-attend-this-expo-conference-fb9d5f363d3e",
  },
  {
    id: 40,
    title:
      "Not Everyone Needs a Tiger — Hiring your first salesperson @ a start-up.",
    date: "April 30, 2018",
    minute: "3 min",
    link:
      "https://www.linkedin.com/pulse/everyone-needs-tiger-hiring-your-first-salesperson-adam-chandler/",
  },

  {
    id: 41,
    title: "If it comes with a manual, it’s not worth your time",
    date: "April 22, 2018",
    minute: "3 min",
    link:
      "https://www.linkedin.com/pulse/everyone-needs-tiger-hiring-your-first-salesperson-adam-chandler/",
  },

  {
    id: 42,
    title:
      "Eulerity Launches New Platform to Unlock the Power of Digital Advertising for Anyone",
    date: "Jan 29, 2018",
    minute: "3 min",
    link: "https://www.prweb.com/releases/2018/01/prweb15136377.htm",
  }
];

export const useCases = [
  {
    id: 1,
    color: "#8DC63F",
    logo: wowLogo,
    text:
      "[WOW] - WOW 1 DAY PAINTING specializes in making both commercial and residential paint jobs. Each painting project is completed by a team of professionals who use environmentally-friendly zero-VOC and low-VOC paints ensuring that no harsh odors or fumes are left behind. Furthermore, their state-of-the-art call center and online booking service ensures both speed and quality in their one-day painting service.",
    pdf: wowPDF,
  },
  {
    id: 2,
    color: "#F27B32",
    logo: skyzoneLogo,
    text:
      "[SKY ZONE] - Sky Zone operates over 150 franchise locations expanding globally and venturing into new countries. As their business grew so did the number of owner operators it needed to support. Sky Zone’s priority was to find an easy-to-use, easy-to-manage platform that enabled their franchise partners a cost-effective marketing solution that drove results. Read how Eulerity became their technology choice of record.",
    pdf: skyzone,
  },
  {
    id: 3,
    text:
      "[CLEAN JUICE] - Clean Juice wanted to expand their franchise market and needed a way to effectively scale their local marketing to their 150+ locations open and in development. Read how Eulerity became the brands marketing system of record.",
    pdf: cleanjuice,
    logo: juiceLogo,
    color: "#A3CC06",
  },
  {
    id: 4,
    logo: blologo,
    color: "#FF0099",

    text:
      "[BLO BLOW DRY BAR] - Blo Blow Dry Bar needed an effective marketing platform to see direct correlation from media spend to location bookings. Blo was unable to see a significant change in sales with previous marketing partners and needed support. Learn more about their experience with Eulerity.",
    pdf: blo,
  },
  {
    id: 5,
    logo: gymlogo,
    color: "#00A7DD",
    text:
      "[THE LITTLE GYM] - With nearly 400 locations worldwide, The Little Gym sought an effective and efficient way to help manage and enhance their franchise owned marketing strategies. Learn how Eulerity provided an all in-one marketing platform enabling business owners to easily distribute branded marketing assets and generate targeted ads to increase traffic and conversions to their websites.",
    pdf: tlg,
  },

  {
    id: 6,
    color: "#5F87A0",
    logo: kitch,
    text:
      "[KITCHEN SOLVERS] - The marketing team at Kitchen Solvers felt like they were spending too much time liaising between their franchisees and agencies and wanted to bring marketing support in-house. When introduced to Eulerity, they were excited by the way their team could manage all digital marketing on Search, Social, Display and Video on behalf of their franchisees and also provide their franchisees with a robust app to see all creative and their results in real time.",
    pdf: kitchen,
  },

  {
    id: 7,
    logo: woodhouse,
    color: "#6B4514",
    text:
      "[WOODHOUSE DAY SPA] - Like many franchisors, The Woodhouse Day Spa’s success is based on the success of its franchisees, so the brand puts an enormous amount of resources behind supporting local store marketing. See how they used Eulerity's platform to grow their business in store and online.",
    pdf: wood,
  },
  {
    id: 8,
    logo: workout,
    color: "#002691",
    text:
      "[WORKOUT ANYTIME] - The brand’s team planned to expand its footprint and reach its full potential from both a franchise and consumer perspective. To accomplish this they needed an advanced marketing system that could promote their in person clubs, virtual options, raise their app visibility and on demand workouts – all through one automated system. Read how they used Eulerity's software to achieve that vision.",
    pdf: woat,
  },
  {
    id: 9,
    color: "#00AEE6",
    logo: zero,
    text:
      "[ZEROREZ] - Zerorez's marketing managers have a long list of things to keep up with each day - creating content, analyzing data, preparing reports, posting on social media, creating email campaigns, meeting with vendors, research, and so much more. With so much on their plate, having tools and applications that streamline these processes are vital.",
    pdf: zerorez,
  },
  {
    id: 10,
    logo: saladlogo,
    color: "#44A247",
    text:
      "[THE SALAD HOUSE] - The Salad House needed an all-in-one marketing solution and required advanced data analytic capabilities to support their small corporate marketing team. The technology needed to be nimble and easy to augment the company’s growth and introduce even more consumers to their delicious, health- conscious food offerings.",
    pdf: saladH,
  },
];
