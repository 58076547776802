import React, { useState } from 'react';
import Navbar from './Navbar';
import NavbarItem from './NavigationItem';
import { Flipper } from 'react-flip-toolkit';
import DropdownContainer from './dropdownContainer/DropdownContainer';
import ProductsDropdown from './dropdownContent/ProductsDropdown';
import UseCaseDropdown from './dropdownContent/UseCaseDropdown';
import CompanyDropdown from './dropdownContent/CompanyDropdown';
import CareersDropdown from './dropdownContent/CareersDropdown';

const navbarConfig = [
  { title: 'Products', dropdown: ProductsDropdown },
  { title: 'Use Cases', dropdown: UseCaseDropdown },
  { title: 'Company', dropdown: CompanyDropdown },
  { title: 'Careers', dropdown: CareersDropdown }
];

const AnimatedNavbar = ({ duration }) => {
  const [activeIndices, setActiveIndices] = useState([]);
  const [animatingOut, setIsAnimatingOut] = useState(false);

  let animatingOutTimeout;
  let CurrentDropdown;
  let PrevDropdown;
  let direction;

  const currentIndex = activeIndices[activeIndices.length - 1];
  const prevIndex = activeIndices.length > 1 && activeIndices[activeIndices.length - 2];

  if (typeof currentIndex === 'number')
    CurrentDropdown = navbarConfig[currentIndex].dropdown;
  if (typeof prevIndex === 'number') {
    PrevDropdown = navbarConfig[prevIndex].dropdown;
    direction = currentIndex > prevIndex ? 'right' : 'left';
  }

  // * Reset Dropdown State
  const resetDropdownState = (i) => {
    setActiveIndices(typeof i === 'number' ? [i] : [])
    setIsAnimatingOut(false)

    clearTimeout(animatingOutTimeout);
  };

  const onMouseEnter = (i) => {
    if (animatingOutTimeout) {
      clearTimeout(animatingOutTimeout);
      resetDropdownState(i);
      return;
    }
    if (activeIndices[activeIndices.length - 1] === i) return

    setActiveIndices(prev => prev.concat(i))
    setIsAnimatingOut(false)
  };

  const onMouseLeave = () => {
    setIsAnimatingOut(true)

    animatingOutTimeout = setTimeout(
      resetDropdownState,
      duration
    );
  };

  return (
    <Flipper
      flipKey={currentIndex}
      spring={duration === 300 ? 'noWobble' : { stiffness: 10, damping: 10 }}>
      <Navbar onMouseLeave={onMouseLeave}>
        {navbarConfig.map((n, index) => {
          return (
            <NavbarItem
              key={n.title}
              title={n.title}
              index={index}
              onMouseEnter={onMouseEnter}>
              {currentIndex === index && (
                <DropdownContainer
                  direction={direction}
                  animatingOut={animatingOut}
                  duration={duration}>
                  <CurrentDropdown />
                  {PrevDropdown && <PrevDropdown />}
                </DropdownContainer>
              )}
            </NavbarItem>
          );
        })}
      </Navbar>
    </Flipper>
  );
}

export default AnimatedNavbar;
