import styled from "styled-components";
import bgApp from "../../../assets/franchise-image/app.svg";
import bg from "../../../assets/franchise-image/dash-inverse.png";
import cost from "../../../assets/customer-logo/cost.png";
import masked from "../../../assets/laptop-mask.svg";
import laptopDevice from "../../../assets/laptopDevice.jpg";
import controls from "../../../assets/controlsLaptop.svg";
import groundShadow from "../../../assets/ground-shadow.png";
const Section = styled.section`
  --sectionPaddingTopMax: 116;

  &.less {
    @media (min-width: 670px) {
      margin-top: -120px;
    }
  }
  &.first {
    @media (min-width: 670px) {
      margin-top: -150px;
    }
  }
  &.mtop {
    @media (min-width: 670px) {
      margin-top: 150px;
    }
  }

  &.angleBottom {
    --sectionPaddingBottom: var(--sectionAnglePaddingBottom);
    --sectionMarginBottom: calc(var(--sectionAngleHeight) * -1);
    --sectionTransformOrigin: 0 0;
  }
  &.angleBoth {
    --sectionPaddingBottom: var(--sectionAnglePaddingBottom);
    --sectionMarginBottom: calc(var(--sectionAngleHeight) * -1);
  }

  &.ProductHeroSection {
    --sectionPaddingTop: 0;
    --sectionContentMarginBottom: calc(
      192px + -112 * (var(--windowWidth) - 375px) / 737
    );
    --sectionLayoutMarginBottom: 0;
    --gradientAngle: var(--angleStrong);
    --gradientHeight: 250px;
    z-index: 2;
    @media (min-width: 1112px) {
      --sectionContentMarginBottom: 80px;
    }
  }
`;

export const Main = styled.main`
  font-family: "Open Sans";
`;

const SectionMasked = styled.div`
  overflow: var(--sectionOverflow);
`;

const BackgroundMask = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: var(--sectionBackgroundOverflow);
`;

const SectionBackground = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -webkit-transform-origin: var(--sectionTransformOrigin);
  transform-origin: var(--sectionTransformOrigin);
  -webkit-transform: skewY(var(--sectionAngle));
  transform: skewY(var(--sectionAngle));
  background: #fff;
  overflow: hidden;

  &.angle {
    --sectionPaddingTop: var(--sectionAnglePaddingTop);
    --sectionAngle: var(--angleNormal);
  }

  &.compliance {
    background: linear-gradient(
        180deg,
        rgba(16, 16, 16, 0.2) 43%,
        rgba(16, 16, 16, 0) 100%
      ),
      radial-gradient(
        92.7% 226.09% at 5.09% 21.55%,
        #2460A7FF 0%,
        #85B3D1FF 69.79%,
        #B3C7D6FF 100%
      );
  }

  &.bg {
    background-color: red;
  }

  &.grey {
    background: #f6f9fc;
  }
`;

const CopySubtitle = styled.h1`
  --titleFont: 425 15px/1.6 "Open Sans", sans-serif;
  position: relative;
  font: var(--titleFont);
  color: #000;
  letter-spacing: var(--titleLetterSpacing, inherit);
  font-weight: bold;
`;

const List = styled.div`
  --listSpacing: 4px;
  --linkWeight: var(--fontWeightSemibold);
  --columnCount: 2;
  padding: 0 var(--columnPaddingNormal);
  font: var(--fontWeightNormal) 15px/1.6 var(--fontFamily);
  letter-spacing: 0.2px;
  ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 4px 32px;
  }
  li {
    list-style: disc;
  }
`;

const Guides = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: var(--columnPaddingNormal);
  pointer-events: none;
`;

const GuideContainer = styled.div`
  display: grid;
  grid: 1fr / repeat(var(--columnCountMax), 1fr);
  position: relative;
  max-width: var(--layoutWidth); /**1080px */
  height: 100%;
  margin: 0 auto;
`;
const Guide = styled.div`
  width: 1px;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(var(--guideDashedColor)),
    color-stop(50%, var(--guideDashedColor)),
    color-stop(50%, transparent),
    to(transparent)
  );
  background: linear-gradient(
    180deg,
    var(--guideDashedColor),
    var(--guideDashedColor) 50%,
    transparent 0,
    transparent
  );
  background-size: 1px 8px;
  &:first-of-type {
    background: var(--guideSolidColor);
  }
`;

const SectionContainer = styled.div`
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
`;

const SectionLayoutContainer = styled.div`
  width: 100%;
  max-width: var(--layoutWidth);
  margin: 0 var(--columnPaddingNormal);
`;

const SectionLayout = styled.div`
  padding: 200px 0 var(--sectionPaddingBottom);

  &.smallPadding {
    padding: 50px 0 var(--sectionPaddingBottom);
    @media (min-width: 670px) {
      padding: 50px 0 var(--sectionPaddingBottom);
    }
  }
`;

const Gradient = styled.div`
  --gradientColorZero: #2460A7FF;
  --gradientColorOne: #85B3D1FF;
  --gradientColorTwo: #B3C7D6FF;
  --gradientColorThree: #e2eaef;
  /* --gradientColorThree: #ee8700; */
  --gradientColorZeroTransparent: rgba(169, 96, 238, 0);
  --gradientColorOneTransparent: rgba(255, 51, 61, 0);
  --gradientColorTwoTransparent: rgba(144, 224, 255, 0);
  --gradientColorThreeTransparent: rgba(255, 203, 87, 0);
  --gradientAngle: -12deg;
  --gradientHeight: 200px;
  --sectionAngleSin: 0.212;
  position: absolute;
  bottom: -100px;
  left: 0;
  width: 100%;
  min-width: 1000px;
  height: var(--gradientHeight);
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -webkit-transform: skewY(var(--gradientAngle));
  transform: skewY(var(--gradientAngle));
  z-index: 0;
  @media (min-width: 600px) {
    bottom: -120px;
  }

  @media (min-width: 1920px) {
    bottom: -150px;
  }
`;

const ColumnLayout = styled.div`
  --columnRowGap: var(--rowGapLarge);
  display: grid;
  row-gap: var(--columnRowGap);
  -webkit-box-align: start;
  align-items: flex-start;
  @media (min-width: 600px) {
    &[data-columns="2,2"],
    &[data-columns="2,1,0"],
    &[data-columns="2,1,1"],
    &[data-columns="2,2"],
    &[data-columns="1,1"] {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (min-width: 900px) {
    &[data-columns="3,1"] {
      grid-template-columns: 3fr 1fr;
    }
  }
  @media (min-width: 900px) {
    &[data-columns="1,1,1,1"] {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  @media (min-width: 900px) {
    &[data-columns="1,3"] {
      grid-template-columns: 1fr minmax(0, 3fr);
    }
  }
  &.cards {
    gap: 4px;
  }
`;

const RowLayout = styled.div`
  --rowLayoutGapSmall: 16px;
  --rowLayoutGapNormal: 32px;
  --rowLayoutGapMedium: var(--rowLayoutGapNormal);
  --rowLayoutGapLarge: var(--rowLayoutGapNormal);
  --rowLayoutGapXLarge: var(--rowLayoutGapNormal);
  --rowLayoutGap: var(--rowLayoutGapLarge);
  display: grid;
  grid: auto/minmax(0, 1fr);
  row-gap: var(--rowLayoutGap);

  @media (min-width: 600px) {
    --rowLayoutGapMedium: 48px;
    --rowLayoutGapLarge: var(--rowLayoutGapMedium);
    --rowLayoutGapXLarge: var(--rowLayoutGapMedium);
  }
  @media (min-width: 900px) {
    --rowLayoutGapLarge: 64px;
    --rowLayoutGapXLarge: 96px;
  }
  .bigCard {
    --cardShadow: none;
    --cardShadowMargin: 0;
    position: relative;
    min-width: 100px;
    height: 500px;
    border-radius: 8px;
    background: grey;
    overflow: hidden;
    margin: 0 1px;
  }
`;

const Copy = styled.div`
  display: grid;
  row-gap: var(--rowGap);

  &.variant--Section {
    --titleFontSize: 34px;
    --titleLineHeight: 1.294117647;
    --titleLetterSpacing: -0.1px;
  }
  @media (min-width: 1112px) {
    &.variant--Section {
      --titleFontSize: 38px;
      --titleLineHeight: 1.263157895;
      --titleLetterSpacing: -0.2px;
    }
  }

  .img {
    height: 300px;
    width: 500px;
    color: white;
    background: grey;
  }

  .card {
    background: grey;
    height: 100px;
    width: 200px;
    color: white;
    text-align: center;
  }
`;
const CopyHeader = styled.header`
  display: grid;
  row-gap: 24px;
  position: relative;
  padding: 0 16px 0 16px;
`;

const CopyCaption = styled.h2`
  font: var(--captionFont);
  color: #101010;

  &.green {
    color: #1fdd95;
  }
`;

const CopyTitle = styled.h1`
  --titleFont: 500 30px "Open Sans", sans-serif;
  position: relative;
  font: var(--titleFont);
  color: #101010;
  letter-spacing: var(--titleLetterSpacing, inherit);
  font-weight: bold;

  &.complianceTitle {
    color: #fff;
  }
`;

const CopyBody = styled.div`
  padding: 0 var(--bodyPaddingRight) 0 var(--bodyPaddingLeft);
  font: var(--bodyFont);
  color: #000;
`;

const CopyFooter = styled.div`
  display: grid;
  row-gap: var(--footerRowGap);
  padding: 0 var(--footerPaddingRight) 0 var(--footerPaddingLeft);
`;

const CopyFooterContainer = styled.div`
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;

  & > a:not(:first-of-type) {
    margin-left: var(--ctaSpacing);
  }
`;

const TableLayout = styled.table``;

const CopyBodySubText = styled.p`
  --bodyFont: 500 18px/ 1.555555556 "Open Sans", sans-serif;
  padding: 0 var(--bodyPaddingRight) 0 var(--bodyPaddingLeft);
  font: var(--bodyFont);
  color: #425466;

  &.drive {
    font-size: 15px;
    line-height: 1.6;
    font-weight: 500;
  }

  &.complianceText {
    color: #fff;
  }

  &.learn {
    margin-bottom: 15px;
  }
`;

const Graphic = styled.figure`
  position: relative;
  color: white;
  width: 125%;
  height: 125%;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: left;
  align-self: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.image-app {
    background-image: url(${bgApp});
    background-size: cover;
  }

  &.image-cost {
    background-image: url(${cost});
    background-repeat: no-repeat;
    background-size: contain;
    background-poistion: center;
    width: 50%;
    margin: 0 auto;
  }

  img {
    width: 80%;
    border: 1px solid red;
    height: 80%;
    object-fit: contain;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }
`;
const GraphicLaptop = styled.figure`
  position: relative;
  transform: rotateY(-180deg);
  .device-container {
    position: relative;
    width: 50%;
  }

  .devices-dahboard {
    display: none;
    width: 9000px;
    height: 850px;
    position: absolute;
    z-index: -1;
    left: -220px;
    top: 40px;
    bottom: -150px;
    -webkit-transform: scaleX(0.2);
    transform: scaleX(0.2);

    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
    -webkit-transition: opacity 1s ease;
    transition: opacity 1s ease;

    @media (min-width: 670px) {
      display: block;
      -webkit-transform: none;
      transform: none;
    }

    &.loaded {
      opacity: 1;
    }
    & div {
      position: absolute;
      width: 1000px;
      height: 750px;
      background-size: 100% 100%;
    }
    .screen {
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
      transform: rotateY(90deg);
      overflow: hidden;
      will-change: transform;
    }

    .ground {
      .shadow {
        background-image: url(${groundShadow});
      }
    }

    .laptop {
      border: 1px solid red;
      -webkit-mask: url(${masked});
      mask: url(${masked});

      .device {
        background-image: url(${laptopDevice});
      }

      .controls {
        background-image: url(${controls});
      }

      .screen {
        width: 685px;
        height: 430px;
        border-radius: 6px;
        -webkit-transform: matrix3d(
          0.8562198209416383,
          0.021937972700884066,
          0,
          0.00007058940264233656,
          0.08894470190852294,
          0.8079581585858137,
          0,
          -0.00009582949605178664,
          0,
          0,
          1,
          0,
          35,
          42,
          0,
          1
        );
        transform: matrix3d(
          0.8562198209416383,
          0.021937972700884066,
          0,
          0.00007058940264233656,
          0.08894470190852294,
          0.8079581585858137,
          0,
          -0.00009582949605178664,
          0,
          0,
          1,
          0,
          35,
          42,
          0,
          1
        );
        -webkit-box-shadow: 1px 1px 4px rgba(107, 124, 147, 0.5);
        box-shadow: 1px 1px 4px rgba(107, 124, 147, 0.5);
        background: url(${bg}) 0 0/100% 100%;
        @media (-webkit-min-device-pixel-ratio: 1.3),
          (-webkit-min-device-pixel-ratio: 1.25),
          (min-resolution: 120dpi) {
          background-image: url(${bg});
          background-size: cover;
        }
      }
    }
  }
`;

const SectionStarted = styled.section`
  --sectionPaddingTopMax: 116;
  font-family: "Opens Sans", sans-serif;

  &.top {
    @media (min-width: 670px) {
      margin: -120px 0 0 0;
    }
  }
`;
const CopyIcon = styled.div`
  min-height: 30px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
`;
const CtaButton = styled.a`
  display: inline-block;
  padding: 5px;
  border-radius: 16.5px;
  font: 18px;
  color: #000;
  white-space: nowrap;
  -webkit-transition: var(--hoverTransition);
  transition: var(--hoverTransition);
  outline: none;

  &.variant_button {
    padding-left: 16px;
    padding-right: 16px;
    background-color: ${props => props.background ? props.background : '#009deb'};
    color: #ffff;
    -webkit-transition-property: background-color, opacity;
    transition-property: background-color, opacity;
  }

  &.cta {
    will-change: opacity;
  }

  &.cta-arrow {
    padding-right: 12px;
  }

  &.variant-link {
    font-weight: var(--linkWeight, var(--fontWeightSemibold));
    -webkit-transition-property: color, opacity;
    transition-property: color, opacity;
  }
`;

const AnimationSequence = styled.div``;
const SectionTestimonials = styled.section`
  position: relative;
  padding: 60px 0 54px;
  background-color: #002c59;
  @media (min-width: 670px) {
    padding: 100px 0 54px;
  }

  .main-carousel {
    height: 300px;
    position: relative;

    @media (min-width: 880px) {
      height: 300px;
    }
  }

  .carousel-cell {
    width: 100%; /* full width */
    height: 100%; /* height of carousel */
    margin-right: 10px;
    text-align: center;
    font-size: 2rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    padding: 30px;
    margin-bottom: -10px;
    -webkit-mask-image: linear-gradient(
      180deg,
      #000,
      #000 calc(100% - 10px),
      transparent calc(100% - 10px),
      transparent
    );
    mask-image: linear-gradient(
      180deg,
      #000,
      #000 calc(100% - 10px),
      transparent calc(100% - 10px),
      transparent
    );

    @media (min-width: 670px) {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
  }

  .flickity-prev-next-button {
    background: #87a2ff;
  }

  .flickity-button-icon {
    fill: white;
  }

  .flickity-page-dots .dot {
    width: 12px;
    height: 12px;
    opacity: 1;
    background: transparent;
    border: 2px solid white;
  }
  /* fill-in selected dot */
  .flickity-page-dots .dot.is-selected {
    background: white;
  }

  .author {
    text-align: center;
    margin-bottom: 30px;
    @media (min-width: 670px) {
      text-align: left;
      margin-bottom: 0;
      padding-left: 30px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-preferred-size: 50%;
      flex-basis: 50%;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }

    @media (max-width: 880px) {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
  img {
    box-shadoow: none;
    border-radius: 0;
  }

  .avatar {
    position: relative;
    margin-bottom: 20px;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    overflow: hidden;
    -ms-flex-negative: 0;
    flex-shrink: 0;

    @media (min-width: 670px) {
      margin-bottom: 0;
      margin-right: 25px;
    }
  }

  blockquote {
    -ms-flex-preferred-size: 60%;
    flex-basis: 60%;
    padding-left: 20px;
    font-size: 12px;
    line-height: 15px;
    color: #fff;
    @media (min-width: 670px) {
      font-size: 18px;
      line-height: 25px;
      padding: 7px;
    }
    &::before {
      background-position: 0 0;
      left: -7px;
      margin-left: -12px;
    }
  }

  .byline {
    .name {
      color: #fcd669;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 19px;
      margin-top: 20px;

      @media (max-width: 880px) {
      text-align:center;
    }
    }

    .title {
      color: #fff;
      font-size: 16px;

      @media (max-width: 880px) {
      text-align:center;
    }
  }
`;
export {
  Section,
  SectionMasked,
  BackgroundMask,
  SectionBackground,
  Guides,
  GuideContainer,
  Guide,
  SectionContainer,
  SectionLayout,
  SectionLayoutContainer,
  ColumnLayout,
  RowLayout,
  Copy,
  CopyHeader,
  CopyCaption,
  CopyTitle,
  CopyBody,
  CopyFooter,
  CopyFooterContainer,
  CopyBodySubText,
  Graphic,
  CopySubtitle,
  List,
  TableLayout,
  CopyIcon,
  CtaButton,
  AnimationSequence,
  SectionStarted,
  GraphicLaptop,
  Gradient,
  SectionTestimonials,
};
