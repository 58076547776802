import styled from 'styled-components';

export const CtaButton = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  height: 60px;
  border-radius: 53px;
  font: 425 15px/1.5555 'Open Sans', sans-serif;
  font-size: 24px;
  white-space: nowrap;
  -webkit-transition: transform 0.7s ease;
  transition: transform 0.7s ease;
  text-align: center;
  outline: none;
  color: #000;

  &.variant_button {
    padding-left: 32px;
    padding-right: 28px;
    background-color: #256dfe;
    color: #fff;
    text-align: center;
    -webkit-transition-property: background-color, opacity;
    transition-property: background-color, opacity;
  }

  svg {
    margin-bottom: 4px;
  }

  &.cta {
    will-change: opacity;
  }

  &.cta-arrow {
    padding-right: 28px;
  }

  &.variant-link {
    font-weight: var(--linkWeight, var(--fontWeightSemibold));
    -webkit-transition-property: color, opacity;
    transition-property: color, opacity;
  }

  &:hover {
    transform: translateY(-3px);
  }
`;

export const Wrap = styled.div`
  height: 700px;
  background-color: #1FDD95;
  position: relative;
  font-family: 'Open Sans', sans-serif;

  #hero-image {
    position: absolute;
    z-index: 2;
    right: -450px;
    bottom: -275px;
    height: 600px;
  }

  #waves {
    position: absolute;
    bottom: -550px;
    left: 0px;
    z-index: 1;
  }

  @media screen and (max-width: 1200px) {
    #hero-image {
      display: none;
    }

    #waves {
      left: -200px;
    }
  }

  @media screen and (min-width: 1500px) {
    #waves {
      width: 125vw;
    }
  }

`

export const CopyWrap = styled.div`
  width: 100%;
  max-width: 850px;
  padding-top: 120px;
  position: absolute;
  left: 50%;
  transform: translateX(-60%);
  z-index: 10;

  @media screen and (max-width: 1200px) {
    padding-left: 100px;
  }
`

export const Title = styled.div`
  font-size: 4rem;
  font-weight: 700;

  @media screen and (max-width: 1200px) {
    font-size: 3rem;
  }
`

export const Subtitle = styled.div`
  font-size: 1.5rem;
  margin-top: 1.5rem;
  line-height: 170%;

  @media screen and (max-width: 1200px) {
    font-size: 1.25rem;
    margin-top: 3.5rem;
  }
`

export const ActionsRow = styled.div`
  margin-top: 3.5rem;
  display: flex;
  align-items: center;
  gap: 3rem;
`