import React, { useEffect } from "react";
import Button from "../../../components/buttons/Button";
import Global from "../../../components/utilities/SectionGlobe";
import Footer from "../../../components/footer/Footer";
import LogoApp from "../../../assets/customer-logo/friction.svg";
import fccApp from "../../../assets/customer-logo/gears.svg";
import auto1 from "../../../assets/automotive-image/auto1.png";
import auto2 from "../../../assets/automotive-image/auto2.png";
import auto3 from "../../../assets/automotive-image/auto3.png";
import auto4 from "../../../assets/automotive-image/auto4.jpg";
import auto5 from "../../../assets/automotive-image/auto5.jpg";
import { loadScript } from "../../../helperFunction/helper";
import Shauna from "../../../assets/person/shauna.png";
import Josh from "../../../assets/person/josh.jpg";
import Kali from "../../../assets/person/kali.jpeg";
import Dion from "../../../assets/person/DionBeary .jpg";
import {
  Section,
  SectionMasked,
  BackgroundMask,
  SectionBackground,
  Guides,
  GuideContainer,
  Guide,
  SectionContainer,
  SectionLayout,
  SectionLayoutContainer,
  ColumnLayout,
  RowLayout,
  Copy,
  CopyTitle,
  CopyFooter,
  CopyFooterContainer,
  CopyHeader,
  SectionStarted,
  CopyBodySubText,
  Graphic,
  CopySubtitle,
  CopyBody,
  CtaButton,
  GraphicLaptop,
  Main,
  Gradient,
  SectionTestimonials,
} from "./style";
import AutoTable from "../../../components/table/AutoTable";
const Hero = () => {
  useEffect(() => {
    loadScript("/js/gradient.js");
  }, []);
  return (
    <Section className='Section angleBottom angleBoth ProductHeroSection first '>
      <SectionMasked>
        <BackgroundMask>
          <SectionBackground>
            <Guides>
              <GuideContainer>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
              </GuideContainer>
            </Guides>
          </SectionBackground>
        </BackgroundMask>
      </SectionMasked>
      <SectionContainer>
        <SectionLayoutContainer>
          <SectionLayout>
            <Gradient className='gradient isLoaded'>
              <canvas
                id='gradient-canvas'
                className='Gradient__canvas isLoaded'
              ></canvas>
            </Gradient>

            <ColumnLayout data-columns='2,2'>
              <Copy className='variant--Section'>
                <CopyHeader>
                  <CopyTitle>
                    Dealers want the best — time to give it to them
                  </CopyTitle>
                </CopyHeader>
                <CopyBodySubText className='learn'>
                  Help dealers drive more leads, awareness, and consideration with an always-on, progressive marketing tool.

                  Eulerity’s white label platform automates dealer internet advertising options. With our easy to use software, auto dealers can market to the full internet to fill their lead funnel and maximize the leads they acquire. 

                  Eulerity’s omnichannel marketing technology lets you be everywhere your customers are.
                </CopyBodySubText>

                <CopyFooter>
                  <CopyFooterContainer>
                    <Button bg='#2460A7FF' color='#fff' href={"/contact-us"}>
                      Learn more
                    </Button>
                  </CopyFooterContainer>
                </CopyFooter>
              </Copy>
              <Graphic className='franchise-image-app'>
                <img src={auto1} alt='auto' />
              </Graphic>
            </ColumnLayout>
          </SectionLayout>
        </SectionLayoutContainer>
      </SectionContainer>
    </Section>
  );
};

const Payouts = () => {
  return (
    <Section className='Section mtop'>
      <SectionMasked>
        <BackgroundMask>
          <SectionBackground>
            <Guides>
              <GuideContainer>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
              </GuideContainer>
            </Guides>
          </SectionBackground>
        </BackgroundMask>
      </SectionMasked>

      <SectionContainer>
        <SectionLayoutContainer>
          <SectionLayout>
            <ColumnLayout data-columns='2,2'>
              <RowLayout>
                <Copy className='variant--Section'>
                  <CopyHeader>
                    <CopyTitle>
                      Use automation to acquire more customers
                    </CopyTitle>
                  </CopyHeader>
                  <CopyBodySubText>
                    Enable your dealers to reach in-market shoppers in the browsing, consideration, and intent mode.

                    Have your own valuable prospect/customer data?
                    Connect it to our platform and experience surround sound marketing. Reach your best prospects through omnichannel marketing. 

                    Sick of complicated digital marketing experiences? Tap into the world’s most modern, self-serve advertising platform designed for on-the-go professionals.
                  </CopyBodySubText>
                </Copy>
              </RowLayout>
              <Graphic className='franchise-image-app'>
                <img src={auto2} alt='auto2' />
              </Graphic>
            </ColumnLayout>
          </SectionLayout>
        </SectionLayoutContainer>
      </SectionContainer>
    </Section>
  );
};

const Compliance = () => {
  return (
    <Section className='Section compliance'>
      <SectionMasked>
        <BackgroundMask>
          <SectionBackground className='compliance'>
            <Guides>
              <GuideContainer>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
              </GuideContainer>
            </Guides>
          </SectionBackground>
        </BackgroundMask>
      </SectionMasked>

      <SectionContainer>
        <SectionLayoutContainer>
          <SectionLayout>
            <ColumnLayout data-columns='2,2'>
              <RowLayout>
                <Copy className='variant--Section'>
                  <CopyHeader>
                    <CopyTitle className='complianceTitle'>
                      Pay less but expect more
                    </CopyTitle>
                  </CopyHeader>
                  <CopyBodySubText className='complianceText'>
                    We don’t believe in rigid cost structures or exorbitant
                    fees. Our subscription model provides a flat fee which keeps
                    costs low and predictable. Even better, our technology gives
                    you the flexibility to change media budget at any time - not
                    just at the next billing cycle. With our low flat fees, more
                    money goes directly towards media, resulting in more
                    impressions, more clicks, and ultimately more revenue for
                    your dealers.
                  </CopyBodySubText>
                  <CopyBodySubText className='complianceText'>
                    Just because we’re affordable doesn't mean you are
                    sacrificing on quality. Our technology leverages the most
                    progressive artificial intelligence to optimize each
                    individual location’s budget, minute-by-minute. No human
                    could possibly do what our software is able to! When using
                    Eulerity, you can be confident that you money is going to
                    the creative and channels with the highest likelihood of
                    generating revenue for your dealers.
                  </CopyBodySubText>
                </Copy>
              </RowLayout>
              <Graphic className='franchise-image-app'>
                <img className="laptop" src={auto3} alt='auto3' />
              </Graphic>
            </ColumnLayout>
          </SectionLayout>
        </SectionLayoutContainer>
      </SectionContainer>
    </Section>
  );
};

const Started = () => {
  return (
    <SectionStarted className='Section top anglebottom '>
      <SectionMasked>
        <BackgroundMask>
          <SectionBackground>
            <Guides>
              <GuideContainer>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
              </GuideContainer>
            </Guides>
          </SectionBackground>
        </BackgroundMask>
      </SectionMasked>

      <SectionContainer>
        <SectionLayoutContainer>
          <SectionLayout className='smallPadding'>
            <ColumnLayout data-columns='3,1'>
              <Copy className='variant-footer'>
                <CopyHeader>
                  <CopyTitle>Ready to get started and activate dealerships?</CopyTitle>
                </CopyHeader>
                <CopyBody>
                  Schedule a demo and see the future of marketing technology.
                  Whether you end up using our out of the box or enterprise
                  product, you will minimize manual marketing and charge up your
                  strategy.
                </CopyBody>
                <CopyFooter>
                  <CopyFooterContainer>
                    <CtaButton
                      className='cta variant-link'
                      target="_blank"
                      href="/login.jsp"
                    >
                      Sign In
                    </CtaButton>
                    <CtaButton
                      className=' variant_button cta cta-arrow'
                      href={"/contact-us"}
                      background='#2460A7FF'
                    >
                      Contact sales &rarr;
                    </CtaButton>
                  </CopyFooterContainer>
                </CopyFooter>
              </Copy>
            </ColumnLayout>
          </SectionLayout>
        </SectionLayoutContainer>
      </SectionContainer>
    </SectionStarted>
  );
};

const Automated = () => {
  return (
    <Section className='Section'>
      <SectionMasked>
        <BackgroundMask>
          <SectionBackground>
            <Guides>
              <GuideContainer>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
              </GuideContainer>
            </Guides>
          </SectionBackground>
        </BackgroundMask>
      </SectionMasked>

      <SectionContainer>
        <SectionLayoutContainer>
          <SectionLayout className='smallPadding'>
            <RowLayout>
              <Copy className='variant--Section'>
                <CopyHeader>
                  <CopyTitle>Offer Dealers Omnichannel Marketing Today Right In The Palm Of Their Hands</CopyTitle>
                </CopyHeader>
              </Copy>
              <ColumnLayout data-columns='1,3'>
                <Copy>
                  <CopyHeader>
                    <CopySubtitle>Drive Growth</CopySubtitle>
                  </CopyHeader>
                  <CopyBodySubText className='drive'>
                    Drive profitable advertising growth.
                    <br />
                    <br />
                    Eulerity’s platform enables the buying, creative, optimization, and reporting to thousands of dealers with one single easy-to-use tool.
                  </CopyBodySubText>
                </Copy>
                <AutoTable />
              </ColumnLayout>
            </RowLayout>
          </SectionLayout>
        </SectionLayoutContainer>
      </SectionContainer>
    </Section>
  );
};

const BigCards = () => {
  return (
    <Section className='Section'>
      <SectionMasked>
        <BackgroundMask>
          <SectionBackground>
            <Guides>
              <GuideContainer>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
              </GuideContainer>
            </Guides>
          </SectionBackground>
        </BackgroundMask>
      </SectionMasked>

      <SectionContainer>
        <SectionLayoutContainer>
          <SectionLayout className='smallPadding'>
            <ColumnLayout data-columns='2,2'>
              <RowLayout>
                <div className='testimonialsCard'>
                  <div className='Card-1' auto='auto'>
                    <div className='testimonialsContent'>
                      <div className='testimonialsBody'>
                        <h1>Dealer Experience</h1>
                        <p>
                          Dealers love easy to use tools. Give them the ability to control marketing from the palm of their hands via an app as simple to use as email. Simplify your marketing journey with Eulerity. Your dealers can start using the platform in under 15 minutes.
                        </p>
                      </div>
                      <div className='testimoniallogoContainer'>
                        <img src={LogoApp} className='UserLogo' alt='logo' />
                      </div>
                    </div>
                  </div>
                </div>
              </RowLayout>

              <RowLayout>
                <div className='testimonialsCard'>
                  <div className='Card-2 '>
                    <div className='testimonialsContent'>
                      <div className='testimonialsBody'>
                        <h1>Top Down Management Control</h1>
                        <p>
                          Control the dealer marketing experience. From spend flexibility, ad placement optionality, tap to touch targeting, all features are enabled by the agency.  Maintain full brand control while you enable dealers a seamless buying experience.
                        </p>
                      </div>
                      <div className='testimoniallogoContainer'>
                        <img
                          src={fccApp}
                          className='UserLogo'
                          alt='fccc logo'
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </RowLayout>
            </ColumnLayout>
          </SectionLayout>
        </SectionLayoutContainer>
      </SectionContainer>
    </Section>
  );
};
const Industry = () => {
  return (
    <Main>
      <Hero />
      <Payouts />
      <Compliance />
      <Automated />
      <Global
        title='Use AI to optimize your local media spend'
        subtitle='Automatic media, creative, and audience selection based on performance & ROI'
        paraFirst='Give your dealers the diversification and reach they need, without the high cost of service'
        colorScheme={['#2460A7FF', '#85B3D1FF', '#B3C7D6FF']}
      />
      <BigCards />
      <Started />
      <Footer />
    </Main>
  );
};

export default Industry;
