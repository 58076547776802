import styled from "styled-components";

export const TableContainer = styled.div`
    box-sizing: border-box;
    margin: 0 auto;
    font-weight: 500;
    border-radius: 9px;
    border: 1px;
    box-shadow: 0 0 20px rgb(0 0 0 / 15%);
    overflow: hidden;

    li { 
        background: #eee; 
        padding: 15px;
        font-size: 16px;
    }

    li:nth-child(odd) { background: #fff; }

    li:first-child { 
        color: #fff; 
        background-color: #0a2540;
        font-weight: 700;
        text-align: center;
        font-size: 20px;
        padding: 20px;
    }

`;