import styled from 'styled-components';

export const GlobalContent = styled.div`
  -webkit-box-flex: 1;
  flex-grow: 1;
`;

export const Main = styled.main`
  font-family: 'Open Sans', sans-serif;
`;

export const CommonTitle = styled.h1`
  font-weight: 300;
  font-size: 45px;
  line-height: 56px;
  color: #32325d;
  letter-spacing: -0.01em;

  @media (min-width: 670px) {
    font-size: 53px;
    line-height: 68px;
  }
`;

export const CommonSectionTitle = styled.h2`
  font-weight: 400;
  font-size: 34px;
  line-height: 44px;
  color: #32325d;
  margin-bottom: 8px;

  @media (min-width: 670px) {
    font-size: 42px;
    line-height: 52px;
  }
`;

export const Header = styled.section`
  position: relative;

  .headline {
    background: #e6ebf1;
    padding: 150px 0 690px;
    margin-bottom: -650px;
    text-align: center;

    h1 {
      z-index: 10;
      position: relative;

      @media (max-width: 800px) and (min-width: 450px) {
        margin-top: 100px;
      }

      @media (max-width: 1024px) and (min-width: 880px) {
        margin-top: 100px;
      }

      @media (max-width: 880px) {
        font-size: 30px;
        font-family: 'Open Sans', sans-serif;
      }
    }
  }
`;

export const ContentSection = styled.div`
  position: relative;
  z-index: 2;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    top: 126px;
    right: 0;
    bottom: -20px;
    -webkit-transform: skewY(-12deg);
    transform: skewY(-12deg);
    background: radial-gradient(
      85.92% 1296.69% at 11.32% 8.48%,
      #256dfe 0%,
      #470fbe 100%
    );
  }

  .screencast-container {
    position: relative;
  }

  .screencast {
    width: 100%;
    overflow: hidden;
    padding-top: 10.25%;
    padding-top: calc(9 / 16 * 100%);
    position: relative;
    border-radius: 6px;
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1),
      0 15px 35px rgba(0, 0, 0, 0.1), 0 50px 100px rgba(50, 50, 93, 0.1);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1), 0 15px 35px rgba(0, 0, 0, 0.1),
      0 50px 100px rgba(50, 50, 93, 0.1);
    background: #f6f9fc 50%/100%;

    h1 {
      color: black;
      text-align: center;
    }
    .video {
      position: absolute;
      top: 0;
    }
  }
`;

export const SectionLayoutColumns = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1040px;
  padding: 0 20px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;

  @media (min-width: 670px) {
    display: grid;
    grid-column-gap: 40px;
    grid-row-gap: 40px;
  }

  @media (min-width: 670px) {
    &.layoutColumns--2 {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (min-width: 670px) {
    &.layoutColumns--3 {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media (min-width: 670px) {
    &.layoutColumns--4 {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  @media (min-width: 880px) {
    &.adama_columns:last-of-type {
      margin-top: 40px;
    }
  }
`;

export const CommonMediumIntroText = styled.p`
  font-weight: 400;
  font-size: 19px;
  line-height: 32px;
  color: #525f7f;
`;

export const BefenitSection = styled.section`
  position: relative;
  z-index: 10;
  padding-bottom: 80px;
  margin-top: 150px;
  @media (min-width: 880px) {
    padding-bottom: 120px;
  }

  &.big-section {
    padding: 100px 0 20px;
    @media (min-width: 880px) {
      padding: 180px 0;
    }
  }
`;

export const BenefitIntro = styled.div`
  max-width: 800px;
  text-align: center;
  margin: 0 auto;

  &.header {
    margin-bottom: 50px;
  }
  p {
    margin-top: 20px;
  }
`;
export const BenefitTitle = styled.div``;
export const BenefitText = styled.div``;

export const SectionButton = styled.div`
  border-top: 2px solid #f6f9fc;
  margin: 60px -20px 0;
  padding: 60px 20px 0;

  @media (min-width: 670px) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .cta {
    margin-top: 20px;
    @media (min-width: 670px) {
      margin: 0 0 0 20px;
    }
  }

  &.mtop {
    @media (min-width: 670px) {
      margin-top: 250px;
    }
  }
`;
export const TextSection = styled.div`
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;

  h2 {
    font-size: 24px;
    line-height: 36px;
  }
`;

export const CommonSectionTitleHightlight = styled.h2`
  font-weight: 300;
  color: #6772e5;
`;

/*********ide */

export const CommonMediumBodyText = styled.p`
  font-weight: 400;
  font-size: 19px;
  line-height: 32px;
  color: #525f7f;
`;

export const CommonButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 670px) {
    margin-top: 32px;
  }

  a {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin: 10px 10px 10px 0;
  }

  a:not(:last-child) {
    margin-right: 12px;
  }
  &.common {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
`;

export const CommonButton = styled.a`
  white-space: nowrap;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  -webkit-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
    0 1px 3px rgba(0, 0, 0, 0.08);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  background: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  color: #000;
  text-decoration: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;

  &.default {
    background: #1fdd95;
    color: #fff;
  }
  &:hover {
    transform: translateY(-3px);
  }
`;

export const CommonBodyText = styled.p`
  font-weight: 400;
  font-size: 17px;
  line-height: 28px;
  color: #525f7f;
`;

export const SectionTest = styled.section`
  position: relative;
  background: #f6f9fc;



  .animation{
  display:none;

  @media(min-width:670px){
    flex:50%;
   display:block;
  }
}

  .saas {
    display: flex;
    justify-content: space-between;
   
    padding: 80px 0 20px 30px;
    @media (min-width: 880px) {
      padding: 50px 0;
    }
  }
  }

  .sass_content {
    flex:50%;
    display: flex;
    flex-direction: column;
    justify-content:space-between;
     

    .sass-1{
      margin:30px 0;
    }
    .sass-2{
      margin:30px 0;
    }
    .sass-3{
      margin:30px 0;
    }
  }
`;
