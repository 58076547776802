import styled from 'styled-components';
import dashboardImg from '../../assets/fcc.svg';
import Ipad from '../../assets/fcc.png';
const Section = styled.section`
  --sectionPaddingTopMax: 116;
  font-familly: 'Open Sans', sans-serif;

  &.angleBottom {
    --sectionPaddingBottom: var(--sectionAnglePaddingBottom);
    --sectionMarginBottom: calc(var(--sectionAngleHeight) * -1);
    --sectionTransformOrigin: 0 0;
  }
`;

const SectionMasked = styled.div`
  overflow: hidden;
`;

const BackgroundMask = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: visible;
`;

const SectionBackground = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: skewY(0);
  transform: skewY(0);
  background: #f6f9fc;
  overflow: hidden;
`;

const Guides = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: var(--columnPaddingNormal);
  pointer-events: none;
`;

const GuideContainer = styled.div`
  display: grid;
  grid: 1fr / repeat(var(--columnCountMax), 1fr);
  position: relative;
  max-width: var(--layoutWidth); /**1080px */
  height: 100%;
  margin: 0 auto;
`;
const Guide = styled.div`
  width: 1px;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(var(--guideDashedColor)),
    color-stop(50%, var(--guideDashedColor)),
    color-stop(50%, transparent),
    to(transparent)
  );
  background: linear-gradient(
    180deg,
    var(--guideDashedColor),
    var(--guideDashedColor) 50%,
    transparent 0,
    transparent
  );
  background-size: 1px 8px;
  &:first-of-type {
    background: var(--guideSolidColor);
  }
`;

const SectionContainer = styled.div`
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
`;

const SectionLayoutContainer = styled.div`
  width: 100%;
  max-width: var(--layoutWidth);
  margin: 0 var(--columnPaddingNormal);
`;

const SectionLayout = styled.div`
  --sectionPaddingBottom: var(--sectionAnglePaddingBottom);
  --sectionMarginBottom: calc(var(--sectionAngleHeight) * -1);
  --sectionAnglePaddingBottomBase: calc(
    var(--sectionAnglePaddingBottomBaseMax) * 1px
  );
  padding: var(--sectionPaddingTop) 0 var(--sectionPaddingBottom);

  margin-top: 30px;
  @media (min-width: 670px) {
    margin-top: 0;
  }
`;

const ColumnLayout = styled.div`
  --columnRowGap: var(--rowGapLarge);
  display: grid;
  row-gap: var(--columnRowGap);
  -webkit-box-align: start;
  align-items: flex-start;
  @media (min-width: 600px) {
    &[data-columns='2,2'],
    &[data-columns='2,1,0'],
    &[data-columns='2,1,1'],
    &[data-columns='2,2'] {
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;

const RowLayout = styled.div`
  --rowLayoutGapSmall: 16px;
  --rowLayoutGapNormal: 32px;
  --rowLayoutGapMedium: var(--rowLayoutGapNormal);
  --rowLayoutGapLarge: var(--rowLayoutGapNormal);
  --rowLayoutGapXLarge: var(--rowLayoutGapNormal);
  --rowLayoutGap: var(--rowLayoutGapLarge);
  display: grid;
  grid: auto/minmax(0, 1fr);
  row-gap: var(--rowLayoutGap);

  @media (min-width: 600px) {
    --rowLayoutGapMedium: 48px;
    --rowLayoutGapLarge: var(--rowLayoutGapMedium);
    --rowLayoutGapXLarge: var(--rowLayoutGapMedium);
  }
  @media (min-width: 900px) {
    --rowLayoutGapLarge: 64px;
    --rowLayoutGapXLarge: 96px;
  }
`;

const Copy = styled.div`
  display: grid;
  row-gap: var(--rowGap);

  &.variant--Section {
    --titleFontSize: 34px;
    --titleLineHeight: 1.294117647;
    --titleLetterSpacing: -0.1px;
  }
  @media (min-width: 1112px) {
    &.variant--Section {
      --titleFontSize: 38px;
      --titleLineHeight: 1.263157895;
      --titleLetterSpacing: -0.2px;
    }
  }
`;

const CopyHeader = styled.header`
  display: grid;
  row-gap: 24px;
  position: relative;
  padding: 0 16px 0 16px;
`;

const CopyCaption = styled.h2`
  font: var(--captionFont);
  color: var(--accentColor);

  &.bleu {
    color: #256dfe;
  }
`;

const CopyTitle = styled.h1`
  --titleFont: 500 38px/1.263157 'Open Sans', sans-serif;
  position: relative;
  font: var(--titleFont);
  color: #0a2540;
  letter-spacing: var(--titleLetterSpacing, inherit);
  font-weight: bold;
`;

const CopyBody = styled.div`
  padding: 0 var(--bodyPaddingRight) 0 var(--bodyPaddingLeft);
  font-size: 18px;
  font-weight: 400;
  color: #425466;
  font-familly: 'Opens Sans', sans-serif;

  p {
    font-familly: 'Opens Sans', sans-serif;
  }
`;

const CopyFooter = styled.div`
  display: grid;
  row-gap: var(--footerRowGap);
  padding: 0 var(--footerPaddingRight) 0 var(--footerPaddingLeft);
`;

const CopyFooterContainer = styled.div`
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;

  & > a:not(:first-of-type) {
    margin-left: var(--ctaSpacing);
  }
`;
const CtaButton = styled.a`
  display: inline-block;
  padding: 3px 0 6px;
  border-radius: 16px;
  font: 425 15px/1.5555 'Open Sans', sans-serif;
  color: var(--buttonColor);
  white-space: nowrap;
  -webkit-transition: var(--hoverTransition);
  transition: var(--hoverTransition);
  outline: none;
  text-align: center;

  &.variant_button {
    padding-left: 16px;
    padding-right: 12px;
    background-color: #256dfe;
    text-align: center;
    color: var(--knockoutColor);
    transition: all 0.5s ease-in-out;
  }

  &:hover {
    transform: translateY(-3px);
  }
`;

const HomeGraphic = styled.div`
  position: relative;
  height: 374px;

  @media (min-width: 397px) {
    height: 474px;
  }

  @media (max-height: 899px) and (min-width: 896px) {
    height: 654px;
  }

  @media (min-width: 896px) and (min-height: 900px) {
    height: 764px;
  }
`;

const HomegraphicLayout = styled.div`
  display: grid;
  gap: 16px 16px;
  grid-template-columns: 112px 141px 58px 38px;
  grid-template-rows: 107px 87px 125px 185px;
  position: absolute;
  top: -30px;
  pointer-events: none;

  @media (min-width: 397px) {
    top: -30px;
    gap: 25px 25px;
    grid-template-columns: 253px 392px 201px;
    grid-template-rows: 70px 157px 97px 333px;
  }

  @media (max-height: 899px) and (min-width: 896px) {
    top: -80px;
    gap: 35px 35px;
    grid-template-columns: 323px 501px 256px;
    grid-template-rows: 86px 201px 126px 425px;
  }
  @media (min-width: 896px) and (min-height: 900px) {
    top: -110px;
    gap: 40px 40px;
    grid-template-columns: 380px 589px 301px;
    grid-template-rows: 101px 235px 148px 500px;
  }

  .domGraphic {
    position: relative;
    height: 0;
    width: 100%;
  }
`;
const WatchGraphic = styled.figure`
  grid-area: 2/2;

  @media (min-width: 397px) {
    grid-area: 2/1;
  }

  svg {
    margin: -30px auto 100px 50px;
    @media (min-width: 670px) {
      margin-left: 70px;
    }
  }
`;

const IpadGraphic = styled.figure`
  grid-area: 3/2/3/4;
  background-image: url(${dashboardImg});
  background-size: cover;
  @media (min-width: 397px) {
    grid-area: 2/2 / span 2;
  }
`;

const IphoneGraphic = styled.figure`
  grid-area: 1/3/2/5;

  @media (min-width: 397px) {
    grid-area: span 2/3;
  }
`;
const IphoneGraphictwo = styled.figure`
  grid-area: 2/1;

  @media (min-width: 397px) {
    grid-area: 3/1;
    justify-self: flex-end;
  }

  @media (max-height: 899px) and (min-width: 896px) {
    width: 85% !important;
  }
`;

const DashboardGraphic = styled.figure`
  grid-area: 4/1/4/4;

  background-image: url(${Ipad});
  background-size: cover;

  @media (min-width: 397px) {
    grid-area: 4/2/4/4;
  }
`;
export {
  Section,
  SectionMasked,
  BackgroundMask,
  SectionBackground,
  Guides,
  GuideContainer,
  Guide,
  SectionContainer,
  SectionLayout,
  SectionLayoutContainer,
  ColumnLayout,
  RowLayout,
  Copy,
  CopyHeader,
  CopyCaption,
  CopyTitle,
  CopyBody,
  CopyFooter,
  CopyFooterContainer,
  CtaButton,
  HomeGraphic,
  HomegraphicLayout,
  WatchGraphic,
  IpadGraphic,
  IphoneGraphic,
  IphoneGraphictwo,
  DashboardGraphic
};
