import styled from 'styled-components';
import Bg from '../../../assets/companyBg.jpg';

export const Main = styled.main`
  font-family: 'Open Sans', sans-serif;
`;
export const HeroSection = styled.div`
  position: relative;
`;
export const HeroContainer = styled.div`
  padding: 100px 0 60px;
  width: 90%;
  margin: 0 auto;
  background-image: url(${Bg});
  background-size: cover;
  display: flex;
  border-radius: 5px;
  padding: 1px solid red;

  .btn {
    margin-top: 30px;
  }
`;
export const HeroText = styled.div`
  margin-top: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 100px 0 60px;
  max-width: 500px;
`;
export const CommonTitle = styled.h1`
  position: relative;
  color: #ffffff;
  text-align: left;
  width: 100%;
  font-size: 3.653rem;
  z-index: 2;
  padding-bottom: 15px;
  font-weight: 700;
  text-shadow: 0 4px 20px rgba(0, 0, 0, 0.6);
`;
export const CommonText = styled.p`
  position: relative;
  color: #ffffff;
  text-align: left;
  width: 100%;
  z-index: 9999;
  font-size: 1.266rem;
  max-width: 700px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  line-height: 1.45;
  text-shadow: 0 4px 20px rgba(0, 0, 0, 0.6);
`;

export const NavSection = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: 670px) {
    flex-direction: row;
  }
`;

export const NavSectionContainer = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: 670px) {
    flex-direction: row;
  }
`;

export const NavItem = styled.li`
  margin: 20px 60px;
`;
export const Link = styled.a`
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;

  &:hover {
    border-bottom: 1px solid #1fdd95;
  }
`;

export const SubHeroSection = styled.div`
  position: relative;
`;
export const SubHeroContainer = styled.div`
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 670px) {
    flex-direction: row;
    width: 1200px;
  }
`;
export const BoxOne = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;

  @media (min-width: 670px) {
    margin-top: 0;
  }
`;
export const Icon = styled.span`
  width: 48px;
  height: 48px;
  padding: 5px;
  color: #000;

  i {
    font-size: 40px;
  }
`;
export const BoxText = styled.p`
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
  text-align: center;

  @media (min-width: 670px) {
    width: 70%;
  }
`;

export const CompanySection = styled.section`
  position: relative;
  margin-top: 20px;

  @media (min-width: 670px) {
    padding: 110px 0 60px;
  }

  & ::after {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: -webkit-gradient(
      linear,
      left bottom,
      left top,
      color-stop(85%, #ffffff),
      to(#add0fa)
    );
    background: -webkit-linear-gradient(bottom, #ffffff 85%, #add0fa);
    background: -o-linear-gradient(bottom, #ffffff 85%, #add0fa);
    background: linear-gradient(to top, #ffffff 85%, #add0fa);
    opacity: 0.3;
    -webkit-transform: skew(0deg, 11deg);
    -ms-transform: skew(0deg, 11deg);
    -o-transform: skew(0deg, 11deg);
    transform: skew(0deg, 11deg);
    -webkit-transform-origin: top left;
    -ms-transform-origin: top left;
    -o-transform-origin: top left;
    transform-origin: top left;
  }
`;

export const CompationContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-flow: column-reverse;
  justify-content: space-between;
  margin-bottom: 70px;

  @media (min-width: 670px) {
    flex-direction: row;
  }
`;

export const Left = styled.div`
  flex: 100%;
  @media (min-width: 670px) {
    flex: 40%;
    margin-top: 0;
  }
`;
export const Right = styled.div`
  position: relative;
  color: white;
  text-align: center;
  margin: 0px auto;
  flex: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (min-width: 670px) {
    flex: 60%;
    margin: 0 auto;
  }

  img {
    box-shadow: none;
    border-radius: 0;
    width: 100%;
    height: 100%;
  }
  & :first-child {
    margin: 0 20px;
    @media (min-width: 670px) {
      position: absolute;
      top: 0px;
      left: 0;
      height: 300px;
      width: 500px;
      object-fit: fill;
    }
  }
`;

export const Title = styled.h1`
  font-size: 30px;
  margin-bottom: 30px;
  color: #23292f;
  font-weight: bold;
`;

export const CompanyText = styled.p`
  color: #6b7075;
  font-size: 1rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  margin: 0 0 10px;
`;

export const Images = styled.div`
  position: relative;
  flex: 100%;
  color: white;
  margin-bottom: 30px;
  @media (min-width: 650px) {
    flex: 55%;
    margin-bottom: 0;
  }
  & :first-child {
    position: absolute;
    top: 0px;
    left: 0;
    height: 293px;
    width: 265px;
  }
  & :nth-child(2) {
    position: absolute;
    z-index: 1;
    top: 250px;
    right: 30px;
    height: 261px;
    width: 300px;
  }

  img {
    border-radius: 0;
    box-shadow: none;
  }
`;
export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  @media (min-width: 650px) {
    flex-direction: row;
    flex: 45%;
    margin-top: 0px;
  }
`;
export const GridOne = styled.div``;
export const GridTwo = styled.div``;
export const GridThree = styled.div``;
export const Subtitle = styled.h2``;