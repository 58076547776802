import styled from 'styled-components';

const Section = styled.section`
  --sectionPaddingTopMax: 116;
  font-family: 'Opens Sans', sans-serif;
`;

const SectionMasked = styled.div`
  overflow: hidden;
`;

const BackgroundMask = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: var(--sectionBackgroundOverflow);
`;

const SectionBackground = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -webkit-transform-origin: var(--sectionTransformOrigin);
  transform-origin: var(--sectionTransformOrigin);
  -webkit-transform: skewY(var(--sectionAngle));
  transform: skewY(var(--sectionAngle));
  background: #fff;
  overflow: hidden;

  &.angle {
    --sectionPaddingTop: var(--sectionAnglePaddingTop);
    --sectionAngle: var(--angleNormal);
  }
`;

const Guides = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: var(--columnPaddingNormal);
  pointer-events: none;
`;

const GuideContainer = styled.div`
  display: grid;
  grid: 1fr / repeat(var(--columnCountMax), 1fr);
  position: relative;
  max-width: var(--layoutWidth); /**1080px */
  height: 100%;
  margin: 0 auto;
`;
const Guide = styled.div`
  width: 1px;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(var(--guideDashedColor)),
    color-stop(50%, var(--guideDashedColor)),
    color-stop(50%, transparent),
    to(transparent)
  );
  background: linear-gradient(
    180deg,
    var(--guideDashedColor),
    var(--guideDashedColor) 50%,
    transparent 0,
    transparent
  );
  background-size: 1px 8px;
  &:first-of-type {
    background: var(--guideSolidColor);
  }
`;

const SectionContainer = styled.div`
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
`;

const SectionLayoutContainer = styled.div`
  width: 100%;
  max-width: var(--layoutWidth);
  margin: 0 var(--columnPaddingNormal);
`;

const SectionLayout = styled.div`
  padding: 200px 0 var(--sectionPaddingBottom);
`;

const ColumnLayout = styled.div`
  --columnRowGap: var(--rowGapLarge);
  display: grid;
  row-gap: var(--columnRowGap);
  -webkit-box-align: start;
  align-items: flex-start;
  @media (min-width: 600px) {
    &[data-columns='2,2'],
    &[data-columns='2,1,0'],
    &[data-columns='2,1,1'],
    &[data-columns='2,2'],
    &[data-columns='1,1'] {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (min-width: 900px) {
    &[data-columns='2,1,1'] {
      grid-template-columns: 2fr repeat(2, 1fr);
    }
  }
`;

const RowLayout = styled.div`
  --rowLayoutGapSmall: 16px;
  --rowLayoutGapNormal: 32px;
  --rowLayoutGapMedium: var(--rowLayoutGapNormal);
  --rowLayoutGapLarge: var(--rowLayoutGapNormal);
  --rowLayoutGapXLarge: var(--rowLayoutGapNormal);
  --rowLayoutGap: var(--rowLayoutGapLarge);
  display: grid;
  grid: auto/minmax(0, 1fr);
  row-gap: var(--rowLayoutGap);

  @media (min-width: 600px) {
    --rowLayoutGapMedium: 48px;
    --rowLayoutGapLarge: var(--rowLayoutGapMedium);
    --rowLayoutGapXLarge: var(--rowLayoutGapMedium);
  }
  @media (min-width: 900px) {
    --rowLayoutGapLarge: 64px;
    --rowLayoutGapXLarge: 96px;
  }
`;

const Copy = styled.div`
  display: grid;
  row-gap: var(--rowGap);
  &.variant-footer {
    --titleFontSize: 24px;
    --titleLineHeight: 1.333333333;
    --titleWeight: var(--fontWeightBold);
    --titleLetterSpacing: 0.1px;
  }
  @media (min-width: 1112px) {
    &.variant-footer {
      --paddingRight: var(--columnPaddingXLarge);
      --rowGap: var(--rowGapMedium);
      --paragraphGap: 20px;
    }
  }
`;
const CopyHeader = styled.header`
  display: grid;
  row-gap: 24px;
  position: relative;
  padding: 0 16px 0 16px;
`;

const CopyCaption = styled.h2`
  font: var(--captionFont);
  color: #101010;

  &.green {
    color: #1fdd95;
  }
`;

const CopyTitle = styled.h1`
  position: relative;
  font: 24px;
  font-weight: 700;
  line-height: 1.33;
  font-family: 'Opens Sans', sans-serif;
  color: #101010;
  letter-spacing: var(--titleLetterSpacing, inherit);
`;

const CopyBody = styled.div`
  padding: 0 var(--bodyPaddingRight) 0 var(--bodyPaddingLeft);
  font-weight: 300;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  color: #425466;
`;

const CopyFooter = styled.div`
  display: grid;
  row-gap: var(--footerRowGap);
  padding: 0 var(--footerPaddingRight) 0 var(--footerPaddingLeft);
`;

const CopyFooterContainer = styled.div`
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;

  & > a:not(:first-of-type) {
    margin-left: var(--ctaSpacing);
  }
`;
const CtaButton = styled.a`
  display: inline-block;
  padding: 3px 0 6px;
  border-radius: 16px;
  font: 425 15px/1.5555 'Open Sans', sans-serif;
  color: #000;
  text-align: center;
  white-space: nowrap;
  -webkit-transition: var(--hoverTransition);
  transition: var(--hoverTransition);
  outline: none;

  &.variant_button {
    padding-left: 16px;
    text-align: center;
    padding-right: 12px;
    background-color: #256dfe;
    color: #ffff;
    -webkit-transition-property: background-color, opacity;
    transition-property: background-color, opacity;
  }

  &.cta {
    will-change: opacity;
  }

  &.cta-arrow {
    padding-right: 12px;
  }

  &.variant-link {
    font-weight: var(--linkWeight, var(--fontWeightSemibold));
    -webkit-transition-property: color, opacity;
    transition-property: color, opacity;
  }
`;

const AnimationSequence = styled.div``;

const CopyIcon = styled.div`
  max-height: 30px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
`;

const AnimatedIcon = styled.div`
  --iconSize: 40px;
  --svgSize: 96px;
  position: relative;
  width: var(--iconSize);
  height: var(--iconSize);
  margin-left: calc(-1 * var(--columnPaddingNormal));
  border-radius: 100%;
  background-color: #1fdd95;
  color: #101010;
`;

const CopySubtitle = styled.h1`
  --titleFont: 425 15px/1.6 'Open Sans', sans-serif;
  position: relative;
  font: var(--titleFont);
  color: #101010;
  letter-spacing: var(--titleLetterSpacing, inherit);
  font-weight: bold;
`;

const CopyBodySubText = styled.p`
  --bodyFont: 300 16px/ 1.555555556 'Open Sans', sans-serif;
  padding: 0 var(--bodyPaddingRight) 0 var(--bodyPaddingLeft);
  font: var(--bodyFont);
  color: #425466;
`;

export {
  Section,
  SectionMasked,
  BackgroundMask,
  SectionBackground,
  Guides,
  GuideContainer,
  Guide,
  SectionContainer,
  SectionLayout,
  SectionLayoutContainer,
  ColumnLayout,
  RowLayout,
  Copy,
  CopyHeader,
  CopyCaption,
  CopyTitle,
  CopyBody,
  CopyFooter,
  CopyFooterContainer,
  CtaButton,
  AnimationSequence,
  CopyIcon,
  AnimatedIcon,
  CopySubtitle,
  CopyBodySubText
};
