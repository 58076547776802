import React, { useEffect } from 'react';
import icon from '../../assets/favicon.svg';
import './style.css';

const Spiral = () => {
  //   const [logos, setLogos] = useState([]);
  useEffect(() => {
    // let logos = Array.prototype.slice.call(
    //   document.body.querySelectorAll('#js-partner-logos img')
    // );

    let container = document.querySelector('#js-intro-visual');
    let width = container.getBoundingClientRect().width;
    container.classList.add('.intro-visual--measured');

    function createLayers() {
      return Array.prototype.slice
        .call(document.querySelectorAll('.layer'))
        .map((layer, index) => {
          let scale = 1 - 0.2201 * index;
          let opacity = 0.9 - 0.15 * index;
          let size = width * (1 - 0.2201 * index);
          layer.style.transform = `scale(${scale} )`;
          layer.style.opacity = opacity;
          layer.width = size;
          return layer;
        });
    }

    function logo() {
      let layer = createLayers();
      for (let i = 0; i < layer.length; i++) {
        let last = layer[layer.length - 1];
        last.style.background = `url(${icon})`;
        last.style.backgroundSize = 'cover';
      }
    }

    // function animateEachLayer(rotate) {
    //   let layers = Array.prototype.slice.call(
    //     document.querySelectorAll('.layer')
    //   );

    //   for (let i = 0; i < layers.length; i++) {
    //     let layer = layers[i];
    //     let angle = 360 - i;
    //     layer.style.transform = `rotate(${angle}deg)`;
    //   }
    // }
    function createLogo(angle, position) {
      let newDiv = document.createElement('div');
      newDiv.className = 'logo-rotator';
      var logoDiv = document.createElement('div');
      logoDiv.className = 'logo-scaler';
      logoDiv.style.transform = 'scale(0)';
      logoDiv.style.left = ''.concat(position, 'px');
      newDiv.appendChild(logoDiv);
      let a = Math.abs(-360 + angle);
      let c = document.createElement('div');
      // c.style.transform = 'rotate('.concat(a, 'deg)');
      logoDiv.appendChild(c);
      c.classList.add('red');
      container.appendChild(newDiv);

      return c.animate(
        [
          {
            transform: 'rotate(0deg)'
          },
          {
            transform: `rotate(${a}deg)`
          }
        ],
        { duration: 4000, iterations: Infinity }
      );
    }

    // function createLogo(t) {
    //   let size = [1888, 1472, 1058, 641];
    //   let logocreated = 0;
    //   let n =
    //     arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 0;
    //   let e = arguments.length > 2 ? arguments[2] : void 0;
    //   let newDiv = document.createElement('div');
    //   newDiv.style.transform = 'rotate('.concat(n, 'deg)');
    //   newDiv.className = 'logo-rotator';
    //   var logoDiv = document.createElement('div');
    //   logoDiv.className = 'logo-scaler';
    //   logoDiv.style.transform = 'scale(0)';
    //   logoDiv.style.left = ''.concat(size[t] / 2, 'px');
    //   newDiv.appendChild(logoDiv);
    //   let a = Math.abs(-360 + n);
    //   let c = document.createElement('div');
    //   c.className = 'logo HeaderVisualLogo HeaderVisualLogo-img'.concat(
    //     logocreated % 31
    //   );

    //
    //   logoDiv.appendChild(c);
    //   container.appendChild(newDiv);
    //   var u = {
    //     logo: c,
    //     scaler: logoDiv,
    //     rotator: newDiv,
    //     id: logocreated,
    //     timing: {
    //       speed: (0.7, 1.3),
    //       introStart: void 0,
    //       outroStart: void 0,
    //       duration: e || (4e3, 5e3)
    //     },
    //     track: {
    //       own: t,
    //       next: b(t),
    //       prev: x(t)
    //     },
    //     props: {
    //       rotation: n,
    //       scheduledForDeletion: !1,
    //       scale: 0
    //     }
    //   };
    //   return (visibleLogos = [].concat(visibleLogos, [u]));

    // function removeLogos(t) {
    //   logos.filter((l) => {
    //     return l.id != t.id;
    //   });
    //   t.rotator.remove();
    // }

    // animateEachLayer();
    // createLogo(1, 4e3);
    // createLogo(2, 90, 4500);
    // createLogo(3, 180, 5e3);
    // createLogo(3, 360, 5500);
    // createLogo(4, 130, 6e3);
    // createLogo(4, 250, 6500);
    // createLogo(4, 320, 7e3);
    createLayers();
    logo();
    createLogo(90, 450);
    createLogo(180, 5e2);
    createLogo(360, 550);
    createLogo(130, 6e2);
    createLogo(250, 650);
    createLogo(320, 7e2);
  }, []);

  return (
    <div className='intro-visual'>
      <div
        className='intro-visual-container intro-visual--measured'
        id='js-intro-visual'>
        <div className='layer'></div>
        <div className='layer'></div>
        <div className='layer'></div>
        <div className='layer'></div>
        <div className='layer layer--stripe'></div>
      </div>
    </div>
  );
};

export default Spiral;
