import React from 'react';
import { PhoneGraphic, IphoneScreen, PhoneImage, Phoner } from './style';
const Iphone = ({ image, bg }) => {
  return (
    <PhoneGraphic bg={bg}>
      <Phoner>
        <IphoneScreen>
          <PhoneImage>
            <img src={image} alt='iphone' />
          </PhoneImage>
        </IphoneScreen>
      </Phoner>
    </PhoneGraphic>
  );
};

export default Iphone;
