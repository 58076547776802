import React from 'react';
import { ArticleContainer, Title, Author, Paragraph, Image, Position, EstimatedRead, Headers } from './style';
import LaptopBG from '../../assets/laptop-bg.jpg';

const WhatWeLearnedFromIFA = () => {
    return (
        <ArticleContainer>
            <Title> Virtual Conferences 101: What We Learned From IFA 2021 </Title>
            <Author> By Mary Hanula,&nbsp;
                <Position> Director of Marketing, Eulerity </Position>
                <EstimatedRead> 5 min read </EstimatedRead> 
            </Author>

            <Image src={LaptopBG} alt="image"/>

            <Paragraph>
                This past February, a few of my colleagues and I attended IFA’s Annual Convention 2021, with our company as one of the sponsors. This year looked a bit different, with a well-known event that welcomes thousands of attendees to a different location each year having had to pivot to a virtual, online platform. As a technology company that is all about innovation and automation, we were thrilled to be a part of this progressive new format that the IFA was adopting in order to host the annual convention during this unprecedented time."
            </Paragraph>

            <Paragraph>
                The theme of this year’s convention was #ignite, which could not have been a better fit for our company. With Eulerity being a digital marketing technology platform, we know a thing or two about fueling the fire of innovation, evolution and marketing automation. From the Business Solutions Roundtables to Breakout Sessions, a digital Exhibit Booth and amazing General Session speakers, we wanted to share some of the insights we got from attending our first virtual event of the year and how to really stand out at an online event.
            </Paragraph>

            <Headers>
                In order to stand out, you need to stand up
            </Headers>

            <Paragraph>
                No, we’re not telling you to actually move positions, but to stand up in conversations, sessions and any chance you get. For some, what you may have once found easy to do in person may feel weird to do from behind a screen. For others, it might be easier than ever to address hundreds or thousands of colleagues from a chatbox versus a convention hall. Here’s the thing: it’s incredibly important to forge forward and try your best at creating genuine connections and conversation during a virtual event.
            </Paragraph>

            <Paragraph>
                Everyone may be in sweatpants at home, but mentally they are suited up and ready to talk business. Industry leaders and franchise professionals pivot to virtual events because they know how much value the event itself holds. Make sure you are representing yourself, your company and your brand by introducing yourself, actively taking part in group sessions and also by networking online, which gets me to my next point.
            </Paragraph>

            <Headers>
                Networking may look different, but it’s the same, if not more necessary now
            </Headers>

            <Paragraph>
                The virtual platform used for IFA 2021 gave attendees access to multiple different rooms, including the “Lounge,” where you could see who was actively in the room with you and online. Do you know what radio silence sounds like? Well, I can tell you what it looks like — a lingering chatbox that is just waiting for you to type a thought/statement/suggestion into it.
            </Paragraph>

            <Paragraph>
                Use this to your advantage. Introduce yourself to the room and others in it. Say hi! Everyone is waiting for someone to drop an ice breaker in those types of situations, even if it may just be about the weather. This gives you a chance to type out and offer key points and conversation that you may not get to in person.
            </Paragraph>

            <Paragraph>
                During the event, everyone had their own virtual business card that they could send others to download and keep. I was able to do this with so many people, whether they were in the Lounge with me or I had heard them speak at one of the great Business Solution Roundtables. Exchange information and then see where else you can reach them. Utilize other networking platforms where you can follow-up with them, such as LinkedIn. Maya Angelou once said, “People will forget what you said, people will forget what you did, but people will never forget how you made them feel.” Act as you would in person and sub a handshake for a quick online chat. You truly never know what key connections and contacts you could be making.
            </Paragraph>

            <Headers>
                Be present for presentations
            </Headers>

            <Paragraph>
                Just like in-person, someone is actively taking the time to present themselves and their work to you. Be present, be respectful, and be on time. I made sure to get to my sessions at least 5 minutes early. Why? Because I wanted to personally introduce myself to the moderators and other attendees in the group before the presentation began. I found it important to thank the speakers as well, since it is something I would also do in person.
            </Paragraph>

            <Paragraph>
                IFA 2021 had a fantastic lineup of speakers and moderators. I found myself excited to enter a Zoom meeting that had other people within my specific industry field that were ready to talk about trends, problem-solve and engage with others. One of my favorite Breakout Sessions was “Social Media Content Playbook: Not Your Obvious Tactics to Connect, Engage & Win in 2021.” The session was moderated by Sherri Fishman, CFE, President of Fishman PR, and featured two great industry leaders, Shane Evans, President and Co-Founder of Massage Heights, and Rebecca Miller, Chief Marketing Officer of Smoothie King. As a Director of Marketing, it was so refreshing to hear what social media trends and ad insights were on other people’s radars.
            </Paragraph>

            <Headers>
                Give yourself time to have fun
            </Headers>

            <Paragraph>
                We all know that during an in-person work conference or convention, it’s always fun to see what attractions the city it’s in has to offer or to meet up with colleagues for a drink. IFA 2021 hosted virtual Happy Hours for attendees to go to, where networking was still able to shift into a more relaxed atmosphere.
            </Paragraph>

            <Paragraph>
                Whether your next work event is online or in-person, be yourself and be active. People will remember how you made them feel, even if it is just from a nice follow-up note. You can’t stand out if you don’t try — utilize virtual engagements to your advantage!
            </Paragraph>

            <Paragraph>
                Eulerity is the first of its kind — an artificial intelligence marketing platform for the franchise space. Utilizing the most progressive marketing technology available, we provide the franchisee and franchisor with easy to use software that fits elegantly in the palm of your hand. No commitment, no long term contracts — just results. Market like the fortune 500 companies today. For more information, <a href="https://www.eulerity.com/contact-us/"> schedule a call </a> with our Sales team today!
            </Paragraph>
        </ArticleContainer>
    )
}

export default WhatWeLearnedFromIFA;