import validator from 'validator';

export default function validation(value) {
  const error = {};
  if (value.firstname === '') error.firstname = 'firstname is required';
  if (value.lastname === '') error.lastname = 'lastname is required';
  if (!validator.isEmail(value.email) || value.email === '')
    error.email = 'email is required';
  if (value.company === '') error.company = 'company is required';
  if (value.city === '') error.city = 'business is required';
  if (value.phone === '') error.phone = 'phone number is required';
  return error;
}
