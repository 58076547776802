import React from 'react';
import { ArticleContainer, Title, Author, Paragraph, Image, MediumArticleContainer, EstimatedRead, Headers, SideImageWithText,FloatedImage, BoldItalics } from './style';
import Stocks from '../../assets/stocks-graphic.jpeg';
import Arrow from '../../assets/up-arrow-graphic.jpeg';
import Idea from '../../assets/idea.png';
import Paas from '../../assets/paas.png';

const FutureAsaService = () => {
    return (
        <MediumArticleContainer>
            <Title> The Future As a Service </Title>
            <Author> by Tanuj Joshi, CEO and Co-Founder, Eulerity &nbsp;
                <EstimatedRead> 4 min read </EstimatedRead>
            </Author>

            <Image src={Paas} alt="img" />

            <Paragraph>
                The future of business will be dominated by as-a-Service models. Eight years ago, leading Silicon Valley thought leader Marc Andreesen argued, in the Wall Street Journal, that “software is eating the world”. Soon, industry observers extended the concept, in an aphorism now often attributed to Microsoft CEO Satya Nadella, by insisting that every company must become a software company.
            </Paragraph>

            <Paragraph>
                Businesses may still rely on the functionality encoded in applications. However with more and more data, workloads, application development, and industry clout in the cloud, the software gobbling up business models now comes in the form of services.
            </Paragraph>

            <Paragraph>
                As a consequence, a new mantra has become necessary: every company must become a &#123;insert_your_USP_here&#125;-as-a-Service company. And that new reality has implications for both the companies that provide the service and the customers they serve. 
            </Paragraph>

            <Image src={Idea} alt="img" />

            <Headers>
                Rocking the software boat
            </Headers>

            <Paragraph>
                The industry is now dominated by four types of companies who variously compete and sometimes cooperate for position in the marketplace:
            </Paragraph>

            <Paragraph>
                <BoldItalics> Traditional companies </BoldItalics>develop and market applications that run on premises or in private old-school clouds.
            </Paragraph>

            <Paragraph>
                <BoldItalics>SaaS providers</BoldItalics> offer application functionality consumed in a pay-as-you-go model.
            </Paragraph>

            <Paragraph>
                <BoldItalics>Cloud infras</BoldItalics> — most notably AWS, Google, and Microsoft — provide infrastructure, but they also increasingly offer SaaS products optimized for their environment.
            </Paragraph>

            <Paragraph>
                <BoldItalics>Systems integrators,</BoldItalics> meanwhile, play a more and more important role. They help organizations make sure their legacy on-premises software plays well with their increasingly cloud-native SaaS functionality. They can also help traditional software vendors make the transition to SaaS models.
            </Paragraph>

            <Paragraph>
                What’s disrupting the industry is that every software vendor is essentially under attack from cloud providers growing their cloud-specific SaaS offerings. As evidence, by 2022, the top four cloud mega-platforms will host 80% of all IaaS/PaaS deployments, according to IDC.
            </Paragraph>

            <Paragraph>
                This has serious implications for all major companies, which risk irrelevance if they fail to embrace the as-a-Service future. It also has repercussions for customers, who risk falling behind the competition if they tie their business operations to a sinking traditional ship.
            </Paragraph>

            <SideImageWithText>
                <FloatedImage>
                    <Image src={Arrow} alt="img" />
                </FloatedImage>

                <Headers> As-a-Service at scale </Headers>
                <Paragraph>
                    To survive, companies will have to think about what they offer not as a product with nice features, but as a solution to their customers’ problems. Approached from that perspective, the as-a-Service model only makes sense because it meets the customer’s needs today — without costly and time-consuming implementations — while adapting to new demands tomorrow.
                </Paragraph>
            </SideImageWithText>

            <Paragraph>
                But to make the as-a-Service transition, companies will need to address some points first:
            </Paragraph>

            <Paragraph>
                <BoldItalics> Cloud-specific vs. agnostic</BoldItalics> — Deciding whether to commit to a single cloud provider or remain vendor-agnostic. Remaining vendor-neutral gives the business a broader appeal and doesn’t tie you to a specific technology or brand. Committing to a single provider can lend you legitimacy and give you marketing advantages.
            </Paragraph>

            <Paragraph>
                <BoldItalics> Cloud automation </BoldItalics>— Especially if you sell through a cloud provider’s marketplace, you’ll need to automate updates and upgrades. Initial deployment will have to be easy for your customers, and updates will need to be regular and hassle-free.
            </Paragraph>

            <Paragraph>
                <BoldItalics> Flexible, cost-effective scaling </BoldItalics>— Anticipate whether offering your product as a service will result in large processing loads. If it will, you might want to take a multi-cloud approach to spread the load across clouds. You might also take advantage of spot-price virtual machines to minimize infrastructure costs.
            </Paragraph>

            <Paragraph>
                <BoldItalics> Security and data isolation </BoldItalics>— It won’t just be your product(s) in the cloud; it will also be all your customers’ data. A data breach could torpedo your entire business. Security will have to be important.
            </Paragraph>

            <Image src={Stocks} alt="img" />

            <Headers> The automation-partner advantage </Headers>

            <Paragraph>
                In the end, companies that transform their offerings from traditional products into &#123;insert_your_USP_here&#125;aaS solutions will suddenly be dealing with lots of transactions. That means they’ll need to scale massively on the operational side. They’ll need to figure what technology they’ll build on and how to ramp up their commercial models. The fact is, most companies simply aren’t prepared for the complexity that entails.
            </Paragraph>

            <Paragraph>
                The good news is that an automation partner can help tremendously. An experienced partner can help companies understand the scalability and security implications. It can help automate initial deployment and ongoing updates. And it can offer a cloud-agnostic perspective to drive an effective SaaS strategy.
            </Paragraph>

            <Paragraph>
                Those capabilities are imperative, because SaaS requires companies to make their offerings operate in a production environment and actually deliver real-world functionality. Ironically, that’s what customers have been doing for decades. SaaS focuses companies on delivering a robust platform on which customers can concentrate on business outcomes.
            </Paragraph>

            <Paragraph>
                Increasingly, that’s what customers will demand. And they’ll get it, as more and more companies make the leap to provide cost effective, scalable services.
            </Paragraph>

            <Paragraph>
                ***
            </Paragraph>

            <Paragraph>
                Eulerity is the world’s most efficient local marketing platform. Using machine learning and automation, Eulerity’s state-of-the-art technology simplifies the complex world of developing and executing digital marketing programs — all for a flat and transparent fee — a fraction of the cost of traditional vendors.
            </Paragraph>

            <Paragraph>
                <a href="https://www.eulerity.com"> Learn More About Eulerity </a>
            </Paragraph>
        </MediumArticleContainer>
    )
}

export default FutureAsaService;