import styled from 'styled-components';

export const IphoneGraphic = styled.figure`
  width: 16.5rem;
  height: 33.31rem;
  padding: 0.2rem;
  border-radius: 2.25rem;
  background: ${(props) => props.bg || '#f6f9fc'};
  box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25),
    0 30px 60px -30px rgba(0, 0, 0, 0.3),
    inset 0 -2px 6px 0 rgba(10, 37, 64, 0.35);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const Phoner = styled.div`
  width: 264px;
  height: 533px;
  border-radius: 36px;
  background: ${(props) => props.bg || '#f6f9fc'};
  box-shadow: 0 50px 50px -20px rgba(50, 50, 93, 0.25),
    0 30px 60px -30px rgba(0, 0, 0, 0.3),
    inset 0 -2px 6px 0 rgba(10, 37, 64, 0.35);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
export const IphoneScreen = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
  border-radius: 34px;
  background: #fff;
`;

export const PhoneGraphic = styled.div``;

export const PhoneImage = styled.div`
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  height: 400px;

  img {
    border-radius: 0;
    box-shadow: none;
    width: 100%;
    height: 533px;
  }
`;
