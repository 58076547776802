import React from 'react';
import { EstimatedRead, MediumArticleContainer, Paragraph, Title, Author } from '../style';

const PuttingMoneyWhereYourMouthIs = () => {
  return (
    <MediumArticleContainer>
        <Title>Putting Your Money Where Your Mouth Is</Title>
        <Author>Featuring Kristen Pechacek, Chief Growth Officer, MassageLuXe &nbsp;
            <EstimatedRead>3 min read</EstimatedRead>
        </Author>

        <Paragraph>One of the most common problems franchisors face is getting franchisees to actively spend the marketing dollars they need to maintain a successful business. If you’re wondering how to ditch marketing acronyms and translate marketing performance into something franchisees understand, then this article is for you.</Paragraph>
        <Paragraph>In marketing, you have to be nimble and be able to analyze the numbers. Just as we need reminding of the things we’re doing day to day, franchisees need it too. They didn’t get into this business to be a marketer, so reminders and constant communication of how marketing dollars can positively impact their business is key. One of the most effective ways to show franchisees the impact and importance of spending their marketing budget is to show them just how much money they could be making from it. When the ROI is in front of them and in digestible terms, it makes a world of difference. Know your audience and what makes them tick.</Paragraph>
        <Paragraph>Starting is simple — take a look at the budget and break it down into the budget amount, total transactions/cost per purchase, immediate revenue, and then lifetime profit. If you don’t have real-time or 100% accurate data, use your best guess and/or averages! You’ll get a short term return on your ad spend and long-term return on your investment. Paint this picture for the franchisees so that they understand the money is coming back to them in some way, since it can be scary to put all that money in up front and not know what you’ll get in the long run.</Paragraph>
        <Paragraph>For those of us that may not be in the immediate revenue collection type game, perhaps you’re in the lead game. Yes, this can be done by collecting leads as well, but you have to use estimates in most cases. Brand awareness and reach campaigns can be incredibly effective as well. You’re not selling anything immediately or collecting customer data, but just advertising your brand and creating both awareness and upcoming engagement with consumers. This can be accomplished through earned media value from advertising and also social media posts.</Paragraph>
        <Paragraph>When you think of having these conversations with your franchisees, you need to ask yourself about the timing and format you want to use. Franchisors should be encouraging and reminding their location owners in multiple forms of correspondence across long periods of time. The more that franchisees hear that they should be putting money into their marketing efforts and acknowledge the positive impact it can have on their business, the more likely they are to take action. Remember, what franchisees put in is what they get back when they invest in marketing. Put your money where your mouth is!</Paragraph>

        <Paragraph>
            Watch the full episode <a href="https://www.youtube.com/watch?v=dxJzeUwdS9s" target="_blank">here</a>. Make sure to <a href="https://us02web.zoom.us/webinar/register/7616606773963/WN_vgAA8cEcQhGPL96mpv24xQ" target="_blank">sign-up for live leadership conversations</a> on Eulerity’s Quick Fix series.
        </Paragraph>
    </MediumArticleContainer>
  )
}

export default PuttingMoneyWhereYourMouthIs