import React, {useState} from "react";
import "./tour.css";
import Button from "../../components/buttons/Button";
import Footer from "../../components/footer/Footer";
import eulerity from "../../assets/eulerity.pdf";
const Message = ({text}) => {
  return (
    <p
      style={{
        background: "red",
        color: "black",
        fontSize: "20px",
        width: "20%",
        margin: "0 auto",
        textAlign: "center",
        fontWeight: 400,
      }}
    >
      {text}
    </p>
  );
};
const Tour = () => {
  const Password = "startthetour";
  const [password, setPassword] = useState("");
  const [access, setAcces] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    setPassword(e.target.value);
  };
  const handleSUbmit = (e) => {
    e.preventDefault();
    if (password === "" || password !== Password) {
      setError(true);
      setAcces(false);
      setErrorMessage("Invalid Password");
      setTimeout(() => {
        setErrorMessage("");
      }, 1000);
    } else {
      setAcces(true);
      setError(false);
      setErrorMessage("");
    }
  };

  return (
    <>
      <div className="container-lg">
        <form
          onSubmit={handleSUbmit}
          className={`formTour ${access ? "hide" : "show"}`}
        >
          <label>Please Enter the password</label>
          <input value={password} onChange={handleChange} />
          <input type="submit" value="Enter" />
        </form>
        {access && !error ? (
          <div className="tour btns">
            <Button
              bg="#256DFE"
              color="#fff"
              target="_blank"
              href="https://static1.squarespace.com/static/5c3b9c8289c172c97722c5f9/t/5f0f566e8ddf626adc2f67e4/1594840692141/Eulerity+App+Tour.pdf"
            >
              Take the quick Eulerity tour
            </Button>
            <Button bg="#256DFE" color="#fff" target="_blank" href={eulerity}>
              Take the detailed Eulerity tour
            </Button>
          </div>
        ) : (
          <Message text={errorMessage}></Message>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Tour;
