import styled from 'styled-components';

export const PrivacyTitle = styled.h1`
  font-family: 'Open Sans';
  color: #000;
`;
export const Intro = styled.div`
  font-family: 'Open Sans';
  max-width: 800px;
  margin: 30px auto;
  padding: 10px;
  color: #6b7075;
  margin-top: 50px;
  line-height: 1.45;
  display: flex;
  flex-direction: column;
  @media (min-width: 670px) {
    padding: 0;
  }
`;
export const InTroDate = styled.p`
  margin: 30px 0;
`;
export const IntroBox = styled.div`
  margin: 30px 0;
`;
export const IntroTitle = styled.h2`
  color: #000;
  font-size: 1.424rem;
  font-weight: bold;
`;
export const IntroText = styled.p`
  margin: 30px 0 30px 0;
  font-size: 1rem;
  text-align: left;
  text-transform: none;
  @media (min-width: 670px) {
  }
`;

export const ListContainer = styled.ol`
  padding-left: 1em;

  li {
    margin-bottom: 10px;
  }

  .underscore {
    text-decoration: underline;
  }
`;

export const NumberedListText = styled.li`
  list-style-type: decimal;
  padding-left: 10px;

  a {
    all: unset;
    cursor: pointer;
    text-decoration: underline;
    color: blue;
  }
`;

export const LetteredListText = styled.li`
  list-style-type: lower-alpha;
  padding-left: 10px;
`;

/*******content */
export const TableList = styled.ul``;
export const TableItem = styled.li`
  list-style: disc;
  padding: 0;
  margin: 10px 0;
`;
export const TableTitle = styled.h4`
  font-size: 1.125rem;
  line-height: 1.45;
  font-weight: bold;
  color: #000;
  border: none;
`;

export const BasicTitle = styled.h1`
  margin-bottom: 30px;
`;
export const BasicTextBox = styled.div``;
