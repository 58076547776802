import React from "react";
import styled from "styled-components";
import {
  DropdownSection,
  DropdownBody,
  DropdownMenuLayout,
  ProductList,
  ProductListTitle,
  ProductLists,
  ProductListItem,
  ProductListItemLink,
} from "./style";
import {ReactComponent as EntrepriseLogo} from "../../../assets/customer-logo/entrepriseLogo.svg";
import {ReactComponent as FranchiseLogo} from "../../../assets/customer-logo/franchiseLogo.svg";
import {ReactComponent as HiddenCostLogo} from "../../../assets/customer-logo/hidden-cost.svg";
import {ReactComponent as CarLogo} from "../../../assets/customer-logo/car.svg";
import {ReactComponent as FinancialLogo} from "../../../assets/customer-logo/financial-growth.svg";

// import { Link } from 'react-router-dom';
const DevelopersDropdownEl = styled.div`
  width: 50rem;
`;

const UseCaseDropdown = () => {
  return (
    <DevelopersDropdownEl>
      <DropdownSection data-first-dropdown-section>
        <DropdownBody>
          <DropdownMenuLayout>
            <ProductList>
              <ProductListTitle href="/franchise/industry">
                <FranchiseLogo className="logo" />
                  Franchise
              </ProductListTitle>
              <ProductLists>
                <ProductListItem>
                  <ProductListItemLink
                    href="/franchise/industry"
                    className="deux"
                  >
                    Solutions carved out for the multi location franchise
                    industry to support all your needs
                  </ProductListItemLink>
                </ProductListItem>
              </ProductLists>
            </ProductList>
            <ProductList>
              <ProductListTitle href="/franchise/enterprise">
                <EntrepriseLogo className="logo" />
                  Enterprise
              </ProductListTitle>
              <ProductLists>
                <ProductListItem>
                  <ProductListItemLink
                    href="/franchise/enterprise"
                    className="deux"
                  >
                    Scaled customized deployment with ability to mould our
                    product to your specific needs
                  </ProductListItemLink>
                </ProductListItem>
              </ProductLists>
            </ProductList>

            <ProductList>
              <ProductListTitle href="/franchise/retail">
                <HiddenCostLogo className="logo" />
                  Retail
              </ProductListTitle>
              <ProductLists>
                <ProductListItem>
                  <ProductListItemLink
                    href="/franchise/retail"
                    className="deux"
                  >
                    Activate your mid to long tail advertiser network
                  </ProductListItemLink>
                </ProductListItem>
              </ProductLists>
            </ProductList>

            <ProductList>
              <ProductListTitle href="/automotive">
                <CarLogo className="logo" />
                  Automotive
              </ProductListTitle>
              <ProductLists>
                <ProductListItem>
                  <ProductListItemLink
                    href="/automotive"
                    className="deux"
                  >
                    Activate your dealerships with omnichannel subscription marketing
                  </ProductListItemLink>
                </ProductListItem>
              </ProductLists>
            </ProductList>

            <ProductList>
              <ProductListTitle href="/financial-services">
                <FinancialLogo className="logo" />
                  Financial Services
              </ProductListTitle>
              <ProductLists>
                <ProductListItem>
                  <ProductListItemLink
                    href="/financial-services"
                    className="deux"
                  >
                    Brand-safe and industry compliant omnichannel marketing
                  </ProductListItemLink>
                </ProductListItem>
              </ProductLists>
            </ProductList>

          </DropdownMenuLayout>
        </DropdownBody>
      </DropdownSection>
    </DevelopersDropdownEl>
  );
};

export default UseCaseDropdown;
