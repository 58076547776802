import React from 'react';
import {
  Section,
  SectionMasked,
  BackgroundMask,
  SectionBackground,
  Guides,
  GuideContainer,
  Guide,
  SectionContainer,
  SectionLayout,
  SectionLayoutContainer,
  ColumnLayout,
  // RowLayout,
  Copy,
  CopyTitle,
  CopyBody,
  CopyFooter,
  CopyFooterContainer,
  CtaButton,
  CopyHeader,
  // CopyCaption,
  CopyIcon,
  AnimationSequence,
  // AnimatedIcon,
  CopySubtitle,
  CopyBodySubText
  // CodeEditor
} from './style';

import { ReactComponent as Dolos } from '../../assets/customer-logo/customize.svg';
import { ReactComponent as Metis } from '../../assets/customer-logo/logoMetis.svg';
const GetStarted = () => {
  return (
    <Section className='Section'>
      <SectionMasked>
        <BackgroundMask>
          <SectionBackground className='angle'>
            <Guides>
              <GuideContainer>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
              </GuideContainer>
            </Guides>
          </SectionBackground>
        </BackgroundMask>
      </SectionMasked>

      <SectionContainer>
        <SectionLayoutContainer>
          <SectionLayout>
            <AnimationSequence>
              <ColumnLayout data-columns='2,1,1'>
                <Copy className='variant-footer'>
                  <CopyHeader>
                    <CopyTitle>Ready to get started?</CopyTitle>
                  </CopyHeader>
                  <CopyBody>
                    Schedule a demo and see the future of marketing technology.
                    Our out of the box or enterprise product will minimize
                    manual marketing and charge up your strategy today.
                  </CopyBody>
                  <CopyFooter>
                    <CopyFooterContainer>
                      <CtaButton
                        className='cta variant-link'
                        target="_blank"
                        href='/login.jsp'>
                        Sign In
                      </CtaButton>
                      <CtaButton
                        className=' variant_button cta cta-arrow'
                        href={'/contact-us'}>
                        Contact sales &rarr;
                      </CtaButton>
                    </CopyFooterContainer>
                  </CopyFooter>
                </Copy>
                <Copy>
                  <CopyHeader>
                    <CopyIcon>
                      <Dolos />
                    </CopyIcon>
                    <CopySubtitle>Transparent cost structure</CopySubtitle>
                  </CopyHeader>
                  <CopyBodySubText>
                    No hidden management fees with our monthly subscription
                    service pricing model.
                  </CopyBodySubText>
                  <CopyFooter>
                    <CopyFooterContainer>
                      <CtaButton
                        className='cta variant-link'
                        href='/productThree'>
                        See Dolos &rarr;
                      </CtaButton>
                    </CopyFooterContainer>
                  </CopyFooter>
                </Copy>

                <Copy>
                  <CopyHeader>
                    <CopyIcon>
                      <Metis />
                    </CopyIcon>
                    <CopySubtitle>Lightning fast onboarding</CopySubtitle>
                  </CopyHeader>
                  <CopyBodySubText>
                    Activate your company network in record time. No hassles, no
                    issues. It just works.
                  </CopyBodySubText>
                  <CopyFooter>
                    <CopyFooterContainer>
                      <CtaButton
                        className='cta variant-link'
                        href='/productFour'>
                        See Metis &rarr;
                      </CtaButton>
                    </CopyFooterContainer>
                  </CopyFooter>
                </Copy>
              </ColumnLayout>
            </AnimationSequence>
          </SectionLayout>
        </SectionLayoutContainer>
      </SectionContainer>
    </Section>
  );
};

export default GetStarted;
