import React from 'react'
import QuickFixEpisode5 from '../../../assets/QuickFixEpisode5.png'
import { MediumArticleContainer, Title, Author, EstimatedRead, Image, Paragraph, GrayParagraph, ItalicHeader } from '../style'

const LocalMarketingAdoptionAndEnablement = () => {
  return (
    <MediumArticleContainer>
        <Title>Local Marketing Adoption & Enablement</Title>
        <Author>Featuring Andrea Pirrotti-Dranchak, Global Enterprise Director, NewFlex &nbsp;
            <EstimatedRead>3 min read</EstimatedRead>
        </Author>

        <Image src={QuickFixEpisode5} alt='img' />

        <ItalicHeader>How to effectively communicate and implement new strategies to your franchisees.</ItalicHeader>

        <Paragraph>One of the longstanding challenges for any brand’s marketing team is the low adoption rate of new strategies. The reasons for this range from poor communication, lack of education and resources, or low franchisee-buy in. Learn how to enhance your chances of success for local marketing adoption and how to enable your franchisees effectively and efficiently.</Paragraph>

        <Paragraph><strong>Let’s start with the common problems.</strong> Franchisees are incredibly passionate and protective of their brand. Their local business is their baby, and rightfully so. If they’re saying, “I know my business, I know marketing, let me do my thing,” then they may just reject any future suggestions, conversations, or ideas. It’s important to acknowledge that they do know their business, but that you do as well, and the marketing strategies you suggest are to better — not hinder — it. Some franchisees can fall into having a solely lead-focused attitude. If they are under the impression that they just need leads sent to them and they can handle the rest, then they face a risk of failure. Only focusing on lead generation puts your location at an immediate disadvantage. Leads are only one, small component of the overall offering.</Paragraph>

        <Paragraph>Another common problem is when franchisees want to only be flashy. Sometimes they want to have sky-writing, buses branded with the logo, billboards, the works! It’s important to focus on the basics — it’s what drives revenue and profitability. Last but not least, with franchisees, oftentimes there’s a need for everyone’s buy-in. If you have done the research, considered your options, and chosen a specific strategy for your brand, you should know that each different entity requires adaptation. Ask yourself, how do you communicate this to stakeholders? How do you communicate it to your franchisees?</Paragraph>

        <Paragraph><strong>Best practices for local execution and buy-in.</strong> Consider forming a Marketing Advisory Committee (MAC) as a sounding board for ideas, and then voice to and from the entire system. Explain the holistic marketing strategy and what success looks like overall. Explain the role that the specific tactic/initiative will play in achieving success, why the tactic is important, and the transparent way success will be measured and reported to the system. If you’re still getting push back, it’s important to both hear and address those concerns. The real concern is not always articulated and, if possible, implement a solution for a low-friction, high-optic concern. If a franchisee has a good idea, allow them to test it against the system. This is not about whose ideas are better, who’s wrong or right, but about winning revenue across the system.</Paragraph>

        <Paragraph><strong>Best practices for redirecting the franchisee away from an only-lead gen focus.</strong> As we mentioned earlier in the article, lead generation is only one component of the plan. Use a sound, direct response campaign to generate predictable leads and, ultimately, predictable revenue. That means you run marketing by the numbers, allocating a small percent for testing into new demand generation channels. Once you have checked that box, ensure that marketing is supporting the sales cycle to turn leads into revenue. Test price and promotion and ensure marketing supports every touchpoint from the initial inquiry to contact, and to every milestone through to purchase. Push hard on leads, push hard on conversion. Ultimately, one will fall short and the other compensates. It’s dreamy when you crush both sides of the equation.</Paragraph>

        <Paragraph><strong>Best practices for the franchisee that wants “sizzle.”</strong> Ninety-nine percent of the time, a failing location is in a failing position because they failed to nail down the basics. Sales and marketing leaders must create a foundational plan. Here are a few ways to do that. First, have great photos, fantastic descriptions loaded with benefits, competitive pricing, and a compelling toolkit to respond to objections. From a lead generation standpoint, you must have a fully integrated digital presence, SEO, paid digital, a robust Google My Business page, amazing reviews (trust pilot, birdeye, etc), channel partner and broker relationships/listings. The bottom line is, have a list of basic sales and marketing activities with KPIs against each. You can’t pass go, you can’t move on and test into new activities until the basics are complete.</Paragraph>

        <Paragraph><strong>How can you enable your team?</strong> First and foremost, offer transparency and guidance. One of the biggest ways new marketing strategies fall short is by not being intentionally introduced and acquainted with the team members that will be using them. Make sure your leadership team is communicating the full marketing strategy to your franchisees and offering them guidance on how it can help their business. Be sure you are providing resources. When franchisees have the resources they need, whether it’s education, training, tools, etc., they will feel more supported and engaged with what this new plan is asking of them. Reflect on change as well. Someone once said that it is not change itself we are scared of, but being alone or untethered through it. Set specific standards and goals for your leadership team to help and enable your franchisees each step of the way. Make sure they know that they are not alone and that they shouldn’t be afraid of asking questions.</Paragraph>

        <Paragraph>Watch the full episode <a href="https://www.youtube.com/watch?v=Z_AIeMu_Vk0" target="_blank">here</a>. Make sure to <a href="https://us02web.zoom.us/webinar/register/7616606773963/WN_vgAA8cEcQhGPL96mpv24xQ" target="_blank">sign-up for live leadership conversations</a> on Eulerity’s Quick Fix series.</Paragraph>
    </MediumArticleContainer>
  )
}

export default LocalMarketingAdoptionAndEnablement