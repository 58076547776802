import React from 'react'
import CleanJuice from '../../../../assets/industry/CleanJuice.png';
import LittleGym from '../../../../assets/industry/LittleGym.png';
import HandAndStone from '../../../../assets/industry/HandAndStone.png';
import { StyledCaseStudyCard, StyledCaseStudyGrid } from './styles'

const CaseStudyGrid = () => {

    return (
        <StyledCaseStudyGrid>
            <StyledCaseStudyCard>
                <div className='card__content'>
                    <div className='img__container'>
                        <img className='hand__and__stone' src={HandAndStone}/>
                    </div>
                    <p className='card__title'>Hand & Stone</p>
                    <p className='card__desc'>Learn about the digital marketing strategy used to scale.</p>
                    <a className='card__link' href='https://www.franchise.org/franchise-information/marketing/tackling-2022-how-hand-stone-leveraged-digital-marketing-innovation' target='_blank'>View Case Study</a>
                </div>
            </StyledCaseStudyCard>
            <StyledCaseStudyCard>
                <div className='card__content'>
                    <div className='img__container'>
                        <img className='little__gym' src={LittleGym}/>
                    </div>
                    <p className='card__title'>The Little Gym</p>
                    <p className='card__desc'>Learn how Eulerity provided an all in-one marketing platform to support national media with a localized touch.</p>
                    <a className='card__link' href='https://www.eulerity.com/static/media/The-Little-Gym.bc738e8e.pdf' target='_blank'>View Case Study</a>
                </div>
            </StyledCaseStudyCard>
            <StyledCaseStudyCard>
                <div className='card__content'>
                    <div className='img__container'>
                        <img className='clean__juice' src={CleanJuice}/>
                    </div>
                    <p className='card__title'>Clean Juice</p>
                    <p className='card__desc'>Read how Eulerity became the brands marketing system of record.</p>
                    <a className='card__link' href='https://www.eulerity.com/static/media/Clean-Juice.460ebc5c.pdf' target='_blank'>View Case Study</a>
                </div>
            </StyledCaseStudyCard>
        </StyledCaseStudyGrid>
    )
}

export default CaseStudyGrid