import React from 'react';
import {
  Section,
  SectionMasked,
  BackgroundMask,
  SectionBackground,
  Guides,
  GuideContainer,
  Guide,
  SectionContainer,
  SectionLayout,
  SectionLayoutContainer,
  ColumnLayout,
  RowLayout,
  Copy,
  CopyHeader,
  CopyCaption,
  CopyTitle,
  CopyBody,
  CopyFooter,
  CopyFooterContainer,
  CtaButton,
  HomeGraphic,
  HomegraphicLayout,
  WatchGraphic,
  IpadGraphic,
  IphoneGraphic,
  IphoneGraphictwo,
  DashboardGraphic
} from './style';

import Iphone from '../../components/iphone/Iphone';
import blackscreen from '../../assets/customer-logo/iphonescreen.svg';
import whitescreen from '../../assets/customer-logo/iphone1.svg';
import { ReactComponent as Watch } from '../../assets/watch.svg';

const Platform = () => {
  return (
    <Section className='Section angleBottom'>
      <SectionMasked>
        <BackgroundMask>
          <SectionBackground>
            <Guides>
              <GuideContainer>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
              </GuideContainer>
            </Guides>
          </SectionBackground>
        </BackgroundMask>
      </SectionMasked>

      <SectionContainer>
        <SectionLayoutContainer>
          <SectionLayout>
            <RowLayout>
              <ColumnLayout data-columns='2,2'>
                <Copy className='variant--Section'>
                  <CopyHeader>
                    <CopyCaption className='bleu'>Unified Approach</CopyCaption>
                    <CopyTitle>End to end all in one platform</CopyTitle>
                  </CopyHeader>
                </Copy>
              </ColumnLayout>
              <ColumnLayout data-columns='2,2'>
                <Copy className='variant--Section'>
                  <CopyBody>
                    <p>
                      Finally everything in one place. Eulerity provides all
                      elements required to run digital marketing globally. Our
                      products enable organizations to have complete control of
                      their marketing, whether centralized or decentralized. Our
                      technology powers some of the top owned and operated
                      brands, franchisors, agency holding companies, and local
                      media companies.
                    </p>
                  </CopyBody>
                </Copy>

                <Copy className='variant--Section'>
                  <CopyBody>
                    <p>
                      Evolve your marketing through Eulerity technology to power
                      your creative, buying, optimization, and measurement with
                      complete uniformity.
                    </p>
                  </CopyBody>
                </Copy>
              </ColumnLayout>
              <ColumnLayout data-columns='2,2'>
                <Copy className='variant--Section'>
                  <CopyFooter>
                    <CopyFooterContainer>
                      <CtaButton
                        className='variant_button'
                        href={'/contact-us'}>
                        Talk to our team &rarr;
                      </CtaButton>
                    </CopyFooterContainer>
                  </CopyFooter>
                </Copy>
              </ColumnLayout>
              <HomeGraphic>
                <HomegraphicLayout>
                  <WatchGraphic
                    className='domGraphic'
                    style={{
                      maxWidth: '380px',
                      paddingBottom: '61.8421%'
                    }}>
                    <Watch />
                  </WatchGraphic>
                  <IpadGraphic
                    className='domGraphic'
                    style={{
                      maxWidth: '588px',
                      paddingBottom: '71.7687%'
                    }}></IpadGraphic>
                  <IphoneGraphic
                    className='domGraphic'
                    style={{
                      maxWidth: '301px',
                      paddingBottom: '187.375%'
                    }}>
                    <Iphone image={blackscreen} />
                  </IphoneGraphic>
                  <IphoneGraphictwo
                    className='domGraphic'
                    style={{
                      maxWidth: '301px',
                      paddingBottom: '204.319%'
                    }}>
                    <Iphone image={whitescreen} />
                  </IphoneGraphictwo>
                  <DashboardGraphic
                    className='domGraphic'
                    style={{
                      maxWidth: '929px',
                      paddingBottom: '55.113%'
                    }}></DashboardGraphic>
                </HomegraphicLayout>
              </HomeGraphic>
            </RowLayout>
          </SectionLayout>
        </SectionLayoutContainer>
      </SectionContainer>
    </Section>
  );
};

export default Platform;
