import React from 'react';
import ChatGPTPromptGuide from './ChatGPTPromptGuide.pdf'
import Footer from '../../components/footer/Footer';
import './style.css';

const Success = () => {
  return (
    <>
      <div className='successful container-lg'>
        <h1 className='success_title'>THANK YOU!</h1>
        <i className='fas fa-handshake success_icon'></i>
        <p className='success_text'>
          Our team will be in touch with you shortly.
        </p>
        <p className='success_subtext'>
          In the meantime, here’s an exclusive sneak peek at how Eulerity can help you scale your marketing operational efficiencies with our new ChatGPT e-book. Discover the power of AI-generated prompts to revolutionize your content creation process!
        </p>
        <a href={ChatGPTPromptGuide} download='ChatGPTPromptGuide' className='success_button'>
          Download Your ChatGPT E-book Here
        </a>
      </div>
      <Footer />
    </>
  );
};

export default Success;
