import React from 'react'
import { EstimatedRead, MediumArticleContainer, Paragraph, Headers, Image, Author, Title } from '../style'
import QuickFixEpisode2 from '../../../assets/QuickFixEpisode2.png'

const UseYourDataToTellAStory = () => {
  return (
    <MediumArticleContainer>
        <Title>How to Use Your Data to Tell A Story</Title>
        <Author>Featuring Brandi Kloostra, VP of Digital Marketing, Franworth &nbsp;
            <EstimatedRead>3 min read</EstimatedRead>
        </Author>

        <Image src={QuickFixEpisode2} alt='img' />

        <Paragraph>Data can serve as the building blocks of your digital marketing strategy. How do you dissect data that comes from marketing automation? What’s a common problem leadership teams run into when presented with data and how can they leverage it?</Paragraph>

        <Headers>What’s the stigma with data?</Headers>

        <Paragraph>According to marketingevolution.com, “data-driven marketing is a top priority for marketing leaders, with 40% of organizations aiming to increase data-driven marketing budgets.” Leadership teams need to know the importance of data analytics and the impact they can have on their business. Data is your friend, not your foe. The sooner you lean into it, the better your brand’s marketing strategy becomes.</Paragraph>

        <Headers>You have the data, but you’re not able to tell the story.</Headers>

        <Paragraph>With emerging brands, some of the biggest challenges can be that the proper data isn’t being gathered in a consistent way. People should be using conversion tracking and other tools that are specific. When you’re launching a new campaign, you want to make sure, from start to finish, that if your customer is converting, that the data is flowing into your Google Analytics account or your POS system so that at the end of the month or year, you can say that this particular campaign investment generated X amount of leads, therefore X amount of sales.</Paragraph>

        <Headers>Creating the scope — organizing and sorting your data.</Headers>

        <Paragraph>Identify what is going to determine success for your projects. Make sure you are being specific with the roles and responsibilities that each individual has. Who is analyzing the data? Who is going to pull the reports? Who brings the reports to the table? When you eventually bring your Google Analytics reports to the table, whether it’s traffic volumes or conversion rates, what are you going to do with it? You need to decide what metrics you will be using and focusing on. As marketers, it’s easy to get lost in layers upon layers of data. Be sure you are using the data in an actionable way to make good decisions. It’s important not to boil the ocean and really focus on the KPIs that are going to move the needle and drive the business. For example, your top traffic sources that are generating the most conversions could be your main focus and you then take them and make sure you are doing everything you can to nurture and grow them.</Paragraph>

        <Headers>Communication is key.</Headers>

        <Paragraph>It’s incredibly important to be communicating all of this with your franchisees. You have to make sure they have a simple and digestible way to understand what is going on with your marketing. Finding the right digital partner that is going to deliver and be there for your franchisees to help with the reporting and sifting through the data is key.</Paragraph>

        <Headers>Key solutions and takeaways.</Headers>

        <Paragraph>You should always create a project scope to make sure you are maintaining consistency and direction. It’s important to lean into your Google Analytics and use it as a tool. The information GA provides is there to help your business succeed and provides transparency into your consumer journey. Ask yourself: are we trending in the right direction? Analyzing data and the trends that your brand is seeing online is crucial to making necessary changes. If you see that something isn’t working, you should change it. It’s not a failure, it’s fuel! Lastly, remember to always communicate effectively with leadership and to keep perspective around the data.</Paragraph>

        <Paragraph>
            <a href="https://www.youtube.com/watch?v=QuVZb6SgKss" target='_blank'>Watch the full episode here.</a>
        </Paragraph>
    </MediumArticleContainer>
  )
}

export default UseYourDataToTellAStory