import React, { useState } from 'react';
import useViewport from '../../hooks/useViewportHooks';
import Logo from '../../assets/eulerity-logo.png';
import {
  Header,
  HeaderContainer,
  NavContainer,
  LogoContainer,
  LogoLink,
  LogoImage,
  HeaderButton,
  HeaderNavItemLink
} from './style';

import AnimatedNavbar from './AnimatedNavbar';
import MenuHamburger from '../menuHamburger/menuHamburger';

const Navigation = () => {
  const [duration] = useState(300);
  const [width] = useViewport(window.innerWidth);
  const breakpoint = 1047;

  return (
    <Header isTransparent={window.location.pathname !== "/"}>
      <HeaderContainer>
        <NavContainer>
          <LogoContainer>
            <LogoLink href='/'>
              <LogoImage src={Logo} alt='eulerity logo'></LogoImage>
            </LogoLink>
          </LogoContainer>
          {width > breakpoint ? (
            <AnimatedNavbar duration={duration} />
          ) : (
            <MenuHamburger />
          )}
          <HeaderButton
            href='/login.jsp'
            target='_blank'
            color='#0a2540'>
            Sign in
          </HeaderButton>
        </NavContainer>
      </HeaderContainer>
    </Header>
  );
};

export default Navigation;
