import React from 'react';
import './style.css';
const Navbar = ({ icon, name, href, color }) => {
  return (
    <nav className='DeepNav'>
      <div className='container-lg'>
        <a href={href} className='deepHome' style={{ color: color }}>
          <img src={icon} alt={icon} />
          <span>{name}</span>
        </a>
      </div>
    </nav>
  );
};

export default Navbar;
