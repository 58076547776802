import React from 'react';
import './style.css'; /***style */

const AutoTable = () => {
  return (
    <table>
      <thead>
        <tr>
          <th>Traditional</th>
          <th>Eulerity</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            High cost of sale to service small to midsize dealers
          </td>
          <td>
            <i className='fa fa-check-circle' />
            White label suite enables full control of dealer experience
          </td>
        </tr>
        <tr>
          <td>
           Manual ad enablement
          </td>
          <td>
            <i className='fa fa-check-circle' />
            Dealers download and activate media spend
          </td>
        </tr>
        <tr>
          <td>
            Limited ad formats at scale
          </td>
          <td>
            <i className='fa fa-check-circle' />
            Optimized across search, display, social media channels, video, auto sites, text, email
          </td>
        </tr>
        <tr>
          <td>
            Relying primarily on consumer intent
          </td>
          <td>
            <i className='fa fa-check-circle' />
            Build intent, drive conversions
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default AutoTable;
