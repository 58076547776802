import { useState, useEffect } from 'react';

export default function (defaultValue) {
  const [width, setWidth] = useState(defaultValue);

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  return [width];
}
