import React, {useEffect} from "react";
import {
  Header,
  GlobalContent,
  Main,
  Intro,
  CommonIntroText,
  CommonSectionTitle,
  IntroCopy,
  FormSectionContainer,
  DescriptionSection,
  SectionLayoutColumns,
  SectionTestimonials,
  UsecaseSection,
  Columns,
  FeatureSection,
  SectionFooterContainer,
  Logo,
} from "./style";
import Background from "../../components/Products/background/BackGround";
import LayoutColumn from "../../components/Products/layoutColumn/LayoutColumn";
import Form from "../../components/form/Form";
import Button from "../../components/buttons/Button";
import {loadScript} from "../../helperFunction/helper";
import Footer from "../../components/footer/Footer";

import {ReactComponent as Issue} from "../../assets/customer-logo/issue.svg";
import {ReactComponent as Help} from "../../assets/customer-logo/help.svg";

import Customize from "../../assets/customer-logo/customize.svg";
import Access from "../../assets/customer-logo/access.svg";
import Trust from "../../assets/customer-logo/trust.svg";

const FormSection = () => {
  return (
    <section className="form-section">
      <div className="container-lg">
        <div className="form-column">
          <div className="form-container">
            <Form buttonValue="Let's get started" />
          </div>
        </div>
      </div>
    </section>
  );
};

const Description = () => {
  return (
    <DescriptionSection>
      <SectionLayoutColumns className="layoutColumns--3">
        <LayoutColumn
          icon={Trust}
          title="Why use automation in marketing?"
          text="What if you could have everything in one place? Well, now you can. Marketing technology provides the operational consistency needed to apply towards mundane tasks holding back the scaling of multi-location marketing. Eulerity is the most advanced marketing-automation software that is ready to take your brand’s passion and propel it."
        />
        <LayoutColumn
          icon={Customize}
          title="How would it help my business?"
          text="Increased transparency, brand ownership and first-party data, to name a few. In-house marketing enables organizations to have complete control of their marketing, whether centralized or decentralized. Eulerity’s technology powers some of the top owned and operated brands, franchisors, agency holding companies, and local media companies."
        />
        <LayoutColumn
          icon={Access}
          title="What would it do for my team?"
          text="Access and consistency to key performance indicators is paramount. Your location owners and corporate team deserve them with no hassle necessary. We give it to them automatically, in-depth and not only find their marketing strengths, but utilize them for the brand’s success.Your team should always have access at their fingertips that is visual, simplistic — and deep enough to provide key KPI’s important to your marketing investment. Imagine all the big things your business could do once marketing is automated. With Eulerity, we take care of your marketing so your business can take care of its consumers."
        />
      </SectionLayoutColumns>
    </DescriptionSection>
  );
};

const Testimonials = () => {
  useEffect(() => {
    loadScript("/js/carousel.js");
  }, []);

  return (
    <SectionTestimonials>
      <div className="main-carousel container-lg">
        <div className="carousel-cell">
          <figure className="author">
            <img
              className="avatar"
              src={
                "https://pbs.twimg.com/profile_images/1268627012564013060/v8h1n1P7.jpg"
              }
              alt="Dion Beary"
            />
            <div className="byline">
              <div className="name">Dion Beary</div>
              <div className="title">
                Marketing Services Manager, Clean Juice
              </div>
            </div>
          </figure>
          <blockquote>
            "Eulerity solved a large need for our organization! The technology
            integrated with many of the data points that are of value for us at
            Clean Juice and helped our franchisee's quantify the value of our
            Marketing Services Program.”
          </blockquote>
        </div>
        <div className="carousel-cell">
          <figure className="author">
            <img
              className="avatar"
              src="https://media-exp1.licdn.com/dms/image/C5603AQFV8oavNgS5yg/profile-displayphoto-shrink_800_800/0/1536685663804?e=1615420800&v=beta&t=syUvxqu_Xyi669bZl1YDttJkOV0Zwq4nYm1YqWNs3gI"
              alt="Allison Daly"
            />
            <div className="byline">
              <div className="name">Allison Daly</div>
              <div className="title">VP marketing, Leap</div>
            </div>
          </figure>
          <blockquote>
            "Eulerity offers a very comprehensive digital marketing solution.
            And I’ve probably invested no more than 4 hours of my time toward
            this initiative since our launch!"
          </blockquote>
        </div>
        <div className="carousel-cell">
          <figure className="author">
            <img
              className="avatar"
              src="https://media-exp1.licdn.com/dms/image/C4D03AQErERRGafFibA/profile-displayphoto-shrink_800_800/0/1596054810555?e=1619049600&v=beta&t=99guQAPph8W_ir920WdhT8eA3ZIiP_b9uJ9RrdBtI9s"
              alt="Dennis Holcom"
            />

            <div className="byline">
              <div className="name">Dennis Holcom</div>
              <div className="title">SVP of Franchise Support</div>
            </div>
          </figure>
          <blockquote>
            "Our franchisees love this self-supported AI platform.  It’s easy to manage and provides great visibility of their club's performance through the dashboard.  Eulerity has really helped to increase Workout Anytime's brand awareness."
          </blockquote>
        </div>
        <div className="carousel-cell">
          <figure className="author">
            <img
              className="avatar"
              src="https://media-exp1.licdn.com/dms/image/C5603AQGqJHweQH8wxA/profile-displayphoto-shrink_200_200/0/1517495272237?e=1614211200&v=beta&t=T568EHd8TdX4lJCjZafLV80ruI522-nNjw7vSOD53tY"
              alt="Paul Erdelt"
            />
            <div className="byline">
              <div className="name">Paul Erdelt</div>
              <div className="title">CMO, Woodhouse Day Spa</div>
            </div>
          </figure>
          <blockquote>
            “Eulerity made me master of my digital promotional activities,
            delivering more than what was promised.”
          </blockquote>
        </div>
      </div>
    </SectionTestimonials>
  );
};

const UseCase = () => {
  return (
    <UsecaseSection>
      <h1 className="commonBodyTitle commonBodyTitle-slate1">
        Learn more about our case studies.
      </h1>
      <Button
        bg="#2F6CFE"
        color="#ffff"
        href="/company/case-studies"
        target="_blank"
      >
        Case Studies
      </Button>
    </UsecaseSection>
  );
};

const Features = () => {
  return (
    <FeatureSection className="container-lg">
      <Columns>
        <div className="left">
          <div className="intro-copy container-lg">
            <h2 className="commonSectionTitle">The Year’s Biggest Event</h2>
            <p className="commonBodyText">
              IFA’s Annual Convention is something to look forward to each year
              with top industry trends, technology advances and networking
              connections. We couldn’t be more excited about this year’s theme —
              to #ignite your brand.
            </p>
          </div>
          <ul className="feature-list">
            <li className="feature-block">
              <figure className="helm-logo helm-medium ">
                <Issue />
              </figure>
              <h3 className="commonBodyTitle">Igniting Your Brand’s Future</h3>
              <p className="commonBodyText">
                Last year’s convention taught us that franchising’s future
                starts with us. It only makes sense that IFA 2021’s theme is
                igniting the industry’s best strategies and paving your brand’s
                path forward. That’s where we come in. From marketing
                automation, in-house blueprints, learning to scale your business
                — you name it — and Eulerity is at your service.
              </p>
              <br />
              <p className="commonBodyText">
                We know what the future of franchising looks like because we’ve
                helped create it for some of the biggest brands in the industry.
                Although we’re sad that this year we couldn’t see our friends in
                person, we feel grateful to have made connections virtually at
                IFA’s Annual Convention 2021. Make sure to check out some of our
                takeaways from last year that are still key factors in the
                business.
              </p>
            </li>
          </ul>
        </div>

        <div className="right device-container">
          <figure className="devices-dahboard loaded">
            <div className="ground">
              <div className="shadow"></div>
            </div>
            <div className="laptop">
              <div className="device"></div>
              <div className="controls"></div>
              <div className="screen"></div>
            </div>
          </figure>
        </div>
      </Columns>
      <Columns>
        <div className="left">
          <ul className="feature-list">
            <li className="feature-block">
              <figure className="helm-logo helm-medium "></figure>
              <h3 className="commonBodyTitle">IFA Features</h3>
              <p className="commonBodyText">
                From articles to webinars, IFA features exclusive insights that
                only Eulerity has into what your business can do to get ahead of
                industry changes, not behind them. Check out some of IFA’s
                Eulerity features here:
              </p>
              <ul className="list">
                <li>
                  <i className="fas fa-check-circle"></i>
                  <a
                    href="https://www.franchise.org/franchise-information/technology/apples-latest-ad-tracking-safeguards"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Apple’s Latest Ad Tracking Safeguards.
                  </a>
                </li>
                <li>
                  <i className="fas fa-check-circle"></i>
                  <a
                    href="https://www.franchise.org/franchise-information/technology/changes-in-the-attribution-world"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Changes in the Attribution World.
                  </a>
                </li>
                <li>
                  <i className="fas fa-check-circle"></i>
                  <a
                    href="https://www.franchise.org/blog/the-role-of-machine-learning-in-local-marketing"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    The Role of Machine Learning in Local Marketing.
                  </a>
                </li>
                <li>
                  <i className="fas fa-check-circle"></i>
                  <a
                    href="https://eulerity.medium.com/breaking-down-the-basics-how-new-technology-like-ai-is-transforming-the-franchise-marketing-space-9f567de5fe74"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Breaking Down the Basics How New Technology Like AI is
                    Transforming the Franchise Marketing Space.
                  </a>
                </li>
                <li>
                  <i className="fas fa-check-circle"></i>
                  <a
                    href="https://www.franchise.org/blog/the-translation-machine"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    The Translation Machine.
                  </a>
                </li>

                <li>
                  <i className="fas fa-check-circle"></i>
                  <a
                    href="https://www.franchise.org/blog/internet-marketers-what-the-recent-google-browser-change-means-and-why-you-should-care"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Internet Marketers: What the Recent Google Browser Change
                    Means — and Why You Should Care.
                  </a>
                </li>

                <li>
                  <i className="fas fa-check-circle"></i>
                  <a
                    href="https://www.franchise.org/blog/how-to-communicate-in-a-time-of-crisis-a-spotlight-on-leading-food-service-brands"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    How to Communicate in a Time of Crisis: A Spotlight on
                    Leading Food Service Brands.
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="right">
          <ul className="feature-list">
            <li className="feature-block">
              <figure className="helm-logo helm-medium ">
                <Help />
              </figure>
              <h3 className="commonBodyTitle">We've got your back</h3>
              <p className="commonBodyText">
                IFA 2021 led you here for a reason. If you’re ready to launch
                you’re ready to ignite your in-house marketing with Eulerity,
                let’s get started.
              </p>
            </li>
          </ul>
        </div>
      </Columns>
    </FeatureSection>
  );
};

const SectionFooter = () => {
  return (
    <SectionFooterContainer>
      <Footer />
    </SectionFooterContainer>
  );
};

const Ifa = () => {
  return (
    <>
      <GlobalContent>
        <Main>
          <Header>
            <Intro className="container-lg">
              <Logo>
                <CommonSectionTitle>
                  Welcome, IFA 2021 attendees. Are you ready to #ignite your
                  brand? We're here to help.
                </CommonSectionTitle>
                <img
                  src="https://lh5.googleusercontent.com/12w1OkOvjiZhSb94Bti0c55ynzQLXu9KXAynPV-GHFPgTs4VTHHDaYN5XjgW07xJYapNALP9ESd_BmHE_reqEvWfRzL7jxGi5Pb4wXCZAbjjFm9XKlh7XFdBTp85VWNCQuzvex_AQFc"
                  alt="ifa"
                />
              </Logo>
              <CommonSectionTitle>
                Your brand has a mission.We're here to let everyone know.
              </CommonSectionTitle>
              <IntroCopy className="container-lg">
                <CommonIntroText>
                  The future of marketing automation — at your fingertips.
                  Trusted by hundreds of the industry's top brands and
                  enterprises.
                </CommonIntroText>
              </IntroCopy>
              <FormSectionContainer>
                <FormSection />
              </FormSectionContainer>
            </Intro>
            <Background />
          </Header>
          <Description />
          <Testimonials />
          <UseCase />
          <Features />
        </Main>
      </GlobalContent>
      <SectionFooter />
    </>
  );
};

export default Ifa;
