import React from 'react'
import { EstimatedRead, MediumArticleContainer, Headers, Paragraph, Title, Image, Author } from '../style'
import QuickFix from '../../../assets/QuickFix.png'

const InsAndOutsOfMarketing = () => {
    return (
        <MediumArticleContainer>
            <Title>Quick Fix: Episode 1</Title>
            <Title>In’s and Out’s of Marketing — Inhousing and Outsourcing</Title>
            <Author> Featuring Adam Chandler, COO and Co-Founder, Eulerity &nbsp;
                <EstimatedRead>3 min read</EstimatedRead>
            </Author>

            <Image src={QuickFix} alt="img" />

            <Headers>Decentralization vs. centralization — how can you streamline your efforts?</Headers>

            <Paragraph>If your marketing strategy is decentralized, then it means you’re operating your marketing through a fragmented, multi-supplier method to support your owner/operator marketing needs. You may be spreading out your media buying capabilities with different suppliers and different platforms, which can lead to low adoption rates. Your visibility into spending, data and creatives is extremely limited and can be hard to implement system-wide, data-driven improvements.</Paragraph>

            <Headers>You can gain centralization through creating one common gateway.</Headers>

            <Paragraph>A great option to consider is bringing your marketing in-house. It means more than just operating centrally — it means removing limits based on bandwidth, lack of hires, or budget. Implementation can be hard and adoption of new programs and strategies can be difficult for brands, but the use of marketing automation is a great resource for a streamlined approach.</Paragraph>

            <Headers>Automation doesn’t limit your potential, it increases it.</Headers>

            <Paragraph>Brands are being tasked to do more with less. There shouldn’t be a stigma around AI, as it could be your biggest ally and best marketing tool.</Paragraph>

            <Headers>Questions to ask when considering a marketing partner vendor:</Headers>

            <Paragraph>Do they integrate with your mission critical needs?</Paragraph>

            <Paragraph>Is their data provided real-time on all devices? Desktop, in-app, and customer experiences?</Paragraph>

            <Paragraph>Are their attribution methods “proprietary,” or integrated with trusted, third-party data analytics tools?</Paragraph>

            <Paragraph>What is their core business and what is the company's DNA?</Paragraph>

            <Paragraph>
                <a href='https://www.youtube.com/watch?v=BEK3ow9407U' target='_blank'>Click here to watch the full episode. </a>
            </Paragraph>
        </MediumArticleContainer>
    )
}

export default InsAndOutsOfMarketing