import ReactGA from 'react-ga';

const gaTrackingId = 'UA-170523779-1';

export const initializeGA = () => {
  ReactGA.initialize(gaTrackingId);
};

export const trackInitialPageView = (pathname) => {
  ReactGA.pageview(pathname);
};

export const trackPageView = (history, options = {}) => {
  history.listen((location) => {
    const { pathname } = location || window.location;
    ReactGA.set({ page: pathname, ...options });
    ReactGA.pageview(pathname);
  });
};

export const trackEvent = (category, action, label) => {
  ReactGA.event({
    category,
    action,
    label
  });
};
