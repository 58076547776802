import React, { Children, useEffect, useRef } from 'react';
import { Flipped } from 'react-flip-toolkit';
import {
  DropdownRoot,
  Caret,
  DropdownBackground,
  AltBackground,
  InvertedDiv
} from './style';
import FadeContents from './FadeContents';

const getFirstDropdownSectionHeight = (el) => {
  if (
    !el ||
    !el.querySelector ||
    !el.querySelector('*[data-first-dropdown-section]')
  )
    return 0;
  return el.querySelector('*[data-first-dropdown-section]').offsetHeight;
};

const updateAltBackground = ({
  altBackground,
  prevDropdown,
  currentDropdown
}) => {
  const prevHeight = getFirstDropdownSectionHeight(prevDropdown);
  const currentHeight = getFirstDropdownSectionHeight(currentDropdown);

  const immediateSetTranslateY = (el, translateY) => {
    el.style.transform = `translateY(${translateY}px)`;
    el.style.transition = 'transform 0s';
    requestAnimationFrame(() => (el.style.transitionDuration = ''));
  };

  if (prevHeight) {
    // transition the grey ("alt") background from its previous height to its current height
    immediateSetTranslateY(altBackground, prevHeight);
    requestAnimationFrame(() => {
      altBackground.style.transform = `translateY(${currentHeight}px)`;
    });
  } else {
    // just immediately set the background to the appropriate height
    // since we don't have a stored value
    immediateSetTranslateY(altBackground, currentHeight);
  }
};

const DropdownContainer = ({ children, animatingOut, direction, duration }) => {
  const currentDropdownEl = useRef();
  const prevDropdownEl = useRef();
  let altBackgroundEl;

  useEffect(() => {
    updateAltBackground({
      altBackground: altBackgroundEl,
      prevDropdown: prevDropdownEl.current,
      currentDropdown: currentDropdownEl.current,
      duration: duration
    })
  }, [])

    const [currentDropdown, prevDropdown] = Children.toArray(children);

    return (
      <DropdownRoot
        direction={direction}
        animatingOut={animatingOut}
        duration={duration}>
        <Flipped flipId='dropdown-caret'>
          <Caret />
        </Flipped>
        <Flipped flipId='dropdown'>
          <DropdownBackground>
            <Flipped inverseFlipId='dropdown'>
              <InvertedDiv>
                <AltBackground
                  ref={(el) => (altBackgroundEl = el)}
                  duration={duration}
                />
                <FadeContents
                  direction={direction}
                  duration={duration}
                  ref={currentDropdownEl}>
                  {currentDropdown}
                </FadeContents>
              </InvertedDiv>
            </Flipped>

            <Flipped inverseFlipId='dropdown' scale>
              <InvertedDiv absolute>
                {prevDropdown && (
                  <FadeContents
                    animatingOut
                    direction={direction}
                    duration={duration}
                    ref={prevDropdownEl}>
                    {prevDropdown}
                  </FadeContents>
                )}
              </InvertedDiv>
            </Flipped>
          </DropdownBackground>
        </Flipped>
      </DropdownRoot>
    );
}

export default DropdownContainer;
