import styled from 'styled-components';

const Header = styled.header`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background-color: ${props => props.isTransparent ? "transparent" : "#1FDD95"};
`;

const HeaderContainer = styled.div`
  position: relative;
  max-width: 90%;
  margin: 0 auto;
  padding: 0 1rem;

  // * What is this for? It adds a white space above content
  /* @media (min-width: 768px) {
     {
      padding: 12px 16px;
    } */
  }
`;

const NavContainer = styled.div`
  display: flex;
  width: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-evenly;
  -webkit-box-align: center;
  align-items: flex-start;
  @media (min-width: 670px) {
    align-items: center;
  }
`;

const LogoContainer = styled.h1`
  align-self: center;
`;

const LogoLink = styled.a`
  display: block;
  outline: none;
  border: none;
  padding: 4px;
  margin: -4px;
`;

const LogoImage = styled.img`
  width: 100px;
  height: 50px;
  margin-bottom: 0px;
  box-shadow: none;
  display: block;
  text-align: left;
  @media (max-width: 1047px) {
    display: none;
  }
`;

const HeaderNav = styled.nav`
  position: relative;
`;

const HeaderNavList = styled.ul`
  display: flex;
`;

const HeaderNavItem = styled.li`
  @media (min-width: 670px) {
    margin: 0 2rem;
  }
`;

const HeaderNavItemLink = styled.a`
  padding: 10px 20px;
  font-weight: 700;
  font-size: 15px;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.6;
  letter-spacing: 0.2px;
  color: #0a2540;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: none;
  outline: none;

  @media (max-width: 1047px) {
    display: none;
  }
`;

const HeaderButton = styled.a`
  background: ${(props) => props.color};
  color: #fff !important;
  font-family: 'Opens Sans', sans-serif;
  font-weight: 500;
  padding: 8px 20px;
  justify-content: center;
  border-radius: 100px;
  transition: transform 0.5s ease-in-out;
  -webkit-transition: transform 0.5s ease-in-out;
  &:hover {
    transform: translateY(-3px);
  }
  @media (max-width: 1047px) {
    display: none;
  }
`;

const DropdownSlot = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  perspective: 1500px;
`;

export {
  Header,
  HeaderContainer,
  NavContainer,
  LogoContainer,
  LogoLink,
  LogoImage,
  HeaderNav,
  HeaderNavList,
  HeaderNavItem,
  HeaderNavItemLink,
  HeaderButton,
  DropdownSlot
};
