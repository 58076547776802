import React from 'react';

import SphereGlobe from '../globe/SphereGlobe';

import {
  Section,
  SectionMasked,
  BackgroundMask,
  SectionBackground,
  Guides,
  GuideContainer,
  Guide,
  SectionContainer,
  SectionLayout,
  SectionLayoutContainer,
  ColumnLayout,
  RowLayout,
  Copy,
  CopyTitle,
  CopyBody,
  CopyHeader,
  CopyCaption,
  CopySubtitle,
  CopyBodySubText,
  BackgroundGlobe,
  GlobeSection
} from './style';
const Global = () => {
  return (
    <Section className='Section angleTop'>
      <SectionMasked>
        <BackgroundMask>
          <SectionBackground>
            <BackgroundGlobe>
              <GlobeSection>
                <SphereGlobe />
              </GlobeSection>
            </BackgroundGlobe>
            <Guides>
              <GuideContainer>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
              </GuideContainer>
            </Guides>
          </SectionBackground>
        </BackgroundMask>
      </SectionMasked>
      <SectionContainer>
        <SectionLayoutContainer>
          <SectionLayout>
            <RowLayout>
              <ColumnLayout data-columns='2,2'>
                <Copy className='variant--Section'>
                  <CopyHeader>
                    <CopyCaption>World class technology</CopyCaption>
                    <CopyTitle>
                      Your digital marketing operating system
                    </CopyTitle>
                  </CopyHeader>
                  <CopyBody>
                    Eulerity takes the friction out of marketing. Program your
                    marketing goals via an end to end system that supports
                    millions of users. Our team enables and assists your
                    organization with a real time competitive edge to get to
                    market quickly.
                  </CopyBody>
                </Copy>
              </ColumnLayout>
              <ColumnLayout data-columns='1,1,1,1'>
                <Copy className='variant--Section'>
                  <CopyHeader>
                    <CopySubtitle>10 Billion+</CopySubtitle>
                  </CopyHeader>
                  <CopyBodySubText>
                    opportunities to show your messaging to users daily
                  </CopyBodySubText>
                </Copy>

                <Copy className='variant--Section'>
                  <CopyHeader>
                    <CopySubtitle>94%</CopySubtitle>
                  </CopyHeader>
                  <CopyBodySubText>
                    access to US population to deliver your message
                  </CopyBodySubText>
                </Copy>

                <Copy className='variant--Section'>
                  <CopyHeader>
                    <CopySubtitle>135+</CopySubtitle>
                  </CopyHeader>
                  <CopyBodySubText>
                    countries and currencies supported
                  </CopyBodySubText>
                </Copy>

                <Copy className='variant--Section'>
                  <CopyHeader>
                    <CopySubtitle>99.97%</CopySubtitle>
                  </CopyHeader>
                  <CopyBodySubText>
                    record uptime during peak load seasons
                  </CopyBodySubText>
                </Copy>
              </ColumnLayout>
            </RowLayout>
          </SectionLayout>
        </SectionLayoutContainer>
      </SectionContainer>
    </Section>
  );
};

export default Global;
