import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {Switch, Route, useLocation} from "react-router-dom";

/////////pages links/////////////////
import HomePage from "../pages/HomePage.js";
import ProductOne from "../pages/productOne/ProductOne";
import ProductTwo from "../pages/productTwo/ProductTwo";
import ProductThree from "../pages/productThree/ProductThree";
import Navbar from "../components/navigation/Navigation";
import ProductFour from "../pages/productFour/ProductFour.js";
import Entreprise from "../pages/franchises/entreprise/Entreprise.js";
import NotFound from "../pages/notFound/NotFound.js";
import AboutUs from "../pages/company/aboutUs/AboutUs.js";
import Newsroom from "../pages/company/newsroom/Newsroom.js";
import Industry from "../pages/franchises/industry/Industry.js";
import NewIndustry from "../pages/franchises/industry/NewIndustry.js";
import Contact from "../pages/contact/Contact.js";
import Automotive from "../pages/franchises/automotive/Automotive.js";
import FinancialServices from "../pages/franchises/financial/FinancialServices.js";
import VisionSource from "../pages/franchises/VisionSource.js";


///////terms and privacy links/////////////
import Privacy from "../unconnected/privacy/Privacy.js";
import Terms from "../unconnected/terms/Terms.js";
import DataProcessing from "../unconnected/privacy/DataProcessing.js";
import Msa from "../unconnected/msa/Msa.js";
import TermBlo from "../unconnected/terms/TermBlo";
import TermCleanJ from "../unconnected/terms/TermCleanJ";
import TermWood from "../unconnected/terms/TermWood";
import TermSkyzone from "../unconnected/terms/TermSkyzone";
import TermRockinjump from "../unconnected/terms/TermRockinjump";
import TermTitleBoxing from "../unconnected/terms/TermTitleBoxing";
import TermLittleGym from "../unconnected/terms/TermLittleGym";
import TermTwist from "../unconnected/terms/TermTwist";
import TermWorkout from "../unconnected/terms/TermWorkout";
import TermWow from "../unconnected/terms/TermWow";
import TermMeltShop from "../unconnected/terms/TermMeltShop";
import Thank from "../unconnected/rampup/Thank.js";
import TermMik from "../unconnected/terms/TermMik";
import InternetAdvertising from "../unconnected/msa/InternetAdvertising";
import TermMassageHeights from "../unconnected/terms/TermMassageHeights";
import TermColorMeMine from "../unconnected/terms/TermColorMeMine";
import TermWoworks from "../unconnected/terms/TermWoworks";
import ISIEliteFitness from "../unconnected/terms/TermISIEliteFitness";
import WWHLS from "../unconnected/terms/TermWWHLS";
import TermVisionSource from "../unconnected/terms/TermVisionSource";
import TermSaladWorks from "../unconnected/terms/TermSaladWorks";
import TermSpavia from "../unconnected/terms/TermSpavia";
import TermLittleCaesars from "../unconnected/terms/TermLittleCaesars";
import TermStreamLineBrands from "../unconnected/terms/TermStreamLineBrands";
import TermBigChicken from "../unconnected/terms/TermBigChicken";
import TermOfficeEvolution from "../unconnected/terms/TermOfficeEvolution";
import TermHammerAndNails from "../unconnected/terms/TermHammerAndNails";
import TermFreecoatNails from "../unconnected/terms/TermFreecoatNails";
import TermKoala from "../unconnected/terms/TermKoala";
import TermCapeAM from "../unconnected/terms/TermCapeAM";
import TermARWorkshop from "../unconnected/terms/TermARWorkshop";
import TermMathnasium from "../unconnected/terms/TermMathnasium";
import TermHsmsa from "../unconnected/terms/TermHsmsa";

// * New Terms and MSA
import TermsRegis from "../unconnected/terms/specificTerms/Regis/TermsRegis.js";
import MsaRegis from "../unconnected/terms/specificTerms/Regis/MsaRegis.js";

// Mass TOS Update
import TermCapeAMManagement from "../unconnected/terms/TermCapeAMManagement";
import { TermsObj } from "./termsList.js";

//////ga tracking
import {trackInitialPageView} from "../tracking/Tracking";

////gtm tracking

import {trackInitialPageViewGtm} from "../gtm/Gtm";
import Tour from "../unconnected/msa/Tour.js";
import EmailFailure from "../unconnected/msa/EmailFailure.js";
import PilotSkyzone from "../unconnected/msa/PilotSkyzone.js";
import SkyzoneMarket from "../unconnected/msa/SkyzoneMarket.js";
import Covid from "../unconnected/terms/Covid.js";
import Dynamic from "../unconnected/dynamic/Dynamic.js";
import Success from "../pages/success/Success.js";
import UseCases from "../pages/company/newsroom/UseCases.js";
import TermKitchen from "../unconnected/terms/termKitchen.js";
import Podcast from "../pages/company/newsroom/Podcasts.js";
import Retail from "../pages/franchises/retail/Retail.js";
import Ifa from "../pages/ifa/Ifa.js";
import TermTittle from "../unconnected/terms/TermTittle.js";
import SOIHM from "../unconnected/soihm/SOIHM.js";

// Articles
import TopTipsToAvoidFatigue from "../pages/articles/TopTipsToAvoidFatigue.js";
import FutureServiceAndImplications from "../pages/articles/FutureServiceAndImplications.js";
import WhatWeLearnedFromIFA from "../pages/articles/WhatWeLearnedFromIFA.js";
import FutureAsaService from "../pages/articles/FutureAsaService.js";
import WhatDoISayNow from "../pages/articles/WhatDoISayNow.js";
import EulerityTrainingGuide from "../pages/articles/EulerityTrainingGuide.js";
import FCCTrainingGuide from "../pages/articles/FCCTrainingGuide.js";
import WoodhouseBlog from "../pages/articles/WoodHouseBlog.js";
import EmergenceOfCloudMarketing from "../pages/articles/EmergenceOfCloudMarketing.js";
import TermDefault from "../unconnected/terms/TermDefault";
import CreativeConundrum from "../pages/articles/TheCreativeConundrum.js";
import FourKeysToDigitalMarketing from "../pages/articles/FourKeysToDigitalMarketing.js";
import XponentialAgreement from "../pages/articles/XponentialAgreement.js";
import HowToBuildASuccessfulMarketingTeam from "../pages/articles/HowToBuildASuccessfulMarketingTeam.js";
import TipsForBoostingOnlinePresence from "../pages/articles/TipsForBoostingOnlinePresence.js";

// * Articles (QuickFix)
import InsAndOutsOfMarketing from "../pages/articles/quickfix/InsAndOutsOfMarketing.js";
import UseYourDataToTellAStory from "../pages/articles/quickfix/UseYourDataToTellAStory.js";
import ManagingMarketingBudgetShifts from "../pages/articles/quickfix/ManagingMarketingBudgetShifts.js";
import HowToUseTechToAccelBrandGrowth from "../pages/articles/quickfix/HowToUseTechToAccelBrandGrowth.js";
import LocalMarketingAdoptionAndEnablement from "../pages/articles/quickfix/LocalMarketingAdoptionAndEnablement.js";
import PuttingMoneyWhereYourMouthIs from "../pages/articles/quickfix/PuttingMoneyWhereYourMouthIs.js";
import HowDataTransparencyCanBoostFranchisees from "../pages/articles/quickfix/HowDataTransparencyCanBoostFranchisees.js";
import FiveMarketingPractices from "../pages/articles/quickfix/FiveMarketingPractices.js";
import PowerOfStoryTelling from "../pages/articles/quickfix/PowerOfStoryTelling.js";


const Routes = (props) => {
  const {initialLoadProp, setInitialLoadProp} = props;
  let location = useLocation();

  useEffect(() => {
    if (!initialLoadProp) {
      setInitialLoadProp();
      trackInitialPageView(location.pathname);
      trackInitialPageViewGtm(location.pathname);
    }
  });

  const { arWorkshop, bigChicken, blo, cleanJuice, freeCoatNails, hammerAndNails,
          kitchenSolvers, koalaInsulation, littleCaesars, massageHeights, mathnasium,
          officeEvolution, rockinJump, skyZone, spavia, streamLineBrands, theLittleGym,
          snapology, titleBoxing, twistBrands, visionSource, woodhouseDaySpa, workoutAnytime,
          wowWowLemonade, wow1Day, woWorks, buddysHomeFurnishing, premiumServiceBrands, rockBoxFitness,
          handAndStone, centralBark, fitBodyBootCamp, littleKitchenAcademy, premierMartialArts, xponentialFitness,
          firstLightHomeCare, cycleBar, royalBankOfCanada, youngChefsAcademy, aceHandyMan, aceProPainters, sylvanLearning,
          ellieMentalHealth, yogaSix, i9Sports, costCutters, firstChoiceHair, roosters, pokeWorks, novus, dekaLash
        } = TermsObj;

  return (
    <>
      <Navbar />
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/faq" component={HomePage} />
        <Route exact path="/helpsearch" component={HomePage} />
        <Route exact path="/productone" component={ProductOne} />
        <Route exact path="/producttwo" component={ProductTwo} />
        <Route exact path="/productThree" component={ProductThree} />
        <Route exact path="/productfour" component={ProductFour} />
        <Route exact path="/thankyou" component={Success} />
        <Route exact path="/franchise/enterprise" component={Entreprise} />
        <Route exact path="/company/about-us" component={AboutUs} />
        <Route exact path="/company/contenthub" component={Newsroom} />
        <Route exact path="/press" component={Newsroom} />
        <Route exact path="/company/case-studies" component={UseCases} />
        <Route exact path="/company/podcasts" component={Podcast} />
        <Route exact path="/franchise/industry" component={NewIndustry} />
        <Route exact path="/ifa/2021" component={Ifa} />
        <Route exact path="/ifa2021" component={Ifa} />
        <Route exact path="/franchise/retail" component={Retail} />
        <Route exact path="/automotive" component={Automotive} />
        <Route exact path="/financial-services" component={FinancialServices} />
        <Route exact path="/contact-us" component={Contact} />
        <Route exact path="/privacy" component={Privacy} />
        <Route exact path="/company/privacy" component={Privacy} />
        <Route exact path="/dpt" component={DataProcessing} />
        <Route exact path="/msa" component={Msa} />
        <Route exact path="/emailverified" component={Thank} />
        <Route exact path="/mikterms" component={TermMik} />
        <Route exact path="/termsforinternetadvertising"component={Terms}/>
        <Route exact path="/tour" component={Tour} />
        <Route exact path="/emailverifiedfailure" component={EmailFailure} />
        <Route exact path="/skyzone-pilot-stats" component={PilotSkyzone} />
        <Route exact path="/skyzone-market" component={SkyzoneMarket} />
        <Route exact path="/covid19" component={Covid} />
        <Route exact path="/dynamic" component={Dynamic} />
        <Route exact path="/entrepreneurs" component={HomePage} />
        <Route exact path="/entrepeneurs" component={HomePage} />
        <Route exact path="/the-secrets-of-in-house-marketing" component={SOIHM} />
        <Route exact path="/top-tips-to-avoid-fatigue" component={TopTipsToAvoidFatigue} />
        <Route exact path="/future-as-a-service-and-implications-for-marketers" component={FutureServiceAndImplications} />
        <Route exact path="/what-we-learned-from-ifa" component={WhatWeLearnedFromIFA} />
        <Route exact path="/future-as-a-service" component={FutureAsaService} />
        <Route exact path="/what-do-i-say-now" component={WhatDoISayNow} />
        <Route exact path="/EulerityTrainingGuide" component={EulerityTrainingGuide} />
        <Route exact path="/FCCTrainingGuide" component={FCCTrainingGuide} />
        <Route exact path="/woodhouse-blog" component={WoodhouseBlog} />
        <Route exact path="/the-emergence-of-the-cloud-in-marketing" component={EmergenceOfCloudMarketing} />
        <Route exact path="/tos" component={Terms} />
        <Route exact path="/terms-TITLE" component={TermTittle} noindex={true}  />
        <Route exact path="/terms-massageheights" component={TermMassageHeights} noindex={true}  />
        <Route exact path="/terms-colormemine" component={TermColorMeMine} noindex={true}  />
        <Route exact path="/terms-woworks" component={TermWoworks} noindex={true}  />
        <Route exact path="/terms-kitchensolvers" component={TermKitchen} noindex={true}  />
        <Route exact path="/termsbloblow" component={TermBlo} noindex={true}  />
        <Route exact path="/terms-cleanjuice" component={TermCleanJ} noindex={true}  />
        <Route exact path="/toswhsnew" component={TermWood} noindex={true}  />
        {/* <Route exact path="/terms-skyzone" component={TermSkyzone} noindex={true}  /> */}
        <Route exact path="/terms-rockinjump" component={TermRockinjump} noindex={true}  />
        <Route exact path="/title-boxing" component={TermTitleBoxing} noindex={true}  />
        {/* <Route exact path="/terms-pwt" component={TermTwist} noindex={true}  /> */}
        <Route exact path="/terms-woat" component={TermWorkout} noindex={true}  />
        <Route exact path="/terms-w1dp" component={TermWow} noindex={true}  />
        <Route exact path="/terms-meltshop" component={TermMeltShop} noindex={true}  />
        <Route exact path="/terms-tlg" component={TermLittleGym} noindex={true}  />
        <Route exact path="/terms-isielitefitness" component={ISIEliteFitness} noindex={true}  />
        <Route exact path="/terms-wwhls" component={WWHLS} noindex={true}  />
        <Route exact path="/terms-visionsource" component={TermVisionSource} noindex={true}  />
        <Route exact path="/terms-saladworks" component={TermSaladWorks} noindex={true}  />
        <Route exact path="/terms-spavia" component={TermSpavia} noindex={true}  />
        <Route exact path="/terms-littlecaesars" component={TermLittleCaesars} noindex={true}  />
        <Route exact path="/terms-streamlinebrands" component={TermStreamLineBrands} noindex={true}  />
        <Route exact path="/terms-bigchicken" component={TermBigChicken} noindex={true}  />
        <Route exact path="/terms-officeevolution" render={() => officeEvolution} noindex={true}  />
        <Route exact path="/terms-hammerandnails" component={TermHammerAndNails} noindex={true}  />
        <Route exact path="/terms-freecoatnails" component={TermFreecoatNails} noindex={true}  />
        <Route exact path="/terms-koala" component={TermKoala} noindex={true}  />
        <Route exact path="/terms-capeam" component={TermCapeAM} noindex={true}  />
        <Route exact path="/terms-arworkshop" component={TermARWorkshop} noindex={true}  />
        <Route exact path="/terms-mathnasium" component={TermMathnasium} noindex={true}  />
        {/* <Route exact path="/tos_regis" component={TermsRegis} noindex={true} /> */}
        <Route exact path="/msa_regis" component={MsaRegis} noindex={true} />
        <Route exact path="/h&Smsa" component={TermHsmsa} noindex={true} />

        {/* Agreements */}
        <Route exact path="/terms-XponentialDigitalPartnerProgramAgreement" component={XponentialAgreement} />

        {/* Blogs */}
        <Route exact path="/the-creative-conundrum" component={CreativeConundrum} />
        <Route exact path="/four-keys-to-building-digital-marketing-during-omicron-shutdowns" component={FourKeysToDigitalMarketing} />
        <Route exact path="/the-keys-to-in-house-marketing-and-outsourcing-vendors" component={InsAndOutsOfMarketing} />
        <Route exact path="/how-to-use-data-to-tell-a-story" component={UseYourDataToTellAStory} />
        <Route exact path="/managing-marketing-budget-shifts" component={ManagingMarketingBudgetShifts} />
        <Route exact path="/how-to-use-technology-to-accelerate-brand-growth" component={HowToUseTechToAccelBrandGrowth}/>
        <Route exact path="/local-marketing-adoption-and-enablement" component={LocalMarketingAdoptionAndEnablement}/>
        <Route exact path="/putting-money-where-your-mouth-is" component={PuttingMoneyWhereYourMouthIs} />
        <Route exact path="/how-data-transparency-can-boost-franchisees" component={HowDataTransparencyCanBoostFranchisees}/>
        <Route exact path="/how-to-build-a-successful-marketing-team" component={HowToBuildASuccessfulMarketingTeam}/>
        <Route exact path="/five-marketing-practices-you-need-to-know" component={FiveMarketingPractices}/>
        <Route exact path="/brand-messaging-the-power-of-story-telling" component={PowerOfStoryTelling}/>
        <Route exact path="/5-tips-for-boosting-your-brands-online-presence" component={TipsForBoostingOnlinePresence}/>

        {/* Mass Update TOS */}
        <Route exact path="/terms-ARWorkshopDIY" render={() => arWorkshop} noindex={true} />
        <Route exact path="/terms-bigchickenfranchise" render={() => bigChicken} noindex={true} />
        <Route exact path="/terms-bloblowdry" render={() => blo} noindex={true} />
        <Route exact path="/terms-capeammanagement" component={TermCapeAMManagement} noindex={true} />
        <Route exact path="/terms-cleanjuicefranchise" render={() => cleanJuice} noindex={true} />
        <Route exact path="/terms-freecoatfranchise" render={() => freeCoatNails} noindex={true} />
        <Route exact path="/terms-hammerandnailsgrooming" render={() => hammerAndNails} noindex={true} />
        <Route exact path="/terms-rockinjumpparks" render={() => rockinJump} noindex={true} />
        <Route exact path="/terms-skyzoneparks" render={() => skyZone} noindex={true} />
        <Route exact path="/terms-spaviadayspa" render={() => spavia} noindex={true} />
        <Route exact path="/terms-streamlinebrandsfranchise" render={() => streamLineBrands} noindex={true} />
        <Route exact path="/terms-thelittlegym" render={() => theLittleGym} noindex={true} />
        <Route exact path="/terms-snapology" render={() => snapology} noindex={true} />
        <Route exact path="/terms-titleboxing" render={() => titleBoxing} noindex={true} />
        <Route exact path="/terms-twistbrands" render={() => twistBrands} noindex={true} />
        <Route exact path="/terms-thewoodhouse" render={() => woodhouseDaySpa} noindex={true} />
        <Route exact path="/terms-workoutanytime" render={() => workoutAnytime} noindex={true} />
        <Route exact path="/terms-wowwowhawaiian" render={() => wowWowLemonade} noindex={true} />
        <Route exact path="/terms-wow1day" render={() => wow1Day} noindex={true} />
        <Route exact path="/terms-woworksbrands" render={() => woWorks} noindex={true} />
        <Route exact path="/terms-kitchensolversfranchise" render={() => kitchenSolvers} noindex={true} />
        <Route exact path="/terms-koalainsulation" render={() => koalaInsulation} noindex={true} />
        <Route exact path="/terms-littlecaesarspizza" render={() => littleCaesars} noindex={true} />
        <Route exact path="/terms-massageheightsfranchise" render={() => massageHeights} noindex={true} />
        <Route exact path="/terms-officeevolutiontos" render={() => officeEvolution} noindex={true} />
        <Route exact path="/terms-mathnasiumlearningcenter" render={() => mathnasium} noindex={true} />
        <Route exact path="/terms-visionsourcetos" render={() => visionSource} noindex={true} />
        <Route exact path="/terms-buddys-home-furnishing" render={() => buddysHomeFurnishing} noindex={true} />
        <Route exact path="/terms-premiumservicebrands" render={() => premiumServiceBrands} noindex={true} />
        <Route exact path="/terms-rockboxfitness" render={() => rockBoxFitness} noindex={true} />
        <Route exact path="/handandstone" render={() => handAndStone} noindex={true} />
        <Route exact path="/terms-centralbark" render={() => centralBark} noindex={true} />
        <Route exact path="/terms-fitbodybootcamp" render={() => fitBodyBootCamp} noindex={true} />
        <Route exact path="/terms-littlekitchenacademy" render={() => littleKitchenAcademy} noindex={true} />
        <Route exact path="/terms-premiermartialarts" render={() => premierMartialArts} noindex={true} />
        <Route exact path="/terms-xponentialfitness" render={() => xponentialFitness} noindex={true} />
        <Route exact path="/terms-firstlighthomecare" render={() => firstLightHomeCare} noindex={true} />
        <Route exact path="/terms-cyclebar" render={() => cycleBar} noindex={true} />
        <Route exact path="/terms-royalbankofcanada" render={() => royalBankOfCanada} noindex={true} />
        <Route exact path="/terms-youngchefsacademy" render={() => youngChefsAcademy} noindex={true} />
        <Route exact path="/terms-acehandyman" render={() => aceHandyMan} noindex={true} />
        <Route exact path="/terms-acepropainters" render={() => aceProPainters} noindex={true} />
        <Route exact path="/terms-sylvanlearning" render={() => sylvanLearning} noindex={true}/>
        <Route exact path="/terms-elliementalhealth" render={() => ellieMentalHealth} noindex={true} />
        <Route exact path="/terms-yogasix" render={() => yogaSix} noindex={true}/>
        <Route exact path="/terms-i9sports" render={() => i9Sports} noindex={true}/>
        <Route exact path="/terms-costcutters" render={() => costCutters} noindex={true} />
        <Route exact path="/terms-firstchoicehaircutters" render={() => firstChoiceHair} noindex={true}/>
        <Route exact path="/terms-roosters" render={() => roosters} noindex={true} />
        <Route exact path="/terms-pokeworks" render={() => pokeWorks} noindex={true} />
        <Route exact path="/terms-novus" render={() => novus} noindex={true}/>
        <Route exact path='/terms-dekalash' render={() => dekaLash} noindex={true}/>
        <Route exact path="/terms-of-service" component={TermDefault} />

        {/* Franchise */}
        <Route exact path="/visionsource" component={VisionSource} noindex={true}/>

        <Route exact path="*" component={NotFound} />
      </Switch>
    </>
  );
};

Routes.propTypes = {
  initialLoadProp: PropTypes.bool.isRequired,
  setInitialLoadProp: PropTypes.func.isRequired,
};

export default Routes;
