import React from 'react'
import { MediumArticleContainer, Title, Author, Paragraph, EstimatedRead } from '../style';

const FiveMarketingPractices = () => {
  return (
    <MediumArticleContainer>
      <Title>Five Marketing Practices You Need to Know</Title>
      <Author> Featuring Heather McLeod, CMO, Authority Brands &nbsp;
        <EstimatedRead> 2 min read </EstimatedRead>
      </Author>

      <Paragraph>When it comes to a great marketing strategy, there are tried and true practices that work for your team, but what about key revival elements to make sure your brand is making its mark? Check out the five marketing strategies you should consider when evaluating your marketing operations.</Paragraph>

      <Paragraph><strong>1. Double check all of your marketing.</strong> This means analyzing what you and your team are currently using for outreach, engagement, and brand awareness. Are you conducting business calls? Ask yourself, are you dialed into your geography? Most of us are using zip-code based geography — when was the last time you checked your current offering? It’s important to note if you are generating calls for services that may be less desirable than in the past. Is it time to switch things up?</Paragraph>
      <Paragraph><strong>2. Listen to calls.</strong> That’s right, listen to your call recordings. What are people asking for? Are you providing them with the necessary information to have their questions and concerns answered? You should also take a look at your leads — are you generating the right type? This can help identify places to trim budget, or guide you towards where you should reallocate your budget in the near future.</Paragraph>
      <Paragraph><strong>3. Take advantage of A/B testing.</strong> If you’re not using A/B testing, then now is the time to start. If budgets are slowing or stalling out a little bit, or you have less to launch, double down on what you do have and see what works best. Is there a certain type of messaging that your prospects respond the best to? Perhaps your brand can convey humor to reach customers, or is it a sentimental approach? Maybe even emojis in your email subject lines! A/B testing gives you a way to take a real-time look at what’s working and what you should be taking advantage of.</Paragraph>
      <Paragraph><strong>4. Re-map your customer journey.</strong> Take time to look at your customer journey. Go through and map every little detail. Ask yourself, do you have any gaps? Try adding different programs and different things, then look at it top to bottom and see if anything is missing.</Paragraph>
      <Paragraph><strong>5. Double down on email and email marketing.</strong> Email can be one of your biggest allies. Do you have a good reactivation plan? Build a strategy around how to reactivate people using your existing database to email. What does it look like when a prospect goes cold, or if someone has been sitting in a stage of the pipeline for longer than usual? This is the time to create an effective campaign or template that can really bring people home.</Paragraph>

      <Paragraph>No matter where you are in your brand’s journey, it’s important to always consider what you are already doing for your marketing operations and what can be better. The goal is to consistently have a strong base that you can build upon, only getting better with time.</Paragraph>

      <Paragraph>Watch the full episode <a href="https://www.youtube.com/watch?v=BqCLdBqeZi0" target="_blank">here</a>. Make sure to <a href="https://us02web.zoom.us/webinar/register/7616606773963/WN_vgAA8cEcQhGPL96mpv24xQ" target="_blank">sign-up for live leadership conversations</a> on Eulerity’s Quick Fix series.</Paragraph>
    </MediumArticleContainer>
  )
}

export default FiveMarketingPractices