import React, { useEffect } from "react";
import "./style.css";
import Validation from "./validation";
import {useHistory} from "react-router-dom";
import axios from "axios";
import useFormHook from "../../hooks/useFormHook";

import InlineError from "../messages/InlineError";

const Id = "4408744";
const formNumber = "a84422ff-0a57-4736-baed-54c8f05039e4";
const URL_Hubspot = `https://api.hsforms.com/submissions/v3/integration/submit/${Id}/${formNumber}`;

const Form = ({buttonValue}) => {
  const history = useHistory();
  const [
    value,
    handleChange,
    handleSubmit,
    error,
    selectedOption,
    handleSelect,
    handleReference,
    selectedReference,
    setParamsValue,
  ] = useFormHook(
    {
      firstname: "",
      lastname: "",
      company: "",
      email: "",
      phone: "",
    },
    onSubmit,
    Validation
  );

  useEffect(() => {
    const parts = window.location.search.substr(1).split('&');
    const currEmailQuery = parts.find(part => part.startsWith('email='));
    if (currEmailQuery) setParamsValue({name: "email", value: currEmailQuery.split('=')[1]});
  }, [])

  ///data to send
  let data = {
    submittedAt: Date.now(),
    fields: [
      {
        name: "firstname",
        value: value.firstname,
      },
      {
        name: "lastname",
        value: value.lastname,
      },

      {
        name: "company",
        value: value.company,
      },
      {
        name: "email",
        value: value.email,
      },

      {
        name: "phone",
        value: value.phone,
      },
      {
        name: "type_of_business",
        value: selectedOption,
      },
      {
        name: "reference",
        value: selectedReference
      },
    ],

    context: {
      // hutk: ':hutk', // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
      pageUri: "https://eulerity-website.ue.r.appspot.com/contact-us",
      pageName: "Contact page",
    },
    legalConsentOptions: {
      // Include this object when GDPR options are enabled
      consent: {
        consentToProcess: true,
        text:
          "I agree to allow Example Company to store and process my personal data.",
        communications: [
          {
            value: true,
            subscriptionTypeId: 999,
            text:
              "I agree to receive marketing communications from eulerity Company.",
          },
        ],
      },
    },
    skipValidation: true,
  };

  //submit form on Hubspot
  async function onSubmit() {
    await axios.post(URL_Hubspot, data);
    setTimeout(() => {
      history.push("/thankyou");
    }, 500);
  }

  return (
    <form onSubmit={handleSubmit}>
      <fieldset>
        <div className="form-row text">
          <label htmlFor="firstname">First name</label>
          <input
            id="firstname"
            name="firstname"
            type="text"
            placeholder="Jane"
            onChange={handleChange}
            value={value.firstname}
          />
          {error && <InlineError text={error.firstname} />}
        </div>
        <div className="form-row text">
          <label htmlFor="lastname">Last name</label>
          <input
            id="lastname"
            name="lastname"
            type="text"
            placeholder="Smith"
            onChange={handleChange}
            value={value.lastname}
          />
          {error && <InlineError text={error.lastname} />}
        </div>
        <div className="form-row text">
          <label htmlFor="workEmail">Email</label>
          <input
            id="workEmail"
            name="email"
            type="email"
            placeholder="email@email.com"
            onChange={handleChange}
            value={value.email}
          />
          {error && <InlineError text={error.email} />}
        </div>
        <div className="form-row text">
          <label htmlFor="company">Business Name</label>
          <input
            id="company"
            name="company"
            placeholder="XYZ Corp Ltd"
            type="text"
            onChange={handleChange}
            value={value.company}
          />
          {error && <InlineError text={error.company} />}
        </div>

        <div className="form-row text">
          <label htmlFor="phone">Phone Number</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            placeholder="123-456-7890"
            required
            onChange={handleChange}
            value={value.phone}
          />
          {error && <InlineError text={error.phone} />}
        </div>

        <div className="radio_container">
        <div className="business">
          <label htmlFor="reference">How Did You Hear About Us?</label>
        </div>

        <select name="references" id="reference" onChange={handleReference}>
          <option value="LinkedIn">LinkedIn</option>
          <option value="Web Search">Web Search</option>
          <option value="IFPG">IFPG</option>
          <option value="IFA">IFA</option>
          <option value="A Colleague">A Colleague</option>
          <option value="Other">Other</option>
        </select>

        <div className="business">
          <label htmlFor="businesstype">Business Category Type:</label>
        </div>

        <div className="radio">
          <input
            id="franchise"
            name="businesscategory"
            type="radio"
            checked={selectedOption === "Franchise"}
            onChange={handleSelect}
            value="Franchise"
          />
          <label htmlFor="franchise">Franchise</label>
        </div>

        <div className="radio">
          <input
            id="nonFranchise"
            name="businesscategory"
            type="radio"
            checked={
              selectedOption ===
              "Non franchise single/multi unit independent business owner"
            }
            value="Non franchise single/multi unit independent business owner"
            onChange={handleSelect}
          />
          <label htmlFor="nonFranchise">
            Non franchise single/multi unit independent business owner
          </label>
        </div>

        <div className="radio">
          <input
            id="eulerityApi"
            name="businesscategory"
            type="radio"
            checked={
              selectedOption ===
              "Enterprise looking to use Eulerity's APIs and SDK"
            }
            value="Enterprise looking to use Eulerity's APIs and SDK"
            onChange={handleSelect}
          />
          <label htmlFor="eulerityApi">
            Enterprise looking to use Eulerity's APIs and SDK
          </label>
        </div>
        </div>

        <div>
          <input
            type="submit"
            value={buttonValue}
            className="bg-bleu"
          />
        </div>
      </fieldset>
    </form>
  );
};

export default Form;
