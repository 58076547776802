import React from 'react';
import { TableContainer } from './styles.js';

const FinancialTable = () => {
  return (
    <TableContainer>
        <li> Eulerity</li>
        <li>Brand approved ad templates with local advisor customization</li>
        <li>Multi-channel, paid/organic campaign strategy (search, social, display, video, etc.)</li>
        <li>One, overarching campaign media budget for all channels</li>
        <li>AI-technology that smartly distributes media budget in real-time across best converting channels</li>
        <li>Desktop/Mobile access to execute media strategies and view key performance metrics</li>
        <li>Low monthly platform/subscription fee to support individual advisors media budgets</li>
        <li>Consultation and support from a dedicated account team to ensure Corporate Marketing & Advisor success</li>
    </TableContainer>
  );
};

export default FinancialTable;
