import React from 'react';
import Thankimg from '../../assets/verifiEmail.png';
import thankIcon from '../../assets/thankIcon.png';
import './style.css';
import Footer from '../../components/footer/Footer';
const Thank = () => {
  return (
    <>
      <div className='thank'>
        <div className='container-lg'>
          <h1 className='title'>You are all set</h1>
          <img
            src={Thankimg}
            style={{ borderRadius: 0, boxShadow: 'none' }}
            alt='thank you'
            className='image1'></img>

          <div className='thank-content'>
            <h1>Thank you for choosing Eulerity.</h1>
            <div className='thankBoxContent'>
              <img
                src={thankIcon}
                style={{ borderRadius: 0, boxShadow: 'none' }}
                alt='thank you icon'
              />
              <p className='thankBoxContent_text'>
                As always, if you have any questions please reach out to our
                support team at support@eulerity.com and we will get back to you
                within 24 hours. We work to make advertising simple, affordable,
                and elegant.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Thank;
