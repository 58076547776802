import styled from "styled-components";

export const YoutubePlayer = styled.div`
    display: grid;
    place-items: center;
`;

export const SOIHMContainer = styled.div`

`;

export const Title = styled.h1`
    text-align: center;
    padding: 50px;
`;