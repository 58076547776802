import styled from "styled-components";

export const Main = styled.div`
    background: linear-gradient(90deg, rgba(16, 133, 200, 0.62) 0%, rgba(31, 221, 149, 0.56) 51.41%, rgba(255, 255, 255, 0.81) 100%);
`

export const Section = styled.section``

export const SectionMasked = styled.div`
    overflow: var(--sectionOverflow);
`;

export const BackgroundMask = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: var(--sectionBackgroundOverflow);
`;

export const SectionBackground = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    -webkit-transform-origin: var(--sectionTransformOrigin);
    transform-origin: var(--sectionTransformOrigin);
    -webkit-transform: skewY(var(--sectionAngle));
    transform: skewY(var(--sectionAngle));
    background: #fff;
    overflow: hidden;
`

// * Content Container - for padding
export const SectionContainer = styled.div`
    width: 100%;
    padding-left: 84px;
    padding-right: 84px;
    position: relative;
    z-index: 1;

    img {
        border-radius: 0px !important;
        margin-bottom: 0px !important;
        box-shadow: none !important;
    }

    &.hero__padding {
        padding-top: 97px;
        padding-bottom: 118px;
    }

    &.challenge__padding {
        padding-top: 121px;
        padding-bottom: 83px;
    }

    &.mobile__exp__padding {
        padding-top: 220px;
        padding-bottom: 71px;
    }

    &.corp__padding {
        padding-top: 120px;
        padding-bottom: 220px;
    }

    &.momentum__padding {
        padding-top: 121px;
        padding-bottom: 83px;
    }

    &.cost__padding {
        padding-top: 190px;
        padding-bottom: 122px;
    }

    &.customers__padding {
        padding-top: 121px;
        padding-bottom: 83px;
    }

    &.client__padding {
        padding-top: 121px;
        padding-bottom: 33px;
    }

    &.get__started__padding {
        padding-top: 136px;
        padding-bottom: 230px;
    }

    // * Media Query
    @media (max-width: 768px) {
        padding: 70px 50px !important;
    }

    @media (max-width: 481px) {
        padding-top: 60px !important;
    }
`

// * Hero Content
export const HeroContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, minmax(1fr, auto));
    grid-template-rows: auto 1fr;

    .grid-item-1 {
        grid-column: 1 / 2;
        grid-row: 1 / 2;

        h1 {
            font-size: 70px;
            font-weight: 800;
        }
    }

    .grid-item-2 {
        grid-column: 3 / 5;
        grid-row: 1 / 3;
        width: 100%;

        img {
            width: 100%;
            height: auto;
            max-height: 547px;
            max-width: 571px;
        }
    }

    .grid-item-3 {
        grid-column: 1 / 3;
        grid-row: 2 / 3;
        max-width: 428px;

        .subtext {
            margin-top: 20px;
            font-size: 20px;
            font-weight: 400;
        }

        .check-list-container {
            p {
                font-size: 20px;
                font-weight: 400;
                margin-top: 17px;
            }

            svg {
                margin-left: 16px;
                margin-right: 8px;
            }
        }

        .learn-more-container {
            margin-top: 60px;
            display: flex;
            flex-direction: column;
            gap: 8px;

            .subtext {
                font-size: 16px;
                font-weight: 600;
            }

            .learn-btn {
                height: 54px;
                width: 220px;
                background: #000000;
                color: #ffffff;
                text-decoration: none;
                text-align: center;
                border-radius: 50px;
                padding: 16px 35px;
                font-weight: 600;
            }
        }
    }

    // * Tablet
    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-template-rows: auto;

        .grid-item-1 {
            grid-column: 1;
            grid-row: 1 / 2;
            margin-bottom: 20px;

            h1 {
                text-align: center;
            }
        }

        .grid-item-2 {
            grid-column: 1;
            grid-row: 2 / 3;
            display: flex;
            justify-content: center;
            align-content: center;

            img {
                max-height: 347px;
                max-width: 370px;
            }
        }

        .grid-item-3 {
            grid-column: 1;
            grid-row: 3 / 4;
            margin: auto;

            .learn-more-container {
                justify-content: center;
                align-items: center;

                .learn-btn {
                    width: 100%;
                }
            }
        }
    }

    @media (max-width: 768px) {
        .grid-item-1 h1 {
            font-size: 30px;
        }
    }

    @media (max-width: 481px) {
        .grid-item-2 {
            img {
                max-height: 227px;
                max-width: 240px;
            }
        }

        .grid-item-3 .learn-more-container {
            margin-top: 30px;
        }
    }
`

// * Challenges Content
export const ChallengesContainer = styled.div`
    display: flex;
    flex-direction: column;

    .text-container {
        display: flex;
        flex-direction: column;

        h1 {
            font-size: 46px;
            font-weight: 800;
            text-align: center;
            margin-bottom: 80px;
        }

        p {
            font-size: 24px;
            font-weight: 400;
            margin-bottom: 64px;
        }
    }

    @media (max-width: 768px) {
        .text-container {
            h1 {
                font-size: 30px;
            }
        }
    }

    @media (max-width: 481px) {
        .text-container {
            h1 {
                margin-bottom: 20px;
            }

            p {
                font-size: 18px;
            }
        }
    }
`

// * Mobile Experience Content
export const MobileExperienceContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, auto);

    .header-container {
        grid-column: 1 / 3;
        grid-row: 1 / 2;
        margin-bottom: 45px;

        h1 {
            font-size: 46px;
            font-weight: 800px;
            text-align: center;
        }
    }

    .left-content {
        grid-row: 2 / 4;
        display: flex;
        flex-direction: column;
        justify-content: center;

        h2 {
            font-size: 34px;
            font-weight: 600;
            margin-bottom: 56px;
        }

        p {
            font-size: 28px;
            font-weight: 400;
        }
    }

    .right-content {
        display: flex;
        align-items: center;
        justify-content: center;
        grid-column: 2 / 3;
        grid-row: 2 / 4;

        .img-container {
            margin-left: 68px;
        }

        img {
            width: 364px;
            height: 624px;
        }
    }

    // * Media Query
    @media (max-width: 1024px) {
        width: 100%;
        .left-content {
            h2 {
                font-size: 28px;
            }

            p {
                font-size: 24px;
            }
        }


        .right-content img {
            width: 284px;
            height: 564px;
        }
    }

    @media (max-width: 768px) {
        .header-container h1 {
            font-size: 30px;
        }

        .left-content {
            h2 {
                font-size: 24px;
                margin-bottom: 30px;
            }

            p {
                font-size: 18px;
            }
        }

        .right-content .img-container {
            margin-left: 64px;
        };

        .right-content img {
            height: 328px;
            width: 198px;
        }
    }

    @media (max-width: 481px) {
        grid-template-columns: repeat(1, 1fr);

        .header-container {
            grid-column: 1;
            grid-row: 1;
            margin-bottom: 20px;
        }

        .left-content {
            grid-column: 1;
            grid-row: 3;
        }

        .right-content {
            grid-column: 1;
            grid-row: 2;

            .img-container {
                margin-left: 0px;
                margin-top: 16px;
            }
        }
    }
`

// * Corporate Control Conent
export const CorporateControlContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .left-content {
        grid-column: 1 / 2;
        display: flex;
        align-items: center;
        justify-content: center;

        .img-container {
            margin-right: 10px;

            img {
                width: 585px;
                height: 336px;
            }
        }
    }

    .right-content {
        grid-column: 2 / 3;
        display: flex;
        flex-direction: column;
        justify-content: center;

        h2 {
            font-size: 34px;
            font-weight: 600;
            margin-bottom: 56px;
        }

        p {
            font-size: 28px;
            font-weight: 400;
        }
    }

    @media (max-width: 1024px) {
        .right-content {
            h2 {
                font-size: 28px;
            }

            p {
                font-size: 24px;
            }
        }

        .left-content .img-container img {
            height: 206px;
            width: 405px;
        }
    }

    @media (max-width: 768px) {
        .right-content {
            h2 {
                font-size: 24px;
                margin-bottom: 30px
            }

            p {
                font-size: 18px;
            }
        }

        .left-content .img-container img {
            height: 160px;
            width: 300px;
        }
    }

    @media (max-width: 481px) {
        grid-template-columns: repeat(1, 1fr);

        .left-content {
            grid-column: 1;
            grid-row: 1;

            .img-container {
                margin-right: 0px;

                img {
                    height: 140px;
                    width: 100%;
                    margin-bottom: 20px;
                }
            }
        }

        .right-content {
            grid-column: 1;
            grid-row: 2;
        }
    }
`

// * Gain Momentum Content
export const GainMomentumContainer = styled.div`
    display: flex;
    flex-direction: column;

    .text-container {
        h1 {
            text-align: center;
            font-size: 46px;
            font-weight: 800;
            margin-bottom: 64px;
        }

        p {
            font-size: 24px;
            font-weight: 400;
            color: #4A4949;
        }
    }

    @media (max-width: 768px) {
        .text-container {
            h1 {
                font-size: 30px;
            }
        }
    }

    @media (max-width: 481px) {
        .text-container {
            h1 {
                margin-bottom: 20px;
            }

            p {
                font-size: 18px;
            }
        }
    }
`

// * Scale Media
export const ScaleMediaContainer = styled.div`
    display: flex;
    flex-direction: column;

    .text-container {
        h1 {
            font-size: 46px;
            font-weight: 800;
            text-align: center;
            margin-bottom: 64px;
        }

        p {
            font-size: 24px;
            font-weight: 400;
            color: #4A4949;
            margin-bottom: 60px
        }
    }

    .cost-container {
        display: flex;
        align-items: flex-start;
        justify-content: space-evenly;

        .cost-box {
            margin-bottom: 20px;
            max-width: 312px;

            p {
                font-size: 18px;
                text-align: center;
            }

            .cost-header {
                font-weight: 700;
                margin-bottom: 20px;
            }

            .cost-text {
                font-weight: 400;
            }
        }
    }

    @media (max-width: 768px) {
        .text-container h1 {
            font-size: 30px;
        }

        .cost-container {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    @media (max-width: 481px) {
        .text-container p {
            font-size: 18px;
        }
    }

`

// * Customers
export const CustomerContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
        font-size: 46px;
        font-weight: 800;
        margin-bottom: 64px;
        text-align: center;
    }

    p {
        font-size: 24px;
        font-weight: 400;
        margin-bottom: 40px;
        color: #4A4949;
    }

    @media (max-width: 768px) {
        h1 {
            font-size: 30px;
        }
    }

    @media (max-width: 481px) {
        h1 {
            margin-bottom: 20px;
        }

        p {
            font-size: 18px;
        }
    }
`

export const ClientSuccessContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
        font-size: 46px;
        margin-bottom: 80px;
        text-align: center;
    }

    .case-btn {
        max-width: 333px;
        width: 100%;
        height: 70px;
        background: #B8F4DD;
        border-radius: 50px;
        font-size: 20px;
        font-weight: 700;
        padding: 16px 49px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 40px;
        text-align: center;
    }

    @media (max-width: 768px) {
        h1 {
            font-size: 30px;
        }
    }

    @media (max-width: 481px) {
        h1 {
            margin-bottom: 20px;
        }
    }
`

export const GetStartedContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(6, 100px);

    .grid-item-1 {
        grid-column: 1 / 2;
        grid-row: 1;

        h1 {
            font-size: 70px;
            font-weight: 800;
        }
    }

    .grid-item-2 {
        grid-column: 2 / 3;
        grid-row: 1 / 2;

        img {
            width: 100%;
            height: auto;
            max-width: 642px;
            max-height: 558px;
        }
    }

    .grid-item-3 {
        grid-column: 1 / -1;
        grid-row: 3 / -1;
        max-width: 428px;

        .learn-more-container {
            display: flex;
            flex-direction: column;
            gap: 60px;

            p {
                font-size: 18px;
                font-weight: 400;
            }

            a {
                width: 204px;
                height: 54px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 16px 35px;
                border-radius: 50px;
                background-color: #000000;
                text-align: center;

                span {
                    font-size: 16px;
                    font-weight: 600;
                    color: #ffffff;
                }
            }
        }
    }

    @media (max-width: 768px) {
        grid-template-columns: 1fr;

        .grid-item-1 {
            grid-column: 1;
            grid-row: 1;

            h1 {
                font-size: 40px;
                text-align: center;
            }
        }

        .grid-item-2 {
            grid-column: 1;
            grid-row: 2 / 5;
            margin-bottom: 20px;

            display: flex;
            justify-content: center;
            align-items: center;

            img {
                max-width: 402px;
                max-height: 308px;
            }
        }

        .grid-item-3 {
            margin: auto;
            grid-column: 1;
            grid-row: 5 / -1;

            .learn-more-container a {
                width: 100%;
            }
        }
    }

    @media (max-width: 481px) {
        .grid-item-1 h1 {
            font-size: 26px;
        }
    }
`