import React from 'react';
import './pilot.css';
import skymarket from '../../assets/skymarket.png';
import Footer from '../../components/footer/Footer';
const SkyzoneMarket = () => {
  return (
    <>
      <div className='sky'>
        <div className='container-lg'>
          <h1>Why Market With Eulerity</h1>
          <img src={skymarket} alt='skyzone pilot ' />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SkyzoneMarket;
