import React from "react";
import SphereGlobe from "../globe/SphereGlobe";
import {
  Section,
  SectionMasked,
  BackgroundMask,
  SectionBackground,
  Guides,
  GuideContainer,
  Guide,
  SectionContainer,
  SectionLayout,
  SectionLayoutContainer,
  ColumnLayout,
  RowLayout,
  Copy,
  CopyTitle,
  CopyBody,
  CopyHeader,
  CopyCaption,
  BackgroundGlobe,
  Globe,
} from "./style";
const SectionGlobe = ({title, subtitle, paraFirst, paraSecond, paraThird, colorScheme}) => {
  return (
    <Section className="Section angleTop">
      <SectionMasked>
        <BackgroundMask>
          <SectionBackground colorScheme={colorScheme}>
            <BackgroundGlobe>
              <Globe>
                <SphereGlobe />
              </Globe>
            </BackgroundGlobe>
            <Guides>
              <GuideContainer>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
              </GuideContainer>
            </Guides>
          </SectionBackground>
        </BackgroundMask>
      </SectionMasked>
      <SectionContainer>
        <SectionLayoutContainer>
          <SectionLayout>
            <RowLayout>
              <ColumnLayout data-columns="2,2">
                <Copy className="variant--Section">
                  <CopyHeader>
                    <CopyCaption>{title}</CopyCaption>
                    <CopyTitle>{subtitle}</CopyTitle>
                  </CopyHeader>
                  {paraFirst && <CopyBody>{paraFirst}</CopyBody>}
                  <br />
                  {paraSecond && <CopyBody>{paraSecond}</CopyBody>}
                  <br />
                  {paraThird && <CopyBody>{paraThird}</CopyBody>}
                </Copy>
              </ColumnLayout>
            </RowLayout>
          </SectionLayout>
        </SectionLayoutContainer>
      </SectionContainer>
    </Section>
  );
};

export default SectionGlobe;
