import React, { useEffect } from "react";
import Button from "../../../components/buttons/Button";
import Global from "../../../components/utilities/SectionGlobe";
import Footer from "../../../components/footer/Footer";
import LogoApp from "../../../assets/customer-logo/friction.svg";
import fccApp from "../../../assets/customer-logo/gears.svg";
import businessman from "../../../assets/financial-services/businessman.png";
import crossroads from "../../../assets/financial-services/crossroads.png";
import macbook from "../../../assets/financial-services/macbook.png";


import { loadScript } from "../../../helperFunction/helper";

import {
  Section,
  SectionMasked,
  BackgroundMask,
  SectionBackground,
  Guides,
  GuideContainer,
  Guide,
  SectionContainer,
  SectionLayout,
  SectionLayoutContainer,
  ColumnLayout,
  RowLayout,
  Copy,
  CopyTitle,
  CopyFooter,
  CopyFooterContainer,
  CopyHeader,
  SectionStarted,
  CopyBodySubText,
  Graphic,
  CopySubtitle,
  CopyBody,
  CtaButton,
  Main,
  Gradient,
} from "../automotive/style";
import FinancialTable from "../../../components/table/FinancialTable";
const Hero = () => {
  useEffect(() => {
    loadScript("/js/gradient.js");
  }, []);
  return (
    <Section className='Section angleBottom angleBoth ProductHeroSection first '>
      <SectionMasked>
        <BackgroundMask>
          <SectionBackground>
            <Guides>
              <GuideContainer>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
              </GuideContainer>
            </Guides>
          </SectionBackground>
        </BackgroundMask>
      </SectionMasked>
      <SectionContainer>
        <SectionLayoutContainer>
          <SectionLayout>
            <Gradient className='gradient isLoaded'>
              <canvas
                id='gradient-canvas'
                className='Gradient__canvas isLoaded'
              ></canvas>
            </Gradient>

            <ColumnLayout data-columns='2,2'>
              <Copy className='variant--Section'>
                <CopyHeader>
                  <CopyTitle>
                    Our advice for advisors? Let the tech do the work for you
                  </CopyTitle>
                </CopyHeader>
                <CopyBodySubText className='learn'>
                  At Eulerity, we help brands solve the challenge of executing local digital marketing at scale across their entire location network. Our Local Advisor Digital Marketing Program is designed to help advisors execute customized campaigns (e.g. unique creative, targeting, budgets, optimizations and reporting - across search, social, display and video) to build local market awareness and attract prospective customers to their firm, while aligning with the overall corporate marketing strategy and adhering to brand/industry compliance guidelines. 
                </CopyBodySubText>

                <CopyFooter>
                  <CopyFooterContainer>
                    <Button bg='#2460A7FF' color='#fff' href={"/contact-us"}>
                      Learn more
                    </Button>
                  </CopyFooterContainer>
                </CopyFooter>
              </Copy>
              <Graphic className='franchise-image-app'>
                <img src={businessman} alt='img' />
              </Graphic>
            </ColumnLayout>
          </SectionLayout>
        </SectionLayoutContainer>
      </SectionContainer>
    </Section>
  );
};

const Payouts = () => {
  return (
    <Section className='Section mtop'>
      <SectionMasked>
        <BackgroundMask>
          <SectionBackground>
            <Guides>
              <GuideContainer>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
              </GuideContainer>
            </Guides>
          </SectionBackground>
        </BackgroundMask>
      </SectionMasked>

      <SectionContainer>
        <SectionLayoutContainer>
          <SectionLayout>
            <ColumnLayout data-columns='2,2'>
              <RowLayout>
                <Copy className='variant--Section'>
                  <CopyHeader>
                    <CopyTitle>
                      Compliant digital marketing for financial services
                    </CopyTitle>
                  </CopyHeader>
                  <CopyBodySubText>
                    We are the leading marketing technology partner creating and delivering digital marketing at the scale, speed and compliance required by financial services brands and their agent/advisor networks. Our platform caters to advisors of all experience levels — whether your advisors are new to digital marketing or more savvy. With our platform elevating your brand’s digital marketing, your advisors can focus on serving their clients first and foremost.
                  </CopyBodySubText>
                </Copy>
              </RowLayout>
              <Graphic className='franchise-image-app'>
                <img src={crossroads} alt='auto2' />
              </Graphic>
            </ColumnLayout>
          </SectionLayout>
        </SectionLayoutContainer>
      </SectionContainer>
    </Section>
  );
};

const Compliance = () => {
  return (
    <Section className='Section compliance'>
      <SectionMasked>
        <BackgroundMask>
          <SectionBackground className='compliance'>
            <Guides>
              <GuideContainer>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
              </GuideContainer>
            </Guides>
          </SectionBackground>
        </BackgroundMask>
      </SectionMasked>

      <SectionContainer>
        <SectionLayoutContainer>
          <SectionLayout>
            <ColumnLayout data-columns='2,2'>
              <RowLayout>
                <Copy className='variant--Section'>
                  <CopyHeader>
                    <CopyTitle className='complianceTitle'>
                      Let us introduce you to your clients
                    </CopyTitle>
                  </CopyHeader>
                  <CopyBodySubText className='complianceText'>
                    Oversee advisor digital marketing and create a robust asset library with pre-built campaigns — all while ensuring brand consistency and oversight through built-in compliance. Eulerity’s digital marketing platform allows advisors to build awareness and drive more leads and referrals, and build trust with both clients and prospects through brand compliant marketing campaigns. With our technology, you’ll be able to smartly and efficiently manage local digital marketing on behalf of, or together with, all your individual advisors or agents. With our software automating and optimizing your ad spend, your online ads will be delivered directly to your clients and prospects — wherever they already are on the wide web!
                  </CopyBodySubText>
                </Copy>
              </RowLayout>
              <Graphic className='franchise-image-app'>
                <img className="laptop" src={macbook} alt='img' />
              </Graphic>
            </ColumnLayout>
          </SectionLayout>
        </SectionLayoutContainer>
      </SectionContainer>
    </Section>
  );
};

const Started = () => {
  return (
    <SectionStarted className='Section top anglebottom '>
      <SectionMasked>
        <BackgroundMask>
          <SectionBackground>
            <Guides>
              <GuideContainer>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
              </GuideContainer>
            </Guides>
          </SectionBackground>
        </BackgroundMask>
      </SectionMasked>

      <SectionContainer>
        <SectionLayoutContainer>
          <SectionLayout className='smallPadding'>
            <ColumnLayout data-columns='3,1'>
              <Copy className='variant-footer'>
                <CopyHeader>
                  <CopyTitle>Ready to get started with Financial Services?</CopyTitle>
                </CopyHeader>
                <CopyBody>
                  Schedule a demo and see the future of marketing technology.
                  Whether you end up using our out of the box or enterprise
                  product, you will minimize manual marketing and charge up your
                  strategy.
                </CopyBody>
                <CopyFooter>
                  <CopyFooterContainer>
                    <CtaButton
                      className='cta variant-link'
                      target="_blank"
                      href="/login.jsp"
                    >
                      Sign In
                    </CtaButton>
                    <CtaButton
                      className=' variant_button cta cta-arrow'
                      href={"/contact-us"}
                      background='#2460A7FF'
                    >
                      Contact sales &rarr;
                    </CtaButton>
                  </CopyFooterContainer>
                </CopyFooter>
              </Copy>
            </ColumnLayout>
          </SectionLayout>
        </SectionLayoutContainer>
      </SectionContainer>
    </SectionStarted>
  );
};

const Automated = () => {
  return (
    <Section className='Section'>
      <SectionMasked>
        <BackgroundMask>
          <SectionBackground>
            <Guides>
              <GuideContainer>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
              </GuideContainer>
            </Guides>
          </SectionBackground>
        </BackgroundMask>
      </SectionMasked>

      <SectionContainer>
        <SectionLayoutContainer>
          <SectionLayout className='smallPadding'>
            <RowLayout>
              <Copy className='variant--Section'>
                <CopyHeader>
                  <CopyTitle>The opportunities are endless</CopyTitle>
                  <CopySubtitle>Our brand-safe and compliance-friendly digital marketing solution includes:</CopySubtitle>
                </CopyHeader>
              </Copy>
                <FinancialTable />
            </RowLayout>
          </SectionLayout>
        </SectionLayoutContainer>
      </SectionContainer>
    </Section>
  );
};

const BigCards = () => {
  return (
    <Section className='Section'>
      <SectionMasked>
        <BackgroundMask>
          <SectionBackground>
            <Guides>
              <GuideContainer>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
              </GuideContainer>
            </Guides>
          </SectionBackground>
        </BackgroundMask>
      </SectionMasked>

      <SectionContainer>
        <SectionLayoutContainer>
          <SectionLayout className='smallPadding'>
            <ColumnLayout data-columns='2,2'>
              <RowLayout>
                <div className='testimonialsCard'>
                  <div className='Card-1' auto='auto'>
                    <div className='testimonialsContent'>
                      <div className='testimonialsBody'>
                        <h1>Dealer Experience</h1>
                        <p>
                          Dealers love easy to use tools. Give them the ability to control marketing from the palm of their hands via an app as simple to use as email. Simplify your marketing journey with Eulerity. Your dealers can start using the platform in under 15 minutes.
                        </p>
                      </div>
                      <div className='testimoniallogoContainer'>
                        <img src={LogoApp} className='UserLogo' alt='logo' />
                      </div>
                    </div>
                  </div>
                </div>
              </RowLayout>

              <RowLayout>
                <div className='testimonialsCard'>
                  <div className='Card-2 '>
                    <div className='testimonialsContent'>
                      <div className='testimonialsBody'>
                        <h1>Top Down Management Control</h1>
                        <p>
                          Control the dealer marketing experience. From spend flexibility, ad placement optionality, tap to touch targeting, all features are enabled by the agency.  Maintain full brand control while you enable dealers a seamless buying experience.
                        </p>
                      </div>
                      <div className='testimoniallogoContainer'>
                        <img
                          src={fccApp}
                          className='UserLogo'
                          alt='fccc logo'
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </RowLayout>
            </ColumnLayout>
          </SectionLayout>
        </SectionLayoutContainer>
      </SectionContainer>
    </Section>
  );
};
const Industry = () => {
  return (
    <Main>
      <Hero />
      <Payouts />
      <Compliance />
      <Automated />
      {/* <Global
        title='Use AI to optimize your local media spend'
        subtitle='Automatic media, creative, and audience selection based on performance & ROI'
        paraFirst='Give your dealers the diversification and reach they need, without the high cost of service'
        colorScheme={['#2460A7FF', '#85B3D1FF', '#B3C7D6FF']}
      />
      <BigCards />
      <Started /> */}
      <Footer />
    </Main>
  );
};

export default Industry;
