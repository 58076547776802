import React from 'react'
import QuickFixEpisode4 from '../../../assets/QuickFixEpisode4.png'
import { Author, EstimatedRead, Headers, Image, MediumArticleContainer, Paragraph, Title } from '../style'

const ManagingMarketingBudgetShifts = () => {
  return (
    <MediumArticleContainer>
        <Title>Managing Marketing Budget Shifts</Title>
        <Author>Featuring Nicole Salla, Chief Marketing Officer, Kiddie Academy &nbsp;
            <EstimatedRead>3 min read</EstimatedRead>
        </Author>

        <Image src={QuickFixEpisode4} alt='quickFixEpisode4' />

        <Paragraph>Eulerity’s new series, Quick Fix, features leaders who are looking to take a wrench to some of the industry’s biggest problems. We recently had Nicole Salla, CMO of Kiddie Academy, on our fourth episode where she spoke about managing marketing budget shifts. Having gained momentous insight, this article contains the best practices and key insights that Nicole shared all brands should have in their toolbox.</Paragraph>

        <Headers>First, let’s address current challenges.</Headers>
        <Paragraph>Change is hard — period. When shifting your marketing budget, you need to make sure you are adopting an investment-first mentality. Marketing itself is an investment, not a cost. Start shifting your vocabulary to make sure you’re treating it as such. The future continues to evolve and change as time goes on and none of us know what will happen, when or why. Plan for both the known and unknown. Run scenarios and anticipate outcomes. Start to understand where your focus should be. Remember, a new strategy or budget shift can change everything. You have contracts, partnerships, and approaches with inter-dependencies to consider. It’s crucial that you lay everything out in front of you and assess accordingly. Here are a few ways on how to do that.</Paragraph>

        <Headers>Prioritize your marketing dollars.</Headers>
        <Paragraph>First, know your ROI and how to calculate it. Every investment needs to be quantified. Think in terms of ROI for every dollar and minute spent. Next, determine where you need to prioritize paid efforts in the customer journey. You cannot do it all, even with a healthy marketing investment. Capitalize on organic/low-cost efforts. Can you reinvest staff focus instead of investing budget dollars? How can you make cost-neutral marketing easier for franchisees? It’s key that you understand how your ecosphere affects customer acquisition and expansion. Ask yourself, which investments have the best outcomes at each stage, and how would it be impacted with leaner direct/indirect investment? Remember to think outside of the box. Investment comes in many forms — time, staff, relationships, dollars/outside costs. Get creative in your thinking on how you invest in each of these.</Paragraph>

        <Headers>Use data to see what’s making an impact.</Headers>
        <Paragraph>You need to know where you data lives. Prepare now by mapping out your access to data, proprietary and public. Where is it stored? What story does it tell you? What gaps exist, and how can you fill them to better understand potential marketing impacts/outcomes? Identify and prioritize your KPIs to measure effectiveness. If you have done that, question if your focus would shift in a worst-case scenario of moderate to major budget shifts. It’s also incredibly important to understand your stakeholders’ focus. Make sure you are on the same page with key stakeholders on the data they use to measure success, trends, and likely outcomes. Not all data is owned data. Tap into your vendor partners to understand what they are learning about your customer behavior, trends, ROI, sentiment, outcomes, and more. Understand where you can get insights to help guide decisions outside of your owned data. Make sure to keep close tabs on your customer. Knowing customer sentiment is critical to anticipate your next steps. Invest in small, measurable tests before you invest widely and use the data gained to guide your decisions.</Paragraph>

        <Headers>Stay connected and offer stakeholders insight.</Headers>
        <Paragraph>Identify your advocates and detractors. Outline your stakeholders, especially amongst franchisee and colleague groups. Be sure to communicate! Get your partners and co-communicators on board and on the same page first. The larger the change, the more you may need to open the lines of communication. Enlist colleagues to reinforce and support messaging around the shift, including the “why” and “where we go from here.” You must guide your franchisees. Be transparent and provide information to your franchisees to set them up for success. Help them understand the goal, expected outcomes, and how to effectively build onto national efforts locally for the best confluent marketing approach. Share insights on the customer, industry and business environment.</Paragraph>

        <Headers>Key solutions and takeaways</Headers>
        <Paragraph>Be the expert on your customer! Now is the time to understand your customer so you can best invest the dollars and time you have available. Be available by finding ways to connect and communicate more with your stakeholders. Not only will it be of value to them, but also value to you. Remember, you are not alone. Keep a level of transparency with your vendors. Great vendors are your true partners and they will help give you outside perspective, as well as invest in your success.</Paragraph>

        <Paragraph>
            Watch the full episode <a href="https://www.youtube.com/watch?v=FnXszYI6LO8" target="_blank">here</a>
        </Paragraph>
    </MediumArticleContainer>
  )
}

export default ManagingMarketingBudgetShifts