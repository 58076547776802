import React, { useState, useEffect } from 'react';
import XponentialAgreementPDF from '../../assets/agreements/XponentialAgreement.pdf';
import { Document, Page, pdfjs } from 'react-pdf';
import { PDFContainer } from './style';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';


const XponentialAgreement  = () => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    const getWindowDimensions = () => {
        const { innerWidth: width, innerHeight: height } = window;
        return {
          width,
          height
        };
    }

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [numPages, setNumPages] = useState(null);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [])

    const resolution = () => {
        if (windowDimensions.width < 1040) return windowDimensions.width;
        return 1000;
    }

    const renderPages = () => {
        const pages = [];
        for (let i = 0; i < numPages; i++) {
            pages.push(<Page pageNumber={i + 1} width={resolution()} />)
        }
        return pages;
    }

    return (
        <PDFContainer>
            <Document
                file={XponentialAgreementPDF}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                <div>
                    {renderPages()}
                </div>
            </Document>
        </PDFContainer>
    )
}

export default XponentialAgreement;