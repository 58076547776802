import React, { useRef, Suspense } from 'react';
import './style.css';
import mapTexture from '../../assets/mapTexture.jpg';
import * as THREE from 'three';

import { Canvas, useFrame, extend, useLoader } from 'react-three-fiber';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

extend({ OrbitControls });

function Sphere(props) {
  // This reference will give us direct access to the mesh
  const mesh = useRef();

  const texture = useLoader(THREE.TextureLoader, mapTexture);
  // set the "color space" of the texture
  texture.encoding = THREE.sRGBEncoding;
  // reduce blurring at glancing angles
  texture.anisotropy = 16;

  // Rotate mesh every frame, this is outside of React without overhead
  useFrame(() => (mesh.current.rotation.y -= 0.001));

  return (
    <mesh {...props} ref={mesh}>
      <sphereGeometry args={[1.3, 32, 32]} attach='geometry' />
      <meshBasicMaterial
        attach='material'
        map={texture}
        color='#0384fc'
        transparent={true}
        opacity={1}
        side={THREE.FrontSide}
        depthWrite={false}
      />
    </mesh>
  );
}

const SphereGlobe = (props) => {console.log("@@@", props)
  return (
    <Canvas camera={{ position: [0, 0, 5] }}>
      <Suspense fallback={null}>
        <ambientLight position={[1, 1, 1]} />
        <pointLight position={[5, 5, 5]} />
        <Sphere position={[0, 0, 2]} />
      </Suspense>
    </Canvas>
  );
};

export default SphereGlobe;
