import React from 'react';
import {
  HeroSection,
  HeroContainer,
  HeroText,
  CommonTitle,
  CommonText,
  SubHeroSection,
  SubHeroContainer,
  BoxOne,
  Icon,
  BoxText,
  CompanySection,
  CompationContainer,
  Left,
  Right,
  Title,
  CompanyText,
  Images,
  Grid,
  GridOne,
  GridTwo,
  GridThree,
  Subtitle,
  Main
} from './style';
import Button from '../../../components/buttons/Button';
import Timeline from '../../../components/Products/timeline/Timeline';
import Footer from '../../../components/footer/Footer';
import Team from '../../../components/Products/team/Team';
import team from '../../../assets/team.png';
import team2 from '../../../assets/team2.JPG';

import euler from '../../../assets/euler.png';

const Hero = () => {
  return (
    <HeroSection>
      <HeroContainer>
        <div className='container-lg'>
          <HeroText>
            <CommonTitle>Our Company</CommonTitle>
            <CommonText>Where big ideas get nurtured</CommonText>
            <div className='btn'>
              <Button color='#fff' bg='#1ABF80' href={'/company/contenthub'}>
              Content Hub &rarr;
              </Button>
            </div>
          </HeroText>
        </div>
      </HeroContainer>
    </HeroSection>
  );
};

const SubHero = () => {
  return (
    <SubHeroSection>
      <SubHeroContainer>
        <BoxOne>
          <Icon>
            <i className='fas fa-home'></i>
          </Icon>
          <BoxText>Born and made with love in NYC</BoxText>
        </BoxOne>
        <BoxOne>
          <Icon>
            <i className='fas fa-cogs'></i>
          </Icon>
          <BoxText>More than 50% engineering</BoxText>
        </BoxOne>
        <BoxOne>
          <Icon>
            <i className='fas fa-bullseye'></i>
          </Icon>
          <BoxText>Founded by experts from Google, Facebook and Yahoo</BoxText>
        </BoxOne>
      </SubHeroContainer>
    </SubHeroSection>
  );
};

const Company = () => {
  return (
    <CompanySection>
      <div className='container-lg'>
        <CompationContainer>
          <Left className='left'>
            <Title>Why are we called “Eulerity”?</Title>
            <CompanyText>
              We named the company after Leonhard Euler, one of the most eminent
              mathematicians of the 18th century. He made many influential
              discoveries and introduced much of the mathematical terminology
              and notation we know today, like mathematical functions.
            </CompanyText>
            <CompanyText>
              We wanted to pay tribute to his contributions by alluding to our
              platform’s state-of-the-art technology and algorithms that allow
              people with no knowledge of digital marketing to run successful
              campaigns that drive business results.
            </CompanyText>
          </Left>
          <Right className='right'>
            <div className='image1'>
              <img src={euler} alt='euler' />
            </div>
          </Right>
        </CompationContainer>
      </div>
    </CompanySection>
  );
};

const CompanyTwo = () => {
  return (
    <CompationContainer>
      <div className='container-lg'>
        <CompationContainer>
          <Images>
            <div>
              <img src={team} alt={team} />
            </div>
            <div>
              <img src={team2} alt={team} />
            </div>
          </Images>

          <Grid>
            <Title>What makes us different?</Title>
            <GridOne>
              <Subtitle>Psychological safety</Subtitle>
              <CompanyText>
                First on the list, is safety. Our high performing teams needs an
                environment that feels judgment-free, so that everyone can share
                their thoughts without fear. Our team members feel valued, as
                their thoughts and input matter and contribute to the bigger
                picture, since effective teams actively solicit input and
                opinions from their members.
              </CompanyText>
            </GridOne>
            <GridTwo>
              <Subtitle>Dependability</Subtitle>
              <CompanyText>
                All our team members have roles and responsibilities that allow
                team-related work to be transparent. Each team member not only
                understands their own work and expectations but also what their
                fellow team members are working on. When concrete plans are in
                place, it is clear and easy to see what progress is being made
                and what next step the team is currently taking.
              </CompanyText>
            </GridTwo>
            <GridThree>
              <Subtitle>Impact and Gratitude</Subtitle>
              <CompanyText>
                Our team always has the larger vision of the team’s direct
                contribution to the organization’s larger, long-term goals to
                demonstrate the importance of their work. Our teams aren’t
                afraid to show gratitude and appreciation to everyone involved.
                By the end of any meeting, our high-performing teams understand
                how and what to do to achieve their goals.
              </CompanyText>
            </GridThree>
          </Grid>
        </CompationContainer>
      </div>
    </CompationContainer>
  );
};

const Time = () => {
  return (
    <div className='container-lg'>
      <Timeline />
    </div>
  );
};

// * Commented out in case we end up needing it in the future
// const LeaderShip = () => {
//   return (
//     <SectionLeader>
//       <div className='container-lg'>
//         <div className='kimIntro'>
//           <div className='kimIntro_title'>
//             <h2 className='commonTitle kim_title'>The Best and the Brightest</h2>
//           </div>
//           <div className='kimIntro_text'>
//             <p className='commmonIntroText kim_introText'>
//               Eulerity’s team brings together decades of experience in
//               technology, advertising services and marketing infrastructure.
//             </p>
//           </div>
//         </div>
//         <Team />
//       </div>
//     </SectionLeader>
//   );
// };

const AboutUs = () => {
  return (
    <Main>
      <Hero />
      <SubHero />
      <Company />
      <CompanyTwo />
      <Time />
      <Footer />
    </Main>
  );
};

export default AboutUs;
