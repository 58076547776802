import React from 'react';
import { ArticleContainer, Title, Author, Paragraph, Image, MediumArticleContainer, EstimatedRead, Headers, SideImageWithText,FloatedImage, BoldItalics } from './style';
import Stocks from '../../assets/stocks-graphic.jpeg';
import Arrow from '../../assets/up-arrow-graphic.jpeg';
import Idea from '../../assets/idea.png';
import Paas from '../../assets/paas.png';

const EmergenceOfCloudMarketing = () => {
    return (
        <MediumArticleContainer>
            <Title> The Emergence of the Cloud in Marketing </Title>
            <Author> by Adam Chandler, COO and Co-Founder, Eulerity &nbsp;
                <EstimatedRead> 1.3 min read </EstimatedRead>
            </Author>

            <Paragraph>
                One of the biggest trends of the last decade was the emergence of the cloud infrastructure that people and businesses have come to fully rely on. When businesses had to pivot to working from home, the rise of virtual work environments, shared folders and drives automatically skyrocketed. Offices that once stored file cabinets worth of work and information are now hosting it somewhere else — the cloud.
            </Paragraph>

            <Headers>
                What the cloud includes
            </Headers>

            <Paragraph>
                When you are using a cloud-based system, you are essentially sharing and storing your information to an internal infrastructure that delivers different services. This includes data management, servers, software, databases and data storage.
            </Paragraph>

            <Paragraph>
                Aside from having everything in one place, when your business relies on cloud storage, you are offering your employees to save their data and work all in one place, which is incredibly convenient and also quick. Along with the cloud comes all its vast services, including email, data analysis, audio and video streaming, and more. You also have both public and private cloud offerings, and even hybrids. The most popular and efficient type of cloud computing is that of Software-as-a-Service (SaaS). This involves the “licensing of a software application to <a href="https://www.investopedia.com/terms/c/cloud-computing.asp" target="_blank">customers.</a>” These are usually on-demand or pay-as-you-go models.
            </Paragraph>

            <Headers> How it affects my marketing </Headers>

            <Paragraph>
                As marketing has gotten more complex, traditional marketing partners need to make the evolution to cloud-based marketing automation to simply handle the rate of change and sheer amount of data a robust marketing platform will need to handle. The good news is, the offerings of the cloud could substantially improve your business and increase your revenue. First, its wherever, whenever approach offers flexibility and assurance, as people can access and use its services from any device anywhere and anytime. The utilization of the cloud can cut your spending on IT services and storage by an incredible percentage. If your brand requires customers to make appointments, reservations or meetings, cloud services can automate all of that — the scheduling, management of company and personal calendars, and automation of registration and sign-ups.
            </Paragraph>

            <Paragraph>
                In all, the cloud and its services are something you should certainly consider when operating a growing business. The positive effects it can have on your employees, customers and overall brand are significant. The adoption of a SaaS partner is also something to consider when trying to run automated marketing campaigns, advertisements promotions and more.
            </Paragraph>

            <Paragraph>
                <a href="https://www.eulerity.com"> Learn More About Eulerity </a>
            </Paragraph>
        </MediumArticleContainer>
    )
}

export default EmergenceOfCloudMarketing;