import React from 'react';
import { MediumArticleContainer, Title, Author, Paragraph, EstimatedRead, Headers, BoldItalics } from './style';

const FourKeysToDigitalMarketing = () => {
    return (
        <MediumArticleContainer>
            <Title> 4 Keys to Building Digital Marketing During Omicron Shutdowns </Title>
            <Author> By Mary Hanula, Director of Marketing, Eulerity 
                <EstimatedRead> 3 min read </EstimatedRead>
            </Author>

            <Headers> Restaurants must remain relevant, even amid temporary closings. </Headers>

            <Paragraph>
                The recent rise and rapid transmissibility of COVID-19’s Omicron variant has instantly put restaurant owners back in hot water. For some locations, in-person dining was only recently brought back into the playing field, along with the option for group dining and regular operating hours. As businesses plan a quick response and prepare for a possible shut-down, here are four digital marketing strategies to help amplify your business outreach, presence and production.
            </Paragraph>

            <Headers> 1. Make sure your advertising is up to date, accurate, and reaching the right audience. </Headers>

            <Paragraph>
                With the new variant, your location may very well have to shift back to off-premise dining, or worst case scenario, shut down indefinitely. If you are able to offer your customers a drive-thru or carryout option during this time, make sure that they are not only aware of this capability, but also exposed to the appeal of it. Take a look at your online analytics. What demographic are you currently targeting? Are you advertising on a platform where people are spending most of their time? As people prepare for the threat that the Omicron variant presents, they may be spending more time on social media and websites and less time actually driving by your business. If you have room in your budget to allocate towards your ad spend, now is the time to do it. Reach them where they are. Certain marketing automation gives you the capability to see what gender, age and geo-targeting demographics you’re reaching and what avenues you’re doing it on. Leverage that. Advertise your takeout menu, drive-thru safety precautions, and ability to serve your customers in a way they feel safe. It’s not enough to simply market your product right now — market the steps you’re taking to ensure the safety and happiness of your consumer base.
            </Paragraph>

            <Headers>
                2. Be present, even if you can’t be in person.
            </Headers>

            <Paragraph>
                If your business has to temporarily close due to the Omicron variant, you still have the ability to stay top of mind with your customers and local community. Utilize your online posting, whether that is Facebook or Instagram, and relay what is going on. If possible, reach out to local customers and community-based organizations that you work with and start an online campaign that gives you the opportunity to interact with people. What menu items are they missing the most? What is one favorite memory they have of your restaurant while they’re waiting for it to re-open? A strong consumer base and community connection will take you far once restrictions are lifted, and being able to be transparent and in touch with your consumers will go a long way.
            </Paragraph>

            <Paragraph>
                Maintain consistency. It may sound crazy, but one of the biggest challenges this past year was the deterioration of a location’s digital presence. Last March, brands that decided to shut down or eliminate online advertising completely fared much worse than those who chose to keep it.
            </Paragraph>

            <Paragraph>
                Why? Well, it is crucial to note that you don’t have to eliminate your digital advertising completely during unprecedented times, and instead see it as an opportunity to stay online and in front of customers, just on a different level. Perhaps this means you pause your campaigns, or simply shift your budget to fit what you can offer at the moment. Instead of cancelling your digital marketing vendor partner or subscription completely, try rearranging your campaigns. That’s one of the many pros of marketing automation and 24/7 online presence. Always-on advertising gives you the ability to have control of your advertising whenever you want through subscription-based advertising services.
            </Paragraph>

            <Headers>
                3. Have a loyalty rewards program? Kick it into high gear. 
            </Headers>

            <Paragraph>
                One way to really interact with your customers and keep them coming back is to utilize one of the basics — your loyalty rewards program. What menu items or experiences have you offered as a reward in the past? Engage with your most loyal customers and offer a free treat or meal based on how many deliveries they order. For those that have been with your business for a long time, see what rewards you have that will get them discounts. Incentivise your customers to drive them to order and interact with your location more, no matter where they are.
            </Paragraph>

            <Headers>
                4. Create an at-home challenge. 
            </Headers>

            <Paragraph>
                Ever try to perfect your favorite restaurant’s recipe? With more time at home trying to stay safe, customers are able to cook their own meals. Offer up a challenge for the best-looking burger, most impressive mock dining experience or something engaging that will not only give customers a way to interact with and market your brand, but dedicate time and energy back into your business. Winners can receive meals and/or gift-cards for future use. You’re giving them an incentive to visit and come back to your business, not to mention the free marketing contestants will be doing for you when competing.
            </Paragraph>

            <Paragraph>
                The COVID-19 pandemic has been one of the most influential and up-ending events in the past century. Business owners continue to face challenges and changes each day. We hope that these digital marketing strategies will help you and your business.
            </Paragraph>
            

            <BoldItalics>
                *Article originally published in <a href="https://www.qsrmagazine.com/outside-insights/4-keys-building-digital-marketing-during-omicron-shutdowns">QSR Magazine</a>.
            </BoldItalics>
        </MediumArticleContainer>
    )
}

export default FourKeysToDigitalMarketing;