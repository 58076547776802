import React from "react";
import {
  DropdownSection,
  DropdownBody,
  DropdownMenuLayout,
  ProductList,
  ProductListTitle,
  ProductLists,
  ProductListItem,
  ProductListItemLink,
} from "./style";
// import { Link } from 'react-router-dom';
// Heading, LinkList
import styled from "styled-components";
import {ReactComponent as NewsLogo} from "../../../assets/customer-logo/cashbook.svg";
import {ReactComponent as CompanyLogo} from "../../../assets/customer-logo/standard.svg";
import {ReactComponent as Case} from "../../../assets/customer-logo/friction.svg";
import {ReactComponent as Wave} from "../../../assets/customer-logo/wave.svg";
const CompanyDropdownEl = styled.div`
  width: 45rem;
`;
const CompanyDropdown = () => {
  return (
    <CompanyDropdownEl>
      <DropdownSection data-first-dropdown-section>
        <DropdownBody>
          <DropdownMenuLayout>
            <ProductList>
              <ProductListTitle href="/company/about-us">
                <CompanyLogo className="logo" />
                About Us
              </ProductListTitle>
              <ProductLists>
                <ProductListItem>
                  <ProductListItemLink
                    href="/company/about-us"
                    className="deux"
                  >
                    Learn more on how we got started and what makes us tick
                  </ProductListItemLink>
                </ProductListItem>
              </ProductLists>
            </ProductList>
            <ProductList>
              <ProductListTitle href="/company/contenthub">
                <NewsLogo className="logo" />
                Content Hub
              </ProductListTitle>
              <ProductLists>
                <ProductListItem>
                  <ProductListItemLink
                    href="/company/contenthub"
                    // className="deux"
                  >
                    Resources, articles, press
                  </ProductListItemLink>
                </ProductListItem>
              </ProductLists>
            </ProductList>
            <ProductList>
              <ProductListTitle href="/company/case-studies">
                <Case className="logo" />
                CASE STUDIES
              </ProductListTitle>
              <ProductLists>
                <ProductListItem>
                  <ProductListItemLink
                    href="/company/case-studies"
                    className="deux"
                  >
                    Read how Eulerity has helped our clients solve their
                    marketing needs
                  </ProductListItemLink>
                </ProductListItem>
              </ProductLists>
            </ProductList>

            <ProductList>
              <ProductListTitle href="/company/podcasts">
                <Wave className="logo" />
                Podcasts
              </ProductListTitle>
              <ProductLists>
                <ProductListItem>
                  <ProductListItemLink
                    href="/company/podcasts"
                    className="deux"
                  >
                    Listen to our chats with industry leaders and experts
                  </ProductListItemLink>
                </ProductListItem>
              </ProductLists>
            </ProductList>
          </DropdownMenuLayout>
        </DropdownBody>
      </DropdownSection>
    </CompanyDropdownEl>
  );
};

export default CompanyDropdown;
