import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;

    img {
        all: revert;
        width: 100vw;
        margin-bottom: 50px;
    }
`;