import React, { Component } from "react";
import "./team.css";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import david from "../../../assets/person/david.JPG";
import tanuj from "../../../assets/person/tanuj.jpg";
import adam from "../../../assets/person/adam.jpg";
import joe from "../../../assets/person/joe.jpg";
import haleybrown from "../../../assets/person/haleybrown.jpg";
import josie from "../../../assets/person/josie.jpg";
import julie from "../../../assets/person/julie.jpg";
import mary from "../../../assets/person/mary.jpg";
import nicole from "../../../assets/person/nicole.jpg";
import alex from "../../../assets/person/alex.jpg";
import jake from "../../../assets/person/jake.jpg";
import bharati from "../../../assets/person/bharati.jpg";

const teams = [
  {
    name: "Tanuj Joshi",
    img: tanuj,
    role: "Co-founder and CEO",
    link: "https://www.linkedin.com/in/tajoshi/",
    id: 15,
  },
  {
    name: "Adam Chandler",
    img: adam,
    role: "COO & Co-Founder of Eulerity",
    link: "https://www.linkedin.com/in/adamianchandler/",
    id: 1,
  },

  {
    name: "Joe Ciaramitaro",
    img: joe,
    role: "Co-founder and CTO",
    link: "https://www.linkedin.com/in/giuseppeciaramitaro/",
    id: 8,
  },

  {
    name: "Haley Brown",
    img: haleybrown,
    role: "Lead Software Engineer",
    link: "https://www.linkedin.com/in/haley-aline-brown/",
    id: 5,
  },
  {
    name: "Julie Samuels",
    img: julie,
    role: "Senior Director of Growth",
    link: "https://www.linkedin.com/in/juliesamuelsnyc/",
    id: 10,
  },

  {
    name: "Alex Rodrigues Lourenco",
    img: alex,
    role: " Lead Software Developer",
    link: "https://www.linkedin.com/in/alexrodrigueslourenco/",
    id: 2,
  },

  {
    name: "Bharati Pawar",
    role: "Digital Marketing support executive",
    link: "https://www.linkedin.com/in/bharati-pawar-489874111/",
    img: bharati,
    id: 3,
  },

  {
    name: "David Kim",
    role: " Account Manager",
    img: david,
    link: "https://www.linkedin.com/in/david-kim-25240318a/",
    id: 4,
  },
  {
    name: "Josie Thompson",
    img: josie,
    role: "UX Designer",
    link: "https://www.linkedin.com/in/josie-thompson-102191185/",
    id: 9,
  },
  {
    name: "Nicole Gutierrez",
    img: nicole,
    role: "Junior Web Developer",
    link: "https://www.linkedin.com/in/nicolegutier/",
    id: 12,
  },
  {
    name: "Mary Hanula",
    img: mary,
    role: "Director of Marketing",
    link: "https://www.linkedin.com/in/mary-hanula1/",
    id: 16,
  },
  {
    name: "Jake Fishman",
    img: jake,
    role: "Account Director of Growth",
    link: "https://www.linkedin.com/in/jake-fishman-24118ab4/",
    id: 17,
  },
];

class Team extends Component {
  state = {
    teams: teams.map((team) => (
      <div className='profile-box' key={team.id}>
        <img src={team.img} alt='profile pic' />
        <h3>{team.name}</h3>
        <h4>{team.role}</h4>
        <a href={team.link}>
          <i className='fab fa-linkedin'></i>
        </a>
      </div>
    )),
  };

  responsive = {
    0: { items: 1 },
    880: { items: 2 },
    1024: { items: 3 },
  };
  render() {
    return (
      <div className='page-wrapper'>
        <AliceCarousel
          dotsDisabled={false}
          buttonsDisabled={true}
          items={this.state.teams}
          autoPlayInterval={2000}
          autoPlayDirection='ltr'
          autoPlay
          animationDuration={1000}
          animationType='fadeout'
          infinite
          mouseTrackingEnabled={true}
          responsive={this.responsive}
          disableAutoPlayOnAction={true}
          ref={(el) => (this.Carousel = el)}
        />
      </div>
    );
  }
}

export default Team;
