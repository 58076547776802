import React from 'react';
import { HeaderNav, HeaderNavList } from './style';

const Navbar = ({ children, onMouseLeave }) => {
  return (
    <HeaderNav onMouseLeave={onMouseLeave}>
      <HeaderNavList>{children}</HeaderNavList>
    </HeaderNav>
  )
}

export default Navbar;
