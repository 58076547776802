import React from 'react';
import './style.css';
const Footer = () => {
  return (
    <footer>
      <div className='globalFooterNav'>
        <div className='container-lg'>
          <ul className='metaNav'>
            <li className='country'>
              <a href='/'>United States</a>
            </li>
            <li className='space'></li>
            <li className='copyright'>&copy; Eulerity</li>
          </ul>

          <div className='siteNav'>
            <div className='column'>
              <h4>Products</h4>
              <div className='splitColumn'>
                <ul>
                  <li>
                    <a href='/productone'>Panacea</a>
                  </li>
                  <li>
                    <a href='/producttwo'>Titan</a>
                  </li>
                  <li>
                    <a href='/productthree'>Dolos</a>
                  </li>
                  <li>
                    <a href='/productfour'>Metis</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className='column'>
              <h4>Use cases</h4>
              <div className='splitColumn'>
                <ul>
                  <li>
                    <a href='/franchise/industry'>For Franchise </a>
                  </li>
                  <li>
                    <a href='/franchise/entreprise'>For Enterprise </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className='column'>
              <h4>Company</h4>
              <div className='splitColumn'>
                <ul>
                  <li>
                    <a href='#/about-us'>About us </a>
                  </li>
                  <li>
                    <a href='/jobs'>Jobs</a>
                  </li>
                  <li>
                    <a href='/contenthub'>Content Hub</a>
                  </li>
                  <li>
                    <a href='/privacy'>Privacy Policy</a>
                  </li>
                  <li>
                    <a href='/tos'>Terms of Service </a>
                  </li>
                  <li>
                    <a href='/msa'>MSA </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className='column'>
              <h4>Socials</h4>
              <div className='splitColumn'>
                <ul>
                  <li>
                    <a href='/franchise/industry'>
                      <i className='fab fa-facebook'></i>
                      Facebook
                    </a>
                  </li>
                  <li>
                    <a href='/franchise/entreprise'>
                      <i className='fab fa-twitter'></i>
                      Twitter
                    </a>
                  </li>
                  <li>
                    <a href='/franchise/entreprise'>
                      <i className='fab fa-linkedin'></i>
                      Linkedin
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
