import React from 'react';
import {
    DropdownSection,
    DropdownBody,
    DropdownMenuLayout,
    ProductList,
    ProductListTitle,
    ProductLists,
    ProductListItem,
    ProductListItemLink,
  } from "./style";
import { ReactComponent as Special } from '../../../assets/customer-logo/special.svg';
import styled from 'styled-components';

const CompanyDropdownEl = styled.div`
  width: 20rem;
`;

const CareersDropdown = () => {
  return (
    <CompanyDropdownEl>
        <DropdownSection data-first-dropdown-section>
            <DropdownBody>
                <DropdownMenuLayout>
                    <ProductList>
                        <ProductListTitle target='_blank' href='https://boards.greenhouse.io/eulerity'>
                            <Special className='logo'/>
                            Careers
                        </ProductListTitle>
                        <ProductLists>
                            <ProductListItem>
                                <ProductListItemLink
                                    href='https://boards.greenhouse.io/eulerity'
                                    target='_blank'
                                >
                                    Explore career opportunities at Eulerity
                                </ProductListItemLink>
                            </ProductListItem>
                        </ProductLists>
                    </ProductList>
                </DropdownMenuLayout>
            </DropdownBody>
        </DropdownSection>
    </CompanyDropdownEl>
  )
}

export default CareersDropdown