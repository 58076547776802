import React, { useRef } from 'react';
import HandAndStone from '../../../../assets/industry/HandAndStone.png';
import AceHandyMan from '../../../../assets/industry/AceHandyMan.png';
import BloDryBar from '../../../../assets/industry/BloDryBar.png';
import { StyledCustomerCard, StyledCustomerCarousel, ChevronWrapper } from './styles'
import AliceCarousel from 'react-alice-carousel';
import { ReactComponent as LeftChevron } from '../../../../assets/LeftChevron.svg';
import { ReactComponent as RightChevron } from '../../../../assets/RightChevron.svg';


const CustomersCarousel = () => {
    const carousel = useRef(null);

    const HandAndStoneCard = () => {
        return (
            <StyledCustomerCard>
                <div className='content-container'>
                    <p className='quote'>“Before Eulerity, we were overwhelmed with our approach to making organic and paid advertising work seamlessly together to support our growing franchise system. We knew that as we scaled, we would need to consolidate.</p>
                    <p className='quote'>Eulerity gave us that option by allowing us to manage our paid and organic marketing in one place. Their team has been incredibly responsive, has built custom product features for us, and we look to expand our relationship with Eulerity long-term.”</p>
                    <p>-Eric Haberacker, Social Media Director</p>
                    <p className='company-name'>Hand and Stone Massage and Facial Spa</p>
                    <img className='hand-and-stone' src={HandAndStone} />
                </div>
            </StyledCustomerCard>
        )
    }

    const AceHandyManCard = () => {
        return (
            <StyledCustomerCard>
                <div className='content-container'>
                    <p className='quote'>“Having worked with many brands and suppliers, Eulerity stands out as a company that not only provides a leading technology experience but backs it up with a team that delivers high quality results with the transparency we need.</p>
                    <p className='quote'>The Eulerity team listens to our needs, understands our business, and comes to us with innovative ideas to advance our marketing and measure the effectiveness of our advertising investment.”</p>
                    <p>-Eddy Witty, Chief Marketing Officer</p>
                    <p className='company-name'>Ace Handyman Services</p>
                    <img className='ace-handy-man' src={AceHandyMan} />
                </div>
            </StyledCustomerCard>
        )
    }

    const BlowDryBarCard = () => {
        return (
            <StyledCustomerCard>
                <div className='content-container'>
                    <p className='quote'>“We needed a consistent system to manage all marketing in one place - one that supported our corporate team and franchise partner’s needs. Eulerity’s end-to-end marketing tech not only gave us the customizability we needed, but also helped our local markets generate customers and revenue. All of our creative, placements, and metrics are uniform and automated which saves our teams so much time and energy. We love it!”</p>
                    <p>-Shauna Stalker, VP of Marketing</p>
                    <p className='company-name'>Blo Blow Dry Bar</p>
                    <img className='blow-dry-bar' src={BloDryBar} />
                </div>
            </StyledCustomerCard>
        )
    }

    const items = [
        <HandAndStoneCard />,
        <AceHandyManCard />,
        <BlowDryBarCard />
    ]


    return (
        <StyledCustomerCarousel>
            <AliceCarousel
                items={items}
                infinite
                disableButtonsControls
                disableDotsControls
                mouseTracking={false}
                key='carousel'
                ref={carousel}
            />
            <div className='btn__container'>
                <ChevronWrapper onClick={(e) => carousel.current.slidePrev(e)}>
                    <LeftChevron />
                </ChevronWrapper>
                <ChevronWrapper onClick={(e) => carousel.current.slideNext(e)}>
                    <RightChevron />
                </ChevronWrapper>
            </div>
        </StyledCustomerCarousel>
    )
}

export default CustomersCarousel