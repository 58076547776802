import React, { useState, useEffect } from 'react';
import './app.css';
import { useHistory } from 'react-router-dom';
import Routes from './routes/Routes';
//gta tracking
import { initializeGTM, gtmtrackPageView } from './gtm/Gtm';
////ga tracking
import { trackPageView, initializeGA } from './tracking/Tracking';

initializeGA();
initializeGTM();

function App() {
  const [initialLoad, setInitialLoad] = useState(false);
  const history = useHistory();

  useEffect(() => {
    trackPageView(history);
    gtmtrackPageView(history);
  }, [history]);

  return (
    <div className='app'>
      <Routes
        initialLoadProp={initialLoad}
        setInitialLoadProp={() => setInitialLoad(true)}
      />
    </div>
  );
}

export default App;
