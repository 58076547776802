import React from 'react'
import { EstimatedRead, MediumArticleContainer, Headers, Paragraph, Title, Author, BoldItalics } from '../style';


const HowDataTransparencyCanBoostFranchisees = () => {
  return (
    <MediumArticleContainer>
        <Title>How Data Transparency Can Boost Franchisees</Title>
        <Author>Featuring Scott Greenberg, Author, Franchise Expert, and TEDx Keynote Speaker &nbsp;
            <EstimatedRead>3 min read</EstimatedRead>
        </Author>

        <Paragraph>Let’s start with a quote from Scott’s book, <BoldItalics>The Wealthy Franchisee</BoldItalics>. “Lead your team with empathy. Empathy is less about what you do, and more about what you don’t do. Empathy is not giving advice. It’s not about fixing people’s problems. Mostly, it’s just about being there.” </Paragraph>

        <Headers>Embracing Change</Headers>
        <Paragraph>One of the biggest challenges in any business is introducing change. In the franchise industry, a great example of this is when franchisors introduce a new change to their franchisee, who may resist and push back. The primary tool that franchisors use is data, which is why we have the term of data transparency. They tell franchisees what the change is and their reasons for it through data story-telling. However, since this involves human beings and dedicated business owners, data may not be enough. Sometimes the data doesn’t tell the entire story, which is why we need to also be looking at behavior.</Paragraph>

        <Headers>The Three Key Values for Influencing Leaders</Headers>
        <Paragraph>Using data to help steer the direction and guide change is great, but data alone is not enough to change behavior. You’ll need three key values that go all the way back to Aristotle and 350 B.C., where he talked about the rhetorical triangle. The first key concept is logos – logic, reason, the information we need. That’s the data itself. This may be the franchisor saying that they’re making a change and providing the statistics and reasons why. That’s not enough, because you also need ethos — ethics! Ethos brings you credibility. It refers to the character of the speaker or the organization, in other words, the voice you hear in your head saying “why should I trust you, why should I believe you?” The power of ethos is large and makes you pay closer attention. For a franchisor, this may be them saying that they have done the experiments in our company-owned store, we’ve looked at other locations, and we’ve experimented in some stores. Over time, hopefully they’ve built trust with their franchisees and they’ve built credibility. If the trust isn’t there, then franchisees will doubt the statistics.</Paragraph>
        <Paragraph>The third and most important value is pathos, which is emotion. It’s tapping into the way people feel — if we can get people to feel something, we can get people to do something. On any given day, emotion is going to trump data because we, as humans, are emotional in nature. Think of a car commercial — perhaps you forget the brand or name of the car, but you may remember the beautiful, shiny car itself pulling up on a wet street in front of a restaurant filled with loved ones ready for an intimate dinner together. A way in which a franchisor can evoke pathos is by telling people about all the opportunities and money that is on the table that is within reach if you embrace the new initiative. Or, they could instill a sense of fear by saying here’s what’s going to happen if we don’t change.</Paragraph>
        <Paragraph>All three of these values work together for you to be as persuasive as possible. Some people in the franchise industry can get this better than others.</Paragraph>

        <Headers>Don’t Dismiss Emotions</Headers>
        <Paragraph>Some franchisees may not care about any of the values and just want the data, but even those people are still using some sort of emotion and that will depend on what those numbers make them feel. Do they feel successful, do they feel pushed into something, etc. This resistance happens on a regular basis. We have emotional needs, but some people are hesitant to acknowledge or ask for them. We all need something emotional in nature — security or happiness even. When a franchisee wants you to show them statistics, they may just want reassurance to emotionally be okay with making a huge new investment that involves a bigger amount of time and investment.</Paragraph>
        <Paragraph>Franchisors need to provide data, trust, and reassurance by identifying emotional needs of their franchisees. When the three key values of the rhetorical triangle are used effectively, the communication and encouragement that franchisees feel will only build with time and create stellar new efforts.</Paragraph>

        <Paragraph>
            Watch the full episode <a href="https://www.youtube.com/watch?v=KPdQtWXSpcE" target="_target">here</a>. Make sure to <a href="https://us02web.zoom.us/webinar/register/7616606773963/WN_vgAA8cEcQhGPL96mpv24xQ" target="_target">sign-up for live leadership conversations</a> on Eulerity’s Quick Fix series.
        </Paragraph>
    </MediumArticleContainer>
  )
}

export default HowDataTransparencyCanBoostFranchisees