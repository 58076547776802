import React from 'react'
import { MediumArticleContainer, Title, Author, EstimatedRead, Paragraph, Image} from '../style'
import QuickFixEpisode7 from '../../../assets/QuickFixEpisode7.png'

const HowToUseTechToAccelBrandGrowth = () => {
  return (
    <MediumArticleContainer>
        <Title>How to Use Technology to Accelerate Brand Growth</Title>
        <Author>Featuring Heather Anderson, VP of Operations & Enablement, Eulerity &nbsp;
            <EstimatedRead>3 min read</EstimatedRead>
        </Author>

        <Image src={QuickFixEpisode7} alt='img' />

        <Paragraph>So, you want to grow your brand. What better way to do so than using technology? The ways in which you can use technology to your advantage to accelerate success for your company are endless. Here are some of those ways, along with why they are key to your brand’s future.</Paragraph>

        <Paragraph>The “why” part of implementing technology into your marketing strategy is easy. For prospective franchisees, tech tools are a top value in terms of FranDev and the franchisor support model. Thoughtful and impressive use of technology provides franchisors with a longer list of key differentiators outside of core business, however it’s important to note that there are no silver bullets. Not all marketing tactics are created equal. For HQ teams, it’s a constant quest to find and constantly assess to ensure you’re curating the most effective mix of in-house and outsourced support to make business and life easier for all parties involved. This includes customers, franchisees, and HQ.</Paragraph>

        <Paragraph>The “why not” is a bit more complicated. Challenges to introducing and integrating new technology include a learning curve and time required for adoption. It’s also important to think of the bandwidth of your team, as well as your franchisees, and if there is limited space for that. Think of all the hats they are currently wearing — would this one be a good one to add? Technology should remove operational burden at the local level, not add to it. Owners and teams need to be focused on building relationships and fine-tuning local level operations, not opening multiple different tech tools each day.</Paragraph>

        <Paragraph>Thankfully, there were silver linings from the pandemic for the case of technology accelerating brand growth. Throughout the uncertain time, there was a need-state created that fast-tracked technology adoption to lightning speed. Home office teams and franchisees saw just how fast they could move when needed. If certain aspects of a strategy took too long or were too complicated, they were marked as not performing at a sustainable pace and inducing burnout/ Recovery from the whiplash has put us somewhere between the marathon and the sprint.</Paragraph>

        <Paragraph>How does one take the current state of technology and make the case for adoption? Look for partners that take a crawl, walk, run approach to help team members and franchisees with adoption. This means having a mix between launch, test, and adapt versus the long-game plan to launch. Proof of concept or pilots are often required to gain adoption. It’s important to lean more into certain facets of the business. This includes understanding your ability to influence the technology roadmap. When you’re assessing a new tech partner, it’s important to understand what role customer feedback plays in driving their product roadmap, and how fast they move.</Paragraph>

        <Paragraph>Don’t be afraid to voice your business needs. One of the great things about the franchising community is that we all have common shared challenges, so what’s impacting one is likely impacting others. This is not to say that the company will always pivot when needed, but that there needs to be a balance between customer feedback that benefits the masses, industry trends, and industry-necessary updates. There’s an old saying that goes, “opportunities are what you make of them.” This couldn’t be more true in business. If you’re looking for problems, you’ll find them everywhere. But, if you’re truly willing to look for opportunities for enhancement, you’ll be able to recognize them everywhere you look. That’s exactly what makes Eulerity so special — the team is constantly looking for new and better ways to serve clients.</Paragraph>

        <Paragraph>Remember to look for multi-use tools to scale multiple business operations. As mentioned before, technology should remove operational burden at both the HQ and the local level, not add to it. Owners need to be focused on building relationships and fine-tuning local level operations, not opening seven different tech tools each day. Think of the consumer — both locally and nationally. Use employer marketing and recruitment to fill consumer demand and lean into your franchise development. Don’t forget — utilize AI and Machine Learning. Look for companies that use both to enhance their solutions.</Paragraph>

        <Paragraph>
            Watch the full episode <a href="https://www.youtube.com/watch?v=xDz7MzcUTNg" target="_blank">here</a>. Make sure to <a href="https://us02web.zoom.us/webinar/register/7616606773963/WN_vgAA8cEcQhGPL96mpv24xQ" target="_blank">sign-up for live leadership conversations</a> on Eulerity's Quick Fix series.
        </Paragraph>
    </MediumArticleContainer>
  )
}

export default HowToUseTechToAccelBrandGrowth