import React from 'react';
import Footer from '../../components/footer/Footer';
import {
  PrivacyTitle,
  Intro,
  IntroTitle,
  IntroText,
  TableItem,
  BasicTextBox
} from './style';
const DataProcessing = () => {
  return (
    <>
      <Intro>
        <PrivacyTitle>Data Processing Terms</PrivacyTitle>
        <IntroText>
          Where you are in the European Union or Switzerland, you acknowledge
          that your use of certain Eulerity Products may involve sending
          Personal Data (as defined below) to Eulerity. To the extent that we
          process such data as your Processor (as defined below) these Data
          Processing Terms apply in addition to the applicable Product terms,
          such as the Eulerity Business Tools Terms and the Custom Audience
          Terms (“Applicable Product Terms”). These Processing Terms shall take
          precedence in the event of any conflict with the Applicable Terms.
        </IntroText>
        <IntroText>Eulerity and you agree to the following:</IntroText>
        <BasicTextBox>
          <IntroTitle>1.Data Processing Terms</IntroTitle>
          <IntroText>
            <strong>1.Eulerity shall:</strong>
          </IntroText>
          <TableItem>
            only process Personal Data in accordance with the Applicable Product
            Terms;
          </TableItem>
          <TableItem>
            implement appropriate technical and organizational measures to
            protect the Personal Data;
          </TableItem>
          <TableItem>
            assist you by appropriate technical and organizational measures
            insofar as this is possible (taking into account the nature of the
            processing) to enable you to fulfill any obligations to respond to
            requests for the exercise of data subject rights by a data subject
            under GDPR;
          </TableItem>
          <TableItem>
            assist you in ensuring compliance with your obligations pursuant to
            Articles 32 to 36 of the GDPR, taking into account the nature of the
            processing and the information available to Eulerity;
          </TableItem>
          <TableItem>
            on termination of the Applicable Product Terms, delete the Personal
            Data as soon as reasonably practicable and within a maximum period
            of 180 days unless EU or EU Member State law requires further
            storage, provided however that Eulerity may keep the Personal Data
            if necessary to provide other services set forth in the Applicable
            Product Terms;
          </TableItem>
          <TableItem>
            make available to you all information that is reasonably necessary
            to demonstrate Eulerity’s compliance with its legal obligations as a
            Data Processor under Article 28 of the GDPR; and
          </TableItem>
          <TableItem>
            on an annual basis, procure that a third party auditor conducts a
            SOC 2 Type II audit relating to the Data Processing services or
            another industry standard audit as may be deemed appropriate by
            Eulerity as part of Eulerity’s audit programs. At your request, up
            to a maximum of once per year, Eulerity will provide you with a copy
            of its then-current audit report and such report will be deemed
            Eulerity’s confidential information.
          </TableItem>
          <IntroText>
            <strong>
              2.You agree that Eulerity may subcontract its data processing
              obligations under these Data Processing Terms to a subprocessor,
              but only by way of a written agreement with the sub-processor
              which imposes obligations on the sub-processor no less onerous
              than as are imposed on Eulerity under these Data Processing Terms.
              Where the sub-processor fails to fulfil such obligations, Eulerity
              shall remain fully liable to you for the performance of that
              sub-processor’s obligations. You hereby authorize Eulerity to
              engage Eulerity Inc. (and other Eulerity Companies) as its
              sub-processor(s). Eulerity shall notify you of any additional
              sub-processor(s) in advance. If you reasonably object to such
              additional sub-processor(s), you may inform Eulerity in writing of
              the reasons for your objections. If you object to such additional
              subprocessor(s), you should stop using the Services and providing
              data to Eulerity.
            </strong>
          </IntroText>
          <IntroText>
            <strong>
              3.Eulerity shall notify you without undue delay of the discovery
              by Eulerity of any actual or suspected Personal Data Breach
              involving the Personal Data. Such notice shall include, at the
              time of notification or as soon as possible after notification,
              details of the nature of the breach and number of records
              affected, the category and approximate number of affected data
              subjects, anticipated consequences of the breach and any actual or
              proposed remedies for mitigating the possible adverse effects of
              the breach.
            </strong>
          </IntroText>
          <IntroText>
            <strong>
              4.Eulerity, Inc. has made commitments under the EU-U.S. Privacy
              Shield and Swiss-U.S. Privacy Shield that may apply to data
              transferred by you or Eulerity Ireland Limited to Eulerity, Inc.
              under the Applicable Product Terms. When applicable as the means
              to transfer Personal Data outside of the EU or Switzerland to
              Eulerity, Inc. where you are in the European Union or Switzerland,
              you acknowledge that the Privacy Shield Terms apply to such data
              in addition to the Applicable Product Terms.
            </strong>
          </IntroText>
          <IntroText>
            <strong>
              5.DefinitionsFor the purposes of these Data Processing Terms, the
              following terms have the meaning set out below:“GDPR” means the
              General Data Protection Regulation (Regulation (EU)
              2016/679).“Controller”, “Processor”, “Data Subject”, “Personal
              Data”, “Personal Data Breach” and “Processing” shall have the same
              meanings as in the GDPR and “Processed” and “Process” shall be
              construed in accordance with the definition of “Processing”.
            </strong>
          </IntroText>
        </BasicTextBox>
      </Intro>
      <Footer />
    </>
  );
};

export default DataProcessing;
