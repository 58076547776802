import React, { useState, useEffect } from 'react';
import EULERITY_TRAINING_GUIDE from '../../assets/FCCTrainingGuide.pdf';
import { Document, Page, pdfjs } from 'react-pdf';
import { PDFContainer } from './style';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';


const FCCTrainingGuide  = () => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    const getWindowDimensions = () => {
        const { innerWidth: width, innerHeight: height } = window;
        return {
          width,
          height
        };
    }

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [])

    const resolution = () => {
        if (windowDimensions.width < 1040) return windowDimensions.width;
        return 1000;
    }

    return (
        <PDFContainer>
            <Document
                file={EULERITY_TRAINING_GUIDE}
            >
                <Page pageNumber={1} width={resolution()} />
            </Document>
            <Document
                file={EULERITY_TRAINING_GUIDE}
            >
                <Page pageNumber={2} width={resolution()} />
            </Document>
        </PDFContainer>
    )
}

export default FCCTrainingGuide;