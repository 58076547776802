import React from 'react';
import { Title, Author, Paragraph, Image, MediumArticleContainer, EstimatedRead } from './style';
import WoodhouseConference from '../../assets/WoodhouseConference.jpeg';

const WoodHouseBlog = () => {
    return (
        <MediumArticleContainer>
            <Title> Woodhouse Reunion: Connecting at Client Conferences </Title>
            <Author> By Harlie Kneler, Account Manager, Eulerity &nbsp;
                <EstimatedRead> 2 min read </EstimatedRead>
            </Author>

            <Paragraph>
                The Eulerity team was invited to join our wonderful Woodhouse Day Spa clients at their reunion event at the end of August. I went along with our AVP of Franchise Business, Julie Samuels, with the event set in charming Charleston, SC, and hosting nearly 200 franchisees, vendors and partners. The welcome reception at the Belmond Hotel gave us the opportunity to meet and mingle with all of the franchisees we’d grown accustomed to identifying just by username. We were thrilled to see franchisees we’ve been working with virtually now in-person for the first time, and have to give big props to Sarah Moore and Amanda Bodkin, who were the amazing brains behind the planning.
            </Paragraph>

            <Paragraph>
                The second day consisted of a Vendor Preview, where our table was set within the abundance of other Woodhouse partners and vendors. We loved seeing the expansive reach of the Woodhouse community, with sponsors spread into every corner, showing how they contribute to the spas’ experiences. While we were there to showcase our digital marketing, other vendors provided Hydrafacial stations, massage chairs, microdermabrasion machines, and even a standing salt chamber! Franchise owners began to flow in from their previous sessions with the corporate team, ready to explore.
            </Paragraph>

            <Image src={WoodhouseConference} alt="img" />

            <Paragraph>
                This event brought franchise owners from all around the country to our booth for some great discussions. Meeting our uber-loyal Eulerity users, who began on the platform based on a connection with some on our Executive team, showed us how much the company has grown in just a short time. As Woodhouse was the first account to sign on when Eulerity was introduced to the franchise world, the value of our collaboration runs deep. Knowing that we give serious consideration to our product feedback, we were thankful for conversations that not only told us what our users love about the platform, but also how we could be better for them.
            </Paragraph>

            <Paragraph>
                There was an influx of potential customers getting to see our platform for the first time. We loved being able to roll out new features in person and see truly inspired reactions. Even franchisees who had been previously hesitant now cleared their uncertainty when seeing our new Desktop Access feature in real-time. Calls and platform walk-throughs were scheduled for the next week to follow up with owners interested in setting up their local digital marketing foundation. For those already enjoying the platform, we discussed running separate campaigns for specific advertising. We heard the pains now more than ever about hiring, and started constructing plans dedicated to help our franchisees through the recruitment process.
            </Paragraph>

            <Paragraph>
                The brand’s CEO, Chrstina Russell, and COO, Susan Hern, masterfully presented a comprehensive vision of their company rebranding. “Mood Care” set the stage for a more customizable experience in the spa, promoting affirmations that spa goers could take back home with them. New advertisements, color schemes, and textural layouts gave our team insight into how we should be marketing these improvements.
            </Paragraph>

            <Paragraph>
                Rounding out our trip, all of the attendees met at the new event location to celebrate the week and the efforts put into the brand thus far. Serving as another opportunity to get to know everyone on a deeper level, we made strong connections with the LRXD Marketing team and their CMO Kim Bates, who helped to develop the franchise’s national marketing roll out. Eulerity could not be more excited to work with them to bring more eyes to ads and more bodies to massage tables.
            </Paragraph>

            <Paragraph>
                We were honored and delighted to be in the presence of such dedicated spa owners, who continued to show us why their business is so important to them. We are already looking forward to attending our next conference!
            </Paragraph>
        </MediumArticleContainer>
    )
}

export default WoodHouseBlog;