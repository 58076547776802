import React, { useRef } from 'react'
import { CarouselContainer, ChevronWrapper, StyledChallengeCard } from './styles'
import ChalCreative from '../../../../assets/industry/ChalCreative.png';
import ChalData from '../../../../assets/industry/ChalData.png';
import ChalLocation from '../../../../assets/industry/ChalLocation.png';
import ChalOptimize from '../../../../assets/industry/ChalOptimize.png';
import ChalRapid from '../../../../assets/industry/ChalRapid.png';
import ChalUse from '../../../../assets/industry/ChalUse.png';
import AliceCarousel from 'react-alice-carousel';
import { ReactComponent as LeftChevron } from '../../../../assets/LeftChevron.svg';
import { ReactComponent as RightChevron } from '../../../../assets/RightChevron.svg';
import "react-alice-carousel/lib/alice-carousel.css";


const cardData = [
    {
        cardTitle: 'Ad & Post Creation',
        cardText: 'Automatically resize your creative for all key media platforms.',
        cardImage: ChalCreative,
        id: 1
    },
    {
        cardTitle: 'Localization',
        cardText: 'Streamline your local marketing strategy by managing placement at scale.',
        cardImage: ChalLocation,
        id: 2
    },
    {
        cardTitle: 'Rapid Scaling',
        cardText: 'Accelerate your speed to market with groundbreaking media planning and activation.',
        cardImage: ChalRapid,
        id: 3
    },
    {
        cardTitle: 'Intelligent Optimization',
        cardText: 'Replace manual campaign management and maximize your media investment with AI-powered marketing analytics.',
        cardImage: ChalOptimize,
        id: 4
    },
    {
        cardTitle: 'Real-Time Data',
        cardText: 'Gain full visibility into performance at both the micro and macro scale with access to in-depth data and analytics whenever & wherever.',
        cardImage: ChalData,
        id: 5
    },
    {
        cardTitle: 'Ease Of Use',
        cardText: 'Built mobile first for business owners on the go, the app provides easy access anywhere at any time.',
        cardImage: ChalUse,
        id: 6
    }
]


const ChallengeCarousel = () => {
    const carousel = useRef(null);

    const responsive = {
        768: { items: 1 },
        940: { items: 2 },
        1300: { items: 3 }
    };

    const carouselData = cardData.map((card) => {
        return (
            <StyledChallengeCard key={card.id}>
                <div className='card-content'>
                    <img src={card.cardImage} />
                    <h4>{card.cardTitle}</h4>
                    <p>{card.cardText}</p>
                </div>
            </StyledChallengeCard>
        )
    })

    return (
        <CarouselContainer>
            <AliceCarousel
                key='carousel'
                disableButtonsControls={true}
                disableDotsControls={true}
                mouseTracking={false}
                items={carouselData}
                infinite
                responsive={responsive}
                ref={carousel}
            />
            <div className='btn-container'>
                <ChevronWrapper onClick={(e) => carousel.current.slidePrev(e)}>
                    <LeftChevron />
                </ChevronWrapper>
                <ChevronWrapper onClick={(e) => carousel.current.slideNext(e)}>
                    <RightChevron/>
                </ChevronWrapper>
            </div>
        </CarouselContainer>
    )
}

export default ChallengeCarousel