import React, {useEffect} from "react";
import Navbar from "../../components/Products/nav/Navbar";
import Background from "../../components/Products/background/BackGround";
//icons
import Customize from "../../assets/customer-logo/customize.svg";
import Access from "../../assets/customer-logo/access.svg";
import Statatics from "../../assets/customer-logo/statistics.svg";
import Trust from "../../assets/customer-logo/trust.svg";
import {ReactComponent as Flow} from "../../assets/customer-logo/flow.svg";

import {ReactComponent as Issue} from "../../assets/customer-logo/issue.svg";
import {ReactComponent as Account} from "../../assets/customer-logo/account.svg";
import {ReactComponent as Help} from "../../assets/customer-logo/help.svg";
import {ReactComponent as Special} from "../../assets/customer-logo/special.svg";

import {ReactComponent as Step1} from "../../assets/customer-logo/step1.svg";
import {ReactComponent as Step2} from "../../assets/customer-logo/step2.svg";
import {ReactComponent as Step3} from "../../assets/customer-logo/step3.svg";
import {ReactComponent as Step4} from "../../assets/customer-logo/step4.svg";
import {ReactComponent as Dolos} from "../../assets/customer-logo/dolos.svg";
// import Laptop from '../../assets/MacBook Pro.png';
// import sylvan from '../../assets/customer-logo/sylvan.svg';
import {
  GlobalContent,
  Header,
  Main,
  Intro,
  CommonIntroText,
  CommonSectionTitle,
  IntroCopy,
  SectionLayoutColumns,
  DescriptionSection,
  SectionTestimonials,
  FeatureSection,
  Columns,
  ProcessSection,
  CommonButtonGroup,
} from "./style";
import Button from "../../components/buttons/Button";
import LayoutColumn from "../../components/Products/layoutColumn/LayoutColumn";
import {loadScript} from "../../helperFunction/helper";
import Footer from "../../components/footer/Footer";

const HeroSection = () => {
  return (
    <Header>
      <Intro className="container-lg">
        <CommonSectionTitle>
          Customize & control from the palm of your hand
        </CommonSectionTitle>
        <IntroCopy className="container-lg">
          <CommonIntroText>
            Eulerity’s Dolos is a powerful, safe, and easy-to-use platform for
            giving the right control to your ecosystem. By removing lengthy
            processes, process complexity, and numerous excel sheets, Dolos
            helps you launch your marketing infrastructure from anywhere in the
            world with full custom controls.
          </CommonIntroText>
        </IntroCopy>
        <Button bg="#256DFE" color="#fff" href={"/contact-us"}>
          Start your journey
        </Button>
      </Intro>
      <Background />
    </Header>
  );
};

const Description = () => {
  return (
    <DescriptionSection>
      <SectionLayoutColumns className="layoutColumns--3">
        <LayoutColumn
          icon={Trust}
          title="Trusted by marketers the world over"
          text="Join hundreds of marketing professionals from fortune 500 companies who have excelled at their jobs by giving the right controls to the network they manage."
        />
        <LayoutColumn
          icon={Access}
          title="Access Eulerity’s Command Center"
          text="Use your Eulerity account to access powerful and flexible tools for marketing. Eulerity’s meticulously designed controlling solutions and unmatched functionality help you create the best possible product for your users."
        />
        <LayoutColumn
          icon={Statatics}
          title="Grow and scale with free training"
          text="Every dollar matters when you run a stellar marketing department.Sign up for free training to execute marketing strategies, provide the right controls to your users, and more."
        />
      </SectionLayoutColumns>
    </DescriptionSection>
  );
};

const Testimonials = () => {
  useEffect(() => {
    loadScript("/js/carousel.js");
  }, []);

  return (
    <SectionTestimonials>
      <div className="main-carousel container-lg">
        <div className="carousel-cell">
          <figure className="author">
            <img
              className="avatar"
              src={
                "https://pbs.twimg.com/profile_images/1268627012564013060/v8h1n1P7.jpg"
              }
              alt="Dion Beary"
            />
            <div className="byline">
              <div className="name">Dion Beary</div>
              <div className="title">
                Marketing Services Manager, Clean Juice
              </div>
            </div>
          </figure>
          <blockquote>
            "Eulerity solved a large need for our organization! The technology
            integrated with many of the data points that are of value for us at
            Clean Juice and helped our franchisee's quantify the value of our
            Marketing Services Program.”
          </blockquote>
        </div>
        <div className="carousel-cell">
          <figure className="author">
            <img
              className="avatar"
              src="https://media-exp1.licdn.com/dms/image/C5603AQFV8oavNgS5yg/profile-displayphoto-shrink_800_800/0/1536685663804?e=1615420800&v=beta&t=syUvxqu_Xyi669bZl1YDttJkOV0Zwq4nYm1YqWNs3gI"
              alt="Allison Daly"
            />
            <div className="byline">
              <div className="name">Allison Daly</div>
              <div className="title">VP marketing, Leap</div>
            </div>
          </figure>
          <blockquote>
            "Eulerity offers a very comprehensive digital marketing solution.
            And I’ve probably invested no more than 4 hours of my time toward
            this initiative since our launch!"
          </blockquote>
        </div>
        <div className="carousel-cell">
          <figure className="author">
            <img
              className="avatar"
              src={
                "https://media-exp1.licdn.com/dms/image/C4D03AQErERRGafFibA/profile-displayphoto-shrink_800_800/0/1596054810555?e=1619049600&v=beta&t=99guQAPph8W_ir920WdhT8eA3ZIiP_b9uJ9RrdBtI9s"
              }
              alt="Dennis Holcom"
            />

            <div className="byline">
              <div className="name">Dennis Holcom</div>
              <div className="title">SVP of Franchise Support</div>
            </div>
          </figure>
          <blockquote>
            "Our franchisees love this self-supported AI platform.  It’s easy to manage and provides great visibility of their club's performance through the dashboard.  Eulerity has really helped to increase Workout Anytime's brand awareness."
          </blockquote>
        </div>
        <div className="carousel-cell">
          <figure className="author">
            <img
              className="avatar"
              src={
                "https://media-exp1.licdn.com/dms/image/C5603AQGqJHweQH8wxA/profile-displayphoto-shrink_200_200/0/1517495272237?e=1614211200&v=beta&t=T568EHd8TdX4lJCjZafLV80ruI522-nNjw7vSOD53tY"
              }
              alt="Paul Erdelt"
            />
            <div className="byline">
              <div className="name">Paul Erdelt</div>
              <div className="title">CMO, Woodhouse Day Spa</div>
            </div>
          </figure>
          <blockquote>
            “Eulerity made me master of my digital promotional activities,
            delivering more than what was promised.”
          </blockquote>
        </div>
      </div>
    </SectionTestimonials>
  );
};

const Features = () => {
  return (
    <FeatureSection className="container-lg">
      <Columns>
        <div className="left">
          <div className="intro-copy container-lg">
            <h2 className="commonSectionTitle">Get going in under a day</h2>
            <p className="commonBodyText">
              We guide you through the essentials and then we handle the rest.
              Spend a few minutes with our experts deciding your very own plan
              to customize your deployment. We’ll then create the right
              framework for your company.
            </p>
          </div>
          <ul className="feature-list">
            <li className="feature-block">
              <figure className="helm-logo helm-medium ">
                <Flow />
              </figure>
              <h3 className="commonBodyTitle">Form a template easily</h3>
              <p className="commonBodyText">
                Set up how you would like to organize your network with the
                right keywords, creative messaging and other settings very
                easily.
              </p>
            </li>
          </ul>
        </div>

        <div className="right device-container">
          <figure className="devices-dahboard loaded">
            <div className="ground">
              <div className="shadow"></div>
            </div>
            <div className="laptop">
              <div className="device"></div>
              <div className="controls"></div>
              <div className="screen"></div>
            </div>
          </figure>
        </div>
      </Columns>
      <Columns>
        <div className="left">
          <ul className="feature-list">
            <li className="feature-block">
              <figure className="helm-logo helm-medium ">
                <Issue />
              </figure>
              <h3 className="commonBodyTitle">Issue control to everyone</h3>
              <p className="commonBodyText">
                Create your company’s initial ownership structure using our tool
                to generate control structures with standard terms used by many
                top organizations.
              </p>
            </li>
          </ul>
        </div>
        <div className="right">
          <ul className="feature-list">
            <li className="feature-block">
              <figure className="helm-logo helm-medium ">
                <Account />
              </figure>
              <h3 className="commonBodyTitle">Eulerity account </h3>
              <p className="commonBodyText">
                Activate your Eulerity account to start communicating to your
                customers around the globe. You can also take advantage of a
                range of Eulerity’s other products, including Panacea, Titan,
                and Metis.
              </p>
            </li>
          </ul>
        </div>
      </Columns>
      <Columns>
        <div className="left">
          <ul className="feature-list">
            <li className="feature-block">
              <figure className="helm-logo helm-medium ">
                <Help />
              </figure>
              <h3 className="commonBodyTitle">
                A helpful, supportive community
              </h3>
              <p className="commonBodyText">
                Launching your Local Marketing with Eulerity Dolos grants you
                automatic access to the our training platforms and support
                teams. As a customer, you’ll get personalized help and learn
                best practices.
              </p>
            </li>
          </ul>
        </div>
        <div className="right">
          <ul className="feature-list">
            <li className="feature-block">
              <figure className="helm-logo helm-medium">
                <Special />
              </figure>
              <h3 className="commonBodyTitle">
                Special offers on new upcoming products
              </h3>
              <p className="commonBodyText">
                Receive an early sneak peek into our new products and have the
                opportunity to try new features for free as a part of the
                Eulerity community.
              </p>
            </li>
          </ul>
        </div>
      </Columns>
    </FeatureSection>
  );
};

const Process = () => {
  return (
    <ProcessSection>
      <div className="container-lg">
        <IntroCopy>
          <h2 className="common-UppercaseTittle process">
            What’s the process?{" "}
          </h2>
          <p className="commonIntroText">
            With Eulerity Dolos, your company can be set up within hours. We are
            there with you the entire way.
          </p>
        </IntroCopy>
        <ul className="timeline">
          <li className="line"></li>
          <li className="step">
            <div className="icon-container">
              <figure className="helm-logo helm-medium ">
                <Step1 />
              </figure>
            </div>
            <p className="commonBodyText text text--mp">
              Set yourself up as admin.
            </p>
            <span>step 1</span>
          </li>
          <li className="step">
            <div className="icon-container">
              <figure className="helm-logo helm-medium">
                <Step2 />
              </figure>
            </div>
            <p className="commonBodyText text">
              Choose the template you want your network to follow.
            </p>
            <span>step 2</span>
          </li>
          <li className="step">
            <div className="icon-container text">
              <figure className="helm-logo helm-medium">
                <Step3 />
              </figure>
            </div>
            <p className="commonBodyText text">
              Set up your custom rules and permission settings.
            </p>
            <span>step 3</span>
          </li>
          <li className="step">
            <div className="icon-container">
              <figure className="helm-logo helm-medium">
                <Step4 />
              </figure>
            </div>
            <p className="commonBodyText text text--mp">
              You’re all set for massive scale.
            </p>
            <span>step 4</span>
          </li>
        </ul>
      </div>
    </ProcessSection>
  );
};

const Pricing = () => {
  return (
    <section className="pricing">
      <div className="container-lg">
        <CommonSectionTitle>
          Set up your customizations without hassle
        </CommonSectionTitle>
        <div className="pricing-section">
          <div className="icon">
            <Dolos />
          </div>
          <div className="feature-list core">
            <h3 className="feature-title commonBodyTitle">
              Customization with Eulerity Dolos.
            </h3>
            <p className="commonBodyText">
              We’ll guide you through what’s needed to set up your custom rules
            </p>
            <h4
              className="feature-lead commonBodyTitle"
              style={{textDecoration: "none", cursor: "none"}}
            >
              includes:
            </h4>
            <ul className="commonBodyText">
              <li>
                <i className="fas fa-check-circle"></i>
                <span>Creative controls for your network.</span>
              </li>
              <li>
                <i className="fas fa-check-circle"></i>
                <span>
                  Minimum and Maximum Subscription Amounts for appropriate
                  marketing spend.
                </span>
              </li>
              <li>
                <i className="fas fa-check-circle"></i>
                <span>Bring your own Ad account options.</span>
              </li>
              <li>
                <i className="fas fa-check-circle"></i>
                <span>Ability to limit targeting options in the network.</span>
              </li>
              <li>
                <i className="fas fa-check-circle"></i>
                <span>
                  Linking only brand standard specific social accounts to run
                  marketing.
                </span>
              </li>
            </ul>
          </div>
          <div className="price">
            <h3 className="commonSectionTitle" style={{color: "#f5c15c"}}>
              $0
            </h3>
            <p className="commonBodyTitle">
              Included with all Eulerity accounts.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

const FooterSection = () => {
  return (
    <footer className="globalFooter">
      <article className="helm-sectionCTA">
        <div className="helm-footerCta_container container-lg">
          <div className="helm-footerCta__copy">
            <h1 className="commmonIntroText copy_bleue Helm-FooterCTA__title">
              Not Ready Yet?
            </h1>
            <p>
              Chat with our team of experts to get answers to all your marketing
              queries.
            </p>
          </div>
          <CommonButtonGroup>
            <li>
              <Button bg="#5479FF" color="#fff" href={"/contact-us"}>
                Sign up
              </Button>
            </li>
          </CommonButtonGroup>
        </div>
      </article>
      <Footer />
    </footer>
  );
};

const ProductThree = () => {
  return (
    <>
      <Navbar
        name="Dolos"
        icon={Customize}
        href="/productthree"
        color="#F5C15C"
      />
      <GlobalContent>
        <Main>
          <HeroSection />
          <Description />
          <Testimonials />
          <Features />
          <Process />
          <Pricing />
        </Main>
      </GlobalContent>
      <FooterSection />
    </>
  );
};

export default ProductThree;
