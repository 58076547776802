import React from "react";
import {GlobalContent, Main, GradientBackground, SectionSale} from "./style";
import Form from "../../components/form/Form";
import Footer from "../../components/Products/footer/Footer";
const SaleHeader = () => {
  return (
    <SectionSale>
      <div className="stripess-container  initial">
        <div className="stripe s2"></div>
        <div className="stripe s3"></div>
        <div className="stripe s4"></div>
        <div className="stripe s5"></div>
        <div className="stripe s7"></div>
        <div className="stripe gradient"></div>
        <div className="stripe s1"></div>
        <div className="stripe s6"></div>
        <div className="left-dots-container"></div>
        <div className="light-dots-container"></div>
        <div className="dark-dots-container"></div>
        <div className="medium-dots-container"></div>
      </div>
      <div className="contactTitle">
        <div className="container-lg">
          <h1 className="headline-text">Contact our sales team</h1>
          <h2 className="common-introText">
            We're happy to answer questions and get you acquainted with
            Eulerity.
          </h2>
        </div>
      </div>
    </SectionSale>
  );
};

const FormSection = () => {
  return (
    <section className="form-section">
      <div className="container-lg">
        <div className="form-column">
          <div className="form-container">
            <Form buttonValue="Contact Sales" />
          </div>
        </div>
        <div className="secondary-column">
          <div className="why-eulerity">
            <p className="intro-text">With Eulerity you can:</p>
            <ul className="bullet-points">
              <li>✓ Learn how to reach more relevant audiences</li>
              <li>✓ Get pricing information</li>
              <li>✓ Explore uses cases for your Industry</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

const Cta = () => {
  return (
    <section className="ctas">
      <div className="container-lg">
        <div className="cta general-queries">
          <div className="icon-column"></div>
          <div className="copy-column">
            <h4 className="cta-headline">
              <i className="fas fa-at"></i>General communication
            </h4>
            <p className="cta-copy">
              <span className="non-link">
                For general queries, including partnership opportunities, please
                email
              </span>{" "}
              <a
                className="common-Link linkcontact"
                href="mailto:support@eulerity.com"
              >
                sales@eulerity.com
              </a>
              <span className="non-link">.</span>
            </p>
          </div>
        </div>
        <div className="cta support">
          <div className="icon-column"></div>
          <div className="copy-column">
            <h4 className="cta-headline">
              <i className="fas fa-cogs"></i>Technical or account support
            </h4>
            <p className="cta-copy">
              <span className="non-link">
                We’re here to help! If you have technical issues,
              </span>{" "}
              <a
                className="common-Link common-Link--arrow linkcontact"
                href="mailto:support@eulerity.com"
              >
                contact&nbsp;support
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
const Contact = () => {
  return (
    <>
      <GlobalContent>
        <Main>
          <GradientBackground></GradientBackground>
          <SaleHeader></SaleHeader>
          <FormSection />
          <Cta />
        </Main>
      </GlobalContent>
      <Footer />
    </>
  );
};

export default Contact;
