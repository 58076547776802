//tag manager
import TagManager from 'react-gtm-module';

const GtmId = 'GTM-TGNKW7P';

export const initializeGTM = () => {
  TagManager.initialize({
    gtmId: GtmId
  });
};

export const gtmtrackPageView = (history) => {
  history.listen((location) => {
    const { pathname } = location || window.location;
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        path: pathname
      }
    });
  });
};

export const trackInitialPageViewGtm = (pathname) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageview',
      path: pathname
    }
  });
};
