import React from "react";
import "./style.css"; /***style */

const TableTwo = () => {
  return (
    <table>
      <thead>
        <tr>
          <th></th>
          <th>Traditional Approach</th>
          <th>Eulerity</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td></td>
          <td>
            <i className="fas fa-times red" />
            High cost of sale to service small to mid size merchants
          </td>
          <td>
            <i className="fa fa-check-circle" />
            Marketing automation enables merchants full control
          </td>
        </tr>
        <tr>
          <td></td>
          <td>
            <i className="fas fa-times red" />
            Manual ad enablement
          </td>
          <td>
            <i className="fa fa-check-circle" />
            Merchants download And activate media spend
          </td>
        </tr>
        <tr>
          <td></td>
          <td>
            <i className="fas fa-times red" />
            Limited ad formats at scale
          </td>
          <td>
            <i className="fa fa-check-circle" />
            O&O inventory + paid search, Social, display, & Video options
          </td>
        </tr>
        <tr>
          <td></td>
          <td>
            <i className="fas fa-times red" />
            ROI not understood by merchant
          </td>
          <td>
            <i className="fa fa-check-circle" />
            Visualizes ROI via POS
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default TableTwo;
