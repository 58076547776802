import React from 'react';
import ChatBubbles from '../../../../assets/industry/ChatBubbles.png';
import Droplet from '../../../../assets/industry/Droplet.png';
import MagnifyGlass from '../../../../assets/industry/MagnifyGlass.png';
import Megaphone from '../../../../assets/industry/Megaphone.png';
import { StyledMomentumCard, StlyedMomentumContainer } from './styles';

const cardData = [
    {
        cardText: 'Local, Regional, & National Advertising',
        cardImage: Megaphone,
        id: 1
    },
    {
        cardText: 'Organic Social Content Management',
        cardImage: ChatBubbles,
        id: 2
    },
    {
        cardText: 'Franchise Development',
        cardImage: MagnifyGlass,
        id: 3
    },
    {
        cardText: 'Employee Employment',
        cardImage: Droplet,
        id: 4
    }
]

const MomentumGrid = () => {
    return (
        <StlyedMomentumContainer>
            {
                cardData.map((card) => {
                    return (
                        <StyledMomentumCard key={card.id}>
                            <div className='card__content'>
                                <div className='img__container'>
                                    <img src={card.cardImage} />
                                </div>
                                <p>{card.cardText}</p>
                            </div>
                        </StyledMomentumCard>
                    )
                })
            }
        </StlyedMomentumContainer>
    )
}

export default MomentumGrid