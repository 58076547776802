import React from 'react';
import './style.css';
const Card = ({ icon, title, text, titlecolor, link }) => {
  return (
    <a href={link} className='commonLink globalFooterCard'>
      <span className='helm-logo helm-medium '>
        <img src={icon} alt={icon} />
      </span>
      <h1 className='CommonUpperCaseTitle' style={{ color: titlecolor }}>
        {title}
      </h1>
      <p className='commonBodyText'>{text}</p>
    </a>
  );
};

export default Card;
