import React from 'react';
import './style.css';
import FailImg from '../../assets/imgFailure.png';
import Footer from '../../components/footer/Footer';
const Dynamic = () => {
  return (
    <>
      <div className='dynamic'>
        <div className='container-lg dynamicBloc'>
          <img src={FailImg} alt='fail logo' />
          <h1>Oops…</h1>
          <div className='dynamicBloc_content'>
            <p className='dynamicBloc_text'>
              It looks like you’ve clicked on a dynamic link from a desktop
              browser. The link you’ve clicked is special and will take you to
              our app whether you click on it from an Android or iOS device.
            </p>
            <p className='dynamicBloc_text'>
              Please open the link from your mobile device instead.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Dynamic;
