import { useState } from 'react';

export default function (initialValue, fn, Validation) {
  const [value, setValue] = useState(initialValue);
  const [selectedOption, setSelectedOption] = useState('');
  const [error, setError] = useState({});
  const [selectedReference, setSelectedReference] = useState('');

  const handleSelect = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleReference = (e) => {
    setSelectedReference(e.target.value);
  }

  const handleChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value
    });
  };

  const setParamsValue = (obj) => {
    setValue({
      ...value,
      [obj.name]: obj.value
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let errorField = Validation(value);
    try {
      if (Object.keys(errorField).length === 0) {
        setValue(initialValue);
        fn();
      } else {
        setError(errorField);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return [
    value,
    handleChange,
    handleSubmit,
    error,
    selectedOption,
    handleSelect,
    handleReference,
    selectedReference,
    setParamsValue
  ];
}
