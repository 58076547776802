import styled from 'styled-components';

const Section = styled.section`
  --sectionPaddingTopMax: 116;
`;

const SectionMasked = styled.div`
  overflow: var(--sectionOverflow);
`;

const BackgroundMask = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const SectionBackground = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -webkit-transform-origin: var(--sectionTransformOrigin);
  transform-origin: var(--sectionTransformOrigin);
  -webkit-transform: skewY(var(--sectionAngle));
  transform: skewY(var(--sectionAngle));
  background: #f6f9fc;
  overflow: hidden;

  &.angle {
    --sectionPaddingTop: var(--sectionAnglePaddingTop);
    --sectionAngle: var(--angleNormal);
  }
`;

const Guides = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: var(--columnPaddingNormal);
  pointer-events: none;
`;

const GuideContainer = styled.div`
  display: grid;
  grid: 1fr / repeat(var(--columnCountMax), 1fr);
  position: relative;
  max-width: var(--layoutWidth); /**1080px */
  height: 100%;
  margin: 0 auto;
`;
const Guide = styled.div`
  width: 1px;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(var(--guideDashedColor)),
    color-stop(50%, var(--guideDashedColor)),
    color-stop(50%, transparent),
    to(transparent)
  );
  background: linear-gradient(
    180deg,
    var(--guideDashedColor),
    var(--guideDashedColor) 50%,
    transparent 0,
    transparent
  );
  background-size: 1px 8px;
  &:first-of-type {
    background: var(--guideSolidColor);
  }
`;

const SectionContainer = styled.div`
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
`;

const SectionLayoutContainer = styled.div`
  width: 100%;
  max-width: var(--layoutWidth);
  margin: 0 var(--columnPaddingNormal);
`;

const SectionLayout = styled.div`
  padding: 200px 0 var(--sectionPaddingBottom);
`;

const CopyTitle = styled.h1`
  --titlePaddingLeft: 0;
  --titlePaddingRight: 0;
  position: relative;
  padding: 0 var(--titlePaddingRight) 0 var(--titlePaddingLeft);
  font-family: 'Opens Sans', sans-serif;
  font-weight: 700;
  font-size: 15px;
  line-height: 1.6;
  color: #0a2540;
  letter-spacing: 0.2px;
  margin-bottom: 8px;
`;

/***********foooter *********/

const SectionFooterLayout = styled.nav`
  display: grid;
  grid-template-columns: auto/repeat(2, 1fr);
  row-gap: 20px;

  @media (min-width: 600px) {
    grid-template-columns: repeat(4, 1fr);
  }

  &.smallPadding {
    padding: 50px 0 var(--sectionPaddingBottom);
    @media (min-width: 670px) {
      padding: 50px 0 var(--sectionPaddingBottom);
    }
  }
`;

const SiteFooterSectionColumn = styled.div`
  display: grid;
  row-gap: 20px;

  @media (min-width: 600px) {
    grid: -webkit-min-content/auto;
    grid: min-content/auto;
  }

  &.locale {
    @media (min-width: 600px) {
      grid-template-rows: auto -webkit-min-content;
      grid-template-rows: auto min-content;
    }
  }

  .list {
    --listSpacing: 4px;
    --linkWeight: 425;
    --columnCount: 1;
    padding: 0 var(--columnPaddingNormal);
    font-weight: 300;
    font-size: 15px;
    line-height: 1.6;
    font-family: 'Opens Sans', sans-serif;
    letter-spacing: 0.2px;
  }

  .lists {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .List__item {
    position: relative;
    margin: 4px 0;

    span {
      font-weight: 400;
    }

    i {
      margin-right: 2px;
      font-size: 25px;
    }
  }
  .link {
    font-weight: 400;
    cursor: pointer;
    color: currentColor;
    -webkit-transition: var(--hoverTransition);
    transition: var(--hoverTransition);
    -webkit-transition-property: color, opacity;
    transition-property: color, opacity;
    outline: none;
  }

  .fa-spotify {
    color: #19b26b;
  }

  .fa-apple {
    color: #000000;
  }

  .fa-instagram {
    color: #6e3bbb;
  }

  .fa-linkedin {
    color: #2967b2;
  }
`;

const SectionFooterLocaleContainer = styled.div`
  .sectionfooter_logo {
    width: 46px;
    height: 26px;
    margin: 0 var(--columnPaddingNormal) 6px;
    padding-left: 2px;
  }

  .sectionfooter_logoLink {
    font-size: 20px;
    font-weight: 400;
    color: #0a2540;
  }

  .sectionfooter_adresse {
    margin: 30px 0 50px 0;
    padding: 0 var(--columnPaddingNormal);

    p {
      font-size: 18px;
      font-weight: 400;
    }
    i {
      margin-right: 4px;
    }
  }
`;

const SectionCopyright = styled.section`
  color: #364657;
  font-weight: 700;
  font-size: 17px;
`;

export {
  Section,
  SectionMasked,
  BackgroundMask,
  SectionBackground,
  Guides,
  GuideContainer,
  Guide,
  SectionContainer,
  SectionLayout,
  SectionLayoutContainer,
  CopyTitle,
  SectionFooterLayout,
  SiteFooterSectionColumn,
  SectionFooterLocaleContainer,
  SectionCopyright
};
