import styled from "styled-components";

// * Challenge
export const StyledChallengeCard = styled.div`
    background-color: #ffffff;
    text-align: center;
    width: 355px;
    height: 380px;
    padding: 27px;
    border-bottom-left-radius: 30px;
    border-top-right-radius: 30px;

    .card-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        flex: 1;
    }

    img {
        border-radius: 0;
        box-shadow: none;
        height: 100px;
        width: 100px;
    }

    h4 {
        font-size: 18px;
        font-weight: 700;
        color: #000000;
    }

    p {
        font-size: 18px;
        font-weight: 400;
        margin-top: 10px;
        /* height: 100%; */
    }

    @media (max-width: 481px) {
        width: 180px;
        height: 280px;
        padding: 30px;

        h4 {
            font-size: 14px;
        }

        p {
            font-size: 14px;
        }

        img {
            height: 60px;
            width: 60px;
        }
    }
`

export const CarouselContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;

    .btn-container {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        gap: 20px;
    }

    .alice-carousel {
        margin: auto;
    }

    .alice-carousel div {
        margin: auto;
    }
`

// * Momentum
export const StlyedMomentumContainer = styled.div`
    display: flex;
    align-content: center;
    justify-content: center;
    gap: 40px;
    margin-top: 40px;

    @media (max-width: 1200px) {
        flex-direction: column;
        gap: 20px;
    }
`

export const StyledMomentumCard = styled.div`
    height: 380px;
    width: 234px;
    background-color: #ffffff;
    color: #000000;
    padding: 60px;
    border-bottom-left-radius: 30px;
    border-top-right-radius: 30px;

    .card__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .img__container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .img__container img {
        height: 75px;
        width: 75px;
    }

    p {
        font-size: 24px;
        font-weight: 400;
        text-align: center;
        margin-top: 40px;
    }

    @media (max-width: 1200px) {
        height: 140px;
        width: 100%;
        padding: 20px;
        display: flex;

        .card__content {
            flex-direction: row;
            justify-content: center;
            width: 100%;
        }

        .img__container img {
            margin-right: 16px;
            height: 60px;
            width: 60px;
        }

        p {
            margin-top: 0px;
        }
    }

    @media (max-width: 768px) {
        padding: 16px;

        .img__container img {
            height: 40px;
            width: 40px;
            margin-right: 8px;
        }

        p {
            font-size: 20px;
        }
    }
`
// * Customers
export const StyledCustomerCard = styled.div`
    background: #ffffff;
    border-radius: 0 30px 0 30px;
    text-align: center;
    max-width: 990px;
    min-height: 475px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 55px 35px;
    margin: auto;

    img {
        margin-top: 40px;
        height: auto;
        width: 100%;
    }

    .content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .quote {
        margin-bottom: 20px;
        font-style: italic;
    }

    .hand-and-stone {
        max-width: 208px;
        max-height: 102px;
    }

    .ace-handy-man {
        max-width: 182px;
        max-height: 72px;
    }

    .blow-dry-bar {
        max-width: 114px;
        max-height: 156px;
    }

    .company-name {
        font-weight: 800;
    }

    @media (max-width: 481px) {
        .content-container p {
            font-size: 16px;
            margin-bottom: 8px;
        }
    }
`

export const StyledCustomerCarousel = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    .btn__container {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        gap: 20px;
    }
`

// * Case Study
export const StyledCaseStudyCard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 490px;
    max-width: 303px;
    width: 100%;
    background-color: #ffffff;
    border-radius: 0 30px 0 30px;
    text-align: center;
    padding: 16px 32px;

    .card__content {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        max-width: 100%;

        p {
            font-size: 20px;
        }

        .card__desc {
            flex: 1;
        }

        .img__container {
            height: 100%;
            width: 100%;
            max-height: 250px;
            max-width: 250px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;

            img {
                width: 100%;
                height: auto;
            }

            .hand__and__stone {
                max-width: 238px;
                max-height: 118px;
            }

            .clean__juice {
                max-width: 202px;
                max-height: 202px;
            }

            .little__gym {
                max-width: 202px;
                max-height: 202px;
            }
        }

        .card__title {
            font-size: 18px;
            font-weight: 800;
            margin-bottom: 10px;
        }

        .card__link {
            color: blue;
            cursor: pointer;
        }
    }
`

export const StyledCaseStudyGrid = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-around;

    @media (max-width: 1024px) {
        flex-direction: column;
        align-items: center;
        gap: 12px;
    }
`

// * Chevron
export const ChevronWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 35px;
  background: #ffffff;
  border-radius: 50%;
  cursor: pointer;
`