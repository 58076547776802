import React from 'react';
import './EmailFeilure.css';
import fail from '../../assets/imgFailure.png';
import Footer from '../../components/footer/Footer';
const EmailFailure = () => {
  return (
    <>
      <div className='emailFailure'>
        <div className='failureblog container-lg'>
          <h1>Email Verification Error</h1>
          <img src={fail} alt='fail email logo' />
          <h2>Ooop...</h2>
          <p>
            Something went wrong when trying to verify your email address.
            Please follow the steps below to verify your address
          </p>

          <ol>
            <li>Please open the LNRD/Eulerity app</li>
            <li>Navigate to Settings</li>
            <li>Select “Email Verification”</li>
            <li>Check if your email address has been verified</li>
            <li>
              If not, click “Send verification email” to generate a new
              verification link
            </li>
            <li>Check your Inbox</li>
            <li>Click on the new link</li>
            <li>You’re all set!</li>
          </ol>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EmailFailure;
