import React from 'react';
import './style.css';
const BackGround = () => {
  return (
    <div className='commonGrid testStripes'>
      <div className='backgroundContainer'>
        <div className='grid'>
          <div className='background backgroundColor'></div>
        </div>
      </div>
      <div className='stripeContainer'>
        <div className='grid'>
          <div className='stripe '></div>
          <div className='stripe '></div>
          <div className='stripe '></div>
          <div className='stripe '></div>
          <div className='stripe '></div>
          <div className='stripe '></div>
          <div className='stripe'></div>
          <div className='stripe'></div>
          <div className='stripe'></div>
          <div className='stripe'></div>
          <div className='stripe'></div>
        </div>
      </div>
    </div>
  );
};

export default BackGround;
