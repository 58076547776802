import React from 'react';
import { MediumArticleContainer, Title, Author, Paragraph, EstimatedRead, Headers, BoldItalics } from './style';

const TheCreativeConundrum = () => {
    return (
        <MediumArticleContainer>
            <Title> The Creative Conundrum </Title>
            <Author> By Mary Hanula, Director of Marketing, Eulerity 
                <EstimatedRead> 4 min read </EstimatedRead>
            </Author>

            <Headers>Are you spending time searching for the right creatives to express your brand? Unleash your best art by letting your brand influencers choose.</Headers>

            <Paragraph>
                We've all been scrolling on our phones or sitting at our desk and seen an ad pop up. Before you know it, you're on a brand's site, looking at their services or selection. Wondering how you got there? It all comes back to the power of your ad creatives.
            </Paragraph>

            <Paragraph>
                Digital advertising, when done well, has the ability to capture a customer’s attention in the blink of an eye. Most people are each exposed to around 4,000 to 10,000 advertisements each day. But how do you know what types of creatives actually cause an instant reaction? 
            </Paragraph>

            <Paragraph>
                First, you have to know what works best for your brand. Secondly, you will not only have to monitor, but capitalize on real-time insights and analytics based on your advertising performance. If you’re looking for a way to maximize your digital ad creation, distribution, and outreach, then the tips within this article are a fantastic starting point.
            </Paragraph>

            <Headers>
                Realize that your brand needs creatives — and a good amount of them.
            </Headers>

            <Paragraph>
                No business serves only one, singular ad to customers over the years. Ads are ever-changing. With the rapid growth of online advertising, social media, online presence and more, it’s more important than ever to have updated and relevant ad creatives. You need timely advertisements that not only appeal to your customers, but that best reflect your product and offerings. If you have low-quality creatives, odds are that the customer will assume your brand offers the same caliber. When you put work and dedication into your advertising, it shows, and when it really shows — across various websites, search engines and platforms — then your digital marketing strategy is being done right.
            </Paragraph>

            <Headers>
                Advertising and analytics go hand in hand.
            </Headers>

            <Paragraph>
                When you try something and it doesn’t go well or have a great ROI, do you disregard it and continue to do it? Does the idea of adjusting your strategy seem to be more of a hassle than an opportunity? Without realizing it, this is a pattern that business owners fall into when their digital marketing isn’t working. Digital marketing and advertising is not a one and done thing. It’s a constant, 24/7 job that only pays off if you’re consistently appealing to the new trends, reports and analytics that you receive from the previous ads. This means you need to be analyzing and considering what hasn’t worked in the past, which we all know, takes time. Here’s what can be tricky — if a business owner does not have time to commit to analyzing their ad performance, then they most likely will continue to spend their budget on avenues that simply don’t serve them, don’t work, and don’t further their business.
            </Paragraph>

            <Headers>
                You don’t have to do it all alone.
            </Headers>

            <Paragraph>
                Business owners wear many hats, but, if you find yourself spending the majority of your time on your brand’s digital advertising efforts, then you’re already spending less time in other areas that may need your attention. Worse is if you aren’t seeing any results from these efforts. This may lead you to consider outsourcing a partner company. There are outsourcing companies that create your ads, and some companies will even create and deploy them for you. This takes the hassle and hard work that you may need for other areas of your business and puts them in the hands of trusted providers who are dedicated to your entire digital marketing process and efforts.
            </Paragraph>

            <Headers>
                There is a modern way — it’s all about AI & automation.
            </Headers>

            <Paragraph>
                Business owners have a multitude of choices for digital marketing partners. Some suppliers can create your ads and upload them for you, but how well do they monitor what is performing best, what creatives aren’t hitting the mark, and which trends you should jump on? Even if they do a great job at all of these things — how quick is their response rate to them? As a human, are they able to navigate and collect all the data from your advertisements and make split-second decisions? The answer is simple, and it’s called marketing automation. 
            </Paragraph>

            <Paragraph>
                To put things into perspective, let’s consider the concept of A/B testing. A/B testing, it’s the testing of two variables, in this case, advertisements, to see how well they perform. Many agencies pride themselves on A/B testing, having reports on what ad format & media channel is performing the best and which creatives are creating the most engagement and response from consumers.
            </Paragraph>

            <Headers>
                But what if A/B testing was instant? 
            </Headers>

            <Paragraph>
                A real time marketing technology you need is the automation of your creatives, your ad spend, and your budget. Automation is automatic — A/B testing with technology takes away the days of deliberation in what advertising is working and instantly funnels out the best creatives — all without you having to do a thing.
            </Paragraph>

            <Headers>
                The best for your business, budget, and customer base.
            </Headers>

            <Paragraph>
                When you choose to work with a SaaS technology marketing platform, you’re choosing to automate, amplify, and distribute your online advertising each and every second. The technology that does the heavy lifting for you. If you could have all of the analytics, distribution and optimization of your ads all in one place, all taken care of for you, you would be able to trust a source that guarantees an ROI, because it automatically optimizes your budget. Every single second, every single day, all 365 days of the year. What was once a manual process is now a rapidly performing technology that delivers your ads directly to your customer, with creatives that will make them forget how they even got to your website in the first place, but will deliver them there instantly.
            </Paragraph>

            <BoldItalics>
                * Article originally featured in the International Franchise Association's FranBlog.
            </BoldItalics>
        </MediumArticleContainer>
    )
}

export default TheCreativeConundrum;