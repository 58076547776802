import React from 'react';
import Navbar from '../../../components/Products/nav/Navbar';
import Button from '../../../components/buttons/Button';
import Background from '../../../components/Products/background/BackGround';
import Footer from '../../../components/footer/Footer';
import ReactPlayer from 'react-player';
import Video from '../../../assets/eulerity.mp4';
import EntrepriseLogo from '../../../assets/customer-logo/entrepriseLogo.svg';
import LookandFell from '../../../assets/customer-logo/lookandfell.svg';
import Setup from '../../../assets/customer-logo/setup.svg';
import Democratize from '../../../assets/customer-logo/democratize.svg';
import { ReactComponent as Proof } from '../../../assets/customer-logo/proof.svg';
import { ReactComponent as Friction } from '../../../assets/customer-logo/friction.svg';
import { ReactComponent as Sass } from '../../../assets/customer-logo/saas.svg';

import { ReactComponent as Juice } from '../../../assets/partner-logo/juice.svg';
import { ReactComponent as Woodhouse } from '../../../assets/partner-logo/wood.svg';
import { ReactComponent as Workout } from '../../../assets/partner-logo/workout.svg';
import { ReactComponent as Leap } from '../../../assets/partner-logo/leap.svg';

import {
  GlobalContent,
  Main,
  Header,
  CommonSectionTitle,
  CommonTitle,
  ContentSection,
  SectionLayoutColumns,
  CommonMediumIntroText,
  BefenitSection,
  BenefitIntro,
  BenefitTitle,
  BenefitText,
  SectionButton,
  TextSection,
  CommonSectionTitleHightlight,
  SectionTest,
  CommonMediumBodyText
} from './style';
import LayoutColumn from '../../../components/Products/layoutColumn/LayoutColumn';
import Spiral from '../../../components/spiral/Spiral';

const Content = () => {
  let volume = false;
  return (
    <ContentSection>
      <Background />
      <div className='container-lg screencast-container'>
        <figure className='screencast'>
          <ReactPlayer
            url={Video}
            width='100%'
            height='100%'
            playing={true}
            volume={volume}
            loop={true}
            controls={true}
            className='video'
          />
        </figure>
      </div>
    </ContentSection>
  );
};
const HeaderSection = () => {
  return (
    <Header>
      <div className='headline'>
        <div className='container-lg'>
          <CommonTitle>
            Build and deploy your enterprise grade marketing stack
          </CommonTitle>
        </div>
      </div>
      <Content />
    </Header>
  );
};

const CTaButtons = ({ mtop }) => {
  return (
    <SectionButton className={mtop ? 'mtop' : ''}>
      <TextSection>
        <CommonSectionTitleHightlight>
          Ready to use Eulerity Enterprise?
        </CommonSectionTitleHightlight>
        <CommonSectionTitle>
          Our team of experts will guide you through every step of your custom
          enteprise set up. Chat with us today to learn more about how we can
          automate your marketing operations.
        </CommonSectionTitle>
      </TextSection>
      <Button bg='#470FBE' color='#fff' href={'/contact-us'}>
        Contact our Team
      </Button>
    </SectionButton>
  );
};
const Benefits = () => {
  return (
    <BefenitSection className='container-lg'>
      <BenefitIntro className='header'>
        <BenefitTitle>
          <CommonSectionTitle>
            Scaled deployment for your enterprise customizations
          </CommonSectionTitle>
        </BenefitTitle>
        <BenefitText>
          <CommonMediumIntroText>
            We built Eulerity Enterprise to help businesses quickly set up their
            Eulerity enterprise account and to enable teams to get faster
            business insights.
          </CommonMediumIntroText>
        </BenefitText>
      </BenefitIntro>
      <SectionLayoutColumns className='layoutColumns--3'>
        <LayoutColumn
          icon={LookandFell}
          title='Your own look and feel'
          text='Eulerity Enterprise can be customized to fit your branding guidelines. Your creatives, color, logos, fonts and more all in one place so your network feels connected to your brand with the Eulerity Enterprise product.'
        />
        <LayoutColumn
          icon={Setup}
          title='Bring your pre set up accounts'
          text='No worries if you have  been running your media activities through your own accounts in Google, Facebook and other places. You can bring them easily into your Eulerity Enterprise account.'
        />
        <LayoutColumn
          icon={Democratize}
          title='Democratize data'
          text='Free data from silos and let teammates from all departments including analytics, creatives, finance, support, product management, and other teams run reports directly and take action faster.'
        />
      </SectionLayoutColumns>
      <CTaButtons />
    </BefenitSection>
  );
};

const Ide = () => {
  return (
    <SectionTest>
      <div className='container-lg saas'>
        <div className='sass_content'>
          <div className='sass-1'>
            <span className='helm-logo '>
              <Friction />
            </span>
            <CommonSectionTitle>
              Fraction of your current operating cost
            </CommonSectionTitle>
            <CommonMediumBodyText>
              Eulerity Enterprise cuts your servicing cost by over 70%. The
              platform is built from the ground up on a modern cloud
              infrastructure. Retention and servicing of existing customers has
              never been easier. When you acquire new customers, you don’t have
              to ever worry about the costs of spinning them up.
            </CommonMediumBodyText>
          </div>

          <div className='sass-2'>
            <span className='helm-logo '>
              <Sass />
            </span>
            <CommonSectionTitle>
              Build new SaaS revenue streams
            </CommonSectionTitle>
            <CommonMediumBodyText>
              Package Eulerity Enterprise with your unique services or simply
              build on top of our apis and sdk to cater to your needs and open
              an indirect sales channel and a SaaS Business. Your platform
              powering other small and large multi-location institutions
              servicing local markets. Creates accretive compounding revenue
              stream with high SaaS margins.
            </CommonMediumBodyText>
          </div>

          <div className='sass-3'>
            <span className='helm-logo '>
              <Proof />
            </span>
            <CommonSectionTitle>Future proof your business</CommonSectionTitle>
            <CommonMediumBodyText>
              Implement a bleeding edge operating system to manage your local
              business customers. Local customers need marketing platforms that
              are dead simple & engaging. Many platforms were built years ago
              and lack certain modern features to leverage the cloud, machine
              learning, automated campaign management, and a mobile first
              customer experience.
            </CommonMediumBodyText>
          </div>
        </div>
        <aside className='animation sticky'>
          <Spiral />
        </aside>
      </div>
    </SectionTest>
  );
};

const Quotes = () => {
  return (
    <section className='quotes'>
      <div className='stripe-Container'>
        <div></div>
        <div></div>
      </div>
      <div className='small-row  medium-columns quotes-row first'>
        <article className='cell top-left'>
          <blockquote className='inner stack'>
            <a
              className='icon'
              target='_blank'
              href='https://workoutanytime.com/'
              rel='noopener noreferrer'>
              <Workout />
            </a>

            <p className='quote-body'>
            "Our franchisees love this self-supported AI platform.  It’s easy to manage and provides great visibility of their club's performance through the dashboard.  Eulerity has really helped to increase Workout Anytime's brand awareness."
            </p>
            <footer className='commonUpperText attribution'>
              Dennis Holcom, SVP of Franchise Support
            </footer>
          </blockquote>
        </article>
        <article className='cell top-right'>
          <blockquote className='inner stack '>
            <a
              className='icon'
              target='_blank'
              href='https://cleanjuice.com/'
              rel='noopener noreferrer'>
              <Juice />
            </a>
            <p className='quote-body'>
              "Eulerity solved a large need for our organization! The technology
              integrated with many of the data points that are of value for us
              at Clean Juice and helped our franchisee's quantify the value of
              our Marketing Services Program.”
            </p>
            <footer className='commonUpperText attribution'>
              Dion Beary , Marketing Services Manager
            </footer>
          </blockquote>
        </article>
      </div>

      <div className='small-row  medium-columns quotes-row second'>
        <article className='cell bottom-left special'>
          <blockquote className='inner harri'>
            <a
              className='icon'
              target='_blank'
              href='https://www.woodhousespas.com/'
              rel='noopener noreferrer'>
              <Woodhouse />
            </a>

            <p className='quote-body'>
              “Eulerity made me master of my digital promotional activities,
              delivering more than what was promised.”
            </p>
            <footer className='commonUpperText attribution'>
              Paul Erdelt, CMO
            </footer>
          </blockquote>
        </article>
        <article className='cell bottom-right '>
          <blockquote className='inner harri'>
            <a
              className='icon'
              target='_blank'
              href='https://www.leap.us/'
              rel='noopener noreferrer'>
              <Leap />
            </a>
            <p className='quote-body'>
              "Eulerity offers a very comprehensive digital marketing solution.
              And I’ve probably invested no more than 4 hours of my time toward
              this initiative since our launch!"
            </p>
            <footer className='commonUpperText attribution'>
              Allison Daly, VP Marketing
            </footer>
          </blockquote>
        </article>
      </div>
    </section>
  );
};

const Cta = () => {
  return (
    <article className='container-lg'>
      <CTaButtons mtop='mtop' />
    </article>
  );
};
const Entreprise = () => {
  return (
    <>
      <Navbar name='Enterprise' icon={EntrepriseLogo} />
      <GlobalContent>
        <Main>
          <HeaderSection />
          <Benefits />
          <Ide />
          <Quotes />
          <Cta />
        </Main>
      </GlobalContent>
      <Footer />
    </>
  );
};

export default Entreprise;
