import React from 'react';
import { CommonButton } from './style';
const Button = ({ children, color, bg, href, click, target }) => {
  return (
    <CommonButton
      color={color}
      bg={bg}
      href={href}
      onClick={click}
      target={target}>
      {children}
    </CommonButton>
  );
};

export default Button;
