//load script
export const loadScript = (src) => {
  const script = document.createElement('script');
  script.src = src;
  document.body.appendChild(script);
};

///open new tab
export const openNewTab = (url) => {
  window.open(url.name, '_blank');
};
