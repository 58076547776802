import React from 'react';
// import { useHistory } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import { ReactComponent as PanaceaLogo } from '../../../assets/customer-logo/panacea-logo.svg';
import { ReactComponent as TitanLogo } from '../../../assets/customer-logo/titanLogo.svg';
import { ReactComponent as DolosLogo } from '../../../assets/customer-logo/customize.svg';
import { ReactComponent as MetisLogo } from '../../../assets/customer-logo/logoMetis.svg';

import {
  DropdownSection,
  DropdownBody,
  DropdownMenuLayout,
  ProductList,
  ProductListTitle,
  ProductLists,
  ProductListItem,
  ProductListItemLink
  // ItemLink
} from './style';
import styled from 'styled-components';

const ProductsDropdownEl = styled.div`
  width: 50rem;
  height: 20rem;
`;

const ProductsDropdown = () => {
  // const histority = useHistory();
  return (
    <ProductsDropdownEl>
      <DropdownSection data-first-dropdown-section>
        <DropdownBody>
          <DropdownMenuLayout>
            <ProductList>
              <ProductListTitle href='/productOne'>
                <PanaceaLogo className='logo' />
                Panacea
              </ProductListTitle>
              <ProductLists>
                <ProductListItem>
                  <ProductListItemLink href='/productOne'>
                    Largest set of pre-built integrations
                  </ProductListItemLink>
                </ProductListItem>
              </ProductLists>
            </ProductList>
            <ProductList>
              <ProductListTitle href='/productTwo'>
                <TitanLogo className='logo' />
                Titan
              </ProductListTitle>
              <ProductLists>
                <ProductListItem>
                  <ProductListItemLink href='/productTwo'>
                    Smartest way to optimize everything in media
                  </ProductListItemLink>
                </ProductListItem>
              </ProductLists>
            </ProductList>
            <ProductList>
              <ProductListTitle href='/productthree'>
                <DolosLogo className='logo' />
                Dolos
              </ProductListTitle>
              <ProductLists>
                <ProductListItem>
                  <ProductListItemLink href='/productthree'>
                    Customize and control from the palm of your hand
                  </ProductListItemLink>
                </ProductListItem>
              </ProductLists>
            </ProductList>
            <ProductList>
              <ProductListTitle href='/productfour'>
                <MetisLogo className='logo' />
                Metis
              </ProductListTitle>
              <ProductLists>
                <ProductListItem>
                  <ProductListItemLink href='/productfour'>
                    Cutting edge R&D tools with business intelligence
                  </ProductListItemLink>
                </ProductListItem>
              </ProductLists>
            </ProductList>
          </DropdownMenuLayout>
        </DropdownBody>
      </DropdownSection>
    </ProductsDropdownEl>
  );
};

export default ProductsDropdown;
