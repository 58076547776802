import React from "react";
import Footer from "../../components/footer/Footer";
import {
  PrivacyTitle,
  Intro,
  InTroDate,
  IntroTitle,
  IntroText,
  TableItem,
  BasicTitle,
  BasicTextBox,
} from "../privacy/style";

const Fine = () => {
  return (
    <>
      <BasicTitle>Fine Print</BasicTitle>
      <BasicTextBox>
        <IntroTitle>28. Notice to U.S. Government End Users</IntroTitle>
        <IntroText>
          The Software and Website, including all documentation, are “Commercial
          Items,” as that term is defined at 48 C.F.R. §2.101, and consist of
          “Commercial Computer Software” and “Commercial Computer Software
          Documentation.” The Commercial Computer Software and Commercial
          Computer Software Documentation are licensed to U.S. government end
          users:
        </IntroText>
        <TableItem>only as Commercial Items</TableItem>
        <TableItem>with the same rights as all other end users, and</TableItem>
        <TableItem>according to the Terms.</TableItem>
        <IntroText>
          and unpublished rights are reserved under the copyright laws of the
          United States. The manufacturer is Eulerity Inc. 303 Spring Street,
          New York, NY-10013 USA.
        </IntroText>
      </BasicTextBox>
      <BasicTextBox>
        <IntroTitle>29. Assignments</IntroTitle>
        <IntroText>
          You may not assign any of your rights under this agreement to anyone
          else. We may assign our rights to any other individual or entity at
          our discretion.
        </IntroText>
      </BasicTextBox>
      <BasicTextBox>
        <IntroTitle>30. Choice of Law</IntroTitle>
        <IntroText>
          The State of Georgia’s laws, except for conflict of laws rules, will
          apply to any dispute related to the Agreement or the Service. Any
          dispute related to the Agreement or the Service itself will be decided
          by the state and federal courts in Fulton County, Georgia, and each
          party will be subject to the jurisdiction of those courts.
        </IntroText>
      </BasicTextBox>
      <BasicTextBox>
        <IntroTitle>31. Force Majeure</IntroTitle>
        <IntroText>
          We won’t be held liable for any delays or failure in performance of
          any part of the Service, from any cause beyond our control. This
          includes, but is not limited to, acts of god, changes to law or
          regulations, embargoes, war, terrorist acts, riots, fires,
          earthquakes, nuclear accidents, zombie apocalypse, floods, strikes,
          power blackouts, volcanic action, unusually severe weather conditions,
          and acts of hackers, or third-party internet service providers.
        </IntroText>
      </BasicTextBox>

      <BasicTextBox>
        <IntroTitle>32. Survivability</IntroTitle>
        <IntroText>
          Even if this Agreement is terminated, the following sections will
          continue to apply: Proprietary Rights Owned by Us, Proprietary Rights
          Owned by You, Compliance with Laws, Limitation of Liability, No
          Warranties, Indemnity, Choice of Law, Severability, and Entire
          Agreement.
        </IntroText>
      </BasicTextBox>

      <BasicTextBox>
        <IntroTitle>33. Severability</IntroTitle>
        <IntroText>
          If it turns out that a section of these Terms isn’t enforceable, then
          that section will be removed or edited as little as required, and the
          rest of the Agreement will still be valid.
        </IntroText>
      </BasicTextBox>
      <BasicTextBox>
        <IntroTitle>34. Interpretation</IntroTitle>
        <IntroText>
          The headers and sidebar text are provided only to make these Terms
          easier to read and understand. The fact that we wrote these Terms
          won’t affect the way the Agreement is interpreted.
        </IntroText>
      </BasicTextBox>

      <BasicTextBox>
        <IntroTitle>35. Amendments and Waiver</IntroTitle>
        <IntroText>
          Amendments or changes to the Agreement won’t be effective until we
          post revised Terms on the Website. If we don’t immediately take action
          on a violation of these Terms, we’re not giving up any rights under
          the Terms, and we may still take action at some point.
        </IntroText>
      </BasicTextBox>

      <BasicTextBox>
        <IntroTitle>36. No Changes in Terms at Request of Member</IntroTitle>
        <IntroText>
          Because we have so many Members, we can’t change these Terms for any
          one Member or group.
        </IntroText>
      </BasicTextBox>

      <BasicTextBox>
        <IntroTitle>37. Further Actions</IntroTitle>
        <IntroText>
          You’ll provide all documents and take any actions necessary to meet
          your obligations under these Terms.
        </IntroText>
      </BasicTextBox>

      <BasicTextBox>
        <IntroTitle>38. Notification of Security Breach</IntroTitle>
        <IntroText>
          In the event of a security breach that may affect you or anyone on
          your Campaign distribution lists (each a “List”), we’ll notify you of
          the breach once we have determined, in our discretion, that it
          occurred and will provide a description of what happened. If we
          determine, and notify you, that you need to forward all or part of
          that information to anyone on your Lists, you’ll promptly do so.
        </IntroText>
      </BasicTextBox>

      <BasicTextBox>
        <IntroTitle>39. Notices</IntroTitle>
        <IntroText>
          Any notice to you will be effective when we send it to the last email
          or physical address you gave us or when posted on our Website. Any
          notice to us will be effective when delivered to us along with a copy
          to our legal counsel: Attn. Legal Department, Eulerity Inc. 303 Spring
          Street, New York, NY-10013 USA or any addresses as we may later post
          on the Website.
        </IntroText>
      </BasicTextBox>

      <BasicTextBox>
        <IntroTitle>40. Entire Agreement</IntroTitle>
        <IntroText>
          The Agreement you’ve agreed to by enabling any Add-ons make up the
          entire agreement between us in relation to its subject matter and
          supersede all prior agreements, representations, and understandings.
          Any Additional Terms will be considered incorporated into the
          Agreement when you activate the corresponding Add-on.
        </IntroText>
        <IntroText>
          Where there’s a conflict between these Terms and the Additional Terms,
          the Additional Terms will control to the extent of the conflict.
        </IntroText>
        <IntroText>Congratulations! You’ve reached the end.</IntroText>
        <IntroText>
          Thanks for taking the time to learn about LNRD by Eulerity’s policies.
        </IntroText>
      </BasicTextBox>
    </>
  );
};
const Liability = () => {
  return (
    <>
      <BasicTitle>Liability</BasicTitle>
      <BasicTextBox>
        <IntroTitle>21. Limitation of Liability</IntroTitle>
        <IntroText>
          To the maximum extent permitted by law, you assume full responsibility
          for any loss that results from your use of the Website and the
          Service, including any downloads from the Website. We and our Team
          won’t be liable for any indirect, punitive, special, or consequential
          damages under any circumstances, even if they’re based on negligence
          or we’ve been advised of the possibility of those damages. In any
          calendar month, our total liability to you arising under or in
          connection with the Agreement—whether in contract, tort (including
          negligence), breach of statutory duty, or otherwise—will be no more
          than what you paid us for the Service the preceding month.
        </IntroText>
        <IntroText>
          In addition, for the avoidance of doubt, in no instance will we or our
          Team be liable for any losses or damages you suffer if you use the
          Service in violation of our Acceptable Use Policy, regardless of
          whether we terminate or suspend your account due to such violation.
        </IntroText>
      </BasicTextBox>
      <BasicTextBox>
        <IntroTitle>22. No Warranties</IntroTitle>
        <IntroText>
          To the maximum extent permitted by law, we provide the Website and the
          Service as-is. This means that, except as expressly stated in these
          Terms, we don’t provide warranties, conditions, or undertakings of any
          kind in relation to the Website and/or Service, either express or
          implied. This includes, but isn’t limited to, warranties of
          merchantability and fitness for a particular purpose, which are to the
          fullest extent permitted by law, excluded from the Agreement. Since
          Members use the Service for a variety of reasons, we can’t guarantee
          that it’ll meet your specific needs.
        </IntroText>
      </BasicTextBox>
      <BasicTextBox>
        <IntroTitle>23. Indemnity</IntroTitle>
        <IntroText>
          You agree to indemnify and hold us and our Team harmless from any
          losses, including legal fees and expenses that directly or indirectly
          result from any claims you make that aren’t allowed under these Terms
          due to a “Limitation of Liability” or other provision. (Indemnity is
          an agreement to compensate someone for a loss.) You also agree to
          indemnify and hold us harmless from any losses, including legal fees
          and expenses, that directly or indirectly result from (a) your
          Content, (b) your use of the Service, (c) your violation of any laws
          or regulations, (d) third-party claims that you or someone using your
          password did something that, if true, would violate any of these
          Terms, (e) any misrepresentations made by you, or (f) a breach of any
          representations or warranties you’ve made to us.
        </IntroText>
      </BasicTextBox>
      <BasicTextBox>
        <IntroTitle>24. Legal Fees and Expenses</IntroTitle>
        <IntroText>
          If we file an action against you claiming you breached these Terms and
          we prevail, we’re entitled to recover all reasonable legal fees,
          expenses, and any damages or other relief we may be awarded.
        </IntroText>
      </BasicTextBox>
      <BasicTextBox>
        <IntroTitle>25. Equitable Relief</IntroTitle>
        <IntroText>
          If you violate these Terms, then we may seek injunctive relief
          (meaning we may request a court order to stop you) or other equitable
          relief.
        </IntroText>
      </BasicTextBox>
      <BasicTextBox>
        <IntroTitle>26. Subpoena Fees</IntroTitle>
        <IntroText>
          If we have to provide information in response to a subpoena, court
          order, or other legal, governmental, or regulatory inquiry related to
          your account, then we may charge you for our costs. These fees may
          include attorney and employee time spent retrieving the records,
          preparing documents, and participating in a deposition
        </IntroText>
      </BasicTextBox>
      <BasicTextBox>
        <IntroTitle>27. Disclaimers</IntroTitle>
        <IntroText>
          We and our Team aren’t responsible for the behavior of any third
          parties, linked websites, or other Members.
        </IntroText>
      </BasicTextBox>
    </>
  );
};
const Rules = () => {
  return (
    <>
      <BasicTitle>Rules and Abuse</BasicTitle>
      <BasicTextBox>
        <IntroTitle>16. General Rules</IntroTitle>
        <IntroText>
          By agreeing to these Terms, you promise to follow these rules:
        </IntroText>

        <TableItem>
          {" "}
          You won’t send spam! We mean “spam” as it is defined on the Spamhaus
          website.
        </TableItem>
        <TableItem>
          You won’t use purchased, rented, or third-party lists of email
          addresses.
        </TableItem>
        <TableItem>
          You’ll comply with all our policies, which forms part of these Terms.
        </TableItem>
        <IntroText>
          LNRD by Eulerity doesn’t allow accounts with the primary purpose of
          promoting or inciting harm toward others or the promotion of
          discriminatory, hateful, or harassing Content. To this end, we may
          suspend or terminate your account if you send a Campaign or otherwise
          distribute any Content that we determine, in our sole discretion,
          contains either of the following:
        </IntroText>
        <TableItem>
          A Threat of Physical Harm. This means any statement, photograph,
          advertisement, or other Content that in our sole judgment could be
          reasonably perceived to threaten, advocate, or incite physical harm to
          or violence against others.
        </TableItem>
        <TableItem>
          Hateful Content. This means any statement, image, photograph,
          advertisement, or other Content that in our sole judgment could be
          reasonably perceived to harm, threaten, promote the harassment of,
          promote the intimidation of, promote the abuse of, or promote
          discrimination against others based solely on race, ethnicity,
          national origin, sexual orientation, gender, gender identity,
          religious affiliation, age, disability, disease, or immigration
          status.
        </TableItem>
        <IntroText>
          We also may suspend or terminate your account if we determine, in our
          sole discretion, that you are either:
        </IntroText>
        <TableItem>
          an organization that has publicly stated or acknowledged that its
          goals, objectives, positions, or founding tenets include statements or
          principles that could be reasonably perceived to advocate, encourage,
          or sponsor Hateful Content or A Threat of Physical Harm;
        </TableItem>
        <TableItem>
          a person that has publicly made a comment or statement, or otherwise
          publicly made known a position, including by membership in an
          organization as discussed above, that could be reasonably perceived as
          Hateful Content or A Threat of Physical Harm; or
        </TableItem>
        <TableItem>
          a person or organization that has acted in such a way as could be
          reasonably perceived to support, condone, encourage, or represent
          Hateful Content or A Threat of Physical Harm.
        </TableItem>
        <IntroText>
          If you violate any of these rules, then we may suspend or terminate
          your account.
        </IntroText>
      </BasicTextBox>
      <BasicTextBox>
        <IntroTitle>17. Reporting Abuse</IntroTitle>
        <IntroText>
          If you think anyone is violating any of these Terms, please notify us
          immediately. If you received spam you think came from a LNRD by
          Eulerity Member, please report it to contactus@eulerity.com. (Every
          email Campaign sent through the Service has an embedded campaign
          tracking ID in the email header, making it easy to report suspected
          spam.) If you think anyone has posted material that violates any
          protected marks or copyrights, then you can notify us at
          <a href="mailto:contactus@eulerity.com">contactus@eulerity.com</a>
        </IntroText>
      </BasicTextBox>
      <BasicTextBox>
        <IntroTitle>18. Bandwidth Abuse/Throttling</IntroTitle>
        <IntroText>
          You may only use our bandwidth for your LNRD by Eulerity Campaigns. We
          provide image and data hosting only for your LNRD by Eulerity
          Campaigns, so you may not host images on our servers for anything else
          (like a website). We may throttle your sending or connection through
          our API at our discretion.
        </IntroText>
      </BasicTextBox>

      <BasicTextBox>
        <IntroTitle>19. Compliance with Laws</IntroTitle>
        <IntroText>
          You represent and warrant that your use of the Service will comply
          with all applicable laws and regulations. You’re responsible for
          determining whether the Service is suitable for you to use in light of
          your obligations under any regulations like HIPAA, GLB, EU data
          privacy laws (including the General Data Protection Regulation)
          (collectively, “EU Data Privacy Laws”), United States export control
          laws and regulations and economic sanctions laws and regulations
          (“U.S. Export Control Laws and Regulations”), or other applicable
          laws. If you’re subject to regulations (like HIPAA) and you use the
          Service, then we won’t be liable if the Service doesn’t meet those
          requirements. You may not use the Service for any unlawful or
          discriminatory activities, including acts prohibited by the{" "}
          <a href="https://www.ftc.gov/enforcement/statutes/federal-trade-commission-act">
            {" "}
            Federal Trade Commission Act
          </a>{" "}
          ,{" "}
          <a href="https://www.ftc.gov/enforcement/rules/rulemaking-regulatory-reform-proceedings/fair-credit-reporting-act">
            Fair Credit Reporting Act
          </a>
          ,{" "}
          <a href="https://www.justice.gov/crt/equal-credit-opportunity-act-3">
            Equal Credit Opportunity Act
          </a>
          ,
          <a href="https://www.ftc.gov/enforcement/rules/rulemaking-regulatory-reform-proceedings/childrens-online-privacy-protection-rule">
            Children’s Online Privacy Protection Act
          </a>{" "}
          , or other laws that apply to commerce.
        </IntroText>
        <IntroText>
          If you’re located in the European Economic Area, the United Kingdom,
          or Switzerland (collectively, the “EEA”) and/or distribute Campaigns
          or other Content through the Service to anyone located in the EEA
          (each such Member an “EEA Member”) in creating your Campaign
          distribution list, sending Campaigns via the Service, and/or otherwise
          collecting information as a result of creating or sending Campaigns,
          you represent and warrant to LNRD by Eulerity that:
        </IntroText>
        <TableItem>
          You will clearly post, maintain, and abide by a publicly accessible
          privacy notice on the digital properties from which the underlying
          data is collected that satisfies the requirements of applicable data
          protection laws, describes your use of the Service, and includes a
          link to LNRD by Eulerity’s{" "}
          <a href="/company/privacy">Privacy Policy</a>.
        </TableItem>
        <TableItem>
          You will get and maintain all necessary permissions and valid consents
          required to lawfully transfer data to LNRD by Eulerity and to enable
          such data to be lawfully collected, processed, and shared by LNRD by
          Eulerity for the purposes of providing the Service or as otherwise
          directed by you.
        </TableItem>
        <TableItem>
          You will comply with all laws and regulations applicable to the
          Campaigns sent through the Service, including those relating to (a)
          acquiring consents (where required) to lawfully send Campaigns, (b)
          the Content of Campaigns, and (c) your Campaign deployment practices.
        </TableItem>
        <TableItem>
          You will provide and obtain all notices and obtain all necessary
          consents required by applicable data protection laws to enable LNRD by
          Eulerity to deploy cookies and similar tracking technologies (like web
          beacons or pixels) lawfully on and collect data from the devices of
          contacts and end users of the Service in accordance with our policies.
        </TableItem>
        <IntroText>
          In addition, if you are an EEA Member, you acknowledge and agree that
          we have your prior written authorization to respond, at our
          discretion, to any data subject access requests we receive from your
          contacts made under EU Data Privacy Laws, or, alternatively, we may
          direct any such contacts to you so that you can respond to the request
          accordingly.
        </IntroText>
        <IntroText>
          You agree to indemnify and hold us harmless from any losses, including
          all legal fees and expenses, that result from your breach of this
          Section 20.
        </IntroText>
      </BasicTextBox>
      <BasicTextBox>
        <IntroTitle>20. U.S. Export Controls</IntroTitle>
        <IntroText>
          The software that supports the Service (the “Software”) is subject to
          U.S. Export Control Laws and Regulations. Export laws are set up by
          the government to keep certain goods and services from reaching other
          countries, usually because of security concerns or trade agreements.
          None of the Software may be downloaded or otherwise exported or
          re-exported in violation of U.S. Export Control Laws and Regulations
          and any other applicable export laws and regulations (collectively,
          “Export Control Laws”). Therefore, you agree that you won’t, directly
          or indirectly through a third party, allow the Software or your
          Campaigns to be accessed or generated from within, or distributed or
          sent to, any prohibited or embargoed country as mentioned in any
          Export Control Laws. In addition, you certify that neither you nor any
          principals, officers, directors, or any person or entity you know to
          be directly involved with the use of the Service is designated on any
          U.S. government list of prohibited or restricted persons.
        </IntroText>
        <IntroText>
          It’s important to note that this Section 21 isn’t meant to provide a
          comprehensive summary of the Export Control Laws that govern LNRD by
          Eulerity, the Service, or the Software. You’re downloading and using
          the Software at your own risk, and it’s your responsibility to consult
          with a legal advisor to make sure your use of the Service and the
          Software complies with applicable laws.
        </IntroText>
      </BasicTextBox>
    </>
  );
};
const Rights = () => {
  return (
    <>
      <BasicTitle>Rights</BasicTitle>
      <BasicTextBox>
        <IntroTitle>12. Proprietary Rights Owned by Us</IntroTitle>
        <IntroText>
          You will respect our proprietary rights in the Website and the
          software used to provide the Service (proprietary rights include, but
          aren’t limited to, patents, trademarks, service marks, trade secrets,
          copyrights, and other intellectual property).
        </IntroText>
      </BasicTextBox>
      <BasicTextBox>
        <IntroTitle>13. Proprietary Rights Owned by You</IntroTitle>
        <IntroText>
          You represent and warrant that you either own or have permission to
          use all of the material, content, data, and information (including
          your personal information and the personal information of others) you
          submit to LNRD by Eulerity in the course of using the Service
          (“Content”). You retain ownership of the Content that you upload to
          the Service. We may use or disclose your Content (including any
          personal information therein) only as described in these Terms, our
          <a href="/company/privacy">Privacy Policy</a> .
        </IntroText>
      </BasicTextBox>
      <BasicTextBox>
        <IntroTitle>14. Privacy Policy</IntroTitle>
        <IntroText>
          Your privacy is important to us. Please read our{" "}
          <a href="/company/privacy">Privacy Policy</a> . for information
          regarding how we collect, use, and disclose your Content and personal
          information and protect your privacy when you use the Service.
        </IntroText>
      </BasicTextBox>
      <BasicTextBox>
        <IntroTitle>15. Right to Review Campaigns</IntroTitle>
        <IntroText>
          We may view, copy, and internally distribute Content from your
          Campaigns and account to create algorithms and programs (“Tools”) that
          help us spot problem accounts and improve the Service. We use these
          Tools to find Members who violate these Terms or laws and to study
          data internally to make the Service smarter and create better
          experiences for Members and their contacts.
        </IntroText>
      </BasicTextBox>
    </>
  );
};
const Payment = () => {
  return (
    <>
      <BasicTitle>Payment</BasicTitle>
      <BasicTextBox>
        <IntroTitle>7. Monthly Plans</IntroTitle>
        <IntroText>
          When you sign up, you agree to recurring billing of $99/month as a fixed media management fee from your total media spend with Eulerity. Billing occurs on the same day each month, based on the date that you started the Plan.
        </IntroText>
        <IntroText>
          Our charges may be changed from time to time. If any part of a month
          is included in the Term, then payment is due for the full month.
          Payments are due for any month on the same or closest date to the day
          you made your first monthly payment (the “Pay Date”). If you go over
          your sending limit and reach another pricing level, then you’ll have
          to pay at the higher level on or before the next Pay Date. If the Term
          ends before that payment is due, you’ll still be required to make one
          payment at the higher level.
        </IntroText>
      </BasicTextBox>
      <BasicTextBox>
        <IntroTitle>8. Credit Cards</IntroTitle>
        <IntroText>
          As long as you’re a Member with a paid account or otherwise have an
          outstanding balance with us, you’ll provide us with valid credit card
          information and authorize us to deduct the monthly charges against
          that credit card. You’ll replace the information for any credit card
          that expires with information for a valid one. If your credit card is
          automatically replaced with a new card by a payment processor, you
          acknowledge and agree that we’re authorized to deduct any charges on
          your account against the new credit card. Anyone using a credit card
          represents and warrants that they are authorized to use that credit
          card, and that any and all charges may be billed to that credit card
          and won’t be rejected. If we’re unable to process your credit card
          order, we’ll try to contact you by email and suspend your account
          until your payment can be processed.
        </IntroText>
      </BasicTextBox>
      <BasicTextBox>
        <IntroTitle>9. Refunds</IntroTitle>
        <IntroText>
          We’ll give you a refund for a prepaid month if we stop providing the
          Service and terminate your account without cause. You won’t be
          entitled to a refund from us under any other circumstances. We may, at
          our sole discretion, offer a refund if a Member requests one.
        </IntroText>
      </BasicTextBox>
      <BasicTextBox>
        <IntroTitle>10. Charges for Add-Ons</IntroTitle>
        <IntroText>
          If you use an Add-on that has a charge, then you’ll be billed that
          additional amount with each billing cycle for as long as the Add-on is
          active.
        </IntroText>
      </BasicTextBox>
      <BasicTextBox>
        <IntroTitle>11. Billing Changes</IntroTitle>
        <IntroText>
          We may change our fees at any time by posting a new pricing structure
          to our Website and/or sending you a notification by email. Quoted fees
          don’t include sales or other transaction-based taxes of any kind.
        </IntroText>
      </BasicTextBox>
    </>
  );
};
const Account = () => {
  return (
    <>
      <BasicTitle>Account</BasicTitle>
      <BasicTextBox>
        <IntroTitle>1. Eligibility</IntroTitle>
        <IntroText>In order to use the Service, you must:</IntroText>
        <TableItem>
          be at least eighteen (18) years old and able to enter into contracts;
        </TableItem>
        <TableItem>complete the registration process;</TableItem>
        <TableItem>agree to these Terms;</TableItem>
        <TableItem>
          provide true, complete, and up-to-date contact and billing
          information; and
        </TableItem>
        <TableItem>
          not be based in Cuba, Iran, North Korea, Syria, or any other country
          that is subject to a U.S. government embargo, or that has been
          designated by the U.S. government as a “terrorist-supporting” country.
        </TableItem>
        <IntroText>
          By using the Service, you represent and warrant that you meet all the
          requirements listed above, and that you won’t use the Service in a way
          that violates any laws or regulations. Note that by representing and
          warranting, you are making a legally enforceable promise.
        </IntroText>
        <IntroText>
          LNRD by Eulerity may refuse service, close accounts of any users, and
          change eligibility requirements at any time.
        </IntroText>
      </BasicTextBox>
      <BasicTextBox>
        <IntroTitle>2. Term</IntroTitle>
        <IntroText>
          When you sign up for the Service and agree to these Terms, the
          Agreement between you and LNRD by Eulerity is formed, and the term of
          the Agreement (the “Term”) will begin. The Term will continue for as
          long as you have a LNRD by Eulerity account or until you or we
          terminate the Agreement in accordance with these Terms, whichever
          happens first. Entering your username and clicking the “Create Free
          Account” button means that you’ve officially “signed” and accepted the
          Terms. If you sign up for the Service on behalf of a company or other
          entity, you represent and warrant that you have the authority to
          accept these Terms and enter into the Agreement on its behalf.
        </IntroText>
      </BasicTextBox>
      <BasicTextBox>
        <IntroTitle>3. Closing Your Account</IntroTitle>
        <IntroText>
          You may cancel your subscription with Eulerity at anytime after 120
          days of initial subscription date by giving us a 30 day cancellation
          notice. We may suspend the Service to you at any time, with or without
          cause. If we terminate your account without cause, and your account is
          a paid account, we’ll refund a prorated portion of your monthly
          prepayment or reimburse you for unused amount. We won’t refund or
          reimburse you in any other situation, including if your account is
          suspended or terminated for cause, like a breach or any violation of
          the Agreement. If your account is inactive for 24 or more months, we
          may delete the account. Once your account is terminated, you
          acknowledge and agree that we may permanently delete your account and
          all the data associated with it, including your Campaigns. Usernames
          are unique and can only be used once. If your account has been
          terminated, the username will no longer be available for use on any
          future accounts and cannot be reclaimed.
        </IntroText>
      </BasicTextBox>
      <BasicTextBox>
        <IntroTitle>4. Changes</IntroTitle>
        <IntroText>
          We may change any of the Terms by posting revised Terms of Use on our
          Website and/or by notifying you of the new Terms by sending an email
          to the last email address you gave us or displaying prominent notice
          within the Service. Unless you terminate your account within 10 days,
          the new Terms will be effective immediately and apply to any continued
          or new use of the Service. We may change the Website, the Service,
          Add-ons, or any features of the Service at any time, and we may
          discontinue the Website, the Service, Add-ons, or any features of the
          Service at any time.
        </IntroText>
      </BasicTextBox>
      <BasicTextBox>
        <IntroTitle>5. Account and Password</IntroTitle>
        <IntroText>
          You’re responsible for keeping your account name and password
          confidential. You’re also responsible for any account that you have
          access to and any activity occurring in such account (other than
          activity that LNRD by Eulerity is directly responsible for that isn’t
          performed in accordance with your instructions), whether or not you
          authorized that activity. You’ll immediately notify us of any
          unauthorized access or use of your accounts. We’re not responsible for
          any losses due to stolen or hacked passwords that are caused by or
          result from your negligence. We don’t have access to your current
          password, and for security reasons, we may only provide you with
          instructions on how to reset your password. We have the right to
          update any of your contact information in your account for billing
          purposes. In addition, you represent and warrant that all information
          you provide to us when you establish an account, and when you access
          and use the Service, is and will remain complete and accurate.
        </IntroText>
      </BasicTextBox>

      <BasicTextBox>
        <IntroTitle>6. Account Disputes</IntroTitle>
        <IntroText>
          We don’t know the inner workings of your organization or the nature of
          your personal relationships. You won’t request access to or
          information about an account that’s not yours, and you’ll resolve any
          account-related disputes directly with the other party. We decide who
          owns an account based on the content in that account, and if multiple
          people or entities are identified in the content, then we’ll rely on
          the contact and profile information listed for that account. In cases
          where differing contact and profile information is present, we’ll
          require you to resolve the matter through proper channels outside of
          LNRD by Eulerity.
        </IntroText>
        <IntroText>
          When a dispute is identified, we may suspend any account associated
          with the dispute, including disabling login and sending capabilities,
          to protect the security and privacy of the data held within the
          account.
        </IntroText>
      </BasicTextBox>
    </>
  );
};
const Saladworks = () => {
  return (
    <div>
      <Intro>
        <PrivacyTitle>
            Terms of Service for Saladworks
        </PrivacyTitle>
        <InTroDate>Effective Date: Tuesday, June 28th, 2021</InTroDate>
        <IntroText>Thanks for using LNRD by Eulerity.</IntroText>
        <IntroText>
          Please read these Terms carefully. By using LNRD by Eulerity or
          signing up for an account, you’re agreeing to these Terms, which will
          result in a legal agreement between you and LNRD by Eulerity
          (“Agreement”). We’ll start with the basics, including a few
          definitions that should help you understand these Terms. LNRD by
          Eulerity (“LNRD by Eulerity,” “we,” or “us”) is an online marketing
          platform (the “Service”) offered through the URL{" "}
          <a href="http://www.eulerity.com">www.eulerity.com</a> (we’ll refer to
          it as the “Website”) that allows you to, among other things, create,
          send, and manage certain marketing campaigns, including, without
          limitation, emails, advertisements, and mailings (each a “Campaign,”
          and collectively, “Campaigns”). LNRD by Eulerity is a Georgia limited
          liability company whose legal name is Eulerity Inc. LNRD by Eulerity
          has employees, independent contractors, and representatives (“our
          Team”). As a customer of the Service or a representative of an entity
          that’s a customer of the Service, you’re a “Member” according to this
          Agreement (or “you”).
        </IntroText>
        <IntroText>
          These Terms of Use define the terms and conditions under which you’re
          allowed to use the Service in accordance with the Agreement, and how
          we’ll treat your account while you’re a Member. If you don’t agree to
          these Terms, you must immediately discontinue your use of the Service.
        </IntroText>
        <IntroText>
          LNRD by Eulerity uses Google Maps to provide certain features of the
          Service, and, as a result, we are contractually obligated to make our
          Members aware of certain terms related to the use of such features.
          Therefore, you acknowledge and agree that by signing up for an account
          and using the Service, you are also bound by the{" "}
          <a href="https://maps.google.com/help/terms_maps/">
            Google Maps/Google Earth Additional Terms of Service
          </a>{" "}
          (including the{" "}
          <a href="https://policies.google.com/privacy?hl=en&gl=us">
            Google Privacy Policy
          </a>
          ). If you have a
        </IntroText>
        <IntroText>
          If you have any questions about our Terms, feel free to email us at{" "}
          <a href="mailto:contactus@eulerity.com">contactus@eulerity.com </a> .
        </IntroText>
        <Account />
        <Payment />
        <Rights />
        <Rules />
        <Liability />
        <Fine />
      </Intro>
      <Footer />
    </div>
  );
};

export default Saladworks;
