import React, {useState} from "react";
import {
  News,
  NewsContent,
  NewsContentTitle,
  NewsRead,
  NewsImage,
  NewsList,
  NavList,
  NavListItem,
  NavListLink,
  NavBar,
  Load,
  CaseCard,
  CaseLogo,
  PodcastItem,
  ContentHubBannerContainer
} from "./style";
import Button from "../../../components/buttons/Button";
import Footer from "../../../components/footer/Footer";
import contentHubBanner from '../../../assets/contentHubBanner.png';

import {NewsArticle, useCases} from "./news";
import {PodcastList} from "./podcastList";
import {Press} from './press';

const Hero = () => {
  return (
    <ContentHubBannerContainer>
        <img src={contentHubBanner} alt='img' />
    </ContentHubBannerContainer>
  )
};

const NewsNavBar = ({step, handleStep}) => {
  return (
    <NavBar>
      <NavList>
        <NavListItem>
        <NavListLink
            onClick={() => handleStep(0)}
            className={step === 0 ? "active" : ""}
          >
            Press
          </NavListLink>
          <NavListLink
            onClick={() => handleStep(1)}
            className={step === 1 ? "active" : ""}
          >
            Content
          </NavListLink>
          <NavListLink
            onClick={() => handleStep(2)}
            className={step === 2 ? "active" : ""}
          >
            Case Studies
          </NavListLink>
          <NavListLink
            onClick={() => handleStep(3)}
            className={step === 3 ? "active" : ""}
          >
            Podcasts
          </NavListLink>
        </NavListItem>
      </NavList>
    </NavBar>
  );
};

const SingleNews = ({title, date, minute, by, img, link, description}) => {
  return (
    <News>
      <NewsContent>
        <span className="date">{date}</span>
        <NewsContentTitle href={link} target="_blank">
          {title}
        </NewsContentTitle>
        {description ?
        <NewsRead>
          {description}
        </NewsRead> :
        <NewsRead>
          {minute} read {by ? <span className="by"> - by {by}</span> : ""}
        </NewsRead>
        }
      </NewsContent>
      {img && <NewsImage src={img} alt="article logo" />}
    </News>
  );
};

const NewsLists = ({news, v}) => {
  return (
    <NewsList>
      {news.slice(0, v).map((article) => (
        <SingleNews {...article} key={article.id} />
      ))}
    </NewsList>
  );
};

const LoadMore = ({visible}) => {
  return (
    <Load>
      <Button click={visible} bg="#256DFE" color="#ffffff">
        load more
      </Button>
    </Load>
  );
};

///use case
const UseCase = ({useCaseList}) => {
  return (
    <NewsList className="usecase">
      {useCaseList.map((data) => (
        <SingleUseCase {...data} key={data.id} />
      ))}
    </NewsList>
  );
};

const SingleUseCase = ({text, pdf, logo, color}) => {
  return (
    <CaseCard>
      <CaseLogo color={color}>
        <img src={logo} alt="logo" />
      </CaseLogo>
      <div className="content">
        <span>{text}</span>
        <Button
          bg={color}
          color="#ffffff"
          className="contentBtn"
          href={pdf}
          target="_blank"
        >
          Case study
        </Button>
      </div>
    </CaseCard>
  );
};

//podcasts
const Podscasts = ({PodcastList}) => {
  return (
    <NewsList className="podcasts">
      {PodcastList.map((podcast) => (
        <PodcastItem key={podcast.episode}>
          <div className="episodes__line-item">
            <a
              href={podcast.spotifyLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="episodes__player">
                <div className="play-button"></div>
              </div>
              <div className="episodes__details">
                <div className="episodes__details__count">
                  Episode {podcast.episode}
                </div>
                <div className="episodes__details__title">{podcast.title}</div>
                <div className="episodes__details__duration">
                  Duration - {podcast.duration} mins
                </div>
                <div className="episodes__details__subtitle">
                  {podcast.subtitle}
                </div>
              </div>
            </a>
            <div className="episodes__actions">
              <a
                href={podcast.spotifyLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="episodes__actions__item">
                  <div className="episodes__actions__item__icon episodes__actions__item__icon__download">
                    <i className="fab fa-spotify"></i>
                  </div>
                  Spotify
                </div>
              </a>

              {podcast.appleLink ? (
                <a
                  href={podcast.appleLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="episodes__actions__item trigger-subscription">
                    <div className="episodes__actions__item__icon episodes__actions__item__icon__subscribe">
                      <i className="fab fa-apple"></i>
                    </div>
                    Apple
                  </div>
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
        </PodcastItem>
      ))}
    </NewsList>
  );
};

const Newsroom = () => {
  const [visible, setVisible] = useState(3);
  const [newsList] = useState(NewsArticle);
  const [step, setStep] = useState(0);
  const handleMore = () => {
    setVisible(visible + 2);
  };

  const HandleRoute = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <NewsLists news={Press} v={visible} />
            <LoadMore visible={handleMore} />
          </>
        )
      case 1:
        return (
          <>
            <NewsLists news={newsList} v={visible} />
            <LoadMore visible={handleMore} />
          </>
        );
      case 2:
        return <UseCase useCaseList={useCases} />;
      case 3:
        return <Podscasts PodcastList={PodcastList} />;
      default:
        return (
          <>
            <NewsLists news={newsList} v={visible} />
            <LoadMore visible={handleMore} />
          </>
        );
    }
  };

  return (
    <>
      <Hero />
      <NewsNavBar handleStep={setStep} step={step} />
      {HandleRoute(step)}
      <Footer />
    </>
  );
};

export default Newsroom;
