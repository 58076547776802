import React from 'react';
import { MediumArticleContainer, Title, Author, Paragraph, Image, EstimatedRead, Headers, BoldItalics } from './style';
import Aaronlevie from '../../assets/aaronlevie.jpg';

const WhatDoISayNow = () => {
    return (
        <MediumArticleContainer>
            <Title> What do I say now? </Title>
            <Author> How communication in this new era is being tested. 
                <EstimatedRead> 2.5 min read </EstimatedRead>
            </Author>

            <Paragraph>
                First and foremost, we deeply appreciate your partnership with Eulerity, and we understand that this can be a very difficult time within your teams and local communities. As with any challenging time, new opportunities can be created. To this point, we hope to provide some constructive thoughts based on our own experiences with customers to help the broader business community.
            </Paragraph>

            <Headers>
                We are writing a new chapter on how to communicate in crisis
            </Headers>

            <Paragraph>
                Communication styles have never been as important to our employees & customers during this unprecedented time. Over the last month, we’ve seen companies take on different communication strategies in a day by day changing marketplace.
            </Paragraph>

            <Paragraph>
            From our observation, we’ve seen brands and their business stakeholders communicate in three ways:
            </Paragraph>

            <Headers>
                1: Business as usual
            </Headers>

            <Paragraph>
                No evident change in their marketing or communication strategy.
            </Paragraph>

            <Headers>
                2: COVID-19 proactive communication
            </Headers>

            <Paragraph>
                This includes integrating their brand into this new reality while providing consumer awareness of the considerations and accommodations to serve customers.
            </Paragraph>

            <Headers>
                3: No communication
            </Headers>

            <Paragraph>
                In this time of uncertainty, consumers are searching and consuming the Internet in record volume. With more working people at home than ever before, there is simply more time to plan for the future.
            </Paragraph>

            <Headers>
                The durability of the enterprise
            </Headers>

            <Paragraph>
                At Eulerity, we work with corporate enterprise teams in charge of supporting their business owners globally. During the last month we’ve seen brands adapt to this changing crisis at different speeds.
            </Paragraph>

            <Paragraph>
                The common thread of the companies out in front of this pandemic vs. the companies catching up often has to do with how they operate from the inside.
            </Paragraph>

            <Paragraph>
                Back in September, we wrote about <a href="https://eulerity.medium.com/thinking-digital-without-disruption-943a55df1064">“Thinking Digital Without Disruption”.</a>
            </Paragraph>

            <Paragraph>
                As it turns out…disruption may be needed to make your systems digital first.
            </Paragraph>

            <Headers> Aaron Levie, CEO of Box said it best this week: </Headers>

            <Image src={Aaronlevie} alt="img" />

            <Paragraph>
                Never before has speed and flexibility been needed to adapt and change to the current cycle of health news. This agility makes it possible to empower business owners trying to save their business and plan for an eventual rebound.
            </Paragraph>

            <BoldItalics> “If digital transformation isn’t a priority now, then when?” </BoldItalics>

            <Paragraph>
                While this may be obvious, communicating options to your existing or new customers with regard to how you are supporting them during this time is critical. Today’s business disruption requires reviewing how digital transformation operates within your company.
            </Paragraph>

            <Headers> A few questions come to mind: </Headers>

            <Paragraph>
                1: Could any of our customer communication practices happen faster with technology?
            </Paragraph>

            <Paragraph>
                2: Is my current system vulnerable to more efficient methods being used by my competitors?
            </Paragraph>

            <Paragraph>
                3: How many hours am I using in the day to support my team, customers, and business stakeholders? Is there a more efficient way to do this?
            </Paragraph>

            <Paragraph>
                As the world rebounds the speed of digital transformation will be judged not only by your internal teams and constitutes, but by your customers. Modern technology has allowed leading brands to take advantage of the speed of distribution in getting important messages out to audiences at a record breaking pace.
            </Paragraph>
        </MediumArticleContainer>
    )
}

export default WhatDoISayNow;