import React from 'react';

import {
  Section,
  SectionMasked,
  BackgroundMask,
  SectionBackground,
  Guides,
  GuideContainer,
  Guide,
  SectionContainer,
  SectionLayout,
  SectionLayoutContainer,
  LogoGrid,
  CopyBody,
  CopyWrap,
  OurBrandTitle,
  CopyContent,
  OurBrandSeparator
} from './style';

import workout from '../../assets/customer-logo/workout.svg';
import wpp from '../../assets/customer-logo/wpp.svg';
import wod from '../../assets/customer-logo/wood.svg';
import Subway from '../../assets/customer-logo/subway.svg';
import Juice from '../../assets/customer-logo/juice.svg';
import Mathnasium from '../../assets/customer-logo/mathnasium.png';
import HandAndStone from '../../assets/customer-logo/hand-and-stone.png';
import Luxottica from '../../assets/customer-logo/luxottica.png';
import Xponential from '../../assets/customer-logo/xponential.png';

const Customer = () => {
  return (
    <Section className='Section' style={{marginTop: "1rem"}}>
      <CopyWrap>
        <OurBrandTitle>Our Brands</OurBrandTitle>
        <OurBrandSeparator />
        <CopyContent>
            Companies of all sizes—from startups to Fortune 500s—use Eulerity's software and APIs to
            automate their marketing operations, optimize their ad spend, and manage their marketing
            efforts.
        </CopyContent>
      </CopyWrap>
      <SectionMasked>
        <BackgroundMask>
          <SectionBackground>
            <Guides>
              <GuideContainer>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
                <Guide></Guide>
              </GuideContainer>
            </Guides>
          </SectionBackground>
        </BackgroundMask>
      </SectionMasked>

      <SectionContainer>
        <SectionLayoutContainer>
          <SectionLayout>
            <LogoGrid>
              <img src={Juice} alt='clean juice' className='customerlogo' />

              <img src={Subway} alt='Subway' className='customerlogo' />

              <img src={HandAndStone} alt='hand and stone' className='customerlogo' />

              <img src={Mathnasium} alt='mathnasium' className='customerlogo' />

              <img src={workout} alt='workout logo' className='customerlogo' />

              <img src={Xponential} alt='Xponential logo' className='customerlogo' />

              <img src={Luxottica} alt='luxottica' className='customerlogo' />

              <img src={wpp} alt='wpp logo' className='customerlogo' />
            </LogoGrid>
          </SectionLayout>
        </SectionLayoutContainer>
      </SectionContainer>
    </Section>
  );
};

export default Customer;
