import styled from 'styled-components';

export const GlobalContent = styled.div`
  -webkit-box-flex: 1;
  flex-grow: 1;
`;

export const Logo = styled.span`
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.medium {
    height: 48px;
    width: 48px;
  }

  &.color-green-bg {
    background: #1fdd95;
    color: #fff;
  }
`;
export const ControlTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
export const ControlIcon = styled.figure`
  margin-right: 16px;

  @media (min-width: 1040px) {
    margin-left: -20px;
  }
`;

export const ControlContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;

  @media (min-width: 420px) {
    flex-direction: row;
    align-items: center;
    margin-top: 0;
  }
`;

export const CommonUpperCaseTitle = styled.h1`
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;

  @media (min-width: 670px) {
    font-size: 21px;
    line-height: 32px;
  }
`;
export const Main = styled.main`
  font-family: 'Opens Sans', sans-serif;
`;

export const Nav = styled.nav`
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.025em;
  padding: 12px 0;
  z-index: calc(500 - 1);

  &.container-lg {
    display: flex;
    align-items: center;
    justify-content: space-between;
    -moz-box-pack: justify;
    position: relative;
  }
`;
export const Home = styled.a`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
`;
export const NavMaskContainer = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin: 0 -20px 0 20px;
  overflow: auto;
`;

export const NavList = styled.ul`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 0 10px;
  line-height: 30px;
  margin-bottom: -10px;
`;

export const Item = styled.li`
  padding: 0 10px;
`;

export const HeroSection = styled.section`
  padding: 120px 0 216px;

  &.container {
    position: relative;
  }
  .testStripes .stripe:first-child {
    background-color: #87a2ff;
    grid-row: -2;
    grid-column: 1 / span 7;
  }
  .testStripes .stripe:nth-child(2) {
    background-color: #87a2ff;
    grid-row: -2;
    grid-column: span 7 / -5;
  }

  .testStripes .stripe:nth-child(3) {
    background-color: #256dfe;
    grid-row: -2;
    grid-column: span 6 / -1;
  }

  .testStripes .stripe:nth-child(4) {
    background-color: #765de2;
    grid-row: -3;
    grid-column: span 7 / -1;
  }
  .testStripes .stripe:nth-child(5) {
    background-color: #87a2ff;
    grid-row: -4;
    grid-column: span 5 / -6;
  }

  .testStripes .stripe:nth-child(7) {
    background-color: #256dfe;
    grid-row: -1;
    grid-column: 8 / span 1;
  }
  .testStripes .stripe:nth-child(8) {
    -webkit-box-shadow: inset 0 0 0 1.5px #e6ebf1;
    box-shadow: inset 0 0 0 1.5px #e6ebf1;
    grid-row: -5;
    grid-column: 10 / span 5;
  }
  .testStripes .stripe:nth-child(9) {
    -webkit-box-shadow: inset 0 0 0 1.5px #e6ebf1;
    box-shadow: inset 0 0 0 1.5px #e6ebf1;
    grid-row: -5;
    grid-column: 1 / span 5;
  }
  .testStripes .stripe:nth-child(10) {
    -webkit-box-shadow: inset 0 0 0 1.5px #e6ebf1;
    box-shadow: inset 0 0 0 1.5px #e6ebf1;
    grid-row: -6;
    grid-column: 1 / span 7;
  }

  .testStripes .stripe:nth-child(11) {
    -webkit-box-shadow: inset 0 0 0 1.5px #e6ebf1;
    box-shadow: inset 0 0 0 1.5px #e6ebf1;
    grid-row: -7;
    grid-column: 8 / span 7;
  }
  .testStripes .stripe:nth-child(6) {
    -webkit-box-shadow: inset 0 0 0 1.5px #e6ebf1;
    box-shadow: inset 0 0 0 1.5px #e6ebf1;
    grid-row: -8;
    grid-column: span 8/ -1;
  }
`;

export const CommonTitle = styled.h1`
  font-weight: 300;
  font-size: 45px;
  line-height: 56px;
  color: #0a2540;

  letter-spacing: -0.01em;
  @media (min-width: 670px) {
    font-size: 53px;
    line-height: 68px;
  }
  &.productTitle {
    max-width: 740px;
    margin-top: 32px;
    font-weight: 400;
  }
`;

export const CommonButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 670px) {
    margin-top: 32px;
  }

  a {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin: 10px 10px 10px 0;
  }

  a:not(:last-child) {
    margin-right: 12px;
  }
  &.common {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
`;

export const CommonButton = styled.a`
  white-space: nowrap;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  -webkit-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
    0 1px 3px rgba(0, 0, 0, 0.08);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  background: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  color: #000;
  text-decoration: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;

  &.default {
    background: #1fdd95;
    color: #fff;
  }
  &:hover {
    transform: translateY(-3px);
  }
`;

export const SectionIntro = styled.section`
  margin-top: 100px;
  @media (min-width: 800px) {
    margin-top: 50px;
  }
  @media (min-width: 1285px) {
    margin-top: 350px;
  }
  @media (min-width: 2000px) {
    margin-top: 500px;
  }
`;

export const HighLight = styled.em`
  color: #765de2;
  padding: 0;
`;

export const SectionLayoutColumns = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1040px;
  padding: 0 20px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 670px) {
    display: grid;
    grid-column-gap: 40px;
    grid-row-gap: 40px;
  }

  @media (min-width: 670px) {
    &.layoutColumns--3 {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media (min-width: 670px) {
    &.layoutColumns--4 {
      grid-template-columns: repeat(4, 1fr);
    }
  }
`;

export const LayoutColumn = styled.div``;

export const FeatureDetail = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  @media (min-width: 670px) {
    margin-bottom: 0;
  }
`;

export const FeatureDetailIcon = styled.div`
  margin: 0 16px 20px 0;
  display: flex;
`;

export const FeatureDetailContent = styled.div``;

export const FeatureDetailTitleContent = styled.div`
  margin-top: 8px;
`;

export const FeatureDetailBody = styled.div`
  margin-top: 8px;
`;
export const CommonBodyText = styled.p`
  font-weight: 400;
  font-size: 17px;
  line-height: 28px;
  color: #525f7f;
`;

export const CommonBodyTittle = styled.h1`
  font-weight: 500;
  font-size: 19px;
  line-height: 32px;
  color: #32325d;

  &.normal {
    color: #32325d;
  }
`;

/******logo******/

export const LogoItem = styled.section`
  margin-top: 50px;
  margin-bottom: 50px;

  .sub {
    width: 150px;
    height: 150px;
    margin: 0 4px;
  }
`;
export const LogoList = styled.ul`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  @media (min-width: 1040px) {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 -20px;
  }

  img {
    border-radius: 0;
    box-shadow: none;
  }
`;

/**********tech */
