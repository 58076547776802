import styled from "styled-components";
import bg from "../../assets/fcc.png";
import masked from "../../assets/laptop-mask.svg";
import laptopDevice from "../../assets/laptopDevice.jpg";
import controls from "../../assets/controlsLaptop.svg";
import groundShadow from "../../assets/ground-shadow.png";
export const GlobalContent = styled.div`
  -webkit-box-flex: 1;
  flex-grow: 1;
`;

export const Header = styled.header`
  position: relative;

  .testStripes .stripe:first-child {
    background-color: #f5c15c;
    grid-row: -2;
    grid-column: 1 / span 8;
  }

  .testStripes .stripe:nth-child(2) {
    background-color: #f5c15c;
    grid-row: -9;
    grid-column: span 6 / -1;
  }

  .testStripes .stripe:nth-child(3) {
    background-color: #256dfe;
    grid-row: -10;
    grid-column: span 4 / -1;
  }

  .testStripes .stripe:nth-child(4) {
    background-color: #256dfe;
    grid-row: -3;
    grid-column: 1 / span 5;
  }
  .testStripes .stripe:nth-child(5) {
    -webkit-box-shadow: inset 0 0 0 1.5px #e6ebf1;
    box-shadow: inset 0 0 0 1.5px #e6ebf1;
    grid-row: -4;
    grid-column: 5 / span 5;
  }

  .testStripes .stripe:nth-child(6) {
    -webkit-box-shadow: inset 0 0 0 1.5px #e6ebf1;
    box-shadow: inset 0 0 0 1.5px #e6ebf1;
    grid-row: -5;
    grid-column: 10 / span 5;
  }

  .testStripes .stripe:nth-child(7) {
    -webkit-box-shadow: inset 0 0 0 1.5px #e6ebf1;
    box-shadow: inset 0 0 0 1.5px #e6ebf1;
    grid-row: -4;
    grid-column: 15 / span 5;
  }
`;

export const Main = styled.main``;

export const Intro = styled.section`
  margin: 0 auto;
  padding: 190px 0 280px;
  text-align: center;
  position: relative;
  z-index: 1;

  @media (max-width: 1024px) and (min-width: 600px) {
    margin-top: 35px;
  }
`;

export const IntroCopy = styled.div`
  max-width: 880px;
  margin: 0 auto;
  @media (min-width: 670px) {
    text-align: center;
  }
  .process {
    @media (max-width: 880px) {
      margin-top: -200px;
    }
  }
`;

export const CommonSectionTitle = styled.h2`
  font-weight: 400;
  font-size: 34px;
  line-height: 44px;
  color: #32325d;

  margin: 8px 0;

  @media (min-width: 670px) {
    font-size: 42px;
    line-height: 52px;
    margin-top: 40px;
  }
`;

export const CommonIntroText = styled.p`
  font-weight: 300;
  font-size: 21px;
  line-height: 32px;
  color: #424770;
  margin-bottom: 8px;

  @media (min-width: 670px) {
    font-size: 24px;
    line-height: 36px;
  }

  &.color-blue {
    color: #32325d;
  }
`;

export const DescriptionSection = styled.section`
  position: relative;
  padding: 10px 0 120px;
  margin-top: 180px;
  font-family: "Open Sans", sans-serif;
`;
export const SectionLayoutColumns = styled.div`
  display: flex;
  font-family: "Open Sans", sans-serif;
  flex-direction: column;
  max-width: 1040px;
  padding: 0 20px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;

  @media (min-width: 670px) {
    display: grid;
    grid-column-gap: 40px;
    grid-row-gap: 40px;
  }

  @media (min-width: 670px) {
    &.layoutColumns--3 {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media (min-width: 670px) {
    &.layoutColumns--4 {
      grid-template-columns: repeat(4, 1fr);
    }
  }
`;

export const SectionTestimonials = styled.section`
  position: relative;
  padding: 20px 0 24px;
  background-color: #32325d;
  @media (min-width: 880px) {
    margin-top:-100px;
  }

  .main-carousel {
    height: 200px;
    position: relative;

    @media (max-width: 880px) {
      height: 300px;
    }
  }

  .carousel-cell {
    width: 100%; /* full width */
    height: 100%; /* height of carousel */
    margin-right: 10px;
    text-align: center;
    font-size: 2rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    padding: 30px;
    margin-bottom: -10px;
    -webkit-mask-image: linear-gradient(
      180deg,
      #000,
      #000 calc(100% - 10px),
      transparent calc(100% - 10px),
      transparent
    );
    mask-image: linear-gradient(
      180deg,
      #000,
      #000 calc(100% - 10px),
      transparent calc(100% - 10px),
      transparent
    );

    @media (max-width: 880px) {
     height: 300px;
     flex-direction:column;
   
    }

    @media (min-width: 885px) {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
  }

  .flickity-prev-next-button {
    background: #87a2ff;
  }

  .flickity-button-icon {
    fill: white;
  }

  .flickity-page-dots .dot {
    width: 12px;
    height: 12px;
    opacity: 1;
    background: transparent;
    border: 2px solid white;
  }
  /* fill-in selected dot */
  .flickity-page-dots .dot.is-selected {
    background: white;
  }

  .author {
    text-align: center;
    margin-bottom: 30px;
    @media (min-width: 670px) {
      text-align: left;
      margin-bottom: 0;
      padding-left: 30px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-preferred-size: 50%;
      flex-basis: 50%;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }

    @media (max-width: 880px) {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
  img {
    box-shadoow: none;
    border-radius: 0;
  }

  .avatar {
    position: relative;
    margin-bottom: 20px;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    overflow: hidden;
    -ms-flex-negative: 0;
    flex-shrink: 0;

    @media (min-width: 670px) {
      margin-bottom: 0;
      margin-right: 25px;
    }
  }

  blockquote {
    -ms-flex-preferred-size: 60%;
    flex-basis: 60%;
    padding-left: 20px;
    font-weight:700;
    font-size: 12px;
    line-height: 15px;
    color: #fff;
  

    @media (min-width: 880px) {
    font-size: 16px;
    line-height: 25px;
    padding: 7px;
    }

    @media (max-width: 886px) {
    font-size: 13px;
    width: 90%;
    margin:0 auto;
    font-weight:500;
    line-height: 15px;
    margin-top: 135px;

 
    }
    &::before {
      background-position: 0 0;
      left: -7px;
      margin-left: -12px;
    }
  }

  .byline {
    .name {
      color: #fcd669;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 19px;
      margin-top: 20px;

      @media (max-width: 880px) {
      text-align:center;
      margin-top:-10px;
 
    }
    }

    .title {
      color: #fff;
      font-size: 16px;

      @media (max-width: 880px) {
      margin-top:-10px;
      text-align:center;
    }
  }
`;

export const FeatureSection = styled.section`
  position: relative;
  padding: 0 0 120px;
  margin-top: 150px;

  @media (max-width: 880px) {
    margin-top: 0px;
  }
`;

export const Columns = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

  @media (min-width: 670px) {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .left {
    -ms-flex-preferred-size: 51%;
    flex-basis: 51%;
  }
  .right {
    -ms-flex-preferred-size: 49%;
    flex-basis: 49%;
  }

  .device-container {
    position: relative;
  }

  .devices-dahboard {
    display: none;
    width: 1000px;
    height: 750px;
    position: absolute;
    z-index: -1;
    left: 30px;
    top: 0px;
    bottom: -140px;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
    -webkit-transition: opacity 1s ease;
    transition: opacity 1s ease;

    @media (min-width: 670px) {
      display: block;
      -webkit-transform: none;
      transform: none;
    }

    &.loaded {
      opacity: 1;
    }
    & div {
      position: absolute;
      width: 1000px;
      height: 750px;
      background-size: 100% 100%;
    }
    .screen {
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
      overflow: hidden;
      will-change: transform;
    }

    .ground {
      .shadow {
        background-image: url(${groundShadow});
      }
    }

    .laptop {
      -webkit-mask: url(${masked});
      mask: url(${masked});

      .device {
        background-image: url(${laptopDevice});
      }

      .controls {
        background-image: url(${controls});
      }

      .screen {
        width: 720px;
        height: 450px;
        border-radius: 6px;
        -webkit-transform: matrix3d(
          0.8562198209416383,
          0.021937972700884066,
          0,
          0.00007058940264233656,
          0.08894470190852294,
          0.8079581585858137,
          0,
          -0.00009582949605178664,
          0,
          0,
          1,
          0,
          35,
          42,
          0,
          1
        );
        transform: matrix3d(
          0.8562198209416383,
          0.021937972700884066,
          0,
          0.00007058940264233656,
          0.08894470190852294,
          0.8079581585858137,
          0,
          -0.00009582949605178664,
          0,
          0,
          1,
          0,
          35,
          42,
          0,
          1
        );
        -webkit-box-shadow: 1px 1px 4px rgba(107, 124, 147, 0.5);
        box-shadow: 1px 1px 4px rgba(107, 124, 147, 0.5);
        background: url(${bg}) 0 0/100% 100%;
        @media (-webkit-min-device-pixel-ratio: 1.3),
          (-webkit-min-device-pixel-ratio: 1.25),
          (min-resolution: 120dpi) {
          background-image: url(${bg});
        }
      }
    }
  }

  .right {
    position: relative;
  }
`;

export const ProcessSection = styled.section`
  background-color: #f6f9fc;
  padding-top: 260px;
  z-index: 0;
  position: relative;
  padding: 150px 0 120px;
  margin-top: -50px;
  z-index: 1;
  @media (max-width: 880px) {
    margin-top: -250px;
  }

  .timeline {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    margin-top: 70px;

    @media (min-width: 670px) {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }
    .line {
      position: absolute;
      top: 40px;
      bottom: 40px;
      left: 39px;
      width: 2px;
      background-color: #e6ebf1;

      @media (min-width: 670px) {
        top: 52px;
        bottom: auto;
        left: 80px;
        right: 80px;
        width: auto;
        height: 2px;
      }
    }

    .step {
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: flex-start;
      justify-content: space-between;

      @media (min-width: 670px) {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        max-width: 175px;
        margin: 0 20px;
        align-items: center;
      }

      .text {
        text-align: center;
      }

      .text--mp {
        @media (min-width: 670px) {
          margin-top: -25px;
        }
      }
      .icon-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        position: relative;
        margin-right: 20px;

        @media (min-width: 670px) {
          margin: 0 auto 15px;
        }
      }

      .night {
        display: inline-block;
        height: 24px;
        padding: 0 10px;
        margin-top: 5px;
        line-height: 25px;
        font-size: 14px;
        font-weight: 600;
        border-radius: 12px;
        text-transform: uppercase;
        color: #fff;
        background-color: #27ca79;
      }
      & + & {
        margin-top: 40px;
      }

      @media (min-width: 670px) {
        &.day {
          margin-top: 15px;
        }
      }

      @media (min-width: 670px) {
        &.documents {
          max-width: 191px;
        }
      }
      @media (min-width: 670px) {
        & + & {
          margin-top: 0;
        }
      }

      @media (min-width: 670px) {
        .documents {
          max-width: 191px;
        }
      }
      @media (min-width: 670px) {
        & + & {
          margin-top: 0;
        }
      }
    }
    span {
      display: none;
      @media (min-width: 670px) {
        display: block;
        border-radius: 100%;
        padding: 10px;
        background: #f5c15c;
        margin-top: 5px;
        text-align: center;
      }
    }
  }
`;

export const SectionPricing = styled.section`
  position: relative;
  margin: 90px 0;
  z-index: 1;
`;

export const CommonButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 670px) {
    margin-top: 32px;
  }

  a {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin: 10px 10px 10px 0;
  }

  a:not(:last-child) {
    margin-right: 12px;
  }
  &.common {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
`;
