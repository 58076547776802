import React from 'react';

import { HeaderNavItem, HeaderNavItemLink, DropdownSlot } from './style';
import Navbar from './Navbar';

const NavigationItem = ({ onMouseEnter, index, title, children }) => {

  return (
    <Navbar>
      <HeaderNavItem
        onMouseEnter={() => onMouseEnter(index)}
        onFocus={() => onMouseEnter(index)}>
        <HeaderNavItemLink>{title}</HeaderNavItemLink>
        <DropdownSlot>{children}</DropdownSlot>
      </HeaderNavItem>
    </Navbar>
  );
}

export default NavigationItem;
